import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { Coupon, NewCoupon } from 'store/types/coupon-types';
import { CouponAdminService } from 'api/coupon-admin-service';

interface CouponsState {
  loading: boolean;
  coupons: Coupon[];
}

const initialState: CouponsState = {
  loading: false,
  coupons: [],
};

export const getAllCoupons = createAsyncThunk(
  'coupons/get-all',
  async (_, { rejectWithValue }) => {
    const { error, data, isSuccess } = await CouponAdminService.getAllCoupons();

    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const addCoupon = createAsyncThunk(
  'coupon/add',
  async (coupon: NewCoupon, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await CouponAdminService.addCoupon(coupon);

    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const editCoupon = createAsyncThunk(
  'coupon/edit',
  async (coupon: Partial<Coupon>, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await CouponAdminService.editCoupon(coupon);

    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const deleteCoupon = createAsyncThunk(
  'coupon/delete',
  async (couponId: number, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await CouponAdminService.deleteCoupon(couponId);

    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCoupons.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = action.payload.data;
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addCoupon.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addCoupon.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editCoupon.fulfilled, (state, action) => {
        const editedCoupon = action.meta.arg as unknown as Coupon;
        state.coupons = state.coupons.map((coupon) =>
          coupon.id === editedCoupon.id ? { ...editedCoupon } : coupon,
        );
        state.loading = false;
      })
      .addCase(editCoupon.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        const deletedCouponId = action.meta.arg;
        state.coupons = state.coupons.filter(
          (coupon) => coupon.id !== deletedCouponId,
        );
        state.loading = false;
      })
      .addCase(deleteCoupon.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectCouponsState = (state: RootState) => state.coupons;
export default couponsSlice.reducer;
