import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAllSubscriptions } from 'store/slice/subscriptions-slice';
import { getAllBusinesses } from 'store/slice/business-slice';
import { useToaster } from 'contexts/toast-context';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { SubscriptionAndPlans } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { SubscriptionTable } from './components/subscription-table';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { CreateSubscriptionPopup } from './create-subscription-popup';
import { getSubscriptionRecords, SubscriptionRecord } from './consts';

export const SubscriptionsAndPlansPage = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const { subscriptions, loading } = useAppSelector((state) => ({
    subscriptions: state.subscriptions.subscriptions,
    loading: state.subscriptions.loading,
  }));
  const [openCreateSub, setOpenCreateSub] = useState(false);

  const handleOpenCreateSubscriptionPopup = () => setOpenCreateSub(true);
  const handleCloseCreateSubscriptionPopup = () => setOpenCreateSub(false);

  const getSubscriptions = async () => {
    const response = await dispatch(getAllSubscriptions());
    const { isSuccess, error } = response.payload as ApiResponse<
      SubscriptionAndPlans[]
    >;
    if (!isSuccess) {
      setToaster('error', error);
    }
  };

  const getBussiness = async () => {
    await dispatch(getAllBusinesses());
  };

  const subscriptionRecords: SubscriptionRecord[] = useMemo(() => {
    return getSubscriptionRecords(subscriptions);
  }, [subscriptions]);

  React.useEffect(() => {
    void getSubscriptions();
    void getBussiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Header display="flex" justifyContent="space-between">
        <h1>Subscriptions</h1>
        <ActionFab
          // sx={{ background: '#454284' }}
          aria-label="create subscription"
          onClick={handleOpenCreateSubscriptionPopup}
          disableRipple
        >
          <AddIcon sx={{ color: '#fff' }} />
        </ActionFab>
      </Header>
      <SubscriptionTable
        subscriptionRecords={subscriptionRecords}
        showFilter={true}
        showReviewBanner={true}
      />
      <CreateSubscriptionPopup
        open={openCreateSub}
        handleClose={handleCloseCreateSubscriptionPopup}
        onCreateSubscription={getSubscriptions}
      />
      <LoadingView
        open={loading}
        loadingText="Loading subscriptions..."
        zIndex="13000"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled(Box)`
  padding-top: 90px;
`;

const ActionFab = styled(Fab)`
  &.MuiButtonBase-root {
    background-color: #454284;
    color: #fff;

    &:hover {
      background-color: #2a2853;
    }
  }
`;
