import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { light: '#2eb7ff', main: '#2eb7ff', dark: '#162659' },
    secondary: { light: '#fff', main: '#162659', dark: '#2eb7ff' },
    info: { light: '#e1eaf1', main: '#a8b1b9', dark: '#2eb7ff' },
    neutral: { light: '#e1eaf1', main: '#a8b1b9', dark: '#2eb7ff' },
  },
  status: {
    danger: '#303844',
  },
});
