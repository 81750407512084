type PermissionNames =
  | 'view.admin_subscription_sync'
  | 'write.admin_subscription_sync';

export const isAdminPermitted = (
  permissionNames: PermissionNames[],
  permissionsMap: Record<string, Record<PermissionNames, number>>,
): boolean => {
  return Object.values(permissionsMap).some((usersBusinessPermissions) =>
    permissionNames.every((permission) => usersBusinessPermissions[permission]),
  );
};
