import React from 'react';
import styled from 'styled-components';
import { MenuItemAsComp, menuItem } from './HeaderWrapper';

export interface MenuItemProps {
  to: string;
  label: string;
  id: string;
}

interface MenuItemsProps {
  className: string;
  menuItems: menuItem[];
  pathName: string;
  isMobile: boolean;
  MenuItem: ({ to, label, id }: MenuItemProps) => JSX.Element;
  showLiveTourBtn?: boolean;
  startTourValue?: boolean;
  startTour?: () => void;
  toggleMenu?: () => void;
  customMenuItem?: MenuItemAsComp;
  showCustomMenu?: boolean;
}

export const MenuItems: React.FC<MenuItemsProps> = ({
  className,
  menuItems,
  pathName,
  isMobile,
  MenuItem,
  showLiveTourBtn,
  startTourValue,
  startTour,
  toggleMenu,
  customMenuItem,
  showCustomMenu,
}) => {
  return (
    <Wrapper
      className={className}
      isMobile={isMobile}
      hasCustomMenu={customMenuItem ? true : false}
    >
      {isMobile
        ? menuItems.map(({ to, label, key, id }, index) => (
            <MenuItemDiv key={key} isFirstMenuItem={index} onClick={toggleMenu}>
              <MenuItem to={to} label={label} id={id} />
            </MenuItemDiv>
          ))
        : menuItems.map(({ to, label, key, id }) => (
            <MenuItem key={key} to={to} label={label} id={id} />
          ))}
      {showCustomMenu && customMenuItem && <>{customMenuItem({})}</>}
      {showLiveTourBtn &&
        startTourValue !== undefined &&
        startTour &&
        !pathName.includes('history') &&
        (isMobile ? (
          <MenuItemDiv>
            <LiveTourButton
              mobile="yes"
              id={`${isMobile ? 'mobile-live-tour' : 'live-tour'}`}
              onClick={() => {
                startTour();
                toggleMenu && toggleMenu();
              }}
            >
              Live Tour
            </LiveTourButton>
          </MenuItemDiv>
        ) : (
          <LiveTourButton
            id={`${isMobile ? 'mobile-live-tour' : 'live-tour'}`}
            onClick={startTour}
          >
            Live Tour
          </LiveTourButton>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.nav<{ isMobile: boolean; hasCustomMenu: boolean }>`
  ${(p) =>
    p.isMobile
      ? `width: 90%;
    margin: auto;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
  
    &::after {
      content: "";
      border-bottom: 1px solid #e4e7eb;
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
    }`
      : ''};

  ${(p) =>
    p.isMobile && p.hasCustomMenu
      ? `
      margin-bottom: 30px;
    `
      : ''}

  .link-inactive {
    color: rgba(255, 255, 255, 0.65);
  }

  .link-active {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    padding: 10px;
  }

  .link {
    text-decoration: none;
    display: inline-block;
    margin-right: 30px;
    font-size: 16px;
  }

  .mobile-live-tour {
    width: fit-content;
    margin-left: -3%;
  }
`;

const MenuItemDiv = styled.div<{ isFirstMenuItem?: number }>`
  margin-top: ${(p) => (p.isFirstMenuItem === 0 ? '5%' : '3%')};
  margin-bottom: 3%;
`;

export const LiveTourButton = styled.button<{ mobile?: string }>`
  border: none;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.65);
  background-color: transparent;
  border-radius: 8px;
  padding: 10px;
  padding-left: ${(p) => (p.mobile ? '0' : '')};
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;
