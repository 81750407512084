import axios from 'axios';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { BASE_API_URL, COGNITO_CLIENT_ID, IS_ADMIN } from 'utils/constants';
import { whiteList } from 'api/firebase/whitelist';
import { VerifyAdmin } from 'contexts/admin-context';

export interface LoginWithEmailAndPassword {
  email: string;
  password: string;
}

let interceptors = {
  requestInterceptor: null,
  responseInterceptor: null,
  isIntercepting: false,
};

export function interceptRequestsAndResponses() {
  if (!interceptors.isIntercepting) {
    // @ts-ignore
    interceptors.requestInterceptor = interceptors.isIntercepting = true;
  }

  axios.interceptors.request.use(
    async (config) => {
      const token = getCurrentUserToken();
      const currentHost = config.url || '';

      if (
        config.method !== 'OPTIONS' &&
        !currentHost.includes('s3.amazonaws.com') &&
        !currentHost.includes('graph.facebook.com') &&
        !currentHost.includes('connect.facebook.net') &&
        !currentHost.includes('/graphql')
      ) {
        // @ts-ignore
        config.headers = {
          Authorization: `Bearer ${token}`,
          'equally-auth-provider': 'cognito',
          'equally-client-id': COGNITO_CLIENT_ID,
        };
        // @ts-ignore
        config.requestStartTime = Date.now();
        Cookies.set('accessToken', token);
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

export function getCurrentUserToken() {
  if (localStorage.getItem('accessToken')) {
    return localStorage.getItem('accessToken') as string;
  }

  return '';
}

export async function verifyUserIsAdmin(): Promise<VerifyAdmin> {
  const accessToken = getCurrentUserToken();
  const url = new URL(IS_ADMIN, BASE_API_URL);
  try {
    const { data } = await axios.get(url.toString(), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = get(data, 'message');

    if (
      JSON.parse(atob(accessToken.split('.')[1])).email &&
      whiteList.includes(JSON.parse(atob(accessToken.split('.')[1])).email)
    ) {
      result.accessLevel = 'admin';
    } else {
      result.accessLevel = 'support';
    }

    return {
      type: 'success',
      payload: result,
    };
  } catch (error) {
    return {
      type: 'error',
      payload: null,
      message: 'User is not an admin',
    };
  }
}
