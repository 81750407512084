import React, { useCallback, useState } from 'react';
import { TableWrapper } from 'components/css-components';
import {
  Chip,
  FormControl,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { TABLE_PAGE_LIMIT } from 'utils/constants';
import {
  BusinessAndOwner,
  businessConfig,
  businessFields,
  formatDate,
} from './consts';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { CustomModal } from '../../components/modal/custom-modal';
import { BusinessesTab } from './businesses-tab';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';

interface BusinessesProps {
  businessesAndOwner: BusinessAndOwner[];
}

export const BusinessesTable = (props: BusinessesProps) => {
  const { businessesAndOwner } = props;

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({ filterOptions: businessFields, data: businessesAndOwner });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    searchTerm: searchValue,
    currentData,
    handlePageChange,
    handleSearch,
    handleSort,
  } = usePagination(visibleData, TABLE_PAGE_LIMIT, 'businessName');
  const [open, setOpen] = useState(false);

  const [selectedBusiness, setSelectedBusiness] =
    useState<BusinessAndOwner | null>(null);

  const handleOpenBusinessPopup = async (currentBusiness: BusinessAndOwner) => {
    setSelectedBusiness(currentBusiness);
    setOpen(true);
  };

  const getBusinessWithOwner = useCallback((data: BusinessAndOwner) => {
    setSelectedBusiness(data);
  }, []);

  const handleCloseBusinessPopup = () => {
    setSelectedBusiness(null);
    setOpen(false);
  };

  const handleOwnerChipClick = () => {};

  const getModalTitle = (businessWithOwner: BusinessAndOwner | null) => {
    if (!businessWithOwner) {
      return <></>;
    }

    const { businessName, slug, ownerName, ownerEmail } = businessWithOwner;

    return (
      <>
        <CustomTypography marginBottom="20px">
          <CustomTypography mb="5px" fontSize="26px">
            Business Details
          </CustomTypography>
          {businessName}, {slug}
        </CustomTypography>

        <OwnerContainer>
          <InputContainer>
            <Label htmlFor="owner">Owner</Label>
            <CustomOwnerChip label={ownerName} onClick={handleOwnerChipClick} />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="owner-email">Email</Label>
            <CustomOwnerChip
              label={ownerEmail}
              onClick={handleOwnerChipClick}
            />
          </InputContainer>
        </OwnerContainer>
      </>
    );
  };

  return (
    <Wrapper>
      <BusinessTableHeader>
        <MultiFilterSelect
          filterValueOptions={filterValueOptions}
          handleFilterValueChange={handleFilterValueChange}
          handleFilterSecondValueChange={handleFilterSecondValueChange}
          handleFilterOperatorChange={handleFilterOperatorChange}
          searchTerm={searchTerm}
          addFilter={addFilter}
          isFilterFulfilled={isFilterFulfilled}
          setSearchTerm={setSearchTerm}
          handleOptionClick={handleOptionClick}
          handleSearchInputChange={handleSearchInputChange}
          options={filteredOptions}
          currentFilter={newFilter}
        />
        <FormControl>
          <BusinessSearchBar
            type="text"
            value={searchValue}
            onChange={(
              evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => handleSearch(evt.target.value)}
            id="search-input"
            placeholder="Search"
            variant="outlined"
          />
        </FormControl>
      </BusinessTableHeader>
      {Object.values(filters).length > 0 && (
        <SelectedFilterWrapper>
          {Object.values(filters).map(
            ({
              key,
              displayName,
              filterType,
              filterCondition,
              value,
              value2,
            }) => (
              <SelectedFilterTag key={key}>
                <FilterTag>
                  <TagLabel>{displayName}:</TagLabel>
                  <TagValue>
                    {filterType === 'date' ? formatDate(value) : value}
                  </TagValue>
                  {value2 && (
                    <TagValue style={{ marginLeft: '4px' }}>
                      &nbsp;and&nbsp;
                      {filterType === 'date'
                        ? formatDate(Number(value2))
                        : value2}
                    </TagValue>
                  )}
                </FilterTag>
                <FilterTag>
                  <TagLabel>Operator:</TagLabel>
                  <TagValue>
                    {filterCondition && filterCondition.replaceAll('_', ' ')}
                  </TagValue>
                </FilterTag>
                <RemoveTag onClick={() => removeFilterType(key)} />
              </SelectedFilterTag>
            ),
          )}
        </SelectedFilterWrapper>
      )}
      <TableWrapper>
        <TableContainer>
          <MUITable
            className="table"
            sx={{ minWidth: 650 }}
            aria-label="businesses table"
          >
            <TableHead>
              <TableRow>
                {businessConfig.map((business) => (
                  <TableCell key={business.key}>
                    {business.displayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData.map((currentBusiness, idx) => {
                  return (
                    <TableRow
                      key={idx}
                      sx={{
                        height: 80,
                        '&:last-child td, &:last-child th': {
                          border: '0',
                        },
                      }}
                    >
                      {businessConfig.map((business) => {
                        if (business.isLink) {
                          return (
                            <TableCell key={business.key} scope="row">
                              <Link
                                to={``}
                                onClick={() =>
                                  handleOpenBusinessPopup(currentBusiness)
                                }
                              >
                                {business.format(currentBusiness)}
                              </Link>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={business.key} scope="row">
                            {business.format(currentBusiness)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="no-data">
                    No Businesses Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MUITable>
        </TableContainer>
        {currentData.length > 0 && (
          <Pagination
            pageCount={pageCount}
            handlePageChange={handlePageChange}
          />
        )}

        <CustomModal
          open={open}
          handleClosePopup={handleCloseBusinessPopup}
          ariaDescribedBy="business modal"
          modalTitle={selectedBusiness ? getModalTitle(selectedBusiness) : null}
        >
          {selectedBusiness ? (
            <BusinessesTab
              currentBusinessId={selectedBusiness.id}
              getBusinessWithOwner={getBusinessWithOwner}
            />
          ) : (
            <></>
          )}
        </CustomModal>
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  overflow-y: auto;
  position: relative;
  margin-bottom: 50px;
`;

const CustomTypography = styled(Typography)``;

const OwnerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const CustomOwnerChip = styled(Chip)`
  && {
    background-color: #454284;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
  }

  &&:hover {
    color: #fff;
    background-color: #454284;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
  margin-right: 25px;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: #020401;
  margin-bottom: 10px;
`;

const BusinessTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 20px;
`;

const BusinessSearchBar = MuiStyled(TextField)({
  '.MuiOutlinedInput-input': {
    height: 10,
  },
});

const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid rgb(228, 231, 235);
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
`;

const SelectedFilterTag = styled.p`
  border-radius: 16px;
  background-color: rgb(245, 247, 250);
  position: relative;
  margin: 10px 10px 10px 0;
  padding: 7px 28px 7px 12px;
  font-size: 12px;
`;

const FilterTag = styled.span`
  margin: 0;
  display: block;
`;

const TagLabel = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const TagValue = styled.span``;

const RemoveTag = styled(Close)`
  color: red;
  position: absolute;
  font-size: 15px !important;
  top: 2px;
  right: 10px;
  cursor: pointer;
`;
