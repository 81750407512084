import { AdminWidgetConfigType } from '../utils';

type AdminForm = {
  [key in AdminWidgetConfigType]: {
    [key: string]: string | undefined;
  };
};

export const ADMIN_CONFIG_FORMS_INITIAL_VALUES: AdminForm = {
  tabIndex: {
    index: '0',
    selector: '',
  },
  elementsRelationships: {
    selector1: '',
    selector2: '',
    relation: '',
  },
  dynamicElements: {
    selector: '',
    politeness: '',
    role: '',
  },
  metadata: {
    index: '',
  },
  menuSubmenu: {
    index: '',
  },
  moreRoles: {
    role: '',
    selector: '',
  },
  landmarks: {
    selector: '',
    role: '',
  },
  customAttributes: {
    selector: '',
    attribute: '',
    value: '',
  },
};
