import React from 'react';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MUITable from '@mui/material/Table';
import { PlanWithPlanTemplate } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { planConfig } from './consts';

interface SubscriptionPlansProps {
  plans: PlanWithPlanTemplate[];
}

export const SubscriptionAndPlansPopup = ({
  plans,
}: SubscriptionPlansProps) => {
  return (
    <>
      <Typography variant="h5">Plans</Typography>
      <MUITable
        className="table"
        sx={{ minWidth: 650 }}
        aria-label="Plans table"
      >
        <TableHead>
          <TableRow>
            {planConfig.map((subscription) => (
              <TableCell key={subscription.key}>
                {subscription.displayName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.length > 0 ? (
            plans.map((currentRecord, idx) => {
              return (
                <TableRow
                  key={idx}
                  sx={{
                    height: 80,
                    '&:last-child td, &:last-child th': {
                      border: '0',
                    },
                  }}
                >
                  {planConfig.map((subscription) => {
                    return (
                      <TableCell key={subscription.key} scope="row">
                        {subscription.format(currentRecord)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="no-data">
                No Plans Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </>
  );
};
