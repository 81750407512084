import axios from 'axios';
import { BASE_API_URL } from 'utils/constants';
import { interceptRequestsAndResponses } from './auth';
import { Domain } from 'store/slice/domains-slice';

export interface DomainResponseType {
  message: string;
  type: 'success' | 'error';
  data: Domain[];
}

export class DomainUtil {
  static async getAllDomains(): Promise<DomainResponseType> {
    try {
      interceptRequestsAndResponses();
      const response = await axios.get(`${BASE_API_URL}domains/all`);
      if (response.status === 200) {
        return {
          type: 'success',
          message: '',
          data: response.data.message.data,
        };
      } else {
        return {
          type: 'error',
          message: 'An unknown error occurred. Please try again later.',
          data: [],
        };
      }
    } catch (error: any) {
      let errorMessage = 'An unknown error occurred. Please try again later.';

      return {
        type: 'error',
        message: errorMessage,
        data: [],
      };
    }
  }
}
