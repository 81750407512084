import React, { useState } from 'react';
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import { MenuButton } from '../button/MenuButton';
import { Header } from './Header';
import { MenuItemProps, MenuItems } from './MenuItems';
import { MenuItemAsComp, menuItem } from './HeaderWrapper';
import { MenuOption } from './UserInfo';

interface HeaderMobileProps {
  menuItems: menuItem[];
  pathName: string;
  MenuItem: ({ to, label, id }: MenuItemProps) => JSX.Element;
  showLiveTourBtn?: boolean;
  startTourValue?: boolean;
  startTour?: () => void;
  customMenuItem?: MenuItemAsComp;
  showCustomMenu?: boolean;
  menuOptions?: MenuOption[];
}

export const HeaderMobile = ({
  menuItems,
  pathName,
  MenuItem,
  showLiveTourBtn,
  startTour,
  startTourValue,
  customMenuItem,
  showCustomMenu,
  menuOptions,
}: HeaderMobileProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu(!openMenu);

  return (
    <Wrapper>
      <Header
        HeaderElement={
          <MenuButttonDiv>
            <MenuButton open={openMenu} onClick={toggleMenu} />
          </MenuButttonDiv>
        }
        isExtension={false}
      />
      <Collapse in={openMenu} timeout="auto">
        <MenuItems
          menuItems={menuItems}
          className=""
          pathName={pathName}
          isMobile
          MenuItem={MenuItem}
          showLiveTourBtn={showLiveTourBtn}
          startTour={startTour}
          startTourValue={startTourValue}
          toggleMenu={toggleMenu}
          customMenuItem={customMenuItem}
          showCustomMenu={showCustomMenu}
        />
        {menuOptions && (
          <MenuOptionDiv>
            {menuOptions.map(({ label, handleClickAction }) => (
              <MenuOptionItem key={label} onClick={handleClickAction}>
                {label}
              </MenuOptionItem>
            ))}
          </MenuOptionDiv>
        )}
      </Collapse>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #000a14;
`;

const MenuButttonDiv = styled.div`
  height: 100%;
  display: flex;

  button {
    min-width: fit-content;
  }
`;

const MenuOptionDiv = styled.div<{ isFirstMenuItem?: number }>`
  width: 90%;
  margin: auto;
  margin-bottom: 3%;
`;

const MenuOptionItem = styled.p`
  margin: 10px 0;
  color: #ffffff;
  cursor: pointer;
`;
