import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import axios from 'axios';
import { UpdateDomainDetailPayload } from 'store/slice/domain-detail-slice';
import { BASE_API_URL } from 'utils/constants';

class DomainDetailService {
  static async getDomainDetail(domainId: string): Promise<ApiResponse<any>> {
    try {
      const { data } = await axios.get(
        BASE_API_URL + `domain-details/${domainId}`,
      );

      return {
        data: data.message,
        error: null,
        isSuccess: true,
      };
    } catch (error: any) {
      return {
        data: null,
        error: error?.response?.data?.message ?? 'Error getting domain detail.',
        isSuccess: false,
      };
    }
  }

  static async updateDomainDetails({
    domainData,
    domainId,
  }: UpdateDomainDetailPayload): Promise<ApiResponse<any>> {
    try {
      const { data } = await axios.post(
        BASE_API_URL + `domain-details/${domainId}`,
        domainData,
      );

      return {
        data: data.message,
        error: null,
        isSuccess: true,
      };
    } catch (error) {
      return {
        data: null,
        error: 'Error updating domain detail',
        isSuccess: false,
      };
    }
  }
}

export { DomainDetailService };
