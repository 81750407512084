import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import moment from 'moment';
import { BusinessWithOwner } from 'types';

export interface BusinessAndOwner {
  id: number;
  userId: number;
  businessName: string;
  slug: string;
  size: number;
  isPartner: boolean;
  isSpecialDomain: boolean;
  isTestAccount: boolean;
  ownerName: string;
  ownerEmail: string;
}

interface BusinessConfig {
  displayName: string;
  key: string;
  isLink?: boolean;
  format: (business: BusinessAndOwner) => string | number;
}

export const businessConfig: BusinessConfig[] = [
  {
    displayName: 'Business Name',
    key: 'business_name',
    isLink: true,
    format: (business) => business.businessName,
  },
  {
    displayName: 'Size',
    key: 'size',
    format: (business) => business.size,
  },
  {
    displayName: 'Slug',
    key: 'slug',
    format: (business) => business.slug,
  },
  {
    displayName: 'Business Owner',
    key: 'owner_name',
    format: (business) => business.ownerName,
  },
  {
    displayName: 'Owner Email',
    key: 'email',
    format: (business) => business.ownerEmail,
  },
  {
    displayName: 'Has Partner',
    key: 'is_partner',
    format: (business) => (business.isPartner ? 'Yes' : 'No'),
  },
  {
    displayName: 'Special Domain',
    key: 'is_special_domain',
    format: (business) => (business.isSpecialDomain ? 'Yes' : 'No'),
  },
  {
    displayName: 'Test Account',
    key: 'is_test_account',
    format: (business) => (business.isTestAccount ? 'Yes' : 'No'),
  },
];

export const businessFields: Record<string, FilterField> = {
  business_name: {
    key: 'businessName',
    displayName: 'Business Name',
    filterType: 'string',
  },
  size: {
    key: 'size',
    displayName: 'Size',
    filterType: 'number',
  },
  slug: {
    key: 'slug',
    displayName: 'Slug',
    filterType: 'string',
  },
  is_partner: {
    key: 'isPartner',
    displayName: 'Has Partner',
    filterType: 'boolean',
  },
  is_special_domain: {
    key: 'isSpecialDomain',
    displayName: 'Special Domain',
    filterType: 'boolean',
  },
  is_test_account: {
    key: 'isTestAccount',
    displayName: 'Test Account',
    filterType: 'boolean',
  },
  owner_name: {
    key: 'ownerName',
    displayName: 'Business Owner',
    filterType: 'string',
  },
  owner_email: {
    key: 'ownerEmail',
    displayName: 'Owner Email',
    filterType: 'string',
  },
};

export const getMergedBusinessAndOwner = (
  businesses: BusinessWithOwner[],
): BusinessAndOwner[] => {
  return businesses.map(({ business, owner }) => ({
    id: business.id,
    userId: business.user_id,
    businessName: business.name,
    slug: business.slug,
    size: business.size,
    isPartner: business.is_partner,
    isSpecialDomain: business.is_special_domain,
    isTestAccount: business.is_test_account,
    ownerName: owner.name,
    ownerEmail: owner.email,
  }));
};

export const formatDate = (timeStamp: number) => {
  return moment.unix(timeStamp).format('DD/MM/YYYY');
};
