import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { BusinessesTable } from './businesses-table';
import { BusinessesTableData } from './consts';
import { getMergeBusinessUserRolesAndSubscriptions } from 'containers/businesses-redirect/utils/business';

export const BusinessesPage = () => {
  const { users, userRolesAndRolesIdToRoleMap, subscriptions, businesses } =
    useAppSelector((state) => ({
      users: state.users.users,
      userRolesAndRolesIdToRoleMap: state.users.userRolesAndRolesIdToRoleMap,
      subscriptions: state.subscriptions.subscriptions,
      businesses: state.businesses.businesses,
    }));
  const [businessesTableData, setBusinessesTableData] = useState<
    BusinessesTableData[]
  >([]);

  useEffect(() => {
    if (
      businesses.length > 0 &&
      subscriptions.length > 0 &&
      userRolesAndRolesIdToRoleMap.user_roles.length > 0 &&
      users.length > 0
    ) {
      const mergedBusinessesTableData: BusinessesTableData[] =
        getMergeBusinessUserRolesAndSubscriptions(
          businesses,
          subscriptions,
          userRolesAndRolesIdToRoleMap,
          users,
        );
      setBusinessesTableData(mergedBusinessesTableData);
    }
  }, [businesses, userRolesAndRolesIdToRoleMap, subscriptions, users]);

  return (
    <div>
      <h1>Businesses Page</h1>
      <BusinessesTable businessesTableData={businessesTableData} />
    </div>
  );
};
