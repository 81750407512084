import React from 'react';
import { ScannedPagesSummary } from '../../components/latest-scans-table/scanned-pages-summary';
import {
  LatestDomainScansData,
  LatestDomainScansFieldMap,
} from '../../components/latest-scans-table/websites-table';
import styled from 'styled-components';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import { ScanIssuesCountSummary } from '../../components/latest-scans-table/scan-issues-count-summary';
import { LatestScanTotals } from '@equally-ai-front/common/src/types/scans';
import { Cancel, CheckCircleRounded } from '@mui/icons-material';
import { AccessibilityScoreIcon } from './accessibility-score-icon';
import moment from 'moment';

export interface LatestDomainDetailsScanData {
  pageName: string;
  latestScanTotals: LatestScanTotals;
  accessibilityScore: number;
}

export const latestDomainScansResultMap: LatestDomainScansFieldMap[] = [
  {
    key: 'domainName',
    format: (scanData: LatestDomainScansData) => (
      <DomainName>{scanData.domainName}</DomainName>
    ),
    align: 'left',
  },
  {
    key: 'scannedPages',
    format: (scanData: LatestDomainScansData) => (
      <ScannedPagesSummary
        scansSummary={scanData.scannedPages}
        domainName={scanData.domainName}
      />
    ),
    align: 'left',
  },
  {
    key: 'createdAt',
    format: (scanData: LatestDomainScansData) => (
      <span>{moment(scanData.createdAt).format('DD MMM YYYY')}</span>
    ),
    align: 'left',
  },
  {
    key: 'paid',
    format: (scanData: LatestDomainScansData) => (
      <span>
        {scanData.paid ? (
          <CheckCircleRounded sx={{ color: 'success.main' }} />
        ) : (
          <Cancel sx={{ color: 'error.main' }} />
        )}
      </span>
    ),
    align: 'left',
  },
  {
    key: 'totalErrors',
    format: (scanData: LatestDomainScansData) => (
      <ScanIssuesCountSummary
        scanTotals={scanData.latestScanTotals}
        issueTypeKey="totalErrors"
      />
    ),
    align: 'left',
  },
  {
    key: 'totalWarnings',
    format: (scanData: LatestDomainScansData) => (
      <ScanIssuesCountSummary
        scanTotals={scanData.latestScanTotals}
        issueTypeKey="totalWarnings"
      />
    ),
    align: 'left',
  },
  {
    key: 'totalNotices',
    format: (scanData: LatestDomainScansData) => (
      <ScanIssuesCountSummary
        scanTotals={scanData.latestScanTotals}
        issueTypeKey="totalNotices"
      />
    ),
    align: 'left',
  },
  {
    key: 'accessibilityScore',
    format: (scanData: LatestDomainScansData) => (
      <AccessibilityScoreIcon score={scanData.accessibilityScore} />
    ),
    align: 'left',
  },
];

export const latestDomainScansDetailsMap: LatestDomainScansFieldMap[] = [
  {
    key: 'pageName',
    format: (scanData: LatestDomainDetailsScanData) => (
      <DomainName>{scanData.pageName}</DomainName>
    ),
    align: 'left',
  },
  {
    key: 'errors',
    format: (scanData: LatestDomainDetailsScanData) => (
      <ScanIssuesCountSummary
        scanTotals={scanData.latestScanTotals}
        issueTypeKey="totalErrors"
      />
    ),
    align: 'left',
  },
  {
    key: 'warnings',
    format: (scanData: LatestDomainDetailsScanData) => (
      <ScanIssuesCountSummary
        scanTotals={scanData.latestScanTotals}
        issueTypeKey="totalWarnings"
      />
    ),
    align: 'left',
  },
  {
    key: 'notices',
    format: (scanData: LatestDomainDetailsScanData) => (
      <ScanIssuesCountSummary
        scanTotals={scanData.latestScanTotals}
        issueTypeKey="totalNotices"
      />
    ),
    align: 'left',
  },
  {
    key: 'accessibilityScore',
    format: (scanData: LatestDomainDetailsScanData) => (
      <AccessibilityScoreIcon score={scanData.accessibilityScore} />
    ),
    align: 'left',
  },
];

const domainScansFields: Record<string, FilterField> = {
  totalErrors: {
    key: 'totalErrors',
    displayName: 'Critical',
    filterType: 'number',
  },
  totalWarnings: {
    key: 'totalWarnings',
    displayName: 'Warnings',
    filterType: 'number',
  },
  totalNotices: {
    key: 'totalNotices',
    displayName: 'Notices',
    filterType: 'number',
  },
  accessibilityScore: {
    key: 'accessibilityScore',
    displayName: 'Accessibility Score',
    filterType: 'number',
  },
};

export const latestDomainScansFields: Record<string, FilterField> = {
  domainName: {
    key: 'domainName',
    displayName: 'Domain Name',
    filterType: 'string',
  },
  scannedPagesCount: {
    key: 'scannedPagesCount',
    displayName: 'Scanned Pages',
    filterType: 'number',
  },
  createdAt: {
    key: 'createdAt',
    displayName: 'Created At',
    filterType: 'date',
  },
  paid: {
    key: 'paid',
    displayName: 'Paid',
    filterType: 'boolean',
  },
  ...domainScansFields,
};

export const latestDomainScansDetailFields: Record<string, FilterField> = {
  domainName: {
    key: 'pageName',
    displayName: 'Page',
    filterType: 'string',
  },
  ...domainScansFields,
};

const DomainName = styled.div`
  text-transform: initial;
`;

const IssuesCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IssuesCount = styled.p`
  margin: 0;
`;
