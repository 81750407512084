import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomBusinessTabPanel } from './custom-business-tabpanel';
import styled from 'styled-components';
import { getBusinessById } from '../../store/slice/business-slice';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { useAppDispatch } from 'store/hooks';
import { BusinessAndOwner, getMergedBusinessAndOwner } from './consts';
import { BusinessWithOwner } from 'types';

interface CustomTabProps {
  label: string;
  id: string;
  children?: React.ReactNode;
}

interface BusinessesTabProp {
  currentBusinessId: number;
  getBusinessWithOwner: (data: BusinessAndOwner) => void;
}

export const BusinessesTab = (prop: BusinessesTabProp) => {
  const dispatch = useAppDispatch();
  const { currentBusinessId, getBusinessWithOwner } = prop;

  const customTabProps: CustomTabProps[] = [
    {
      label: 'User roles',
      id: 'user-roles',
    },
    {
      label: 'Domains',
      id: 'domains',
    },
  ];

  const [businessTabValue, setBusinessTabValue] = useState(0);

  const handleBusinessTabChange = (event: SyntheticEvent, newValue: number) => {
    setBusinessTabValue(newValue);
  };

  const getOnwerBusinesses = async (currentBusinessId: number) => {
    const response = await dispatch(getBusinessById(currentBusinessId));
    const { isSuccess, error, data } =
      response.payload as ApiResponse<BusinessWithOwner>;

    if (error || !isSuccess || !data) {
      return;
    }

    const [businessAndOwner] = getMergedBusinessAndOwner([data]);

    if (businessAndOwner) {
      getBusinessWithOwner(businessAndOwner);
    }
  };

  useEffect(() => {
    if (!currentBusinessId) {
      return;
    }

    void getOnwerBusinesses(currentBusinessId);
  }, [currentBusinessId]);

  return (
    <Box sx={{ width: '100%' }}>
      <CustomBusinessTabs
        value={businessTabValue}
        onChange={handleBusinessTabChange}
        aria-label="business tabs"
      >
        {customTabProps.map((tab: CustomTabProps, index: number) => (
          <Tab value={index} label={tab.label} id={tab.id} />
        ))}
      </CustomBusinessTabs>
      {customTabProps.map((tab: CustomTabProps, index: number) => {
        return (
          <CustomBusinessTabPanel value={businessTabValue} index={index}>
            <p>{tab.label}</p>
          </CustomBusinessTabPanel>
        );
      })}
    </Box>
  );
};

const CustomBusinessTabs = styled(Tabs)`
  & .MuiTab-root.Mui-selected {
    color: #454284;
  }
  & .MuiTabs-indicator {
    background-color: #454284;
  }
`;
