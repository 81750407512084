import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import axios from 'axios';
import { UpdateDomainDetailPayload } from 'store/slice/domain-detail-slice';
import { BASE_API_URL } from 'utils/constants';
import graphqlClient from '@equally-ai-front/common/src/utils/graphql.util';

class DomainDetailService {
  static async getDomainDetail(domainId: string): Promise<ApiResponse<any>> {
    try {
      const { data } = await axios.get(
        BASE_API_URL + `domain-details/${domainId}`,
      );

      return {
        data: data.message,
        error: null,
        isSuccess: true,
      };
    } catch (error: any) {
      return {
        data: null,
        error: error?.response?.data?.message ?? 'Error getting domain detail.',
        isSuccess: false,
      };
    }
  }

  static async updateDomainDetails({
    domainData,
    domainId,
  }: UpdateDomainDetailPayload): Promise<ApiResponse<any>> {
    try {
      const { data } = await axios.post(
        BASE_API_URL + `domain-details/${domainId}`,
        domainData,
      );

      return {
        data: data.message,
        error: null,
        isSuccess: true,
      };
    } catch (error) {
      return {
        data: null,
        error: 'Error updating domain detail',
        isSuccess: false,
      };
    }
  }

  static async updateDomainRemediationStatus(
    domainId: number,
    allowDomainRemediation: boolean,
  ): Promise<ApiResponse<any>> {
    const query = `mutation allowDomainRemediation($payload: Request!) {
        allowDomainRemediation(payload: $payload) {
          data
          statusCode
          message
        }
      }`;

    const result = await graphqlClient.execute({
      functionName: 'allowDomainRemediation',
      query,
      payload: {
        data: JSON.stringify({
          domainId,
          allowDomainRemediation,
        }),
      },
    });

    const { data, isSuccess, message } = result;
    return {
      data,
      isSuccess,
      error: message,
    };
  }

  static async getDomainRemediationStatus(
    domainId: number,
  ): Promise<ApiResponse<any>> {
    const query = `query getDomainRemediationStatus($payload: Request!) {
        getDomainRemediationStatus(payload: $payload) {
          data
          statusCode
          message
        }
      }`;

    const result = await graphqlClient.execute({
      functionName: 'getDomainRemediationStatus',
      query,
      payload: {
        data: JSON.stringify({
          domainId,
        }),
      },
    });

    const { data, isSuccess, message } = result;
    return {
      data,
      isSuccess,
      error: message,
    };
  }
}

export { DomainDetailService };
