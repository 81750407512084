import React from 'react';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { getTabIndexColumns } from './utils';
import {
  TabIndexConfig,
  WidgetConfigurations,
} from '@equally-ai-front/common/src/helpers';
import { WidgetConfigContainer } from './widget-config-container';

export const ImprovedNavigation = () => {
  const { domainId } = useParams();
  const { data } = useAppSelector((state) => state.adminConfig);

  const tabIndexElements = React.useMemo(
    () => data.tabIndex as TabIndexConfig[],
    [data.tabIndex],
  );

  return (
    <WidgetConfigContainer
      domainId={domainId ?? ''}
      title="Tab Index"
      elements={tabIndexElements}
      configType={WidgetConfigurations.IMPROVED_NAVIGATION}
      getColumns={getTabIndexColumns}
    />
  );
};
