import React from 'react';

import { Typography } from '@mui/material';
import { PlanWithPlanTemplate } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { PlansTable } from './components/plans-table';

interface SubscriptionPlansProps {
  plans: PlanWithPlanTemplate[];
}

export const SubscriptionAndPlansPopup = ({
  plans,
}: SubscriptionPlansProps) => {
  return (
    <>
      <Typography variant="h5">Plans</Typography>
      <PlansTable plans={plans} />
    </>
  );
};
