import { BusinessAndOwner } from 'containers/businesses-page/consts';
import { Domain } from 'store/slice/domains-slice';
import { BusinessWithDomains } from '../consts';
import moment from 'moment';

export const groupDomainsByBusiness = (
  domains: Domain[],
  businessesAndOwner: BusinessAndOwner[],
): BusinessWithDomains[] => {
  return businessesAndOwner.map((business) => {
    const businessDomains = domains.filter(
      (domain) => domain.businessId === business.id,
    );
    const subscriptionsCount = businessDomains.filter(
      ({ txID }) => txID,
    ).length;

    let lastDomainCreatedAt = 0;
    for (const businessDomain of businessDomains) {
      if (businessDomain.registrationDate > lastDomainCreatedAt) {
        lastDomainCreatedAt = businessDomain.registrationDate;
      }
    }

    return {
      ...business,
      domains: businessDomains,
      domainsCount: businessDomains.length,
      subscriptionsCount,
      lastDomainCreatedAt:
        businessDomains.length > 0
          ? moment.unix(lastDomainCreatedAt).format('DD/MM/YYYY')
          : 'N/A',
    };
  });
};
