import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { CustomModal } from 'components/modal/custom-modal';
import styled from 'styled-components';
import { User, BusinessRole, addBusinessRoles } from 'store/slice/users-slice';
import { useAppDispatch } from 'store/hooks';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { useToaster } from 'contexts/toast-context';
import { NewBusinessRole, UserBusinessRole } from '../../types';

interface BusinessRoleModalProps {
  open: boolean;
  selectedUser: User | null;
  userBusinessRole: UserBusinessRole;
  handleCloseBusinessRolePopup: () => void;
  getUsersRoles: () => void;
}

export const BusinessRoleModalForm = (props: BusinessRoleModalProps) => {
  const {
    open,
    selectedUser,
    userBusinessRole,
    getUsersRoles,
    handleCloseBusinessRolePopup,
  } = props;
  const { roles, allBusinesses } = userBusinessRole;
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const [newBusinessRole, setNewBusinessRole] = useState<NewBusinessRole>({
    business_id: undefined,
    role_id: undefined,
    user_id: undefined,
  });

  useEffect(() => {
    if (!selectedUser) {
      return;
    }

    setNewBusinessRole({
      ...newBusinessRole,
      user_id: selectedUser.userID,
    });
  }, [selectedUser]);

  const getModalTitle = () => {
    return (
      <CustomTypography style={{ marginBottom: '20px', fontSize: '20px' }}>
        Add new business role
      </CustomTypography>
    );
  };

  const handleSubmitButton = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const { business_id, role_id, user_id } = newBusinessRole;

    if (!business_id && !role_id && !user_id) {
      return;
    }

    const payload = {
      business_id: business_id,
      role_id: role_id,
      user_id: user_id,
    } as BusinessRole;

    const response = await dispatch(addBusinessRoles(payload));
    const { isSuccess, error } = response.payload as ApiResponse<BusinessRole>;

    if (!isSuccess) {
      setToaster('error', error);
    }

    await getUsersRoles();

    setToaster('success', 'Business role created successfully!');
    handleCloseBusinessRolePopup();
  };

  const handleValueChange = (evt: any) => {
    const { name, value } = evt.target;

    if (!name || !value) {
      return;
    }

    setNewBusinessRole({
      ...newBusinessRole,
      [name]: parseInt(value, 10),
    });
  };

  return (
    <CustomModal
      open={open}
      handleClosePopup={handleCloseBusinessRolePopup}
      modalTitle={selectedUser ? getModalTitle() : null}
      ariaDescribedBy="business role modal"
    >
      <form onSubmit={handleSubmitButton}>
        <InputContainer>
          <FormControl fullWidth>
            <InputLabel id="business_id">Business</InputLabel>
            <Select
              labelId="business_id"
              id="business-select"
              value={newBusinessRole.business_id || ''}
              name="business_id"
              label="Business"
              onChange={handleValueChange}
              required
            >
              {Object.keys(allBusinesses).length > 0 &&
                Object.entries(allBusinesses).map(([id, name]) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContainer>
        <InputContainer>
          <FormControl fullWidth>
            <InputLabel id="role_id">Role</InputLabel>
            <Select
              labelId="role_id"
              id="role-select"
              value={newBusinessRole.role_id || ''}
              name="role_id"
              label="Role"
              onChange={handleValueChange}
              required
            >
              {Object.keys(roles).length > 0 &&
                Object.keys(roles).map((id: string) => (
                  <MenuItem value={id} key={id}>
                    {roles[parseInt(id, 10)]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContainer>
        <SubmitBtn type="submit">Submit</SubmitBtn>
      </form>
    </CustomModal>
  );
};

const CustomTypography = styled(Typography)``;

const SubmitBtn = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #000a14;
  color: #ffffff;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
`;
