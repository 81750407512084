import {
  dummyHrefLink,
  dummyTitleValue,
  dummyContentText,
  dummyImgSrc,
  dummyImgAlt,
  dummyHrefContent,
  dummyId,
  targetBlankFix,
  dummyAriaLabel,
  dummyInputValue,
  dummyForValue,
  dummyGeneralValue,
  dummyAutoCompleteValue,
  dummyAutoCompletePassword,
  dummyAutoCompleteUrl,
  dummyAutoCompleteTelephone,
  dummyAutoCompleteAmount,
  dummyAutoCompleteMonth,
  dummyAutoCompleteDate,
  dummyHeaderAttributeValue,
  dummyTableSummaryValue,
  dummyMapNameId,
  dummyMapCoords,
  dummyMapHref,
  dummyMapAlt,
  dummyAppletCode,
  dummyWidth,
  dummyHeight,
  dummyAppletAlt,
  dummyAppletContent,
  dummyAppletAltFix,
  dummyHeadingContent,
  dummyTitleValue2,
  dummyGermanContent,
} from './constants';
import { SolutionsTemplate } from './devToolsTypeConfig';

export const solutions: SolutionsTemplate[] = [
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.EmptyNoId',
    level: 'A',
    title: 'Completely empty anchor element (no text, no href, no ID).',
    groupLabel: 'clickables',
    issueDescription:
      'An anchor element should not be completely devoid of content i.e (name and value), this is to provide keyboard operation and assistive technology interoperability of interactive user interface elements',
    howToFixTheIssue: [
      `If the anchor tag is used with an image for descriptive purposes then use the title attribute for anchor tags e.g "title=bouncing ball" this helps with identifying the purpose of the link`,
      `If the link has an image then consider adding an alt attribute to the image tag`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
          {
            key: 'title',
            value: dummyTitleValue,
          },
        ],
        content: dummyContentText,
        closingTag: 'a',
      },
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyImgAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: true,
      content: dummyHrefContent,
      attributeKey: 'href',
      attributeValue: dummyHrefLink,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.NoHref',
    level: 'A',
    title: 'Anchor element with no href, but with an ID and text.',
    groupLabel: 'clickables',
    issueDescription:
      'Anchor element without a valid href, but with an ID and text. This often occurs when anchor elements is being used for defining in-page link targets.',
    howToFixTheIssue: [
      `If you're not using the ID for other purposes (such as CSS or scripting), consider moving the ID to a parent element.`,
      `Alternatively, if it was an ommission, make sure the value in the "href" attribute is valid.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
          {
            key: 'id',
            value: dummyId,
          },
        ],
        content: dummyContentText,
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'href',
      attributeValue: dummyHrefLink,
      tagValue: '',
    },
    confidenceLevel: '70%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.Placeholder',
    level: 'A',
    title: 'Link with text, but no href or ID.',
    groupLabel: 'clickables',
    issueDescription: `An anchor element was found with link content, but no href and/or ID attribute has been supplied. This means it does not have an accessibility API "value".`,
    howToFixTheIssue: [
      `Consider filling in the "href" attribute with valid value`,
      `Alternatively, if it was an ommission, make sure the value in the "href" attribute is valid.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
          {
            key: 'id',
            value: dummyId,
          },
        ],
        content: dummyContentText,
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'href',
      attributeValue: dummyHrefLink,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.NoContent',
    level: 'A',
    title: 'Link with a href attribute, but without link text.',
    groupLabel: 'clickables',
    issueDescription: `Anchor element with a href attribute, but without a link text. This means it does not have an accessibility API "name"`,
    howToFixTheIssue: [
      `If the anchor tag is used with an image for descriptive purposes then use the title attribute for anchor tags e.g "title=bouncing ball" this helps with identifying the purpose of the link`,
      `If the link has an image then consider adding an alt attribute to the image tag`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: dummyContentText,
        closingTag: 'a',
      },
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
          {
            key: 'title',
            value: dummyTitleValue,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyImgAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: dummyContentText,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.Empty',
    level: 'A',
    title: 'Empty anchor element (no text, no href) but with an ID.',
    groupLabel: 'clickables',
    issueDescription: `Anchor element without a valid href, but with an ID and text. This often occurs when anchor elements is being used for defining in-page link targets.`,
    howToFixTheIssue: [
      `If you're not using the ID for other purposes (such as CSS or scripting), consider moving the ID to a parent element.`,
      `Alternatively, if it was an ommission, make sure the value in the "href" attribute is valid.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
          {
            key: 'id',
            value: 'exampleId',
          },
        ],
        content: dummyHrefContent,
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'href',
      attributeValue: dummyHrefLink,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.A.EmptyWithName',
    level: 'A',
    title: 'Empty anchor element (no text, no href) but with a name attribute.',
    groupLabel: 'clickables',
    issueDescription: `Empty anchor element i.e an anchor element without text and/or href) but with a name attribute. In HTML5, this breaks the intended role of an "(a)" element being a link, and the "name" attribute is also obsolete.`,
    howToFixTheIssue: [
      `Using the "name" attribute instead of ID is an older method of creating an in-page link target.`,
      `Consider moving the "name" attribute to become an ID of a parent or nearby element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
          {
            key: 'id',
            value: 'exampleId',
          },
        ],
        content: dummyHrefContent,
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.G1,G123,G124.NoSuchId',
    level: 'A',
    title:
      'Anchor (a) or area element with a href element pointing to an anchor in the same document.',
    groupLabel: 'clickables',
    issueDescription: `An anchor "(a)" or area element with a href element pointing to an anchor in the same document, such as "#mainContent", but where that anchor defined through an ID (anywhere), or a name attribute on an "(a)" attribute, does not exist. This applies to full documents.`,
    howToFixTheIssue: [
      `Make sure that the ID used truly exist somewhere in the document.`,
      `Also, If the anchor tag is used to skip to a main content, make sure that the following are true:`,
      `The description of the link communicates that it links to a main content.`,
      `The link is either always visible or visible when it has keyboard focus.`,
      `Activating the link moves the focus to the main content.`,
      `After activating the link, the keyboard focus has moved to the main content.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-skip',
  },
  {
    principle:
      'WCAG2AA.Principle2.Guideline2_4.2_4_1.G1,G123,G124.NoSuchIdFragment2.4.2',
    level: 'A',
    title:
      'Anchor (a) or area element with a href element pointing to an ID in the same document',
    groupLabel: 'clickables',
    issueDescription: `An anchor "(a)" or area element with a href element pointing to an anchor in the same document, such as "#mainContent", but where that anchor defined through an ID (anywhere), or a name attribute on an "(a)" attribute, does not exist. This applies to full documents.`,
    howToFixTheIssue: [
      `Make sure that the ID used truly exist somewhere in the document.`,
      `Also, If the anchor tag is used to skip to a main content, make sure that the following are true:`,
      `The description of the link communicates that it links to a main content.`,
      `The link is either always visible or visible when it has keyboard focus.`,
      `Activating the link moves the focus to the main content.`,
      `After activating the link, the keyboard focus has moved to the main content.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-skip',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_4.H77,H78,H79,H80,H81,H33',
    level: 'A',
    title:
      'Anchor (A) elements in general, where they contain a title attribute.',
    groupLabel: 'clickables',
    issueDescription: `Link text combined with programmatically determined link context, or its title attribute, might not accurately describe the purpose of the link. This could pose an issue for screen readers especially when there are a lot links on the page as this is supposed to help them decide if they want to follow it or not.`,
    howToFixTheIssue: [
      `Provide a description for this link that will accurately describe the purpose of the link from it's surrounding link context.`,
      `These descriptions will be most useful to the user if the additional information needed to understand the link precedes the link. If the additional information follows the link, there can be confusion and difficulty for screen reader users who are reading through the page in order (top to bottom).`,
      `NOTE: Whenever possible, provide link text that identifies the purpose of the link without needing additional context.`,
    ],
    exampleCode: [
      {
        openingTagName: 'p',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content: "We're bringing a new feature to your door steps",
            closingTag: '',
          },
          {
            openingTagName: 'a',
            attributes: [
              {
                key: 'href',
                value: dummyHrefLink,
              },
            ],
            content: 'Read more...',
            closingTag: 'a',
          },
        ],
        closingTag: 'p',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-refs',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_4.H77,H78,H79,H80,H81',
    level: 'A',
    title: 'Anchor (A) elements in general (no title attribute).',
    groupLabel: 'clickables',
    issueDescription: `Link text combined with programmatically determined link context, or its title attribute, might not accurately describe the purpose of the link. This could pose an issue for screen readers especially when there are a lot links on the page as this is supposed to help them decide if they want to follow it or not.`,
    howToFixTheIssue: [
      `Provide a description for this link that will accurately describe the purpose of the link from it's surrounding link context.`,
      `These descriptions will be most useful to the user if the additional information needed to understand the link precedes the link. If the additional information follows the link, there can be confusion and difficulty for screen reader users who are reading through the page in order (top to bottom).`,
      `NOTE: Whenever possible, provide link text that identifies the purpose of the link without needing additional context.`,
    ],
    exampleCode: [
      {
        openingTagName: 'p',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content: "We're bringing a new feature to your door steps",
            closingTag: '',
          },
          {
            openingTagName: 'a',
            attributes: [
              {
                key: 'href',
                value: dummyHrefLink,
              },
            ],
            content: 'Read more...',
            closingTag: 'a',
          },
        ],
        closingTag: 'p',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-refs',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_9.H30',
    level: 'AAA',
    title: 'Anchor (A) elements in general.',
    groupLabel: 'clickables',
    issueDescription: `The text of this link might not accurately describe the purpose of the link. This is important as it helps a user distinguish this link from other links in the Web page and helps the user determine whether to follow the link. The URI of the destination is generally not sufficiently descriptive.`,
    howToFixTheIssue: [
      `Provide a descriptive text as the content of the "a" element that accurately describe the purpose of the link.`,
      `If an image is the only content of the link, then the text alternative for the image describes the unique function of the link.`,
      `Also, when the content of the link contains both text and one or more images, if the text is sufficient to describe the purpose of the link, the images may have an empty text alternative. But when the images convey information beyond the purpose of the link, they must also have an appropriate alt text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: dummyHrefContent,
        closingTag: 'a',
      },
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyHrefContent,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-link',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_5.H83.3',
    level: 'AAA',
    title: 'Link elements that have a "_blank" target attribute.',
    groupLabel: 'clickables',
    issueDescription: `Link element has a ""_blank"" target attribute, meaning it will open in a new window, but does not have text saying that. Letting the users know that the link will be opened in a new window is important so as to avoid confusion that may be caused by the appearance of new windows that were not requested by the user. Suddenly opening new windows can disorient users or be missed completely by some.`,
    howToFixTheIssue: [
      `Make sure that this link's link text contains information indicating that the link will open in a new window, and if doesn't, make sure to include it.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: `${dummyHrefContent} ${targetBlankFix}.`,
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: targetBlankFix,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '95%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-consistent-behavior-no-extreme-changes-context',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.F68',
    level: 'A',
    title: 'Form element should be labelled appropriately.',
    groupLabel: 'forms',
    issueDescription: `This element does not have a valid name available to an accessibility API.`,
    howToFixTheIssue: [
      `Use a label element with a for attribute whose value will be the id of the form element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'userName',
              },
            ],
            content: 'Enter your username',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'aria-label',
                value: dummyAriaLabel,
              },
              {
                key: 'name',
                value: 'username',
              },
              {
                key: 'id',
                value: 'username',
              },
              {
                key: 'type',
                value: 'text',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '98%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-rsv',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.[NodeName].Name',
    level: 'A',
    title: 'Form control which does not have a valid accessibility API "name".',
    groupLabel: 'forms',
    issueDescription: `This element does not have a valid name available to an accessibility API.`,
    howToFixTheIssue: [
      `Include a valid accessiblity API name with values. Examples of valid accessibility API names are "aria-label", "aria-labelledby".`,
    ],
    exampleCode: [
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'aria-label',
            value: dummyAriaLabel,
          },
          {
            key: 'name',
            value: 'username',
          },
          {
            key: 'type',
            value: 'text',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-rsv',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_2.H91.[NodeName].value',
    level: 'A',
    title:
      'Form control which does not have a valid accessibility API "value".',
    groupLabel: 'forms',
    issueDescription: `This element does not have a valid value available to an accessibility API.`,
    howToFixTheIssue: [
      `Elements should have valid values available to an accessiblity API.`,
    ],
    exampleCode: [
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'value',
            value: dummyInputValue,
          },
          {
            key: 'name',
            value: 'username',
          },
          {
            key: 'type',
            value: 'text',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-rsv',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_1.G83,G84,G85',
    level: 'A',
    title: 'Form elements error identification.',
    groupLabel: 'forms',
    issueDescription: `This form likely contains input errors that were not appropriately described or communicated to the user. This usually happens when a field that must be completed has not been completed.`,
    howToFixTheIssue: [
      `If an input error is automatically detected in this form, make sure that the item(s) in the error are properly identified and the error(s) are described to the user in text.`,
      `Make sure that when users fail to provide input for any mandatory form fields, information is provided in text to enable the users to identify which fields were omitted`,
      `Consider including a message or alert, as some users may not be aware that an error has occurred and could assume that the form is not functioning correctly. It is also best practice to include an error notification in the page title (title element) since a screen reader user is likely to believe the page was submitted correctly and continue to navigate to another page as soon as the new page is returned instead of reading the main content area of the page again.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-minimize-error-identified',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_2.G131,G89,G184,H90',
    level: 'A',
    title: 'Form elements labels or instructions.',
    groupLabel: 'forms',
    issueDescription: `This form likely contains input fields that do not have descriptive instructions on what type of value should be entered into them.`,
    howToFixTheIssue: [
      `Make sure that descriptive labels or instructions (including for required fields) are provided for user input in this form.`,
      `If this form asks for the name of the user and it consists of two input fields to ask for the first and last name. The first field can be labeled as "First name" and the second labeled as "Last name" using the ""name"" attribute.`,
      `In addition to identifying the field, the label for each required field should include the word “required" in parentheses.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'loginPassWord',
              },
            ],
            content: 'Enter your password (required)',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'password',
              },
              {
                key: 'required',
                value: 'true',
              },
              {
                key: 'id',
                value: 'loginPassWord',
              },
              {
                key: 'value',
                value: '',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-minimize-error-cues',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_3.G177',
    level: 'AA',
    title: 'Form elements error suggestion',
    groupLabel: 'forms',
    issueDescription: `This form likely contains input field(s) that doesn't suggest correct text or suggest at all, when the information supplied by the user is not accepted and possible correct text is known.`,
    howToFixTheIssue: [
      `Make sure that this form provides suggested corrections to errors in user input, unless it would jeopardize the security or purpose of the content.`,
      `Depending on the form, suggestions could be located next to the field where the error was identified, elsewhere on the page or via a search mechanism or reference where results would be listed on another URI.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-minimize-error-suggestions',
  },
  {
    principle:
      'WCAG2AA.Principle3.Guideline3_3.3_3_4.G98,G99,G155,G164,G168.LegalForms',
    level: 'AA',
    title: 'Form elements error Prevention (Legal, Financial, Data)',
    groupLabel: 'forms',
    issueDescription: `This form likely does not contain any form of error prevention, incase a user accidentally enters wrong inputs. This could have adverse effects in the case of legal or financial forms.`,
    howToFixTheIssue: [
      `If this form would bind a user to a financial or legal commitment, modify/delete user-controllable data, or submit test responses, ensure that at least one of the following is true`,
      `Reversible: Make srue that submissions are reversible.`,
      `Checked: Ensure that the data entered by the user is checked for input errors and the user is provided an opportunity to correct them.`,
      `Confirmed: A mechanism is available for reviewing, confirming, and correcting information before finalizing the submission.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-minimize-error-reversible',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_2.H32.2',
    level: 'A',
    title:
      'Forms which do not provide an ability on their interface to be submitted using the mouse.',
    groupLabel: 'forms',
    issueDescription: `Form does not contain a submit button (input type="submit", input type="image", or button type="submit").`,
    howToFixTheIssue: [
      `Provide a submit button with type attribute of "submit".`,
    ],
    exampleCode: [
      {
        openingTagName: 'form',
        attributes: [
          {
            key: 'action',
            value: dummyHrefLink + '/subscribe',
          },
          {
            key: 'method',
            value: 'post',
          },
        ],
        content: [
          {
            openingTagName: 'p',
            attributes: [],
            content: 'Enter your email to subscribe',
            closingTag: 'p',
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'email',
              },
            ],
            content: 'Enter email:',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'email',
              },
              {
                key: 'id',
                value: 'email',
              },
              {
                key: 'name',
                value: 'email',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'submit',
              },
              {
                key: 'value',
                value: 'Subscribe',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'form',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'submit',
      tagValue: '',
    },
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-consistent-behavior-unpredictable-change',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H93',
    level: 'A',
    title: 'Multiple labels exist with the same "for" attribute.',
    groupLabel: 'forms',
    issueDescription: `Second and subsequent sightings of a specific value for the "for" attribute. Labels should be unique per element so as to avoid key errors that are known to cause problems for assistive technologies when they are trying to parse content that has the same id attribute on different elements.`,
    howToFixTheIssue: [
      `Make sure that the labels with the same "for" attribute, if they refer to different form controls. These controls should have unique "id" attributes.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-parses',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NonExistent',
    level: 'A',
    title:
      'Label with a "for" attribute that does not match any ID used in the document.',
    groupLabel: 'forms',
    issueDescription: `This label's "for" attribute does not match any ID used in the document. This is often used to associate text labels with form controls.`,
    howToFixTheIssue: [
      `Make sure that the label and the "for" attribute's value is the same with the ID of the associated form control.`,
    ],
    exampleCode: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NonExistentFragment',
    level: 'A',
    title:
      'Label with a "for" attribute that does not match any ID used in the document fragment.',
    groupLabel: 'forms',
    issueDescription: `This label's "for" attribute does not match any ID used in the document. This is often used to associate text labels with form controls.`,
    howToFixTheIssue: [
      `Make sure the label and "for" attribute's value is the same with the ID of the associated form control.`,
    ],
    exampleCode: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NotFormControl',
    level: 'A',
    title:
      'Label with a "for" attribute that matches an ID of an element that is not a form control (that should have a label).',
    groupLabel: 'forms',
    issueDescription: `This label's "for" attribute matches an ID that is attached to an element that is not a form control e.g (input, select, textarea elements).`,
    howToFixTheIssue: [
      `"for" attributes are used to associate text labels with form controls, hence make sure that the for attribute's value matches the ID of a form control.`,
    ],
    exampleCode: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NoForAttr',
    level: 'A',
    title: 'Label without a "for" attribute.',
    groupLabel: 'forms',
    issueDescription: `Label doesn't have a "for" attribute. This means it could be being used implicitly (ie. wrapped around the field), or for some other purposes without an association with a field at all.`,
    howToFixTheIssue: [
      `Add a "for" attribute to the label.`,
      `Use the value of the label's "for" attribute as the value of the ID of the associated form control.`,
      `If the label isn't associated or meant to be associated with a form control then consider changing the label element to another element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'for',
      attributeValue: dummyForValue,
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NoId',
    level: 'A',
    title: 'Form control without an ID.',
    groupLabel: 'forms',
    issueDescription: `Form control does not have an ID, therefore it cannot have an explicit label.`,
    howToFixTheIssue: [
      `Add an ID to the form control, then on the label at a "for" attribute with the value being the ID.`,
    ],
    exampleCode: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'id',
      attributeValue: dummyId,
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H65.3',
    level: 'A',
    title: `Form control with no label, and has a "title" attribute (which it should have if no label) - but the "title" attribute is empty.`,
    groupLabel: 'forms',
    issueDescription: `Form control with no label, and has a "title" attribute (which it should have if there is no label). But the "title" attribute is empty. The "title" attribute should identify the purpose of the control when there is no label.`,
    howToFixTheIssue: [
      `Fill the "title" attribute with content otherwise remove the "title" attribute and use a label element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'text',
          },
          {
            key: 'title',
            value: 'Type search value here',
          },
        ],
        content: '',
        closingTag: null,
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'submit',
          },
          {
            key: 'value',
            value: 'Search',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'id',
      attributeValue: dummyId,
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H65',
    level: 'A',
    title: 'Form control with a validly specified title attribute (no label).',
    groupLabel: 'forms',
    issueDescription: `Form control with a validly specified title attribute (no label). Title attributes are meant to only be used where the visual design does not permit the inclusion of a visible label element.`,
    howToFixTheIssue: [
      `Make sure that the title attribute identifies the purpose of the control, and that a label element is not appropriate.`,
      `If having a label doesn't impede the UI consider using a label element with a "for" attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'email',
              },
            ],
            content: 'Enter email:',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'email',
              },
              {
                key: 'id',
                value: 'email',
              },
              {
                key: 'name',
                value: 'email',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'submit',
          },
          {
            key: 'value',
            value: 'Submit',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.2',
    level: 'A',
    title:
      'Form control with an ID that can be used to associate a label with, but has no explicit label or title attribute.',
    groupLabel: 'forms',
    issueDescription: `Form control with an ID that can be used to associate a label with, but has no explicit label or title attribute.`,
    howToFixTheIssue: [
      `Either include a label with a "for" attribute that matches the ID on the form control.`,
      `Or use a "title" attribute with valid value on the form control.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'email',
              },
            ],
            content: 'Enter email:',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'email',
              },
              {
                key: 'id',
                value: 'email',
              },
              {
                key: 'name',
                value: 'email',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'text',
              },
              {
                key: 'title',
                value: 'Type search value here',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'submit',
              },
              {
                key: 'value',
                value: 'Search',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent:
        "<label for='element-id'>Form Input Description</label>",
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.NoLabelAllowed',
    level: 'A',
    title:
      'Form controls which do not require a label, yet one has been provided.',
    groupLabel: 'forms',
    issueDescription: `This form control does not require a label, yet one has been provided. Button and hidden elements fall into this category.`,
    howToFixTheIssue: [
      `If you're associating a label element to a button, consider removing it as buttons already have a label (the text on the button).`,
      `If the label was added to a hidden element consider removing since the element been associated with the label is hidden.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.1.After',
    level: 'A',
    title:
      'Form control where the label should be placed after the element, but not.',
    groupLabel: 'forms',
    issueDescription: `Form control where the label should be placed after the element, but wasn't. Radio and checkbox inputs fall into this category.`,
    howToFixTheIssue: [
      `Place the label element for this control after the form control.`,
    ],
    exampleCode: [
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'checkbox',
          },
          {
            key: 'id',
            value: dummyId,
          },
          {
            key: 'name',
            value: dummyGeneralValue,
          },
        ],
        content: '',
        closingTag: null,
      },
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: dummyId,
          },
        ],
        content: dummyContentText,
        closingTag: 'label',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H44.1.Before',
    level: 'A',
    title:
      'Form control where the label should be placed before the element, but not.',
    groupLabel: 'forms',
    issueDescription: `Form control where the label should be placed before the element, but wasn't. Textbox based inputs fall into this category (including text, textarea, password, file upload etc.)`,
    howToFixTheIssue: [
      `The label element for this control should be placed before this element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'text',
          },
        ],
        content: 'Enter text:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'text',
          },
          {
            key: 'id',
            value: 'text',
          },
          {
            key: 'name',
            value: '',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H71.3',
    level: 'A',
    title: 'Fieldset without legend element.',
    groupLabel: 'forms',
    issueDescription: `Fieldset does not contain a legend element. All fieldsets should contain a legend element that provides a description of the field group.`,
    howToFixTheIssue: [
      `Add a legend element as the first element in the fieldset. This provides a label or description for the group`,
    ],
    exampleCode: [
      {
        openingTagName: 'fieldset',
        attributes: [],
        content: [
          {
            openingTagName: 'legend',
            attributes: [],
            content: 'I want to get the following (Check where applicable)',
            closingTag: 'legend',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: dummyId + '1',
              },
              {
                key: 'name',
                value: dummyGeneralValue + '1',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: dummyId + '1',
              },
            ],
            content: dummyContentText,
            closingTag: 'label',
          },

          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: dummyId + '2',
              },
              {
                key: 'name',
                value: dummyGeneralValue + '2',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: dummyId + '2',
              },
            ],
            content: dummyContentText,
            closingTag: 'label',
          },
        ],
        closingTag: 'fieldset',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent: '<legend>Fieldset Description</legend>',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H85.2',
    level: 'A',
    title: 'Select element not using optgroups.',
    groupLabel: 'forms',
    issueDescription: `Select element not using optgroups.`,
    howToFixTheIssue: [
      `If this selection list contains groups of related options, then they should be grouped with optgroup. NOTE: The use of optgroup may or may not be necessary.`,
    ],
    exampleCode: [
      {
        openingTagName: 'form',
        attributes: [
          {
            key: 'action',
            value: '...',
          },
        ],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'color',
              },
            ],
            content: 'What is your favorite color?',
            closingTag: 'label',
          },
          {
            openingTagName: 'select',
            attributes: [
              {
                key: 'id',
                value: 'color',
              },
            ],
            content: [
              {
                openingTagName: 'optgroup',
                attributes: [
                  {
                    key: 'label',
                    value: 'Primary',
                  },
                ],
                content: [
                  {
                    openingTagName: 'option',
                    attributes: [
                      {
                        key: 'value',
                        value: '1',
                      },
                    ],
                    content: 'Red',
                    closingTag: 'option',
                  },
                  {
                    openingTagName: 'option',
                    attributes: [
                      {
                        key: 'value',
                        value: '2',
                      },
                    ],
                    content: 'Blue',
                    closingTag: 'option',
                  },
                  {
                    openingTagName: 'option',
                    attributes: [
                      {
                        key: 'value',
                        value: '3',
                      },
                    ],
                    content: 'Yellow',
                    closingTag: 'option',
                  },
                ],
                closingTag: 'optgroup',
              },
            ],
            closingTag: 'select',
          },
        ],
        closingTag: 'form',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H71.2',
    level: 'A',
    title:
      'Radio or checkbox inputs with the same name attribute, signifying they are part of a group, but with no fieldset marking them up as such.',
    groupLabel: 'forms',
    issueDescription: `Radio or checkbox inputs with the same name attribute, signifying they are part of a group, but with no fieldset marking them up as such.`,
    howToFixTheIssue: [
      `Radio buttons or check boxes with the same name attribute must be contained within a fieldset element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'fieldset',
        attributes: [],
        content: [
          {
            openingTagName: 'legend',
            attributes: [],
            content: 'I want to get the following (Check where applicable)',
            closingTag: 'legend',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: dummyId + '1',
              },
              {
                key: 'name',
                value: dummyGeneralValue + '1',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: dummyId + '1',
              },
            ],
            content: dummyContentText,
            closingTag: 'label',
          },

          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: dummyId + '2',
              },
              {
                key: 'name',
                value: dummyGeneralValue + '2',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: dummyId + '2',
              },
            ],
            content: dummyContentText,
            closingTag: 'label',
          },
        ],
        closingTag: 'fieldset',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.FaultyValue',
    level: 'AA',
    title: 'Potential faulty value in autocomplete attribute.',
    groupLabel: 'forms',
    issueDescription: `This element contains a potentially faulty value in its autocomplete attribute. Usually for input, select, or textarea elements.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'username',
              },
            ],
            content: 'Username',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'text',
              },
              {
                key: 'id',
                value: 'username',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteValue,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Text',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Text control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Text control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a text control group (input type="text").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'username',
              },
            ],
            content: 'Username',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'text',
              },
              {
                key: 'id',
                value: 'username',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteValue,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Multiline',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Multiline control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Multiline control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a Multiline control group like the textarea element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'biography',
              },
            ],
            content: 'Write about your biography',
            closingTag: 'label',
          },
          {
            openingTagName: 'textarea',
            attributes: [
              {
                key: 'id',
                value: 'biography',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteValue,
              },
            ],
            content: '',
            closingTag: 'textarea',
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Password',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Password control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Password control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a password control group (input type="password").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'password',
              },
            ],
            content: 'Password',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'password',
              },
              {
                key: 'id',
                value: 'password',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompletePassword,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'password',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Url',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Url control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Url control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a url control group (input type="url").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'websiteName',
              },
            ],
            content: 'Website name',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'url',
              },
              {
                key: 'id',
                value: 'websiteName',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteUrl,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'url',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Telephone',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Telephone control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Telephone control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a telephone control group (input type="tel").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'telePhone',
              },
            ],
            content: 'Telephone number',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'tel',
              },
              {
                key: 'id',
                value: 'telePhone',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteTelephone,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'tel',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Numeric',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Numeric control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Numeric control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a numeric control group (input type="number").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'amount',
              },
            ],
            content: 'Amount',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'number',
              },
              {
                key: 'id',
                value: 'amount',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteAmount,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'number',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Month',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Month control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Month control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a month control group (input type="month").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'month',
              },
            ],
            content: 'Month',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'month',
              },
              {
                key: 'id',
                value: 'month',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteMonth,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'month',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.InvalidAutocomplete_Date',
    level: 'AA',
    title:
      'Invalid autocomplete value, element does not belong to Date control group.',
    groupLabel: 'forms',
    issueDescription: `Invalid autocomplete value. Element does not belong to the Date control group.`,
    howToFixTheIssue: [
      `Add the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
      `Use a date control group (input type="date").`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'favoriteDate',
              },
            ],
            content: 'Favorite date',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'date',
              },
              {
                key: 'id',
                value: 'favoriteDate',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteDate,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'type',
      attributeValue: 'date',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.Purpose',
    level: 'AA',
    title: 'Input Purposes for User Interface Components section',
    groupLabel: 'forms',
    issueDescription: `Input field needs to accurately define the purpose of collecting information about the user such that it can be programmatically determined, so that user agents can extract and present this purpose to users using different modalities.`,
    howToFixTheIssue: [
      `Use appropriate visible labels and instruction that can help users understand the purpose of the form input field.`,
      `Ensure the type of the input accurately matches what it does e.g Use an (input type="email") to collect email address etc.`,
      `Make sure that the input field serves a purpose identified in the Input Purposes for User Interface Components section.`,
    ],
    exampleCode: [
      {
        openingTagName: 'p',
        attributes: [],
        content: 'Enter your email to subscribe',
        closingTag: 'p',
      },
      {
        openingTagName: 'label',
        attributes: [
          {
            key: 'for',
            value: 'email',
          },
        ],
        content: 'Enter email:',
        closingTag: 'label',
      },
      {
        openingTagName: 'input',
        attributes: [
          {
            key: 'type',
            value: 'email',
          },
          {
            key: 'id',
            value: 'email',
          },
          {
            key: 'name',
            value: 'email',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_5_H98.MissingAutocomplete',
    level: 'AA',
    title: 'Missing autocomplete attribute',
    groupLabel: 'forms',
    issueDescription: `This element does not have an autocomplete attribute. It's important to add an autocomplete attribute for input fields that collects user information to pass the criteria`,
    howToFixTheIssue: [
      `If this input field collects user information then consider adding the appropriate autocomplete value to make the identified inputs Programmatically Determinable. This will help people with cognitive disabilities who may not immediately know the purpose of the field because the label used by the author is not familiar to them. When inputs have been programmatically assigned, third party plugins and software can manipulate these form fields to make them more accessible to people with cognitive disabilities.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'firstName',
              },
            ],
            content: 'First name',
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'text',
              },
              {
                key: 'id',
                value: 'firstName',
              },
              {
                key: 'autocomplete',
                value: dummyAutoCompleteValue,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'autocomplete',
      attributeValue: dummyAutoCompleteValue,
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=211#identify-input-purpose',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_3.3_3_5.G71,G184,G193',
    level: 'AAA',
    title: 'Help availability in form elements',
    groupLabel: 'forms',
    issueDescription: `There is no context-sensitive help available for this form, at a Web-page and/or control level.`,
    howToFixTheIssue: [
      `If you're not using the ID for other purposes (such as CSS or scripting), consider moving the ID to a parent element.`,
      `Alternatively, if it was an ommission, make sure that the value in the "href" attribute is valid.`,
    ],
    exampleCode: [
      {
        openingTagName: 'form',
        attributes: [],
        content: [
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'example',
              },
            ],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: 'Example control',
                closingTag: '',
              },
              {
                openingTagName: 'a',
                attributes: [
                  {
                    key: 'href',
                    value: 'help.html',
                  },
                ],
                content: 'Help',
                closingTag: 'a',
              },
            ],
            closingTag: 'label',
          },
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'text',
              },
              {
                key: 'id',
                value: 'example',
              },
              {
                key: 'name',
                value: 'example',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'form',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-minimize-error-context-help',
  },
  {
    principle:
      'WCAG2AA.Principle3.Guideline3_3.3_3_6.G98,G99,G155,G164,G168.AllForms',
    level: 'AAA',
    title: 'Form elements Error Prevention (All)',
    groupLabel: 'forms',
    issueDescription: `This form likely does not contain any form of error prevention, incase a user accidentally enters wrong inputs. This could have adverse effects in the case of legal or financial forms.`,
    howToFixTheIssue: [
      `If this form would bind a user to a financial or legal commitment, modify/delete user-controllable data, or submit test responses, ensure that at least one of the following is true`,
      `Reversible: Make sure that submissions are reversible.`,
      `Checked: Ensure that the data entered by the user is checked for input errors and the user is provided an opportunity to correct them.`,
      `Confirmed: A mechanism is available for reviewing, confirming, and correcting information before finalizing the submission.`,
    ],
    exampleCode: [],
    confidenceLevel: '80%',
    suggestedCodeFixTemplate: null,
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-minimize-error-reversible-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_3.G96',
    level: 'A',
    title: 'Sensory Characteristics',
    groupLabel: 'sensory',
    issueDescription: `Element relying on solely sensory characteristics such as shape, size, sound or location.`,
    howToFixTheIssue: [
      `Where instructions are provided for understanding the content, do not rely on sensory characteristics alone (such as shape, size or location) to describe objects.`,
      `In other words provide both textual or content descriptions and not just descriptions based on sensory characterisitics.`,
      `E.g: A round button is provided on a form to submit the form and move onto the next step in a progression. The button is labeled with the text "go." The instructions state, "to submit the form press the round button labeled go". This includes both shape and textual information to locate the button.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-understanding',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_2.G57',
    level: 'A',
    title: 'Meaningful Sequence',
    groupLabel: 'semantics',
    issueDescription: `Possible unordering of content when linearized, which is important because when the sequence in which the content is presented affects its meaning, a correct reading sequence can be programmatically determined.`,
    howToFixTheIssue: [
      `If css has been used to change the ordering of content, make sure that the content is ordered in a meaningful sequence when linearised, such as when style sheets have been disabled.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-sequence',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H48.1',
    level: 'A',
    title: 'Paragraph content appearing like a bulleted list in text.',
    groupLabel: 'semantics',
    issueDescription: `Paragraph content appearing like a bulleted list in text.`,
    howToFixTheIssue: [
      `Content appears to have the visual appearance of a bulleted list. It may be appropriate to mark this content up using a ul element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'ul',
        attributes: [],
        content: [
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 1',
            closingTag: 'li',
          },
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 2',
            closingTag: 'li',
          },
        ],
        closingTag: 'ul',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: true,
      hasAttribute: false,
      hasContent: false,
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: 'ul',
    },
    confidenceLevel: '95%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H48.2',
    level: 'A',
    title: 'Paragraph content appearing like a numbered list in text.',
    groupLabel: 'semantics',
    issueDescription: `Paragraph content appearing like a numbered list in text.`,
    howToFixTheIssue: [
      `Content appears to have the visual appearance of a numbered list. It may be appropriate to mark this content up using an ol element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'ol',
        attributes: [],
        content: [
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 1',
            closingTag: 'li',
          },
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 2',
            closingTag: 'li',
          },
        ],
        closingTag: 'ol',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: true,
      hasAttribute: false,
      hasContent: false,
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: 'ol',
    },
    confidenceLevel: '95%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.G141',
    level: 'A',
    title: 'Header elements when a skip of a heading level is detected',
    groupLabel: 'semantics',
    issueDescription: `The heading structure is not logically nested. This occurs in Header elements when a skip of a heading level is detected - for example, H2 followed by H4.`,
    howToFixTheIssue: [
      `Use a h1 element for the overall title, and h2 elements for major sections, and h3 elements for sub-sections.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: 'h1',
                attributes: [],
                content: 'Accessiblity techniques',
                closingTag: 'h1',
              },
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
              {
                openingTagName: 'h2',
                attributes: [],
                content: 'Add alt to images',
                closingTag: 'h2',
              },
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
              {
                openingTagName: 'section',
                attributes: [],
                content: [
                  {
                    openingTagName: 'h2',
                    attributes: [],
                    content: 'Contact us',
                    closingTag: 'h2',
                  },
                  {
                    openingTagName: '',
                    attributes: [],
                    content: '...some text here...',
                    closingTag: '',
                  },
                ],
                closingTag: 'section',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: true,
      hasAttribute: false,
      hasContent: false,
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: 'h1',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H48',
    level: 'A',
    title: 'P or DIV element that has more than one link.',
    groupLabel: 'semantics',
    issueDescription: `P or DIV element that has more than one link.`,
    howToFixTheIssue: [
      `If this element contains a navigation section, it is recommended that it be marked up as a list using "ol" or "ul".`,
    ],
    exampleCode: [
      {
        openingTagName: 'ol',
        attributes: [],
        content: [
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 1',
            closingTag: 'li',
          },
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 2',
            closingTag: 'li',
          },
        ],
        closingTag: 'ol',
      },
      {
        openingTagName: 'ul',
        attributes: [],
        content: [
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 1',
            closingTag: 'li',
          },
          {
            openingTagName: 'li',
            attributes: [],
            content: 'Number 2',
            closingTag: 'li',
          },
        ],
        closingTag: 'ul',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: true,
      hasAttribute: false,
      hasContent: false,
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: 'ul',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.[NodeName]',
    level: 'A',
    title:
      'Element that is used for presentational purposes (by HTML 4`s standards).',
    groupLabel: 'semantics',
    issueDescription: `The appropriate semantic markup was not used. Semantic markup can be used to mark emphasized or special text so that it can be programmatically determined. This also provides structure to the document and user agents can then make the structure perceivable to the user, for example using a different visual presentation for different types of structures or by using a different voice or pitch in an auditory presentation.`,
    howToFixTheIssue: [
      `If you want to display an emphasized content use the "em" element.`,
      `If you want to display an bold content use the "strong" element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'em',
        attributes: [],
        content: 'An emphasized content',
        closingTag: 'em',
      },
      {
        openingTagName: 'strong',
        attributes: [],
        content: 'A bold content',
        closingTag: 'strong',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '89%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.AlignAttr',
    level: 'A',
    title: 'Element with an align attribute.',
    groupLabel: 'semantics',
    issueDescription: `Element with a align attribute.`,
    howToFixTheIssue: [
      `Use CSS (text-align in text-related elements like paragraphs, or float with image floating) to achieve desired alignment rather than the "align" attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'style',
                attributes: [],
                content: [
                  {
                    openingTagName: '',
                    attributes: [],
                    content: '#paragraphElem { text-align: center; }',
                    closingTag: '',
                  },
                  {
                    openingTagName: '',
                    attributes: [],
                    content: 'img { float: right; }',
                    closingTag: '',
                  },
                ],
                closingTag: 'style',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H42',
    level: 'A',
    title:
      'Paragraph of text that appears to have bold or italic content (whether through b/i or strong/em) as its only child.',
    groupLabel: 'semantics',
    issueDescription: `Paragraph of text that appears to have bold or italic content (whether through b/i or strong/em) as its only child.`,
    howToFixTheIssue: [
      `Use heading markup if this content is intended as a heading.`,
    ],
    exampleCode: [
      {
        openingTagName: 'h1',
        attributes: [],
        content: 'Just an example',
        closingTag: 'h1',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: true,
      hasAttribute: false,
      hasContent: false,
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: 'h1',
    },
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H63.3',
    level: 'A',
    title: 'TH element with an invalid scope attribute.',
    groupLabel: 'tables',
    issueDescription: `TH element with an invalid scope attribute. Valid values are row, col, rowgroup, or colgroup. The scope attribute may be used to clarify the scope of any cell used as a header. The scope identifies whether the cell is a header for a row, column, or group of rows or columns.`,
    howToFixTheIssue: [
      `Make sure that all th elements have a scope attribute.`,
      `Make sure that all td elements that act as headers for other elements have a scope attribute.`,
      `Make sure that all scope attributes have the value row, col, rowgroup, or colgroup.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'border',
            value: '1',
          },
        ],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H63.2',
    level: 'A',
    title: 'TD element with a scope attribute.',
    groupLabel: 'tables',
    issueDescription: `TD element with a scope attribute. This is legal in HTML 4 but obsolete in HTML 5.`,
    howToFixTheIssue: [
      `Scope attributes on td elements that act as headers for other elements are obsolete in HTML5. Use a th element instead.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'border',
            value: '1',
          },
        ],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: true,
      hasAttribute: false,
      hasContent: false,
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: 'th',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.ScopeAmbiguous',
    level: 'A',
    title:
      'Table with multiple levels of row or column headers, but using scope attribute.',
    groupLabel: 'tables',
    issueDescription: `Table with multiple levels of row or column headers, but using scope attribute. Scope attributes on th elements are ambiguous in a table with multiple levels of headings.`,
    howToFixTheIssue: [`Use the headers attribute on td elements instead.`],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'rowspan',
                    value: '2',
                  },
                  {
                    key: 'id',
                    value: 'h',
                  },
                ],
                content: 'Homework',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'e',
                  },
                ],
                content: 'Exams',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'p',
                  },
                ],
                content: 'Projects',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'ef',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'pf',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'h',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e1',
                  },
                ],
                content: '20%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e2',
                  },
                ],
                content: '16%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e ef',
                  },
                ],
                content: '60%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p1',
                  },
                ],
                content: '29%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p2',
                  },
                ],
                content: '25%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p pf',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'header',
      attributeValue: dummyHeaderAttributeValue,
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.IncorrectAttr',
    level: 'A',
    title:
      'TD element with a headers attribute which does not match the one expected from its relevant header cells (the IDs of the TH elements it is aligned with)',
    groupLabel: 'tables',
    issueDescription: `TD element with a "headers" attribute which does not match the one expected from its relevant header cells (the IDs of the TH elements it is aligned with)`,
    howToFixTheIssue: [
      `Make sure the "headers" attribute matches the ID of the TH elements it is aligned with.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'rowspan',
                    value: '2',
                  },
                  {
                    key: 'id',
                    value: 'h',
                  },
                ],
                content: 'Homework',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'e',
                  },
                ],
                content: 'Exams',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'p',
                  },
                ],
                content: 'Projects',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'ef',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'pf',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'h',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e1',
                  },
                ],
                content: '20%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e2',
                  },
                ],
                content: '16%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e ef',
                  },
                ],
                content: '60%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p1',
                  },
                ],
                content: '29%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p2',
                  },
                ],
                content: '25%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p pf',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.HeadersRequired',
    level: 'A',
    title:
      'Table with multiple levels of row or column headers, not using headers attributes at all.',
    groupLabel: 'tables',
    issueDescription: `Table with multiple levels of row or column headers, not using headers attributes at all.`,
    howToFixTheIssue: [
      `The relationship between td elements and their associated th elements is not defined. As this table has multiple levels of th elements, you must use the headers attribute on td elements.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'rowspan',
                    value: '2',
                  },
                  {
                    key: 'id',
                    value: 'h',
                  },
                ],
                content: 'Homework',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'e',
                  },
                ],
                content: 'Exams',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'p',
                  },
                ],
                content: 'Projects',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'ef',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'pf',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'h',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e1',
                  },
                ],
                content: '20%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e2',
                  },
                ],
                content: '16%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e ef',
                  },
                ],
                content: '60%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p1',
                  },
                ],
                content: '29%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p2',
                  },
                ],
                content: '25%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p pf',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'header',
      attributeValue: dummyHeaderAttributeValue,
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.MissingHeaderIds',
    level: 'A',
    title: 'Table where not all TH elements contain IDs.',
    groupLabel: 'tables',
    issueDescription: `Not all "TH" elements in this table contain an "ID" attribute. These cells should contain ids so that they may be referenced by "TD" elements "headers" attributes.`,
    howToFixTheIssue: [
      `Include "ID" attributes in every "TH" element that doesn't have it.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'rowspan',
                    value: '2',
                  },
                  {
                    key: 'id',
                    value: 'h',
                  },
                ],
                content: 'Homework',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'e',
                  },
                ],
                content: 'Exams',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'p',
                  },
                ],
                content: 'Projects',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'ef',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'pf',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'h',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e1',
                  },
                ],
                content: '20%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e2',
                  },
                ],
                content: '16%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e ef',
                  },
                ],
                content: '60%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p1',
                  },
                ],
                content: '29%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p2',
                  },
                ],
                content: '25%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p pf',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43.MissingHeadersAttrs',
    level: 'A',
    title:
      'Table where not all TD elements contain headers attributes (but some do).',
    groupLabel: 'tables',
    issueDescription: `Not all "TD" elements in this table contain a "headers" attribute, but some do.`,
    howToFixTheIssue: [
      `Each "headers" attribute should list the IDs of all "TH" elements associated with that cell.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'rowspan',
                    value: '2',
                  },
                  {
                    key: 'id',
                    value: 'h',
                  },
                ],
                content: 'Homework',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'e',
                  },
                ],
                content: 'Exams',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'p',
                  },
                ],
                content: 'Projects',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'ef',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'pf',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'h',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e1',
                  },
                ],
                content: '20%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e2',
                  },
                ],
                content: '16%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e ef',
                  },
                ],
                content: '60%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p1',
                  },
                ],
                content: '29%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p2',
                  },
                ],
                content: '25%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p pf',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H43,H63',
    level: 'A',
    title:
      'Table with a single levels of row AND column headers, not using headers or scope attributes at all.',
    groupLabel: 'tables',
    issueDescription: `Table with single levels of row AND column headers, either not using headers or "scope" attributes at all (either is acceptable in this situation), hence the relationship between "TD" elements and their associated "TH" elements is not defined.`,
    howToFixTheIssue: [
      `Use either the "scope" attribute on "TH" elements, or the headers attribute on "TD" elements.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'border',
            value: '1',
          },
        ],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'rowspan',
                    value: '2',
                  },
                  {
                    key: 'id',
                    value: 'h',
                  },
                ],
                content: 'Homework',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'e',
                  },
                ],
                content: 'Exams',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'colspan',
                    value: '3',
                  },
                  {
                    key: 'id',
                    value: 'p',
                  },
                ],
                content: 'Projects',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'e2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e',
                  },
                  {
                    key: 'id',
                    value: 'ef',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p1',
                  },
                ],
                content: '1',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'p2',
                  },
                ],
                content: '2',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p',
                  },
                  {
                    key: 'id',
                    value: 'pf',
                  },
                ],
                content: 'Final',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'h',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e1',
                  },
                ],
                content: '20%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e e2',
                  },
                ],
                content: '16%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'e ef',
                  },
                ],
                content: '60%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p1',
                  },
                ],
                content: '29%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p p2',
                  },
                ],
                content: '25%',
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [
                  {
                    key: 'headers',
                    value: 'p pf',
                  },
                ],
                content: '15%',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H63.1',
    level: 'A',
    title: 'Table where some TH elements contain scope attributes',
    groupLabel: 'tables',
    issueDescription: `Not all "TH" elements in this table contain "scope" attributes, signifying an intention to use that format of association (and it's allowed).`,
    howToFixTheIssue: [
      `These "TH" elements should contain a scope attribute to identify their association with "TD" elements.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'border',
            value: '1',
          },
        ],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H39,H73.4',
    level: 'A',
    title:
      'Table where the summary attribute and caption element match exactly (after trimming).',
    groupLabel: 'tables',
    issueDescription: `Table where the "summary" attribute and "caption" element match exactly (after trimming).`,
    howToFixTheIssue: [
      `If this table is a data table, and both a summary attribute and a caption element are present, the summary should not duplicate the caption.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'summary',
            value:
              "A list of family information to display for everybody, it should be a very long list but just brace up, because it's great to be accessible.",
          },
        ],
        content: [
          {
            openingTagName: 'caption',
            attributes: [],
            content: 'Hello everyone this just contains info',
            closingTag: 'caption',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      removeAttribute: true,
      attributeNameToRemove: 'caption',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H73.3.Check',
    level: 'A',
    title: 'Table with specified summary attribute.',
    groupLabel: 'tables',
    issueDescription: `Table with specified summary attribute that may not accurately convey what the table is about.`,
    howToFixTheIssue: [
      `If this table is a data table, make sure that the summary attribute describes the tables organization or explains how to use the table.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'summary',
            value:
              'A list of family information to display for everybody, it could be a very long list but just brace up as it will include the name and phone number so you could contact the family member you want.',
          },
        ],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H73.3.NoSummary',
    level: 'A',
    title: 'Table without summary attribute.',
    groupLabel: 'tables',
    issueDescription: `Table without summary attribute, but seems like it needs one.`,
    howToFixTheIssue: [
      `If this table is a data table, consider using the summary attribute of the table element to give an overview of this table.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [
          {
            key: 'summary',
            value:
              'A list of family information to display for everybody, it could be a very long list but just brace up as it will include the name and phone number so you could contact the family member you want.',
          },
        ],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'summary',
      attributeValue: dummyTableSummaryValue,
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H39.3.Check',
    level: 'A',
    title: 'Table with specified caption element.',
    groupLabel: 'tables',
    issueDescription: `Table with a specified caption element that might not be accurate.`,
    howToFixTheIssue: [
      `If this table is a data table, make sure that the caption element accurately describes this table.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'caption',
            attributes: [],
            content:
              'Hello everyone this just contains name and phone number information.',
            closingTag: 'caption',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H39.3.NoCaption',
    level: 'A',
    title: 'Table without caption element.',
    groupLabel: 'tables',
    issueDescription: `Table without a caption element, but looks like it might need one.`,
    howToFixTheIssue: [
      `If this table is a data table, consider using a caption element to the table element to identify this table.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'caption',
            attributes: [],
            content:
              'Hello everyone this just contains name and phone number information.',
            closingTag: 'caption',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Name',
                closingTag: 'th',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'col',
                  },
                ],
                content: 'Phone',
                closingTag: 'th',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'td',
                attributes: [],
                content: '1.',
                closingTag: 'td',
              },
              {
                openingTagName: 'th',
                attributes: [
                  {
                    key: 'scope',
                    value: 'row',
                  },
                ],
                content: 'Barry Allen',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: '234-567',
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      isExtraElementAfterBeginningTag: true,
      extraElementContent: '<caption>Descriptive table caption</caption>',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '90%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H53',
    level: 'A',
    title:
      'Object element (or group of nested object elements) that does not contain a text alternative at the deepest level.',
    groupLabel: 'objects',
    issueDescription: `Object element (or group of nested object elements) that does not contain a text alternative at the deepest level.`,
    howToFixTheIssue: [
      `Include a text alternative in "Object" element after all other alternatives are exhausted.`,
    ],
    exampleCode: [
      {
        openingTagName: 'object',
        attributes: [
          {
            key: 'classid',
            value: 'http://www.1234/derive.py',
          },
        ],
        content: [
          {
            openingTagName: 'p',
            attributes: [],
            content: 'Text that describes this object and its operation.',
            closingTag: 'p',
          },
        ],
        closingTag: 'object',
      },
      {
        openingTagName: 'object',
        attributes: [
          {
            key: 'classid',
            value: 'http://www.1234/derive.py',
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: 'image.gif',
              },
              {
                key: 'alt',
                value: 'Image that describes this object and its operation.',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'object',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent: '<p>Descriptive text</p>',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94,G92.Object',
    level: 'A',
    title:
      'Object elements (with a correctly specified text alternative) in general.',
    groupLabel: 'objects',
    issueDescription: `Object elements (with a correctly specified text alternative) in general.`,
    howToFixTheIssue: [
      `Make sure that short (and if appropriate, long) text alternatives are available for non-text content that serve the same purpose and present the same information.`,
      `Ask this questions when choosing the appropriate text alternative:`,
      `Why is this non-text content here?`,
      `What information is it presenting?`,
      `What purpose does it fulfill?`,
      `If I could not use the non-text content, what words would I use to convey the same function and/or information?`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_4.RestrictView',
    level: 'AA',
    title: 'Orientation',
    groupLabel: 'orientation',
    issueDescription: `Orientation of page content should not be fixed or constrained to a particular view.`,
    howToFixTheIssue: [
      `Make sure that the content does not restrict its view and operation to a single display orientation, such as portrait or landscape, unless a specific display orientation is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '70%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#orientation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_4.',
    level: 'AA',
    title: 'Orientation',
    groupLabel: 'orientation',
    issueDescription: `Orientation of page content should not be fixed or constrained to a particular view.`,
    howToFixTheIssue: [
      `Make sure that the content does not restrict its view and operation to a single display orientation, such as portrait or landscape, unless a specific display orientation is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '70%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#orientation',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_6.H62.1.HTML5',
    level: 'AAA',
    title: 'Ruby element without a child rt element.',
    groupLabel: 'ruby',
    issueDescription: `Ruby element does not contain an "rt" element containing pronunciation information for its body text. This is the HTML5 version of ruby, where the base text is placed in the body of the ruby element.`,
    howToFixTheIssue: [
      `Include an "rt" element to give the pronounciation of the body text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'Example',
        attributes: [],
        content:
          'This example uses Ruby annotation to give the pronunciation of the initialism (acronym) formed by the first letters of the words Web Content Accessibility Guidelines. The letters WCAG are the base (the rb element), and the pronunciation information is shown by the Ruby text (the rt element). The Ruby parenthesis element rp is used for user agents that do not support Ruby annotations to indicate that the text in the rt element provides the pronunciation information. The pronunciation information is rendered in parentheses immediately following the base text. (User agents that support Ruby do not show the parentheses.)',
        closingTag: null,
      },
      {
        openingTagName: 'p',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content: 'Guidelines that help accessibilty, we often call them',
            closingTag: '',
          },
          {
            openingTagName: 'ruby',
            attributes: [],
            content: [
              {
                openingTagName: 'rb',
                attributes: [],
                content: 'WCAG',
                closingTag: 'rb',
              },
              {
                openingTagName: 'rp',
                attributes: [],
                content: '(',
                closingTag: 'rp',
              },
              {
                openingTagName: 'rt',
                attributes: [],
                content: 'Wuh-KAG',
                closingTag: 'rt',
              },
              {
                openingTagName: 'rp',
                attributes: [],
                content: ')',
                closingTag: 'rp',
              },
            ],
            closingTag: 'ruby',
          },
        ],
        closingTag: 'p',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent: '<rt>Pro-Nun-See-A-Shun</rt>',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-pronunciation',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_6.H62.1.XHTML11',
    level: 'AAA',
    title: 'Ruby element without a child rt element.',
    groupLabel: 'ruby',
    issueDescription: `Ruby element does not contain an rt element containing pronunciation information for the text inside the rb element.`,
    howToFixTheIssue: [
      `Include an rt element containing the information for the text inside the rb element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'Example',
        attributes: [],
        content:
          'This example uses Ruby annotation to give the pronunciation of the initialism (acronym) formed by the first letters of the words Web Content Accessibility Guidelines. The letters WCAG are the base (the rb element), and the pronunciation information is shown by the Ruby text (the rt element). The Ruby parenthesis element rp is used for user agents that do not support Ruby annotations to indicate that the text in the rt element provides the pronunciation information. The pronunciation information is rendered in parentheses immediately following the base text. (User agents that support Ruby do not show the parentheses.)',
        closingTag: null,
      },
      {
        openingTagName: 'p',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content: 'Guidelines that help accessibilty, we often call them',
            closingTag: '',
          },
          {
            openingTagName: 'ruby',
            attributes: [],
            content: [
              {
                openingTagName: 'rb',
                attributes: [],
                content: 'WCAG',
                closingTag: 'rb',
              },
              {
                openingTagName: 'rp',
                attributes: [],
                content: '(',
                closingTag: 'rp',
              },
              {
                openingTagName: 'rt',
                attributes: [],
                content: 'Wuh-KAG',
                closingTag: 'rt',
              },
              {
                openingTagName: 'rp',
                attributes: [],
                content: ')',
                closingTag: 'rp',
              },
            ],
            closingTag: 'ruby',
          },
        ],
        closingTag: 'p',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent: '<rt>Pro-Nun-See-A-Shun</rt>',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-pronunciation',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_6.H62.2',
    level: 'AAA',
    title: 'Ruby element without child rp elements.',
    groupLabel: 'ruby',
    issueDescription: `Ruby element does not contain rp elements, which provide extra punctuation to browsers that do not support ruby text.`,
    howToFixTheIssue: [
      `Include an rp element to provide extra punctuation for browsers that do not support ruby text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'Example',
        attributes: [],
        content:
          'This example uses Ruby annotation to give the pronunciation of the initialism (acronym) formed by the first letters of the words Web Content Accessibility Guidelines. The letters WCAG are the base (the rb element), and the pronunciation information is shown by the Ruby text (the rt element). The Ruby parenthesis element rp is used for user agents that do not support Ruby annotations to indicate that the text in the rt element provides the pronunciation information. The pronunciation information is rendered in parentheses immediately following the base text. (User agents that support Ruby do not show the parentheses.)',
        closingTag: null,
      },
      {
        openingTagName: 'p',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content: 'Guidelines that help accessibilty, we often call them',
            closingTag: '',
          },
          {
            openingTagName: 'ruby',
            attributes: [],
            content: [
              {
                openingTagName: 'rb',
                attributes: [],
                content: 'WCAG',
                closingTag: 'rb',
              },
              {
                openingTagName: 'rp',
                attributes: [],
                content: '(',
                closingTag: 'rp',
              },
              {
                openingTagName: 'rt',
                attributes: [],
                content: 'Wuh-KAG',
                closingTag: 'rt',
              },
              {
                openingTagName: 'rp',
                attributes: [],
                content: ')',
                closingTag: 'rp',
              },
            ],
            closingTag: 'ruby',
          },
        ],
        closingTag: 'p',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-pronunciation',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_5.G86,G103,G79,G153,G160',
    level: 'AAA',
    title: 'Reading level.',
    groupLabel: 'readability',
    issueDescription: `Complex content should be provided with a summary something that is easier to understand.`,
    howToFixTheIssue: [
      `Where the content requires reading ability more advanced than the lower secondary education level, supplemental content or an alternative version should be provided.`,
      `For example, using charts, diagrams, animations, photographs, graphic organizers, or other visual materials for users with disablilities that makes it difficult to read and understand complex text.`,
      `You could also provide a spoken version of the complex text.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-supplements',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_5.Check',
    level: 'AAA',
    title: 'Target size',
    groupLabel: 'readability',
    issueDescription: `Target size of elements`,
    howToFixTheIssue: [
      `Make sure that the size of the target for pointer inputs is at least 44 by 44 CSS pixels except when:`,
      `Equivalent: The target is available through an equivalent link or control on the same page that is at least 44 by 44 CSS pixels.`,
      `Inline: The target is in a sentence or block of text.`,
      `User Agent Control: The size of the target is determined by the user agent and is not modified by the author.`,
      `Essential: A particular presentation of the target is essential to the information being conveyed.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#target-size',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_4.G142',
    level: 'AA',
    title: 'Resize text',
    groupLabel: 'readability',
    issueDescription: `Text might not follow the resize text guideline, which makes it resizeable without assistive technology.`,
    howToFixTheIssue: [
      `Make sure that except for captions and images of text, text can be resized without assistive technology up to 200 percent without loss of content or functionality.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-scale',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_5.G140,C22,C30.AALevel',
    level: 'AA',
    title: 'Images of text',
    groupLabel: 'readability',
    issueDescription: `Using images of text which could be a problem for certain users with disabilities.`,
    howToFixTheIssue: [
      `If the technologies being used can achieve the visual presentation, make sure that the text is used to convey information rather than images of text, except when the image of text is essential to the information being conveyed, or can be visually customised to the user's requirements.`,
      `When using an image of text for example an image that just contains words, Instead of using the image of text containing the words consider using CSS to style the text to look like the image.`,
      `Note: Logotypes (text that is part of a logo or brand name) are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#name-role-value',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.G148,G156,G175',
    level: 'AAA',
    title: 'Selecting foreground and background colours for blocks of text.',
    groupLabel: 'readability',
    issueDescription: `Readability of text placed on a background with color.`,
    howToFixTheIssue: [
      `Make sure that a mechanism is available for the user to select foreground and background colours for blocks of text, either through the Web page or the browser.`,
      `Make sure that users can read text that is presented over a background. With this you don't have to do any contrast measures by simply not specifying the text color and not specifying the background. As a result the colors of both are completely determined by the user agent.`,
      `Don't specify either text color or background, and do not use CSS to style. As a result the user can set their browser defaults to provide the colors and contrasts that work well for them.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-visual-presentation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.H87,C20',
    level: 'AAA',
    title: 'Width of a block of text',
    groupLabel: 'readability',
    issueDescription: `Width of blocks of text should be resizeable in a way that it will still be readable.`,
    howToFixTheIssue: [
      `Make sure that a mechanism exists to reduce the width of a block of text to no more than 80 characters (or 40 in Chinese, Japanese or Korean script).`,
      `Use relative measurements to set column widths so that lines can average 80 characters or less when the browser is resized.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-visual-presentation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.C19,G172,G169',
    level: 'AAA',
    title: 'Justification of blocks of text.',
    groupLabel: 'readability',
    issueDescription: `Justification of blocks of text.`,
    howToFixTheIssue: [
      `Make sure that blocks of text are not fully justified - that is, to both left and right edges or a mechanism exists to remove full justification.`,
      `Use CSS "text-align" property to achieve desired justification.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-visual-presentation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.G188,C21',
    level: 'AAA',
    title: 'Line spacing in blocks of text.',
    groupLabel: 'readability',
    issueDescription: `Line spacing in blocks of text.`,
    howToFixTheIssue: [
      `Make sure that line spacing in blocks of text are at least 150% in paragraphs, and paragraph spacing is at least 1.5 times the line spacing, or that a mechanism is available to achieve this.`,
      `Use CSS propeties "line-height" and "letter-spacing" to achieve the above condition.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-visual-presentation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_8.H87,G146,C26',
    level: 'AAA',
    title: 'Resizing text without assistive technology.',
    groupLabel: 'readability',
    issueDescription: `Resizing text without assistive technology.`,
    howToFixTheIssue: [
      `Make sure that text can be resized without assistive technology up to 200 percent without requiring the user to scroll horizontally on a full-screen window.`,
      `Use liquid or fluid layouts that will resize with text, and reflow as needed to display the region on the screen.`,
      `Liquid layouts can be achieved by positioning the layout regions as a row of adjacent floating boxes, which wrap to new rows as needed in much the same way as words in a paragraph wrap.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-visual-presentation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_9.G140,C22,C30.NoException',
    level: 'AAA',
    title: 'Images of text(no exception).',
    groupLabel: 'readability',
    issueDescription: `Using images of text (no exception) which could be a problem for certain users with disabilities.`,
    howToFixTheIssue: [
      `Make sure that images of text are only used for pure decoration or where a particular presentation of text is essential to the information being conveyed.`,
      `Use CSS properties like: "font-style", "font-weight", "text-align" etc. to style the texts to look like the image of text.`,
      `Note: Logotypes (text that is part of a logo or brand name) are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-text-images',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_12_C36,C35.Check',
    level: 'AA',
    title: 'Text spacing',
    groupLabel: 'readability',
    issueDescription: `Elements containing text should be sufficiently spaced.`,
    howToFixTheIssue: [
      `Make sure that no loss of content or functionality occurs by setting all of the following and by changing no other style property:`,
      `Line height (line spacing) to at least 1.5 times the font size.`,
      `Spacing following paragraphs to at least 2 times the font size.`,
      `Letter spacing (tracking) to at least 0.12 times the font size.`,
      `Word spacing to at least 0.16 times the font size.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#text-spacing',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_12.C36,C35',
    level: 'AA',
    title: 'Text spacing',
    groupLabel: 'readability',
    issueDescription: `Elements containing text should be sufficiently spaced.`,
    howToFixTheIssue: [
      `Make sure that no loss of content or functionality occurs by setting all of the following and by changing no other style property:`,
      `Line height (line spacing) to at least 1.5 times the font size.`,
      `Spacing following paragraphs to at least 2 times the font size.`,
      `Letter spacing (tracking) to at least 0.12 times the font size.`,
      `Word spacing to at least 0.16 times the font size.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#text-spacing',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_3.2_3_3.Check',
    level: 'AAA',
    title: 'Animation from Interactions',
    groupLabel: 'graphics',
    issueDescription: `Animation from interactions.`,
    howToFixTheIssue: [
      `Make sure that motion animation triggered by interaction can be disabled, unless the animation is essential to the functionality or the information being conveyed.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#animation-from-interactions',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_3.2_3_3.Check',
    level: 'AAA',
    title: 'Animation from Interactions',
    groupLabel: 'graphics',
    issueDescription: `Animation from interactions.`,
    howToFixTheIssue: [
      `Make sure that motion animation triggered by interaction can be disabled, unless the animation is essential to the functionality or the information being conveyed.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#animation-from-interactions',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_1.G14,G182',
    level: 'A',
    title: 'Use of colour',
    groupLabel: 'graphics',
    issueDescription: `Use of colour in conveying information without alternatives.`,
    howToFixTheIssue: [
      `Ensure that additional visual cues are available when text color differences are used to convey information.`,
      `Ensure that information conveyed by color differences is also available in text. For example check out the text below.`,
      `The schedule for sessions at a technology conference is organized into three tracks. Sessions for Track 1 are displayed over a blue background. Sessions in Track 2 are displayed over a yellow background. Sessions in Track 3 are displayed on a green background. After the name of each session is a code identifying the track in text: T1 for Track 1, T2 for Track 2, and T3 for Track 3.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-without-color',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G18.Alpha',
    level: 'AA',
    title:
      'Elements with text with colours that have insufficient colour contrast of at least 4.5:1',
    groupLabel: 'graphics',
    issueDescription: `Elements with text that have colours with insufficient colour contrast for Level AA's requirements.`,
    howToFixTheIssue: [`Consider using a contrast ratio of at least 4.5:1.`],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G145.Alpha',
    level: 'AA',
    title:
      'Elements with text with colours that have insufficient colour contrast of at least 3:1',
    groupLabel: 'graphics',
    issueDescription: `Elements with text with colours that have insufficient colour contrast for the Level AA's requirements for "large text"`,
    howToFixTheIssue: [`Consider using a contrast ratio of at least 3:1.`],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G18',
    level: 'AA',
    title: 'Text placed in front of a background image',
    groupLabel: 'graphics',
    issueDescription: `This element's text is placed in front of a background image.`,
    howToFixTheIssue: [
      `Ensure the contrast ratio between the text and all covered parts of the image are at least 4.5:1.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G18.Fail',
    level: 'AA',
    title: 'Text placed in front of a background image',
    groupLabel: 'graphics',
    issueDescription: `This element's text is placed in front of a background image.`,
    howToFixTheIssue: [
      `Ensure the contrast ratio between the text and all covered parts of the image are at least 4.5:1.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.G145',
    level: 'AA',
    title: 'Text defined as large text placed in front of a background image.',
    groupLabel: 'graphics',
    issueDescription: `This element's text which is considered "large" is placed in front of a background image.`,
    howToFixTheIssue: [
      `Ensure the contrast ratio between the text and all covered parts of the image are at least 3:1.`,
      `The contrast ratio can sometimes be maintained by changing the relative luminance of the letters as the relative luminance of the background changes across the page.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.F24.BGColour',
    level: 'AA',
    title:
      'Elements with a background style or bgcolor attribute, without a corresponding foreground color style or attribute.',
    groupLabel: 'graphics',
    issueDescription: `Elements with a background style or bgcolor attribute, without a corresponding foreground color style or attribute.`,
    howToFixTheIssue: [
      `Make sure that this element has an inherited foreground colour to complement the corresponding inline background colour or image.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3.F24.FGColour',
    level: 'A',
    title:
      'Elements with a foreground color style or attribute, without a corresponding background color style or bgcolor attribute.',
    groupLabel: 'graphics',
    issueDescription: `Elements with a foreground color style or attribute, without a corresponding background color style or bgcolor attribute.`,
    howToFixTheIssue: [
      `Make sure that this element has an inherited background colour or image to complement the corresponding inline foreground colour.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3_F24.F24.BGColour',
    level: 'AA',
    title:
      'Elements with a background style or bgcolor attribute, without a corresponding foreground color style or attribute.',
    groupLabel: 'graphics',
    issueDescription: `Elements with a background style or bgcolor attribute, without a corresponding foreground color style or attribute.`,
    howToFixTheIssue: [
      `Make sure that this element has an inherited foreground colour to complement the corresponding inline background colour or image.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_3_F24.F24.FGColour',
    level: 'A',
    title:
      'Elements with a foreground color style or attribute, without a corresponding background color style or bgcolor attribute.',
    groupLabel: 'graphics',
    issueDescription: `Elements with a foreground color style or attribute, without a corresponding background color style or bgcolor attribute.`,
    howToFixTheIssue: [
      `Make sure that this element has an inherited background colour or image to complement the corresponding inline foreground colour.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G17',
    level: 'AAA',
    title:
      'Elements with text with colours that have insufficient colour contrast of at least 7:1.',
    groupLabel: 'graphics',
    issueDescription: `Elements containing text with colours that have insufficient colour contrast for Level AAA's requirements.`,
    howToFixTheIssue: [
      `Make sure text in this element has a contrast ratio of at least 7:1.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast7',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G18',
    level: 'AAA',
    title:
      'Elements with text with colours that have insufficient colour contrast for "large text".',
    groupLabel: 'graphics',
    issueDescription: `Elements containing text with colours that have insufficient colour contrast for the Level AAA's requirements for "large text".`,
    howToFixTheIssue: [
      `Make sure text in this element has a contrast ratio of at least 4.5:1.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast7',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G17',
    level: 'AAA',
    title: 'Text placed in front of a background image.',
    groupLabel: 'graphics',
    issueDescription: `Text placed in front of a background image.`,
    howToFixTheIssue: [
      `Ensure the contrast ratio between the text and all covered parts of the image are at least 7:1.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast7',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_6.G18',
    level: 'AAA',
    title: '"Large text" placed in front of a background image',
    groupLabel: 'graphics',
    issueDescription: `Text defined as "large text" placed in front of a background image.`,
    howToFixTheIssue: [
      `Ensure the contrast ratio between the text and all covered parts of the image are at least 4.5:1.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast7',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_11_G195,G207,G18,G145,G174,F78.Check',
    level: 'AA',
    title: 'Non-text Contrast',
    groupLabel: 'graphics',
    issueDescription: `Insufficient contrast for non text content.`,
    howToFixTheIssue: [
      `Make sure that the visual presentation of the following have a contrast ratio of at least 3:1 against adjacent color(s):`,
      `User Interface Components: Visual information required to identify user interface components and states, except for inactive components or where the appearance of the component is determined by the user agent and not modified by the author`,
      `Graphical Objects: Parts of graphics required to understand the content, except when a particular presentation of graphics is essential to the information being conveyed.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#non-text-contrast',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_11.G195,G207,G18,G145,G174,F78',
    level: 'AA',
    title: 'Non-text Contrast',
    groupLabel: 'graphics',
    issueDescription: `Insufficient contrast for non text content.`,
    howToFixTheIssue: [
      `Make sure that the visual presentation of the following have a contrast ratio of at least 3:1 against adjacent color(s):`,
      `User Interface Components: Visual information required to identify user interface components and states, except for inactive components or where the appearance of the component is determined by the user agent and not modified by the author`,
      `Graphical Objects: Parts of graphics required to understand the content, except when a particular presentation of graphics is essential to the information being conveyed.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#non-text-contrast',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H30.2',
    level: 'A',
    title:
      'Image elements with an absent or empty alt attribute, that is the only non-blank content of a link',
    groupLabel: 'images',
    issueDescription: `Img element is the only content of the link, but is missing alt text. The alt text should describe the purpose of the link.`,
    howToFixTheIssue: [
      `Include an alt attribute which describes the purpose of the link.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyImgAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'alt',
      attributeValue: dummyImgAlt,
      tagValue: '',
      targetTagSelector: 'img',
    },
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H37',
    level: 'A',
    title:
      'Image element (outside of a link) that is completely missing an alt attribute',
    groupLabel: 'images',
    issueDescription: `Image element (outside of a link) that is completely missing an alt attribute.`,
    howToFixTheIssue: [
      `Include an alt attribute to specify a short text alternative.`,
    ],
    exampleCode: [
      {
        openingTagName: 'img',
        attributes: [
          {
            key: 'src',
            value: dummyImgSrc,
          },
          {
            key: 'alt',
            value: dummyImgAlt,
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'alt',
      attributeValue: dummyImgAlt,
      tagValue: '',
    },
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H67.1',
    level: 'A',
    title:
      'Image element (outside of a link) that is marked with an empty alt attribute (intending it to be ignored), but has a non-empty title attribute',
    groupLabel: 'images',
    issueDescription: `Image element (outside of a link) that is marked with an empty alt attribute (intending it to be ignored), but has a non-empty title attribute.`,
    howToFixTheIssue: [
      `If the image element is decorative or you want the alt to be ignored, then remove the title attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'img',
        attributes: [
          {
            key: 'src',
            value: dummyImgSrc,
          },
          {
            key: 'alt',
            value: '',
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      removeAttribute: true,
      attributeNameToRemove: 'title',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H67.2',
    level: 'A',
    title:
      'Image element (outside of a link) that is marked with an empty alt attribute, and its title attribute is empty or absent.',
    groupLabel: 'images',
    issueDescription: `Image element (outside of a link) that is marked with an empty alt attribute, and its title attribute is empty or absent.`,
    howToFixTheIssue: [
      `If the image element is decorative or you want the alt to be ignored, then remove the title attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'img',
        attributes: [
          {
            key: 'src',
            value: dummyImgSrc,
          },
          {
            key: 'alt',
            value: dummyImgAlt,
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'alt',
      attributeValue: dummyImgAlt,
      tagValue: '',
    },
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94.Image',
    level: 'A',
    title: 'Images with alt text, in general.',
    groupLabel: 'images',
    issueDescription: `Images with alt text, in general should describe the image.`,
    howToFixTheIssue: [
      `Ensure that the img element's alt text serves the same purpose and presents the same information as the image.`,
    ],
    exampleCode: [
      {
        openingTagName: 'img',
        attributes: [
          {
            key: 'src',
            value: dummyImgSrc,
          },
          {
            key: 'alt',
            value: dummyImgAlt,
          },
        ],
        content: '',
        closingTag: null,
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'alt',
      attributeValue: dummyImgAlt,
      tagValue: '',
    },
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H36',
    level: 'A',
    title: 'Image submit button that has a missing or empty alt attribute.',
    groupLabel: 'images',
    issueDescription: `Image submit button that has a missing or empty alt attribute.`,
    howToFixTheIssue: [
      `Specify a text alternative that describes the button's function, using the alt attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'form',
        attributes: [
          {
            key: 'action',
            value: dummyHrefLink,
          },
          {
            key: 'method',
            value: 'post',
          },
        ],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'image',
              },
              {
                key: 'name',
                value: 'submit',
              },
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: 'Submit',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'form',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94.Button',
    level: 'A',
    title: 'Image submit buttons.',
    groupLabel: 'images',
    issueDescription: `Image submit buttons in general, not accurately describing what the button does.`,
    howToFixTheIssue: [
      `Ensure that the image submit button's alt text identifies the purpose of the button.`,
    ],
    exampleCode: [
      {
        openingTagName: 'form',
        attributes: [
          {
            key: 'action',
            value: dummyHrefLink,
          },
          {
            key: 'method',
            value: 'post',
          },
        ],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'image',
              },
              {
                key: 'name',
                value: 'submit',
              },
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: 'Submit',
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'form',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'alt',
      attributeValue: dummyImgAlt,
      tagValue: '',
    },
    confidenceLevel: '88%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H24',
    level: 'A',
    title:
      'Area elements in an image map that have an empty or missing alt attribute.',
    groupLabel: 'images',
    issueDescription: `Area elements in an image map that have an empty or missing alt attribute.`,
    howToFixTheIssue: [
      `Make sure that each area element must have a text alternative that describes the function of the image map area.`,
    ],
    exampleCode: [
      {
        openingTagName: 'map',
        attributes: [
          {
            key: 'id',
            value: dummyMapNameId,
          },
          {
            key: 'name',
            value: dummyMapNameId,
          },
        ],
        content: [
          {
            openingTagName: 'area',
            attributes: [
              {
                key: 'shape',
                value: dummyMapNameId,
              },
              {
                key: 'coords',
                value: dummyMapCoords,
              },
              {
                key: 'href',
                value: dummyMapHref,
              },
              {
                key: 'alt',
                value: dummyMapAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'area',
            attributes: [
              {
                key: 'shape',
                value: dummyMapNameId,
              },
              {
                key: 'coords',
                value: dummyMapCoords,
              },
              {
                key: 'href',
                value: dummyMapHref,
              },
              {
                key: 'alt',
                value: dummyMapAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'map',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H24.2',
    level: 'A',
    title: 'Area elements with alt text, in general.',
    groupLabel: 'images',
    issueDescription: `Area elements with alt text, in general.`,
    howToFixTheIssue: [
      `Ensure that the area element's text alternative serves the same purpose as the part of image map image it references.`,
    ],
    exampleCode: [
      {
        openingTagName: 'map',
        attributes: [
          {
            key: 'id',
            value: dummyMapNameId,
          },
          {
            key: 'name',
            value: dummyMapNameId,
          },
        ],
        content: [
          {
            openingTagName: 'area',
            attributes: [
              {
                key: 'shape',
                value: dummyMapNameId,
              },
              {
                key: 'coords',
                value: dummyMapCoords,
              },
              {
                key: 'href',
                value: dummyMapHref,
              },
              {
                key: 'alt',
                value: dummyMapAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'area',
            attributes: [
              {
                key: 'shape',
                value: dummyMapNameId,
              },
              {
                key: 'coords',
                value: dummyMapCoords,
              },
              {
                key: 'href',
                value: dummyMapHref,
              },
              {
                key: 'alt',
                value: dummyMapAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'map',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '79%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G73,G74',
    level: 'A',
    title: 'Images with alt text.',
    groupLabel: 'images',
    issueDescription: `Images with alt text, in general. Images such as graphs may need a long description`,
    howToFixTheIssue: [
      `If this image cannot be fully described in a short text alternative, ensure a long text alternative is also available, such as in the body text or through a link.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H2.EG5',
    level: 'A',
    title:
      'Image inside a link along with text content, where the alt attribute duplicates the text content (after trimming).',
    groupLabel: 'images',
    issueDescription: `Image inside a link along with text content, where the alt attribute duplicates the text content (after trimming)`,
    howToFixTheIssue: [
      `Make sure Img element inside a link does not use alt text that duplicates the text content of the link.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyImgAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H2.EG4',
    level: 'A',
    title:
      'Img element inside a link has empty or missing alt text when a link beside it contains link text.',
    groupLabel: 'images',
    issueDescription: `Img element inside a link has empty or missing alt text when a link beside it contains link text.`,
    howToFixTheIssue: [
      `Combine the link text an img element together like in the example shown.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyImgAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: '',
            attributes: [],
            content: dummyHrefContent,
            closingTag: '',
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H2.EG3',
    level: 'A',
    title:
      'Image inside a link, that is next to a link that has the same text content (after trimming).',
    groupLabel: 'images',
    issueDescription: `Image inside a link, that is next to a link that has the same text content (after trimming). "Next to" means the only thing between them is whitespace.`,
    howToFixTheIssue: [
      `Make sure the Img element inside a link must not use alt text that duplicates the content of a text link beside it.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: dummyHrefLink,
          },
        ],
        content: [
          {
            openingTagName: 'img',
            attributes: [
              {
                key: 'src',
                value: dummyImgSrc,
              },
              {
                key: 'alt',
                value: dummyImgAlt,
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: '',
            attributes: [],
            content: dummyHrefContent,
            closingTag: '',
          },
        ],
        closingTag: 'a',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '70%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H35.3',
    level: 'A',
    title: 'Applet element without a body text alternative.',
    groupLabel: 'applet',
    issueDescription: `Applet element without a body text alternative. Applet elements must contain a text alternative in the element's body, for browsers without support for the applet element.`,
    howToFixTheIssue: [
      `Provide a text alternative for the applet by using the alt attribute to label the applet and providing the text alternative in the body of the applet element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'applet',
        attributes: [
          {
            key: 'code',
            value: dummyAppletCode,
          },
          {
            key: 'width',
            value: dummyWidth,
          },
          {
            key: 'height',
            value: dummyHeight,
          },
          {
            key: 'alt',
            value: dummyAppletAlt,
          },
        ],
        content: dummyAppletContent,
        closingTag: 'applet',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent: "<p'>Applet element description</p>",
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.H35.2',
    level: 'A',
    title: 'Applet element without an alt attribute.',
    groupLabel: 'applet',
    issueDescription: `Applet element without an alt attribute. Applet elements must contain an alt attribute, to provide a text alternative to browsers supporting the element but are unable to load the applet.`,
    howToFixTheIssue: [
      `Provide a text alternative for the applet by using the alt attribute to label the applet.`,
    ],
    exampleCode: [
      {
        openingTagName: 'applet',
        attributes: [
          {
            key: 'code',
            value: dummyAppletCode,
          },
          {
            key: 'width',
            value: dummyWidth,
          },
          {
            key: 'height',
            value: dummyHeight,
          },
          {
            key: 'alt',
            value: dummyAppletAlt,
          },
        ],
        content: dummyAppletContent,
        closingTag: 'applet',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'alt',
      attributeValue: dummyAppletAltFix,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_1.1_1_1.G94,G92.Applet',
    level: 'A',
    title:
      'Applet elements (with correctly specified text alternatives) in general.',
    groupLabel: 'applet',
    issueDescription: `Applet elements (with correctly specified text alternatives) in general.`,
    howToFixTheIssue: [
      `Provide a short and/or long text alternative that can substitute for the non-text content if the non-text content were removed from the page and substituted with the text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'applet',
        attributes: [
          {
            key: 'code',
            value: dummyAppletCode,
          },
          {
            key: 'width',
            value: dummyWidth,
          },
          {
            key: 'height',
            value: dummyHeight,
          },
          {
            key: 'alt',
            value: dummyAppletAlt,
          },
        ],
        content: dummyAppletContent,
        closingTag: 'applet',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-text-equiv-all',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_1.H42.2',
    level: 'A',
    title: 'Heading tag found with no content',
    groupLabel: 'title',
    issueDescription: `Heading element (h1-h6) that has no content. Non-breaking spaces are included in definition of "no content" in this case.`,
    howToFixTheIssue: [
      `Fill in heading tag with content.`,
      `If text is not intended as a heading then do not mark it up with heading tags.`,
    ],
    exampleCode: [
      {
        openingTagName: 'h1',
        attributes: [],
        content: dummyContentText,
        closingTag: 'h1',
      },
      {
        openingTagName: 'h2',
        attributes: [],
        content: dummyContentText,
        closingTag: 'h2',
      },
      {
        openingTagName: 'h3',
        attributes: [],
        content: dummyContentText,
        closingTag: 'h3',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: dummyContentText,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-content-structure-separation-programmatic',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_6.G130,G131',
    level: 'AA',
    title: 'Headings and labels',
    groupLabel: 'title',
    issueDescription: `Headings and labels should have a good description of the content after them.`,
    howToFixTheIssue: [
      `Provide a descriptive text for the heading and/or label.`,
    ],
    exampleCode: [
      {
        openingTagName: 'h1',
        attributes: [],
        content: dummyContentText,
        closingTag: 'h1',
      },
      {
        openingTagName: 'h2',
        attributes: [],
        content: dummyContentText,
        closingTag: 'h2',
      },
      {
        openingTagName: 'h3',
        attributes: [],
        content: dummyContentText,
        closingTag: 'h3',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: dummyHeadingContent,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-descriptive',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.H64.1',
    level: 'A',
    title: 'Iframe elements that have missing or empty title attribute.',
    groupLabel: 'media',
    issueDescription: `Iframe elements that have missing or empty title attribute. Iframe element requires a non-empty title attribute that identifies the frame.`,
    howToFixTheIssue: [`Add a title attribute that will identify the frame.`],
    exampleCode: [
      {
        openingTagName: 'iframe',
        attributes: [
          {
            key: 'src',
            value: '...',
          },
          {
            key: 'title',
            value: 'Example',
          },
        ],
        content: [
          {
            openingTagName: 'a',
            attributes: [
              {
                key: 'href',
                value: dummyHrefLink,
              },
            ],
            content: dummyContentText,
            closingTag: 'a',
          },
        ],
        closingTag: 'iframe',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: dummyTitleValue2,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-rsv',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_1.G159,G166',
    level: 'A',
    title: 'Elements which may contain video.',
    groupLabel: 'media',
    issueDescription: `Elements which may contain video but doesn't have text alternatives to represent the information in the video.`,
    howToFixTheIssue: [
      `Provide an alternative text or audio that describes the video. It could be a link to an audio recording or just text saying what the video is about.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-av-only-alt',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_2.G87,G93',
    level: 'A',
    title: 'Captions (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `If this embedded object contains pre-recorded synchronised media and is not provided as an alternative for text content, check that captions are provided for audio content.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-captions',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_1.G158',
    level: 'A',
    title: 'Audio-only and Video-only (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain audio.`,
    howToFixTheIssue: [
      `If this embedded object contains pre-recorded audio only, and is not provided as an alternative for text content, check that an alternative text version is available.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-av-only-alt',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_3.G69,G78,G173,G8',
    level: 'A',
    title: 'Audio Description or Media Alternative (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `Provide an alternative for time-based media or an audio description of the prerecorded video content for synchronized media, except when the media is a media alternative for text and is clearly labeled as such.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-audio-desc',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_4.G9,G87,G93',
    level: 'AA',
    title: 'Captions (Live)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `If this embedded object contains synchronised media, check that captions are provided for live audio content.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-real-time-captions',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_5.G78,G173,G8',
    level: 'AA',
    title: 'Audio Description (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `If this embedded object contains pre-recorded synchronised media, check that an audio description is provided for its video content.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-audio-desc-only',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_6.G54,G81',
    level: 'AAA',
    title: 'Sign Language (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `If this embedded object contains pre-recorded synchronised media, check that a sign language interpretation is provided for its audio.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-sign',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_7.G8',
    level: 'AAA',
    title: 'Extended Audio Description (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `If this embedded object contains synchronised media, and where pauses in foreground audio is not sufficient to allow audio descriptions to convey the sense of pre-recorded video, check that an extended audio description is provided, either through scripting or an alternate version.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-extended-ad',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_8.G69,G159',
    level: 'AAA',
    title: 'Media Alternative (Prerecorded)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain synchronised media.`,
    howToFixTheIssue: [
      `If this embedded object contains pre-recorded synchronised media or video-only content, check that an alternative text version of the content is provided.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-text-doc',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_2.1_2_9.G150,G151,G157',
    level: 'AAA',
    title: 'Audio-only (Live)',
    groupLabel: 'media',
    issueDescription: `Elements which may contain audio.`,
    howToFixTheIssue: [
      `If this embedded object contains live audio-only content, check that an alternative text version of the content is provided.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-media-equiv-live-audio-only',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_2.F23',
    level: 'A',
    title: 'Audio control',
    groupLabel: 'media',
    issueDescription: `Audio control, in cases where user cannot control when an audio starts playing i.e cases whereby audio starts playing without the users consent.`,
    howToFixTheIssue: [
      `If any audio plays automatically for longer than 3 seconds, check that there is the ability to pause, stop or mute the audio.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-dis-audio',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_7.G56',
    level: 'AAA',
    title: 'Low or No Background Audio',
    groupLabel: 'media',
    issueDescription: `Background audio playing when not specifically asked for.`,
    howToFixTheIssue: [
      `Make sure that for pre-recorded audio-only content that is primarily speech (such as narration), any background sounds should be muteable, or be at least 20 dB (or about 4 times) quieter than the speech.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-noaudio',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_2.H25.2',
    level: 'A',
    title: 'Title element should describe the document.',
    groupLabel: 'document',
    issueDescription: `Title element should accurately describe the document.`,
    howToFixTheIssue: [
      `Make sure that the title element describes the document.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'The world wide web page.',
                closingTag: 'title',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: '...',
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: dummyTitleValue2,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-title',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_2.H25.1.NoTitleEl',
    level: 'A',
    title: 'Document with a head section, but missing a title attribute.',
    groupLabel: 'document',
    issueDescription: `Document with a head section, but missing a title attribute.`,
    howToFixTheIssue: [
      `A title should be provided for the document, using the title element in the head section.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'The world wide web page.',
                closingTag: 'title',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: '...',
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      hasExtraElement: true,
      extraElementContent: '<title>Descriptive Title Value</title>',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-title',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_2.H25.1.EmptyTitle',
    level: 'A',
    title: 'Title element that exists, but is empty.',
    groupLabel: 'document',
    issueDescription: `Title element that exists, but is empty.`,
    howToFixTheIssue: [
      `The title element in the head section should be non-empty, consider adding a value to the title element.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'The world wide web page.',
                closingTag: 'title',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: '...',
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: true,
      content: dummyTitleValue2,
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-title',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_10.C32,C31,C33,C38,SCR34,G206.Zoom',
    level: 'AA',
    title: 'Meta element with a name of "viewport" that defines scaling rules.',
    groupLabel: 'document',
    issueDescription: `Meta element with a name of "viewport" that defines scaling rules.`,
    howToFixTheIssue: [
      `Using meta with a name of "viewport", interferes with a user agent's ability to zoom leading to a failure of this Success Criterion.`,
      `Make user-scalable set to yes on the content attribute of the meta tag, this would enable the viewport to not prevent user scaling.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'meta',
                attributes: [],
                content: '',
                closingTag: 'meta',
              },
              {
                openingTagName: 'title',
                attributes: [],
                content: 'The world wide web page.',
                closingTag: 'title',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: '...',
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'content',
      attributeValue: 'user-scalable=yes',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#reflow',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_1.F40.2',
    level: 'A',
    title:
      'Meta refresh element with a timeout greater than zero (with a URL to redirect to).',
    groupLabel: 'document',
    issueDescription: `Meta refresh tag used to redirect to another page, with a time limit that is not zero. Users cannot control this time limit.`,
    howToFixTheIssue: [
      `Make sure that for each time limit that is set by the content, at least one of the following is true:`,
      `Turn off: The user is allowed to turn off the time limit before encountering it.`,
      `Adjust: The user is allowed to adjust the time limit before encountering it over a wide range that is at least ten times the length of the default setting.`,
      `Extend: The user is warned before time expires and given at least 20 seconds to extend the time limit with a simple action (for example, "press the space bar"), and the user is allowed to extend the time limit at least ten times.`,
      `Real-time Exception: The time limit is a required part of a real-time event (for example, an auction), and no alternative to the time limit is possible.`,
      `Essential Exception: The time limit is essential and extending it would invalidate the activity.`,
      `20 Hour Exception: The time limit is longer than 20 hours.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-required-behaviors',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_1.F41.2',
    level: 'A',
    title:
      'Meta refresh element with a timeout greater than zero (and no URL, indicating "refresh the current URL").',
    groupLabel: 'document',
    issueDescription: `Meta refresh element with a timeout greater than zero (and no URL, indicating "refresh the current URL").`,
    howToFixTheIssue: [
      `Make sure that for each time limit that is set by the content, at least one of the following is true:`,
      `Turn off: The user is allowed to turn off the time limit before encountering it.`,
      `Adjust: The user is allowed to adjust the time limit before encountering it over a wide range that is at least ten times the length of the default setting.`,
      `Extend: The user is warned before time expires and given at least 20 seconds to extend the time limit with a simple action (for example, "press the space bar"), and the user is allowed to extend the time limit at least ten times.`,
      `Real-time Exception: The time limit is a required part of a real-time event (for example, an auction), and no alternative to the time limit is possible.`,
      `Essential Exception: The time limit is essential and extending it would invalidate the activity.`,
      `20 Hour Exception: The time limit is longer than 20 hours.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-required-behaviors',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_8.H59.1',
    level: 'AAA',
    title: 'Link element placed outside the head element of the document.',
    groupLabel: 'document',
    issueDescription: `Link element placed outside the head element of the document. Link elements can only be located in the head section of the document.`,
    howToFixTheIssue: [
      `Place the link element inside the head section of document.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'link',
                attributes: [
                  {
                    key: 'rel',
                    value: 'Contents',
                  },
                  {
                    key: 'href',
                    value: 'Contents.html',
                  },
                  {
                    key: 'title',
                    value: 'Table of Contents',
                  },
                ],
                content: [],
                closingTag: null,
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-location',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_8.H59.2a',
    level: 'AAA',
    title: 'Link element with missing or empty rel attribute.',
    groupLabel: 'document',
    issueDescription: `Link element with missing or empty rel attribute, which is meant to identify the link type`,
    howToFixTheIssue: [
      `Add a rel attribute with value to the link element if missing and if rel attribute is already present but empty then add a value to the rel attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'link',
                attributes: [
                  {
                    key: 'rel',
                    value: 'Contents',
                  },
                  {
                    key: 'href',
                    value: 'Contents.html',
                  },
                  {
                    key: 'title',
                    value: 'Table of Contents',
                  },
                ],
                content: [],
                closingTag: null,
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'rel',
      attributeValue: 'descriptive rel value',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-location',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_8.H59.2b',
    level: 'AAA',
    title: 'Link element with missing or empty href attribute.',
    groupLabel: 'document',
    issueDescription: `Link element with missing or empty href attribute, which is supposed to point to the resource being linked.`,
    howToFixTheIssue: [
      `Add a href attribute with value to the link element if missing and if href attribute is already present but empty then add a value to the href attribute.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'link',
                attributes: [
                  {
                    key: 'rel',
                    value: 'Contents',
                  },
                  {
                    key: 'href',
                    value: 'Contents.html',
                  },
                  {
                    key: 'title',
                    value: 'Table of Contents',
                  },
                ],
                content: [],
                closingTag: null,
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'href',
      attributeValue: dummyHrefLink,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-location',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.2',
    level: 'A',
    title: 'HTML element with no language attributes.',
    groupLabel: 'document',
    issueDescription: `HTML element with no language attributes. The primary language of the page must be specified in the document, so it can be examined in an environment outside of a server.`,
    howToFixTheIssue: [
      `Add a a lang or xml:lang attribute to describes the language of the document.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [
          {
            key: 'lang',
            value: 'en',
          },
        ],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'link',
                attributes: [
                  {
                    key: 'rel',
                    value: 'Contents',
                  },
                  {
                    key: 'href',
                    value: 'Contents.html',
                  },
                  {
                    key: 'title',
                    value: 'Table of Contents',
                  },
                ],
                content: [],
                closingTag: null,
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'lang',
      attributeValue: 'en',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-doc-lang-id',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.3.Lang',
    level: 'A',
    title: 'HTML element with a lang attribute',
    groupLabel: 'document',
    issueDescription: `The language specified in the lang attribute of the document element does not appear to be well-formed.`,
    howToFixTheIssue: [
      `Check the value of the lang attribute and make sure it is correct. Valid values include "fr", "en".`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [
          {
            key: 'lang',
            value: 'en',
          },
        ],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'link',
                attributes: [
                  {
                    key: 'rel',
                    value: 'Contents',
                  },
                  {
                    key: 'href',
                    value: 'Contents.html',
                  },
                  {
                    key: 'title',
                    value: 'Table of Contents',
                  },
                ],
                content: [],
                closingTag: null,
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'lang',
      attributeValue: 'en',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-doc-lang-id',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.3.XmlLang',
    level: 'A',
    title:
      'HTML element with an xml:lang attribute (as used by XHTML) that is not well-formed.',
    groupLabel: 'document',
    issueDescription: `The language specified in the xml:lang attribute of the document element does not appear to be well-formed.`,
    howToFixTheIssue: [
      `Check the value of the xml:lang attribute and make sure it is correct. Valid values include "fr", "en".`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [
          {
            key: 'xml:lang',
            value: 'en',
          },
        ],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
              {
                openingTagName: 'link',
                attributes: [
                  {
                    key: 'rel',
                    value: 'Contents',
                  },
                  {
                    key: 'href',
                    value: 'Contents.html',
                  },
                  {
                    key: 'title',
                    value: 'Table of Contents',
                  },
                ],
                content: [],
                closingTag: null,
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: '',
                attributes: [],
                content: '...some text here...',
                closingTag: '',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'xml:lang',
      attributeValue: 'en',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-doc-lang-id',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_2.H58',
    level: 'AA',
    title: 'Language of parts.',
    groupLabel: 'document',
    issueDescription: `Identifying changes in language in the content of the document.`,
    howToFixTheIssue: [
      `Ensure that any change in language is marked using the lang and/or xml:lang attribute on an element, is appropriate and well formed.`,
    ],
    exampleCode: [
      {
        openingTagName: 'blockquote',
        attributes: [
          {
            key: 'xml:lang',
            value: 'de',
          },
        ],
        content: [
          {
            openingTagName: 'p',
            attributes: [],
            content: dummyGermanContent,
            closingTag: 'p',
          },
        ],
        closingTag: 'blockquote',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'xml:lang',
      attributeValue: 'en',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-other-lang-id',
  },
  {
    principle:
      'WCAG2AA.Principle2.Guideline2_4.2_4_5.G125,G64,G63,G161,G126,G185',
    level: 'AA',
    title: 'Multiple ways',
    groupLabel: 'general',
    issueDescription: `User's ability to locate content in multiple ways.`,
    howToFixTheIssue: [
      `If this Web page is not part of a linear process, check that there is more than one way of locating this Web page within a set of Web pages E.g:`,
      `Creating a list of links if not present to navigate users to different content or pages.`,
      `Providing a search function to help users find content.`,
      `Providing a site map.`,
      `After activating the link, the keyboard focus has moved to the main content.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-mult-loc',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_10.C32,C31,C33,C38,SCR34,G206.Scrolling',
    level: 'AA',
    title: 'Preformatted text i.e "pre" tag elements',
    groupLabel: 'general',
    issueDescription: `Preformatted text i.e "pre" tag elements which may require scrolling in two dimensions, and is considered a failure of this Success Criterion.`,
    howToFixTheIssue: [
      `Make sure that text can be resized without assistive technology up to 200 percent without requiring the user to scroll horizontally on a full-screen window.`,
      `Use liquid or fluid layouts that will resize with text, and reflow as needed to display the region on the screen.`,
      `Liquid layouts can be achieved by positioning the layout regions as a row of adjacent floating boxes, which wrap to new rows as needed in much the same way as words in a paragraph wrap.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#reflow',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_3.1_3_6_ARIA11.Check',
    level: 'AAA',
    title: 'Identify purpose',
    groupLabel: 'general',
    issueDescription: `The purpose of User Interface Components, icons, and regions should be able to be programmatically determined to help screen readers and users with disabilities.`,
    howToFixTheIssue: [
      `Provide appropriate landmark roles. Landmarks programmatically identify sections of a page. Example of landmark roles includes:`,
      `Banner: A region that contains the prime heading or internal title of a page.`,
      `Complementary: Any section of the document that supports the main content, yet is separate and meaningful on its own.`,
      `Contentinfo: A region that contains information about the parent document such as copyrights and links to privacy statements.`,
      `Form: A region of the document that represents a collection of form-associated elements, some of which can represent editable values that can be submitted to a server for processing.`,
      `Main: Main content in a document. In almost all cases a page will have only one role="main".`,
      `Navigation: A collection of links suitable for use when navigating the document or related documents.`,
      `Search: The search tool of a Web document.`,
    ],
    exampleCode: [
      {
        openingTagName: 'div',
        attributes: [
          {
            key: 'id',
            value: 'header',
          },
          {
            key: 'role',
            value: 'banner',
          },
        ],
        content: 'A banner image and introductory title.',
        closingTag: 'div',
      },
      {
        openingTagName: 'div',
        attributes: [
          {
            key: 'id',
            value: 'sitesearch',
          },
          {
            key: 'role',
            value: 'search',
          },
        ],
        content: '...',
        closingTag: 'div',
      },
      {
        openingTagName: 'div',
        attributes: [
          {
            key: 'id',
            value: 'nav',
          },
          {
            key: 'role',
            value: 'navigation',
          },
        ],
        content: '...a list of links here...',
        closingTag: 'div',
      },
      {
        openingTagName: 'div',
        attributes: [
          {
            key: 'id',
            value: 'content',
          },
          {
            key: 'role',
            value: 'main',
          },
        ],
        content: '...everything good starts here...',
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#identify-purpose',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_10.C32,C31,C33,C38,SCR34,G206.Check',
    level: 'AA',
    title: 'Presenting content without loss of information.',
    groupLabel: 'general',
    issueDescription: `Content should be presentable without loss of information.`,
    howToFixTheIssue: [
      `Make sure that content can be presented without loss of information or functionality, and without requiring scrolling in two dimensions for:`,
      `Vertical scrolling content at a width equivalent to 320 CSS pixels.`,
      `Horizontal scrolling content at a height equivalent to 256 CSS pixels.`,
      `Parts of the content which require two-dimensional layout for usage or meaning.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#reflow',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_10.C32,C31,C33,C38,SCR34,G206',
    level: 'AA',
    title: 'Elements with "position: fixed"',
    groupLabel: 'general',
    issueDescription: `This element has "position: fixed". This may require scrolling in two dimensions, which is considered a failure of this Success Criterion.`,
    howToFixTheIssue: [
      `Remove CSS style "position: fixed". Consider using other attributes like "position: static" or any other styling so as to prevent 2d scrolling.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#reflow',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline1_4.1_4_10.C32,C31,C33,C38,SCR34,G206.Fixed',
    level: 'AA',
    title: 'Elements with "position: fixed"',
    groupLabel: 'general',
    issueDescription: `This element has "position: fixed". This may require scrolling in two dimensions, which is considered a failure of this Success Criterion.`,
    howToFixTheIssue: [
      `Remove CSS style "position: fixed". Consider using other attributes like "position: static" or any other styling so as to prevent 2d scrolling.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#reflow',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_13_F95.Check',
    level: 'AA',
    title: 'Content on hover or focus.',
    groupLabel: 'general',
    issueDescription: `Additional content that is displayed when a user moves the pointer over a trigger or moves the keyboard focus to the trigger (for example, a pop-up) must remain visible to allow users time to read and interact with the content and must allow the user to move the pointer over the additional content.`,
    howToFixTheIssue: [
      `Make sure that when receiving and then removing pointer hover or keyboard focus triggers additional content to become visible and then hidden, the following are true:`,
      `Dismissable: A mechanism is available to dismiss the additional content without moving pointer hover or keyboard focus, unless the additional content communicates an input error or does not obscure or replace other content.`,
      `Hoverable: If pointer hover can trigger the additional content, then the pointer can be moved over the additional content without the additional content disappearing.`,
      `Persistent: The additional content remains visible until the hover or focus trigger is removed, the user dismisses it, or its information is no longer valid.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#content-on-hover-or-focus',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline1_4.1_4_13.F95',
    level: 'AA',
    title: 'Content on hover or focus.',
    groupLabel: 'general',
    issueDescription: `Additional content that is displayed when a user moves the pointer over a trigger or moves the keyboard focus to the trigger (for example, a pop-up) must remain visible to allow users time to read and interact with the content and must allow the user to move the pointer over the additional content.`,
    howToFixTheIssue: [
      `Make sure that when receiving and then removing pointer hover or keyboard focus triggers additional content to become visible and then hidden, the following are true:`,
      `Dismissable: A mechanism is available to dismiss the additional content without moving pointer hover or keyboard focus, unless the additional content communicates an input error or does not obscure or replace other content.`,
      `Hoverable: If pointer hover can trigger the additional content, then the pointer can be moved over the additional content without the additional content disappearing.`,
      `Persistent: The additional content remains visible until the hover or focus trigger is removed, the user dismisses it, or its information is no longer valid.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#content-on-hover-or-focus',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.DblClick',
    level: 'A',
    title: 'Elements with an inline ondblclick attribute.',
    groupLabel: 'general',
    issueDescription: `Elements with an inline ondblclick attribute.`,
    howToFixTheIssue: [
      `Ensure the functionality provided by double-clicking on this element is available through the keyboard.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-keyboard-operable',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseOver',
    level: 'A',
    title: 'Elements with an inline onmouseover attribute.',
    groupLabel: 'general',
    issueDescription: `Elements with an inline onmouseover attribute.`,
    howToFixTheIssue: [
      `Make sure the functionality provided by mousing over this element is available through the keyboard; for instance, using the focus event.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-keyboard-operable',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseOut',
    level: 'A',
    title: 'Elements with an inline onmouseout attribute.',
    groupLabel: 'general',
    issueDescription: `Elements with an inline onmouseout attribute.`,
    howToFixTheIssue: [
      `Make sure the functionality provided by mousing out of this element is available through the keyboard; for instance, using the blur event.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-keyboard-operable',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseMove',
    level: 'A',
    title: 'Elements with an inline onmousemove attribute.',
    groupLabel: 'general',
    issueDescription: `Elements with an inline onmousemove attribute.`,
    howToFixTheIssue: [
      `Ensure the functionality provided by moving the mouse on this element is available through the keyboard.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-keyboard-operable',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseDown',
    level: 'A',
    title: 'Elements with an inline onmousedown attribute.',
    groupLabel: 'general',
    issueDescription: `Elements with an inline onmousedown attribute.`,
    howToFixTheIssue: [
      `Make sure the functionality provided by mousing down on this element is available through the keyboard; for instance, using the keydown event.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-keyboard-operable',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_1.SCR20.MouseUp',
    level: 'A',
    title: 'Elements with an inline onmouseup attribute.',
    groupLabel: 'general',
    issueDescription: `Elements with an inline onmouseup attribute.`,
    howToFixTheIssue: [
      `Make sure the functionality provided by mousing up on this element is available through the keyboard; for instance, using the keyup event.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-keyboard-operable',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_2.F10',
    level: 'A',
    title: 'No keyboard trap',
    groupLabel: 'general',
    issueDescription: `Applets or object elements, where content creates a situation where the user can enter the content using the keyboard, but cannot exit the content using the keyboard.`,
    howToFixTheIssue: [
      `Provide the ability to move the focus away from the element when using the keyboard without closing the user agent or restarting the system.`,
      `Alternatively, if it was an ommission, check the value in the "href" attribute if it's valid.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-keyboard-operation-trapping',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_1.2_1_4.Check',
    level: 'A',
    title: 'Character Key Shortcuts',
    groupLabel: 'general',
    issueDescription: `Users should have control and be able to turn off character key shortcuts.`,
    howToFixTheIssue: [
      `If a keyboard shortcut is implemented in content using only letter (including upper- and lower-case letters), punctuation, number, or symbol characters, then make sure at least one of the following is true:`,
      `Turn off: A mechanism is available to turn the shortcut off.`,
      `Remap: A mechanism is available to remap the shortcut to use one or more non-printable keyboard characters (e.g. Ctrl, Alt, etc).`,
      `Active only on focus: The keyboard shortcut for a user interface component is only active when that component has focus.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#character-key-shortcuts',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_1.2_1_4.Check',
    level: 'A',
    title: 'Character Key Shortcuts',
    groupLabel: 'general',
    issueDescription: `Users should have control and be able to turn off character key shortcuts.`,
    howToFixTheIssue: [
      `If a keyboard shortcut is implemented in content using only letter (including upper- and lower-case letters), punctuation, number, or symbol characters, then make sure at least one of the following is true:`,
      `Turn off: A mechanism is available to turn the shortcut off.`,
      `Remap: A mechanism is available to remap the shortcut to use one or more non-printable keyboard characters (e.g. Ctrl, Alt, etc).`,
      `Active only on focus: The keyboard shortcut for a user interface component is only active when that component has focus.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#character-key-shortcuts',
  },
  {
    principle:
      'WCAG2AA.Principle2.Guideline2_2.2_2_2.SCR33,SCR22,G187,G152,G186,G191',
    level: 'A',
    title: 'Pause, stop, hide',
    groupLabel: 'general',
    issueDescription: `Animations or content that moves, scrolls or blinks longer than 5seconds.`,
    howToFixTheIssue: [
      `Make sure that if any part of the content moves, scrolls or blinks for more than 5 seconds, or auto-updates, that there is a mechanism available to pause, stop, or hide the content via a script for example. Unless it is part of an activity that is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-pause',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_2.F4',
    level: 'A',
    title: 'Elements that have a blink style (text-decoration: blink)',
    groupLabel: 'general',
    issueDescription: `Elements that have a blink style e.g (text-decoration: blink).`,
    howToFixTheIssue: [
      `Create some method (via scripting, for example) to be able to turn these off within 5 seconds. Unless it is part of an activity that is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-pause',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_2.F47',
    level: 'A',
    title: 'Blink elements, in general.',
    groupLabel: 'general',
    issueDescription: `Elements that blinks generally either via (text-decoration: blink) or some other animation.`,
    howToFixTheIssue: [
      `Create some method (via scripting, for example) to be able to turn these off within 5 seconds. Unless it is part of an activity that is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-pause',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_3.G5',
    level: 'AAA',
    title: 'No timing',
    groupLabel: 'general',
    issueDescription: `Users should not be bound by time when viewing content, as they should have enough time to process whatever information the content provides.`,
    howToFixTheIssue: [
      `Make sure that timing is not an essential part of the event or activity presented by the content, except for non-interactive synchronized media and real-time events.`,
      `Remove any time constraints on events or activities except for non-interactive synchronized media and real-time events.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-no-exceptions',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_4.SCR14',
    level: 'AAA',
    title: 'Interruptions',
    groupLabel: 'general',
    issueDescription: `Non essential interruptions or alert should be optional.`,
    howToFixTheIssue: [
      `Make sure that all interruptions (including updates to content) can be postponed or suppressed by the user, except interruptions involving an emergency.`,
      `Use scripts to make non-essential alerts optional.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-postponed',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_2.2_2_5.G105,G181',
    level: 'AAA',
    title: 'Re-authenticating',
    groupLabel: 'general',
    issueDescription: `Loss of data on re-authentication of users.`,
    howToFixTheIssue: [
      `If this Web page is part of a set of Web pages with an inactivity time limit, check that an authenticated user can continue the activity without loss of data after re-authenticating.`,
      `Save data so that a re-authenticated user can still have access to them.`,
      `Encode user data as hidden or by encrypting them on re-authentication pages.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-server-timeout',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_2.2_2_6.Check',
    level: 'A',
    title: 'Timeouts',
    groupLabel: 'general',
    issueDescription: `Sudden timeouts that could affect users actively on the webpage.`,
    howToFixTheIssue: [
      `Make sure that users are warned of the duration of any user inactivity that could cause data loss, unless the data is preserved for more than 20 hours when the user does not take any actions.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-time-limits-no-exceptions',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_3.2_3_1.G19,G176',
    level: 'A',
    title: 'Three Flashes or Below Threshold',
    groupLabel: 'general',
    issueDescription: `Content flashing more than three times in a 1 second period.`,
    howToFixTheIssue: [
      `Make sure that there is no component of the content that flashes more than three times in any 1-second period, or that the size of any flashing area is sufficiently small.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-seizure-does-not-violate',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_3.2_3_2.G19',
    level: 'AAA',
    title: 'Three flashes',
    groupLabel: 'general',
    issueDescription: `Content flashing more than three times in any 1 second period.`,
    howToFixTheIssue: [
      `Make sure that there is no component of the content that flashes more than three times in any 1-second period, as these could cause seizures in some users.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-seizure-three-times',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.H64.2',
    level: 'A',
    title: 'Title attribute of frame',
    groupLabel: 'general',
    issueDescription: `Possible invalid identification of frame and iframe elements.`,
    howToFixTheIssue: [
      `Make sure that the title attribute of this element contains text that identifies the frame.`,
    ],
    exampleCode: [
      {
        openingTagName: 'html',
        attributes: [],
        content: [
          {
            openingTagName: 'head',
            attributes: [],
            content: [
              {
                openingTagName: 'title',
                attributes: [],
                content: 'Accessiblity',
                closingTag: 'title',
              },
            ],
            closingTag: 'head',
          },
          {
            openingTagName: 'body',
            attributes: [],
            content: [
              {
                openingTagName: 'frameset',
                attributes: [
                  {
                    key: 'cols',
                    value: '10%, 90%',
                  },
                ],
                content: [
                  {
                    openingTagName: 'frame',
                    attributes: [
                      {
                        key: 'src',
                        value: 'nav.html',
                      },
                      {
                        key: 'title',
                        value: 'Main menu',
                      },
                    ],
                    content: '',
                    closingTag: null,
                  },
                  {
                    openingTagName: 'frame',
                    attributes: [
                      {
                        key: 'src',
                        value: 'doc.html',
                      },
                      {
                        key: 'title',
                        value: 'Documents',
                      },
                    ],
                    content: '',
                    closingTag: null,
                  },
                ],
                closingTag: 'frameset',
              },
            ],
            closingTag: 'body',
          },
        ],
        closingTag: 'html',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'title',
      attributeValue: dummyTitleValue2,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-rsv',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_1.G1,G123,G124,H69',
    level: 'A',
    title: 'Bypass blocks',
    groupLabel: 'general',
    issueDescription: `It should be possible to bypass blocks and go directly to the main content on a webpage.`,
    howToFixTheIssue: [
      `Provide skip links at the page top that goes straight to the main content.`,
      `Use header elements at the beginning of sections of content.`,
      `Alternatively, you can also use ARIA landmark roles to achieve this. E.g role="navigation", or using a semantic element like "nav" but not both.`,
      `Generally ensure that any common navigation elements can be bypassed; for instance, by use of skip links, header elements, or ARIA landmark roles.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'role',
      attributeValue: 'navigation',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-skip',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_3.H4.2',
    level: 'A',
    title: 'Focus order.',
    groupLabel: 'general',
    issueDescription: `Inconsistent focus order. Usually when tabindex has been inconsistently used, hence the order may not be correct.`,
    howToFixTheIssue: [
      `Check and make sure that if tabindex is used, the tab order specified by the tabindex attributes follows the relationships in the content.`,
    ],
    exampleCode: [
      {
        openingTagName: 'table',
        attributes: [],
        content: [
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [],
                content: 'First name',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: [
                  {
                    openingTagName: 'input',
                    attributes: [
                      {
                        key: 'type',
                        value: 'text',
                      },
                      {
                        key: 'title',
                        value: 'First name of groom',
                      },
                      {
                        key: 'tabindex',
                        value: '1',
                      },
                    ],
                    content: '',
                    closingTag: null,
                  },
                ],
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: [
                  {
                    openingTagName: 'input',
                    attributes: [
                      {
                        key: 'type',
                        value: 'text',
                      },
                      {
                        key: 'title',
                        value: 'First name of bride',
                      },
                      {
                        key: 'tabindex',
                        value: '3',
                      },
                    ],
                    content: '',
                    closingTag: null,
                  },
                ],
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
          {
            openingTagName: 'tr',
            attributes: [],
            content: [
              {
                openingTagName: 'th',
                attributes: [],
                content: 'Last name',
                closingTag: 'th',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: [
                  {
                    openingTagName: 'input',
                    attributes: [
                      {
                        key: 'type',
                        value: 'text',
                      },
                      {
                        key: 'title',
                        value: 'Last name of groom',
                      },
                      {
                        key: 'tabindex',
                        value: '2',
                      },
                    ],
                    content: '',
                    closingTag: null,
                  },
                ],
                closingTag: 'td',
              },
              {
                openingTagName: 'td',
                attributes: [],
                content: [
                  {
                    openingTagName: 'input',
                    attributes: [
                      {
                        key: 'type',
                        value: 'text',
                      },
                      {
                        key: 'title',
                        value: 'Last name of bride',
                      },
                      {
                        key: 'tabindex',
                        value: '4',
                      },
                    ],
                    content: '',
                    closingTag: null,
                  },
                ],
                closingTag: 'td',
              },
            ],
            closingTag: 'tr',
          },
        ],
        closingTag: 'table',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-focus-order',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_4.2_4_7.G149,G165,G195,C15,SCR31',
    level: 'AA',
    title: 'Focus visible',
    groupLabel: 'general',
    issueDescription: `Keyboard focus visibility.`,
    howToFixTheIssue: [
      `Make sure that focusable elements receive focus and those focus are visible. Consider using the default focus for things like inputs, or adding a tabindex that is >= 0 for clickable elements to receive focus.`,
      `Take out any styling that entirely removes the default focus visibility of focusable elements.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'tabindex',
      attributeValue: '0',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-navigation-mechanisms-focus-visible',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_1.Check',
    level: 'A',
    title: 'Pointer gestures',
    groupLabel: 'general',
    issueDescription: `Pointer gestures functionality for users that cannot use path based gestures.`,
    howToFixTheIssue: [
      `Make sure that all functionality that uses multipoint or path-based gestures for operation can be operated with a single pointer without a path-based gesture, unless a multipoint or path-based gesture is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#pointer-gestures',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_1.Check',
    level: 'A',
    title: 'Pointer gestures',
    groupLabel: 'general',
    issueDescription: `Pointer gestures functionality for users that cannot use path based gestures.`,
    howToFixTheIssue: [
      `Make sure that all functionality that uses multipoint or path-based gestures for operation can be operated with a single pointer without a path-based gesture, unless a multipoint or path-based gesture is essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#pointer-gestures',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_2.SinglePointer_Check',
    level: 'A',
    title: 'Pointer cancellation',
    groupLabel: 'general',
    issueDescription: `Pointer gestures functionality for users that cannot use path based gestures.`,
    howToFixTheIssue: [
      `Make sure that path-based drag-and-drop action, used to move an item from the initial location to a drop target can be aborted after picking up the target. This can be done either by releasing the item outside a drop area, or by moving the item back to its original position in a separate action that undoes the first action.`,
      `Use pointer's up-event (for example, the onclick or mouseup event) to ensure that users who attempt to interact with a control do not trigger the action of the event accidentally.`,
      `In general make sure that for functionality that can be operated using a single pointer, at least one of the following is true:`,
      `No Down-Event: The down-event of the pointer is not used to execute any part of the function.`,
      `Abort or Undo: Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion.`,
      `Up Reversal: The up-event reverses any outcome of the preceding down-event.`,
      `Essential: Completing the function on the down-event is essential.`,
      `NOTE: Functions that emulate a keyboard or numeric keypad key press are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#pointer-cancellation',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_2.SinglePointer_Check',
    level: 'A',
    title: 'Pointer cancellation',
    groupLabel: 'general',
    issueDescription: `Pointer gestures functionality for users that cannot use path based gestures.`,
    howToFixTheIssue: [
      `Make sure that path-based drag-and-drop action, used to move an item from the initial location to a drop target can be aborted after picking up the target. This can be done either by releasing the item outside a drop area, or by moving the item back to its original position in a separate action that undoes the first action.`,
      `Use pointer's up-event (for example, the onclick or mouseup event) to ensure that users who attempt to interact with a control do not trigger the action of the event accidentally.`,
      `In general make sure that for functionality that can be operated using a single pointer, at least one of the following is true:`,
      `No Down-Event: The down-event of the pointer is not used to execute any part of the function.`,
      `Abort or Undo: Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion.`,
      `Up Reversal: The up-event reverses any outcome of the preceding down-event.`,
      `Essential: Completing the function on the down-event is essential.`,
      `NOTE: Functions that emulate a keyboard or numeric keypad key press are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '75%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#pointer-cancellation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_2.Mousedown_Check',
    level: 'A',
    title: 'All elements with an "onmousedown" event.',
    groupLabel: 'general',
    issueDescription: `Pointer gestures involving elements with an "onmousedown" event.`,
    howToFixTheIssue: [
      `Make sure that path-based drag-and-drop action, used to move an item from the initial location to a drop target can be aborted after picking up the target. This can be done either by releasing the item outside a drop area, or by moving the item back to its original position in a separate action that undoes the first action.`,
      `Use pointer's up-event (for example, the onclick or mouseup event) to ensure that users who attempt to interact with a control do not trigger the action of the event accidentally.`,
      `In general make sure that for functionality that can be operated using a single pointer, at least one of the following is true:`,
      `No Down-Event: The down-event of the pointer is not used to execute any part of the function.`,
      `Abort or Undo: Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion.`,
      `Up Reversal: The up-event reverses any outcome of the preceding down-event.`,
      `Essential: Completing the function on the down-event is essential.`,
      `NOTE: Functions that emulate a keyboard or numeric keypad key press are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#pointer-cancellation',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_2.Mousedown_Check',
    level: 'A',
    title: 'All elements with an "onmousedown" event.',
    groupLabel: 'general',
    issueDescription: `Pointer gestures involving elements with an "onmousedown" event.`,
    howToFixTheIssue: [
      `Make sure that path-based drag-and-drop action, used to move an item from the initial location to a drop target can be aborted after picking up the target. This can be done either by releasing the item outside a drop area, or by moving the item back to its original position in a separate action that undoes the first action.`,
      `Use pointer's up-event (for example, the onclick or mouseup event) to ensure that users who attempt to interact with a control do not trigger the action of the event accidentally.`,
      `In general make sure that for functionality that can be operated using a single pointer, at least one of the following is true:`,
      `No Down-Event: The down-event of the pointer is not used to execute any part of the function.`,
      `Abort or Undo: Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion.`,
      `Up Reversal: The up-event reverses any outcome of the preceding down-event.`,
      `Essential: Completing the function on the down-event is essential.`,
      `NOTE: Functions that emulate a keyboard or numeric keypad key press are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#pointer-cancellation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_2.Touchstart_Check',
    level: 'A',
    title: 'All elements with an "ontouchstart" event.',
    groupLabel: 'general',
    issueDescription: `Pointer gestures involving elements with an "ontouchstart" event.`,
    howToFixTheIssue: [
      `Make sure that path-based drag-and-drop action, used to move an item from the initial location to a drop target can be aborted after picking up the target. This can be done either by releasing the item outside a drop area, or by moving the item back to its original position in a separate action that undoes the first action.`,
      `Use pointer's up-event (for example, the onclick or mouseup event) to ensure that users who attempt to interact with a control do not trigger the action of the event accidentally.`,
      `In general make sure that for functionality that can be operated using a single pointer, at least one of the following is true:`,
      `No Down-Event: The down-event of the pointer is not used to execute any part of the function.`,
      `Abort or Undo: Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion.`,
      `Up Reversal: The up-event reverses any outcome of the preceding down-event.`,
      `Essential: Completing the function on the down-event is essential.`,
      `NOTE: Functions that emulate a keyboard or numeric keypad key press are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#pointer-cancellation',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_2.Touchstart_Check',
    level: 'A',
    title: 'All elements with an "ontouchstart" event.',
    groupLabel: 'general',
    issueDescription: `Pointer gestures involving elements with an "ontouchstart" event.`,
    howToFixTheIssue: [
      `Make sure that path-based drag-and-drop action, used to move an item from the initial location to a drop target can be aborted after picking up the target. This can be done either by releasing the item outside a drop area, or by moving the item back to its original position in a separate action that undoes the first action.`,
      `Use pointer's up-event (for example, the onclick or mouseup event) to ensure that users who attempt to interact with a control do not trigger the action of the event accidentally.`,
      `In general make sure that for functionality that can be operated using a single pointer, at least one of the following is true:`,
      `No Down-Event: The down-event of the pointer is not used to execute any part of the function.`,
      `Abort or Undo: Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion.`,
      `Up Reversal: The up-event reverses any outcome of the preceding down-event.`,
      `Essential: Completing the function on the down-event is essential.`,
      `NOTE: Functions that emulate a keyboard or numeric keypad key press are considered essential.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#pointer-cancellation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_3_F96.Check',
    level: 'A',
    title: 'Label in name.',
    groupLabel: 'general',
    issueDescription: `Labels with text or images of text, the accessibile name should be part of the text.`,
    howToFixTheIssue: [
      `Make sure that for user interface components with labels that include text or images of text, the name contains the text that is presented visually.`,
      `Generally to pass the criteria, for labels make sure the name given to the accessibility api contains part of the content in the labels text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: 'rich.html',
          },
          {
            key: 'aria-label',
            value: 'Read more about Insufficient link names',
          },
        ],
        content: 'Read more',
        closingTag: 'a',
      },
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: 'notification',
              },
              {
                key: 'name',
                value: 'notify',
              },
              {
                key: 'value',
                value: 'delays',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'notification',
              },
            ],
            content: 'Notify me of delays.',
            closingTag: 'label',
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#label-in-name',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_3_F96.Check',
    level: 'A',
    title: 'Label in name.',
    groupLabel: 'general',
    issueDescription: `Labels with text or images of text, the accessibile name should be part of the text.`,
    howToFixTheIssue: [
      `Make sure that for user interface components with labels that include text or images of text, the name contains the text that is presented visually.`,
      `Generally to pass the criteria, for labels make sure the name given to the accessibility api contains part of the content in the labels text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: 'rich.html',
          },
          {
            key: 'aria-label',
            value: 'Read more about Insufficient link names',
          },
        ],
        content: 'Read more',
        closingTag: 'a',
      },
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: 'notification',
              },
              {
                key: 'name',
                value: 'notify',
              },
              {
                key: 'value',
                value: 'delays',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'notification',
              },
            ],
            content: 'Notify me of delays.',
            closingTag: 'label',
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#label-in-name',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_3.F96',
    level: 'A',
    title: 'Label in name.',
    groupLabel: 'general',
    issueDescription: `Labels with text or images of text, the accessibile name should be part of the text.`,
    howToFixTheIssue: [
      `Make sure that for user interface components with labels that include text or images of text, the name contains the text that is presented visually.`,
      `Generally to pass the criteria, for labels make sure the name given to the accessibility api contains part of the content in the labels text.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: 'rich.html',
          },
          {
            key: 'aria-label',
            value: 'Read more about Insufficient link names',
          },
        ],
        content: 'Read more',
        closingTag: 'a',
      },
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: 'notification',
              },
              {
                key: 'name',
                value: 'notify',
              },
              {
                key: 'value',
                value: 'delays',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'notification',
              },
            ],
            content: 'Notify me of delays.',
            closingTag: 'label',
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#label-in-name',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_3_F96.AccessibleName',
    level: 'A',
    title:
      'Anchor (A), button, and input elements. Also applies to all elements with a label.',
    groupLabel: 'general',
    issueDescription: `Anchor (A), button, and input elements and generally all elements with a label, whose accessible name does not contain a visible label text.`,
    howToFixTheIssue: [
      `Make sure that for user interface components with labels that include text or images of text, the name contains the text that is presented visually.`,
      `NOTE: A best practice is to have the text of the label at the start of the name.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: 'rich.html',
          },
          {
            key: 'aria-label',
            value: 'Read more about Insufficient link names',
          },
        ],
        content: 'Read more',
        closingTag: 'a',
      },
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: 'notification',
              },
              {
                key: 'name',
                value: 'notify',
              },
              {
                key: 'value',
                value: 'delays',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'notification',
              },
            ],
            content: 'Notify me of delays.',
            closingTag: 'label',
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#label-in-name',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_3_F96.AccessibleName',
    level: 'A',
    title:
      'Anchor (A), button, and input elements. Also applies to all elements with a label.',
    groupLabel: 'general',
    issueDescription: `Anchor (A), button, and input elements and generally all elements with a label, whose accessible name does not contain a visible label text.`,
    howToFixTheIssue: [
      `Make sure that for user interface components with labels that include text or images of text, the name contains the text that is presented visually.`,
      `NOTE: A best practice is to have the text of the label at the start of the name.`,
    ],
    exampleCode: [
      {
        openingTagName: 'a',
        attributes: [
          {
            key: 'href',
            value: 'rich.html',
          },
          {
            key: 'aria-label',
            value: 'Read more about Insufficient link names',
          },
        ],
        content: 'Read more',
        closingTag: 'a',
      },
      {
        openingTagName: 'div',
        attributes: [],
        content: [
          {
            openingTagName: 'input',
            attributes: [
              {
                key: 'type',
                value: 'checkbox',
              },
              {
                key: 'id',
                value: 'notification',
              },
              {
                key: 'name',
                value: 'notify',
              },
              {
                key: 'value',
                value: 'delays',
              },
            ],
            content: '',
            closingTag: null,
          },
          {
            openingTagName: 'label',
            attributes: [
              {
                key: 'for',
                value: 'notification',
              },
            ],
            content: 'Notify me of delays.',
            closingTag: 'label',
          },
        ],
        closingTag: 'div',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'aria-label',
      attributeValue: dummyAriaLabel,
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#label-in-name',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_4.Check',
    level: 'A',
    title: 'Motion actuation',
    groupLabel: 'general',
    issueDescription: `Elements invloving motion e.g sensor etc.`,
    howToFixTheIssue: [
      `Provide conventional controls and an application setting for motion activated input.`,
      `Make sure that functionality which can be operated by device motion or user motion can also be operated by user interface components and that responding to the motion can be disabled to prevent accidental actuation, except when:`,
      `Supported Interface: The motion is used to operate functionality through an accessibility supported interface.`,
      `Essential: The motion is essential for the function and doing so would invalidate the activity.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '78%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#motion-actuation',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_4.Check',
    level: 'A',
    title: 'Motion actuation',
    groupLabel: 'general',
    issueDescription: `Elements invloving motion e.g sensor etc.`,
    howToFixTheIssue: [
      `Provide conventional controls and an application setting for motion activated input.`,
      `Make sure that functionality which can be operated by device motion or user motion can also be operated by user interface components and that responding to the motion can be disabled to prevent accidental actuation, except when:`,
      `Supported Interface: The motion is used to operate functionality through an accessibility supported interface.`,
      `Essential: The motion is essential for the function and doing so would invalidate the activity.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '78%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#motion-actuation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_4.Devicemotion',
    level: 'A',
    title: 'Elements with an "ondevicemotion" event.',
    groupLabel: 'general',
    issueDescription: `Elements invloving motion e.g sensor etc., with an "ondevicemotion" event.`,
    howToFixTheIssue: [
      `Provide conventional controls and an application setting for motion activated input.`,
      `Make sure that functionality which can be operated by device motion or user motion can also be operated by user interface components and that responding to the motion can be disabled to prevent accidental actuation, except when:`,
      `Supported Interface: The motion is used to operate functionality through an accessibility supported interface.`,
      `Essential: The motion is essential for the function and doing so would invalidate the activity.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '78%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#motion-actuation',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_4.Devicemotion',
    level: 'A',
    title: 'Elements with an "ondevicemotion" event.',
    groupLabel: 'general',
    issueDescription: `Elements invloving motion e.g sensor etc., with an "ondevicemotion" event.`,
    howToFixTheIssue: [
      `Provide conventional controls and an application setting for motion activated input.`,
      `Make sure that functionality which can be operated by device motion or user motion can also be operated by user interface components and that responding to the motion can be disabled to prevent accidental actuation, except when:`,
      `Supported Interface: The motion is used to operate functionality through an accessibility supported interface.`,
      `Essential: The motion is essential for the function and doing so would invalidate the activity.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '78%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#motion-actuation',
  },
  {
    principle: 'WCAG2AA.Principle1.Guideline2_5.2_5_6.Check',
    level: 'AAA',
    title: 'Concurrent Input Mechanisms',
    groupLabel: 'general',
    issueDescription: `Concurrent Input Mechanisms.`,
    howToFixTheIssue: [
      `Check that the content does not restrict use of input modalities available on a platform except where the restriction is essential, required to ensure the security of the content, or required to respect user settings.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '78%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#concurrent-input-mechanisms',
  },
  {
    principle: 'WCAG2AA.Principle2.Guideline2_5.2_5_6.Check',
    level: 'AAA',
    title: 'Concurrent Input Mechanisms',
    groupLabel: 'general',
    issueDescription: `Concurrent Input Mechanisms.`,
    howToFixTheIssue: [
      `Check that the content does not restrict use of input modalities available on a platform except where the restriction is essential, required to ensure the security of the content, or required to respect user settings.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '78%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/#concurrent-input-mechanisms',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_2.H58.1.Lang',
    level: 'AA',
    title: 'Element (other than a HTML element) with a lang attribute',
    groupLabel: 'general',
    issueDescription: `The language specified in the lang attribute of this element does not appear to be well-formed.`,
    howToFixTheIssue: [
      `Ensure that any change in language is marked using the lang and/or xml:lang attribute on an element, is appropriate and well formed.`,
    ],
    exampleCode: [
      {
        openingTagName: 'blockquote',
        attributes: [
          {
            key: 'lang',
            value: 'de',
          },
        ],
        content: [
          {
            openingTagName: 'p',
            attributes: [],
            content: dummyGermanContent,
            closingTag: 'p',
          },
        ],
        closingTag: 'blockquote',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'lang',
      attributeValue: 'en',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-other-lang-id',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_2.H58.1.XmlLang',
    level: 'AA',
    title:
      'Element (other than a HTML element) with an xml:lang attribute (as used by XHTML) that is not well-formed.',
    groupLabel: 'general',
    issueDescription: `The language specified in the xml:lang attribute of this element does not appear to be well-formed.`,
    howToFixTheIssue: [
      `Ensure that any change in language is marked using the lang and/or xml:lang attribute on an element, is appropriate and well formed.`,
    ],
    exampleCode: [
      {
        openingTagName: 'blockquote',
        attributes: [
          {
            key: 'xml:lang',
            value: 'de',
          },
        ],
        content: [
          {
            openingTagName: 'p',
            attributes: [],
            content: dummyGermanContent,
            closingTag: 'p',
          },
        ],
        closingTag: 'blockquote',
      },
    ],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'xml:lang',
      attributeValue: 'en',
      tagValue: '',
    },
    confidenceLevel: '85%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-other-lang-id',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_1.3_1_3.H40,H54,H60,G62,G70',
    level: 'AAA',
    title: 'Unusual words.',
    groupLabel: 'general',
    issueDescription: `Unsual words and phrases in which users can't understand without additional help.`,
    howToFixTheIssue: [
      `Provide the description of names or terms by presenting them in a description list.`,
      `Consider using the dfn element to identify the defining instance of a word.`,
      `Provide a function to search an online dictionary.`,
      `Generally, ensure that there is a mechanism available for identifying specific definitions of words or phrases used in an unusual or restricted way, including idioms and jargon.`,
    ],
    exampleCode: [
      {
        openingTagName: 'dl',
        attributes: [],
        content: [
          {
            openingTagName: 'dt',
            attributes: [],
            content: 'Knot',
            closingTag: 'dt',
          },
          {
            openingTagName: 'dd',
            attributes: [],
            content: [
              {
                openingTagName: 'p',
                attributes: [],
                content: 'Hello',
                closingTag: 'p',
              },
            ],
            closingTag: 'dd',
          },
          {
            openingTagName: 'dt',
            attributes: [],
            content: 'Grum',
            closingTag: 'dt',
          },
          {
            openingTagName: 'dd',
            attributes: [],
            content: [
              {
                openingTagName: 'p',
                attributes: [],
                content: 'Legends of the world.',
                closingTag: 'p',
              },
            ],
            closingTag: 'dd',
          },
        ],
        closingTag: 'dl',
      },
      {
        openingTagName: 'p',
        attributes: [],
        content: [
          {
            openingTagName: '',
            attributes: [],
            content:
              'The Web Content Accessibility Guidelines require that non-text content has a text alternative.',
            closingTag: '',
          },
          {
            openingTagName: 'dfn',
            attributes: [],
            content: 'Non-text content.',
            closingTag: 'dfn',
          },
          {
            openingTagName: '',
            attributes: [],
            content: '...some remaining text...',
            closingTag: '',
          },
        ],
        closingTag: 'p',
      },
    ],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-meaning-idioms',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_1.G107',
    level: 'A',
    title: 'On focus',
    groupLabel: 'general',
    issueDescription: `Context changing when an element recieves focus.`,
    howToFixTheIssue: [
      `Make sure that a change of context does not occur when any input field receives focus.`,
      `Use "activate" rather than "focus" as a trigger for changes of context, so that a change of context such as form submission only occurs when the user clicks the submit button and not whent the user is tabbing or moving through content on the page.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-consistent-behavior-receive-focus',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_3.G61',
    level: 'AA',
    title: 'Consistent Navigation',
    groupLabel: 'general',
    issueDescription: `Navigation should be consistent on every page.`,
    howToFixTheIssue: [
      `Make sure that navigational mechanisms that are repeated on multiple Web pages occur in the same relative order each time they are repeated, unless a change is initiated by the user.`,
      `Basically present repeated components in the same relative order each time they appear.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-consistent-behavior-consistent-locations',
  },
  {
    principle: 'WCAG2AA.Principle3.Guideline3_2.3_2_4.G197',
    level: 'AA',
    title: 'Consistent Identification',
    groupLabel: 'general',
    issueDescription: `Contents with similar behaviour should be identified the same way accross all pages.`,
    howToFixTheIssue: [
      `Ensure that components that have the same functionality within this Web page are identified consistently in the set of Web pages to which it belongs.`,
      `Basically, make sure that labels, names, and text alternatives are consistent for content that has the same functionality.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: null,
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-consistent-behavior-consistent-functionality',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_1.F77',
    level: 'A',
    title: 'Second and subsequent sightings of an element ID.',
    groupLabel: 'general',
    issueDescription: `Second and subsequent sightings of an element ID.`,
    howToFixTheIssue: [
      `Make sure that this element's ID is unique. Issues like this can occur in a lists where each of the list item accidentally has the same ID repeated all through.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: false,
      hasContent: false,
      removeAttribute: true,
      attributeNameToRemove: 'id',
      content: '',
      attributeKey: '',
      attributeValue: '',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink:
      'https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-ensure-compat-parses',
  },
  {
    principle:
      'WCAG2AA.Principle1.Guideline4_1.4_1_3_ARIA22,G199,ARIA19,G83,G84,G85,G139,G177,G194,ARIA23.Check',
    level: 'AA',
    title: 'Status messages',
    groupLabel: 'general',
    issueDescription: `Status messages receiving focus.`,
    howToFixTheIssue: [
      `Ensure that status messages can be programmatically determined through role or properties such that they can be presented to the user by assistive technologies without receiving focus.`,
      `Use roles such as 'role="live"' or 'role="status"' or 'role="alert"' or 'role="log"' for sequential information updates etc.`,
      `Provide text descriptions to identify required fields that were not completed, in the case of form fields.`,
      `Provide a text description when the user provides information that is not in the list of allowed values if it's a form field.`,
      `You can also create a mechanism that allows users to jump to errors.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'role',
      attributeValue: 'status',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#status-messages',
  },
  {
    principle: 'WCAG2AA.Principle4.Guideline4_1.4_1_3.',
    level: 'AA',
    title: 'Status messages',
    groupLabel: 'general',
    issueDescription: `Status messages receiving focus.`,
    howToFixTheIssue: [
      `Ensure that status messages can be programmatically determined through role or properties such that they can be presented to the user by assistive technologies without receiving focus.`,
      `Use roles such as 'role="live"' or 'role="status"' or 'role="alert"' or 'role="log"' for sequential information updates etc.`,
      `Provide text descriptions to identify required fields that were not completed, in the case of form fields.`,
      `Provide a text description when the user provides information that is not in the list of allowed values if it's a form field.`,
      `You can also create a mechanism that allows users to jump to errors.`,
    ],
    exampleCode: [],
    suggestedCodeFixTemplate: {
      hasTag: false,
      hasAttribute: true,
      hasContent: false,
      content: '',
      attributeKey: 'role',
      attributeValue: 'status',
      tagValue: '',
    },
    confidenceLevel: '80%',
    relatedWCAGLink: 'https://www.w3.org/WAI/WCAG21/quickref/#status-messages',
  },
];
