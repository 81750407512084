import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 90%;
  margin: auto;
`;

export const Tag = styled.div`
  text-transform: uppercase;
  margin-right: 12px;
  border-radius: 8px;
  display: inline-block;
  padding: 4px 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #1e5643;
`;

export const CustomScroll = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  border: 2px solid #f5f7fa;
  border-radius: 8px;
`;
