import React, { useEffect, useMemo, useState } from 'react';
import { DomainsTable } from './domains-table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Domain, getDomains } from 'store/slice/domains-slice';
import { DomainsGraph } from './domains-graph';
import { BusinessDomainsTable } from './business-domains-table';
import {
  BusinessAndOwner,
  getMergedBusinessAndOwner,
} from '../../containers/businesses-page/consts';
import { BusinessAdminService } from '../../api/business-admin-service';
import { useToaster } from '../../contexts/toast-context';
import { BusinessWithDomains } from './consts';
import moment from 'moment';

export const DomainsPage = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const { domains } = useAppSelector((state) => state.domains);
  const [businessesAndOwner, setBusinessesAndOwner] = useState<
    BusinessAndOwner[]
  >([]);
  const [currentTab, setCurrentTab] = useState(0);

  const onTabChange = (evt: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getBusinesses = async () => {
    const { error, data, isSuccess } =
      await BusinessAdminService.getBusinesses();

    if (!isSuccess || !data) {
      setToaster('error', error);
      return;
    }

    const businessAndOwner = getMergedBusinessAndOwner(data);
    setBusinessesAndOwner(businessAndOwner);
  };

  const groupDomainsByBusiness = (
    domains: Domain[],
    businessesAndOwner: BusinessAndOwner[],
  ): BusinessWithDomains[] => {
    return businessesAndOwner.map((business) => {
      const businessDomains = domains.filter(
        (domain) => domain.businessID === business.id,
      );
      const subscriptionsCount = businessDomains.filter(
        ({ txID }) => txID,
      ).length;

      let lastDomainCreatedAt = 0;
      for (const businessDomain of businessDomains) {
        if (businessDomain.registrationDate > lastDomainCreatedAt) {
          lastDomainCreatedAt = businessDomain.registrationDate;
        }
      }

      return {
        ...business,
        domains: businessDomains,
        domainsCount: businessDomains.length,
        subscriptionsCount,
        lastDomainCreatedAt:
          businessDomains.length > 0
            ? moment.unix(lastDomainCreatedAt).format('DD/MM/YYYY')
            : 'N/A',
      };
    });
  };

  const businessesWithDomains: BusinessWithDomains[] = useMemo(
    () => groupDomainsByBusiness(domains, businessesAndOwner),
    [domains, businessesAndOwner],
  );

  useEffect(() => {
    void getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDomains());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1>DomainsPage</h1>
      <DomainsGraph />
      {currentTab === 0 ? (
        <DomainsTable currentTab={currentTab} onTabChange={onTabChange} />
      ) : (
        <BusinessDomainsTable
          currentTab={currentTab}
          onTabChange={onTabChange}
          businessesWithDomains={businessesWithDomains}
        />
      )}
    </>
  );
};
