import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import LoopIcon from '@mui/icons-material/Loop';
import { ComplianceDocumentStatus } from '../../types/compliance-document';

export const statusIconColorMap: Record<
  ComplianceDocumentStatus,
  {
    icon: React.ReactNode;
    color: string;
    text: string;
  }
> = {
  approved: {
    icon: (
      <CheckIcon sx={{ color: '#89DFC3', width: '16px', height: '16px' }} />
    ),
    color: '#89DFC3',
    text: 'Verified',
  },
  declined: {
    icon: <LoopIcon sx={{ color: '#FF9999', width: '16px', height: '16px' }} />,
    color: '#FF9999',
    text: 'Declined',
  },
  pending: {
    icon: <></>,
    color: '#F2DF87',
    text: 'Pending',
  },
  default: {
    icon: <></>,
    color: '#454284',
    text: 'Submit',
  },
};
