import { ThemeProvider } from 'contexts/mui-theme-context';
import { App } from 'containers/app';
import { AdminProvider } from 'contexts/admin-context';
import { ToastProvider } from 'contexts/toast-context';
import { CustomToastContainer } from 'components/custom-toast-container';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { interceptRequestsAndResponses } from 'utils/auth';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ScrollProvider } from '@equally-ai-front/common/src/contexts/scroll-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

interceptRequestsAndResponses();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 1000,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <ThemeProvider>
            <ToastProvider>
              <AdminProvider>
                <ScrollProvider>
                  <App />
                  <CustomToastContainer />
                </ScrollProvider>
              </AdminProvider>
            </ToastProvider>
          </ThemeProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
