import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { DomainUtil } from 'utils/domain';

type LoadingStateValues = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface Domain {
  ID: number;
  domainID: number;
  domainName: string;
  accountName: string;
  email: string;
  subscriptionPlan: string;
  expirationDate: number;
  lastPaymentDate: number;
  archived: boolean;
  invalid: boolean;
  active: boolean;
  hasWidget: boolean;
  hasWidgetIntegrated: boolean;
  nextCapture: string;
  paymentSchedule: string;
  autoRenewal: boolean;
  price: string;
  paymentMethod: string;
  userID: number;
  registrationDate: number;
  txID: string;
}

interface DomainsState {
  loading: LoadingStateValues;
  error: string;
  currentRequestId: string | undefined;
  domains: Domain[];
}

const initialState: DomainsState = {
  loading: 'idle',
  error: '',
  currentRequestId: undefined,
  domains: [],
};

export const getDomains = createAsyncThunk(
  'domains/get-all',
  async (_, { rejectWithValue, requestId, getState }) => {
    const { loading, currentRequestId } = (getState() as RootState).domains;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    try {
      const result = await DomainUtil.getAllDomains();
      return result;
    } catch (err: any) {
      return rejectWithValue(
        err?.response?.data?.message ?? 'Error getting all domains.',
      );
    }
  },
);

export const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDomains.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.domains = action.payload.data;
        }
      })
      .addCase(getDomains.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
          state.error = (action.payload as string) ?? 'Login failed';
        }
      });
  },
});

export const selectIssues = (state: RootState) => state.domains;
export default domainsSlice.reducer;
