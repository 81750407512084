import React, { useState } from 'react';
import { Chip, Typography } from '@mui/material';
import { CustomModal } from 'components/modal/custom-modal';
import styled from 'styled-components';
import { User } from 'store/slice/users-slice';
import { UsersTab } from './users-tab';
import { BusinessRoleModalForm } from './business-role-modal-form';
import { UserBusinessRole } from '../../types';

interface UsersModalProps {
  open: boolean;
  selectedUser: User | null;
  userBusinessRole: UserBusinessRole;
  handleCloseRowPopup: () => void;
  getUsersRoles: () => void;
  handleUserChipDelete: (businessId: number, rolesId: number) => void;
}

export const UsersModal = (props: UsersModalProps) => {
  const {
    open,
    selectedUser,
    userBusinessRole,
    handleCloseRowPopup,
    getUsersRoles,
    handleUserChipDelete,
  } = props;
  const [openBusinessRoleModal, setOpenBusinessRoleModal] = useState(false);

  const handleCloseBusinessRolePopup = () => {
    setOpenBusinessRoleModal(false);
  };

  const getUserModalTitle = (selectedUser: User) => {
    return (
      <>
        <CustomTypography marginBottom="20px">
          {selectedUser.name}, {selectedUser.userID}
        </CustomTypography>

        {selectedUser.email && (
          <UserContainer>
            <InputContainer>
              <Label htmlFor="user-email">Email</Label>
              <CustomUserChip
                label={selectedUser.email}
                onClick={handleUserChipClick}
              />
            </InputContainer>
          </UserContainer>
        )}
      </>
    );
  };

  const handleUserChipClick = () => {};

  return (
    <>
      <CustomModal
        open={open}
        handleClosePopup={handleCloseRowPopup}
        modalTitle={selectedUser ? getUserModalTitle(selectedUser) : null}
        ariaDescribedBy="user modal"
      >
        {selectedUser && (
          <UsersTab
            {...{
              selectedUser,
              userBusinessRole,
              handleUserChipClick,
              handleOpenBusinessRolesModal: () =>
                setOpenBusinessRoleModal(true),
              handleUserChipDelete,
            }}
          />
        )}
      </CustomModal>

      {openBusinessRoleModal && (
        <BusinessRoleModalForm
          {...{
            selectedUser,
            open: openBusinessRoleModal,
            userBusinessRole,
            handleCloseBusinessRolePopup,
            getUsersRoles,
          }}
        />
      )}
    </>
  );
};

const CustomTypography = styled(Typography)``;

const UserContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const CustomUserChip = styled(Chip)`
  && {
    background-color: #454284;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
  }

  &&:hover {
    color: #fff;
    background-color: #454284;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: #020401;
  margin-bottom: 10px;
`;
