import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { Loader } from 'components/loader';
import React, { useCallback, useEffect } from 'react';
import { useParams, Routes, Route, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DomainDetail, getDomainDetail } from 'store/slice/domain-detail-slice';
import { useToaster } from 'contexts/toast-context';
import { NavigationWithContent } from 'components/navigation/navigation-with-content';
import { useDomainNavList } from 'hooks/use-domain-nav-list';
import { DomainGeneralDetails } from './domain-general-details';
import { ImprovedNavigation } from './improved-navigation';
import { Metadata } from './metadata';
import { Landmarks } from './landmarks';
import { MoreRoles } from './more-roles';
import { ElementsRelationship } from './elements-relationship';
import { DynamicElements } from './dynamic-elements';
import { CustomAttributes } from './custom-attributes';

interface DomainDetailPageProps {
  children: React.ReactNode;
}

export const DomainDetailPage = () => {
  const { domainId } = useParams();
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const { loading, domainDetail } = useAppSelector(
    (state) => state.domainDetail,
  );
  const navigate = useNavigate();
  const { navigationList } = useDomainNavList(domainId ?? '');

  const getDomainDetails = useCallback(
    (domainId: string) => {
      const getDetails = async () => {
        const response = await dispatch(getDomainDetail(domainId));
        const { isSuccess, error } =
          response.payload as ApiResponse<DomainDetail>;
        if (!isSuccess) {
          setToaster('error', error);
        }
      };
      void getDetails();
    },
    [dispatch, setToaster],
  );

  useEffect(() => {
    if (!domainId) {
      navigate('/domains');
      return;
    }

    void getDomainDetails(domainId);
  }, [domainId, navigate]);

  return (
    <>
      {loading === 'pending' ? (
        <Loader />
      ) : (
        <NavigationWithContent navigationList={navigationList}>
          {domainDetail && (
            <Title>Domain Name: {domainDetail.domainName}</Title>
          )}
          <Routes>
            <Route index element={<DomainGeneralDetails />} />
            <Route
              path={'widget-config/navigation'}
              element={<ImprovedNavigation />}
            />
            <Route path={'widget-config/metadata'} element={<Metadata />} />
            <Route path={'widget-config/landmarks'} element={<Landmarks />} />
            <Route path={'widget-config/roles'} element={<MoreRoles />} />
            <Route
              path={'widget-config/relationships'}
              element={<ElementsRelationship />}
            />
            <Route
              path={'widget-config/dynamic-elements'}
              element={<DynamicElements />}
            />
            <Route
              path={'widget-config/custom-attributes'}
              element={<CustomAttributes />}
            />
          </Routes>
        </NavigationWithContent>
      )}
    </>
  );
};

const Title = styled.h2`
  font-size: 18px;
`;
