import { Box, Chip, Tab, Tabs, Typography } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import { CustomBusinessTabPanel } from 'containers/businesses-page/custom-business-tabpanel';
import { getAssetReactAppUrl } from '@equally-ai-front/common/src/utils';
import PlusIcon from '@equally-ai-front/common/src/assets/svg/Plus-white.svg';
import { User } from 'store/slice/users-slice';
import { UserBusinessRole } from '../../types';

interface CustomTabProps {
  label: string;
  id: string;
  children?: React.ReactNode;
}

interface UsersTabProps {
  selectedUser: User | null;
  userBusinessRole: UserBusinessRole;
  handleOpenBusinessRolesModal: () => void;
  handleUserChipClick: () => void;
  handleUserChipDelete: (businessId: number, rolesId: number) => void;
}

const TABS: CustomTabProps[] = [
  {
    label: 'User roles',
    id: 'user-roles',
  },
];

export const UsersTab = (props: UsersTabProps) => {
  const {
    selectedUser,
    userBusinessRole,
    handleOpenBusinessRolesModal,
    handleUserChipClick,
    handleUserChipDelete,
  } = props;

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderTabContent = (id: string) => {
    switch (id) {
      case 'user-roles':
        return (
          <BusinessTabContent
            {...{
              selectedUser,
              userBusinessRole,
              handleOpenBusinessRolesModal,
              handleUserChipClick,
              handleUserChipDelete,
            }}
          />
        );
      default:
        return <>{id}</>;
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <CustomBusinessTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="users tabs"
        >
          {TABS.map((tab: CustomTabProps, index: number) => (
            <Tab value={index} key={index} label={tab.label} id={tab.id} />
          ))}
        </CustomBusinessTabs>
        {TABS.map((tab: CustomTabProps, index: number) => {
          return (
            <CustomBusinessTabPanel key={index} value={tabValue} index={index}>
              {renderTabContent(tab.id)}
            </CustomBusinessTabPanel>
          );
        })}
      </Box>
    </>
  );
};

interface BusinessTabContentProps extends UsersTabProps {}

const BusinessTabContent = (props: BusinessTabContentProps) => {
  const {
    userBusinessRole,
    handleOpenBusinessRolesModal,
    handleUserChipClick,
    handleUserChipDelete,
  } = props;
  const { userBusinesses, userBusinessIdToRoles, roles } = userBusinessRole;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-end',
          alignContent: 'center',
        }}
      >
        <AddNewRole onClick={handleOpenBusinessRolesModal}>
          Add Roles{' '}
          <img
            style={{ marginLeft: '8px' }}
            src={getAssetReactAppUrl(PlusIcon)}
          />
        </AddNewRole>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'flex-start',
          alignContent: 'center',
          overflowY: 'scroll',
          minHeight: 'auto',
          maxHeight: '440px',
        }}
      >
        {Object.keys(userBusinesses).length > 0 &&
          Object.entries(userBusinesses).map(([id, name]) => {
            const businessId = parseInt(id, 10);
            return (
              <BusinessContainer key={id}>
                <Typography sx={{ marginBottom: '5px' }}>{name}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                  }}
                >
                  {Object.keys(userBusinessIdToRoles).length > 0 &&
                    userBusinessIdToRoles[businessId].map((rolesId: number) => {
                      return (
                        <CustomUserModifiedChip
                          key={rolesId}
                          label={roles[rolesId]}
                          onClick={handleUserChipClick}
                          onDelete={() =>
                            handleUserChipDelete(businessId, rolesId)
                          }
                        />
                      );
                    })}
                </Box>
              </BusinessContainer>
            );
          })}
      </Box>
    </>
  );
};

const CustomUserModifiedChip = styled(Chip)`
  && {
    background-color: #4542846e;
    color: #fff;
    border-radius: 20px;
    font-weight: 600;
    height: 25px;
    margin-right: 5px;
  }

  &&:hover {
    color: #fff;
    background-color: #4542846e;
  }
`;

const CustomBusinessTabs = styled(Tabs)`
  & .MuiTab-root.Mui-selected {
    color: #454284;
  }
  & .MuiTabs-indicator {
    background-color: #454284;
  }
`;

const AddNewRole = styled.button`
  border: none;
  padding: 7px 20px;
  border-radius: 9px;
  background-color: #454284;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
`;

const BusinessContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding-bottom: 8px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
`;
