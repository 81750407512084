import { useEffect } from 'react';
import { useAdminAuth } from '../contexts/admin-context';
import { useNavigate } from 'react-router-dom';

export const useAdminRedirect = () => {
  const { isAdmin, isLoading } = useAdminAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAdmin) {
      window.location.href = 'https://equally.ai';
      return;
    }
    navigate('/domains', { replace: true });
  }, [isAdmin, isLoading, navigate]);
};
