import React from 'react';
import styled from 'styled-components';

interface ContentProps {
  children: React.ReactNode;
}

export const Content = ({ children }: ContentProps) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  height: 100%;
  width: 95%;
  padding: 0 15px;
`;
