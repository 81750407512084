import qs from 'qs';
import axios from 'axios';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import {
  COGNITO_AUTH_URL,
  COGNITO_CLIENT_ID,
  COGNITO_POOL_ID,
  COGNITO_REDIRECT_URI,
  COGNITO_TOKEN_URL,
} from '../magicValues';
import { jwtDecode } from 'jwt-decode';
import { RegisterPayload } from '../types';

const poolConfig = {
  UserPoolId: COGNITO_POOL_ID || '',
  ClientId: COGNITO_CLIENT_ID || '',
  ProviderType: 'Google',
  RedirectUri: COGNITO_REDIRECT_URI,
  AuthUrl: COGNITO_AUTH_URL,
};

class CognitoError extends Error {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

export interface CognitoResponse {
  idToken?: string | undefined;
  token?: string | undefined;
  refreshToken?: string | undefined;
  user: any | undefined;
  emailVerified?: boolean;
}

interface TokenResponse {
  access_token: string;
  id_token: string;
  refresh_token: string;
}

interface TokenResult {
  isSuccess: boolean;
  data: null | {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  };
}

export const getGoogleUrl = () => {
  return `${poolConfig.AuthUrl}?identity_provider=Google&response_type=code&client_id=${poolConfig.ClientId}&scope=email openid profile&prompt=select_account&redirect_uri=${poolConfig.RedirectUri}`;
};

const userPool = new CognitoUserPool(poolConfig);
let currentUser = userPool.getCurrentUser();
const codeToMessageMap: { [key: string]: string } = {
  UserNotFoundException:
    "Couldn't find your Equally AI account. If you signed up with a Google account, click Login with Google.",
  UsernameExistsException: 'User already exists',
  LimitExceededException:
    'You have exceeded the limit of requests. Please try again later.',
  NotAuthorizedException_CONFIRMED: 'Email already confirmed, please login',
  InvalidParameterException: 'Phone number is invalid',
  InvalidParameterExceptionBusiness: 'Business name is not valid',
  NotAuthorizedException: 'Invalid username or password',
};

function getCurrentUser() {
  return userPool.getCurrentUser();
}

export const registerUserToPool = async ({
  email,
  password,
  phoneNumber,
  displayName,
  userId,
  businessName,
}: RegisterPayload): Promise<CognitoResponse> => {
  const attributes = [
    new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    }),
    new CognitoUserAttribute({
      Name: 'name',
      Value: displayName,
    }),
  ];

  attributes.push(
    new CognitoUserAttribute({
      Name: 'custom:reg_business_name',
      Value: businessName,
    }),
  );

  if (phoneNumber) {
    attributes.push(
      new CognitoUserAttribute({
        Name: 'phone_number',
        Value: phoneNumber,
      }),
    );
  }

  return new Promise((resolve, reject) => {
    userPool.signUp(
      email,
      password,
      attributes,
      [],
      async (err: Error | undefined, data) => {
        if (err) {
          if (err.name === 'UserLambdaValidationException') {
            reject(
              new CognitoError(
                err.message.replace('PreSignUp failed with error ', ''),
                'UserLambdaValidationException',
              ),
            );
            return;
          }

          // @ts-ignore
          let code = err?.code;

          if (
            code === 'InvalidParameterException' &&
            err.message.includes('custom:reg_business_name')
          ) {
            code = 'InvalidParameterExceptionBusiness';
          }

          reject(
            new CognitoError(
              codeToMessageMap[code] || 'Authentication failed',
              // @ts-ignore
              err.code,
            ),
          );
          return;
        }
        // @ts-ignore
        let currentUserId = userId ? userId : data.userSub;
        // @ts-ignore
        resolve({ ...data, userId: currentUserId });
      },
    );
  });
};

export const loginUserToPool = async (
  email: string,
  password: string,
): Promise<CognitoResponse> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
      ValidationData: {},
    });
    let token = '';

    user.authenticateUser(authDetails, {
      onSuccess(session, userConfirmationNecessary) {
        console.log('login successful');
        token = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        const user = session.getIdToken().decodePayload();
        const refreshToken = session.getRefreshToken().getToken();
        resolve({
          token,
          user,
          idToken,
          emailVerified: user.email_verified,
          refreshToken,
        });
      },
      async onFailure(err) {
        try {
          if (err.code === 'UserNotConfirmedException') {
            currentUser = user;
            resolve({ token, user, emailVerified: false });
            return;
          }

          if (err.code === 'UserNotFoundException') {
            resolve({ token: undefined, user: undefined });
            return;
          }

          // const firebaseUser = (
          //   await AuthUtils.onLoginWithBase(email, password)
          // )?.user;
          // if (firebaseUser) {
          //   const newUserToken = await registerUserToPool({
          //     email: firebaseUser.email || '',
          //     displayName: firebaseUser.displayName || '',
          //     password,
          //     userId: firebaseUser.uid,
          //   });
          //
          //   resolve({
          //     token: newUserToken,
          //     user,
          //     emailVerified: firebaseUser.emailVerified,
          //   });
          else {
            reject(
              new CognitoError(
                // @ts-ignore
                codeToMessageMap[err.code] || 'Authentication failed',
                // @ts-ignore
                err.code,
              ),
            );
            return;
          }
        } catch (error) {
          reject(
            new CognitoError(
              codeToMessageMap[err.code] || 'Authentication failed',
              err.code,
            ),
          );
        }
      },
    });
  });
};

export const verifyEmail = (email: string, verificationCode: string) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  let cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(
      verificationCode,
      true,
      function (err, result) {
        if (err) {
          if (err.code === 'NotAuthorizedException') {
            reject(
              new CognitoError(
                codeToMessageMap['NotAuthorizedException_CONFIRMED'] ||
                  'Authentication failed',
                'NotAuthorizedException_CONFIRMED',
              ),
            );
            return;
          }
          reject(err);
        } else {
          resolve(result === 'SUCCESS');
        }
      },
    );
  });
};

export const sendVerificationCode = (email: string) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  let cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.getAttributeVerificationCode('email', {
      onSuccess: () => {
        console.log('Verification code sent successfully.');
        resolve(true);
      },
      onFailure: (err) => {
        console.error('Failed to send verification code:', err);
        reject(false);
      },
    });
  });
};

export const resendConfirmationCode = (email: string) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  let cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export const initiateForgotPassword = (email: string, callback: () => void) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (err: Error) {
        // @ts-ignore
        if (err.code === 'LimitExceededException') {
          reject(
            new CognitoError(
              codeToMessageMap['LimitExceededException'] ||
                'Authentication failed',
              'LimitExceededException',
            ),
          );
          return;
        }
        reject(
          new CognitoError(
            'Could not reset password, please contact customer support.',
            '',
          ),
        );
      },
      inputVerificationCode() {
        callback();
      },
    });
  });
};

export const confirmNewPassword = (
  email: string,
  verificationCode: string,
  newPassword: string,
) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve('Password reset successful');
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};
export const resetPassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  return new Promise((resolve, reject) => {
    let cognitoUser = getCurrentUser();

    if (!cognitoUser) {
      reject(new CognitoError('No user found', ''));
      return;
    }

    cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err) {
        console.error(err);
        return;
      }
      cognitoUser?.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  });
};

export const getTokensFromGoogleCode = async (
  code: string,
): Promise<TokenResult> => {
  try {
    const { data: responseData } = await axios.post<TokenResponse>(
      COGNITO_TOKEN_URL || '',
      qs.stringify({
        grant_type: 'authorization_code',
        client_id: poolConfig.ClientId,
        redirect_uri: poolConfig.RedirectUri,
        code: code,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return {
      isSuccess: true,
      data: {
        accessToken: responseData.access_token,
        idToken: responseData.id_token,
        refreshToken: responseData.refresh_token,
      },
    };
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return {
      isSuccess: false,
      data: null,
    };
  }
};

export const createUserSignInSession = async (
  idTokenFromUrl: string,
  accessTokenFromUrl: string,
  refreshTokenFromUrl: string,
) => {
  const idToken = new CognitoIdToken({ IdToken: idTokenFromUrl });
  const accessToken = new CognitoAccessToken({
    AccessToken: accessTokenFromUrl,
  });
  const refreshToken = new CognitoRefreshToken({
    RefreshToken: refreshTokenFromUrl,
  });

  const session = new CognitoUserSession({
    IdToken: idToken,
    AccessToken: accessToken,
    RefreshToken: refreshToken,
  });
  const decoded = jwtDecode(idTokenFromUrl);

  // @ts-ignore
  const extractedUsername = decoded['cognito:username'] || decoded['sub'];
  const userData = {
    Username: extractedUsername, // The username associated with the session
    Pool: userPool, // Your Cognito User Pool instance
  };
  currentUser = new CognitoUser(userData);
  currentUser.setSignInUserSession(session);
};

export const refreshSession = async (token: string) => {
  const refreshToken = new CognitoRefreshToken({
    RefreshToken: token,
  });

  return new Promise<{
    idToken: string | null;
    refreshToken: string | null;
    isRefreshed: boolean;
  }>((resolve, reject) => {
    currentUser?.refreshSession(
      refreshToken,
      (error, session: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }

        if (session) {
          const sessionIdToken = session.getIdToken().getJwtToken();
          const sessionRefreshToken = session.getRefreshToken().getToken();

          return resolve({
            idToken: sessionIdToken,
            refreshToken: sessionRefreshToken,
            isRefreshed: true,
          });
        }

        resolve({
          idToken: null,
          refreshToken: null,
          isRefreshed: false,
        });
      },
    );
  });
};

export const logout = () => {
  if (currentUser) {
    currentUser.signOut();
  }
};

export const getUserSession = () => {
  return new Promise<{
    idToken: string | null;
    refreshToken: string | null;
  }>((resolve, reject) => {
    currentUser?.getSession(
      (error: Error, session: CognitoUserSession | null) => {
        if (error) {
          reject(error);
        }

        if (session) {
          const refreshToken = session.getRefreshToken().getToken();
          const idToken = session.getAccessToken().getJwtToken();

          return resolve({
            idToken,
            refreshToken,
          });
        }

        resolve({
          idToken: null,
          refreshToken: null,
        });
      },
    );
  });
};
