import { User } from '@equally-ai-front/common/src';

export enum AuthActionTypes {
  AUTHENTICATE = 'AUTHENTICATE',
  USER_DETAIL = 'USER_DETAIL',
  IS_ADMIN_UPDATED = 'IS_ADMIN_UPDATED',
  LOG_OUT = 'LOG_OUT',
  SET_LOADING = 'SET_LOADING',
  SET_ADMIN_PROFILE_INFO = 'SET_ADMIN_PROFILE_INFO',
}

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  isAdmin: boolean;
  isLoading: boolean;
  currentUser: any;
}
export interface AuthAction {
  type: AuthActionTypes;
  payload: any;
}
