import { TableWrapper } from 'components/css-components';
import React from 'react';
import { usersWithRolesFields, UserWithRoles } from '../../consts';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { Table } from '../table/table';

const PAGE_LIMIT = 10;
interface UsersWithRolesProps {
  usersWithRoles: UserWithRoles[];
}

export const UsersWithRoles = (props: UsersWithRolesProps) => {
  const { usersWithRoles } = props;

  const { pageCount, currentData, handlePageChange, handleSort, sortOptions } =
    usePagination(usersWithRoles, PAGE_LIMIT, 'name');

  return (
    <TableWrapper>
      <Table
        currentData={currentData}
        tableFields={usersWithRolesFields}
        tableAriaLabel="Users table"
        emptyDataText="No Users Available"
        handleSort={handleSort}
        sortOptions={sortOptions}
      />
      {currentData.length > 0 && (
        <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
      )}
    </TableWrapper>
  );
};
