import React from 'react';
import styled from 'styled-components';
import LightLogo from '../../assets/svg/light-logo.svg';
import { getAssetReactAppUrl } from '../../utils/media.utils';

interface LogoProps {
  logoText?: string;
  onClick?: () => void;
  logoWidth?: string;
}

export const EquallyAiLogo = (props: LogoProps) => {
  const { onClick, logoWidth, logoText } = props;
  return (
    <Wrapper onClick={onClick}>
      <CompanyLogo
        src={getAssetReactAppUrl(LightLogo)}
        alt="equally ai logo"
        logoWidth={logoWidth}
      />
      <LogoText>{logoText}</LogoText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoText = styled.span`
  font-size: 18px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  margin-left: 5px;
`;

const CompanyLogo = styled.img<{ logoWidth?: string }>`
  width: 24px;
`;
