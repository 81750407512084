import React from 'react';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { DomainsTableBody } from './domains-table-body';
import styled from 'styled-components';
import { withStyles } from '@mui/styles';
import { TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { domainFields as itemFields } from '../../containers/domains-page/consts';
import { SortOption } from '@equally-ai-front/common/src/hooks/use-pagination';
import { Domain } from 'store/slice/domains-slice';

interface DomainsTableProps {
  pageCount: number;
  currentData: any[];
  handlePageChange: (selectedPage: { selected: number }) => void;
  selectedDomains: Record<number, Domain>;
  handleSelectDomain: (payload: { checked: boolean; domainId: number }) => void;
  handleSort: (key: string) => void;
  sortOptions: SortOption;
}
export const DomainsTable = (props: DomainsTableProps) => {
  const {
    pageCount,
    currentData,
    handlePageChange,
    selectedDomains,
    handleSelectDomain,
    handleSort,
    sortOptions,
  } = props;

  return (
    <>
      <TableContainer>
        <StyledDomainsTable
          sx={{ minWidth: 650, tableLayout: 'fixed' }}
          aria-label="domains table"
          stickyHeader
        >
          <DomainsTableHead>
            <DomainsTableRow>
              {Object.values(itemFields).map((itemField) => (
                <CustomTableHeadCell
                  key={itemField.key}
                  style={{
                    maxWidth: itemField.key === 'id' ? '50px' : '200px',
                  }}
                >
                  <TableHeaderCell
                    onClick={() => {
                      handleSort(itemField.key);
                    }}
                  >
                    <span>{itemField.displayName}</span>
                    <TableSortIconWrapper
                      $isvisible={sortOptions[itemField.key] ? 'true' : 'false'}
                    >
                      {sortOptions[itemField.key] ? (
                        sortOptions[itemField.key] === 'desc' ? (
                          <SouthIcon style={{ width: '65%' }} />
                        ) : (
                          <NorthIcon style={{ width: '65%' }} />
                        )
                      ) : (
                        <NorthIcon style={{ width: '65%' }} />
                      )}
                    </TableSortIconWrapper>
                  </TableHeaderCell>
                </CustomTableHeadCell>
              ))}
            </DomainsTableRow>
          </DomainsTableHead>
          <DomainsTableBody
            currentData={currentData}
            selectedDomains={selectedDomains}
            handleSelectDomain={handleSelectDomain}
          />
        </StyledDomainsTable>
      </TableContainer>
      <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
    </>
  );
};

const StyledDomainsTable = styled(MUITable)`
  width: 100%;
  max-width: 100%;
  table-layout: auto;
`;

const DomainsTableHead = styled(TableHead)`
  border-top: none;
  border-bottom: 2px solid #f4f3fe;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const CustomTableHeadCell = withStyles((theme) => ({
  head: {
    verticalAlign: 'bottom',
    border: '2px solid #f5f7fa',
    borderBottom: 'none',
    borderTop: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '200px',
    whiteSpace: 'nowrap',
  },
}))(TableCell);

const TableSortIconWrapper = styled.span<{ $isvisible: 'true' | 'false' }>`
  display: flex;
  align-items: center;
  margin-left: 2px;
  width: 25px;
  height: 25px;
  opacity: ${(p) => (p.$isvisible === 'true' ? '1' : '0')};
`;

const TableHeaderCell = styled.p`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0;
  cursor: pointer;
  width: fit-content;

  &:hover ${TableSortIconWrapper} {
    opacity: 0.5;
  }
`;

export const DomainsTableRow = withStyles((theme) => ({
  root: {
    width: '100%',
    tableLayout: 'fixed',
    display: 'table',

    '&:last-child td, &:last-child th': {
      borderTop: '1px solid #f4f3fe',
      borderRight: '2px solid #f5f7fa',
      borderLeft: '2px solid #f5f7fa',
    },
  },
}))(TableRow);
