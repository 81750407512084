import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComplianceDocumentsAPI } from '../../api/compliance-documents';
import {
  ComplianceDocument,
  ComplianceDocumentsState,
  ComplianceDocumentTemplate,
  ComplianceDocumentTypes,
  UpdateDocumentFormData,
} from '../../types/compliance-document';

export const templateToDocumentInfoMap: Record<
  number,
  {
    title: string;
    description: string;
    country: string;
  }
> = {
  1: {
    title: 'EAA Accessibility Statement',
    description: 'EAA Accessibility Statement',
    country: 'EU Member States',
  },
  2: {
    title: 'Italy Accessibility Declaration',
    description: 'Italian Accessibility Statement Declaration',
    country: 'Italy',
  },
  3: {
    title: 'Israel Accessibility Statement',
    description: 'Accessibility Statement',
    country: 'Israel',
  },
  4: {
    title: 'USA - ADA / Section 508 (Optional)',
    description: 'Accessibility statement',
    country: 'USA',
  },
};

export const COMPLIANCE_DOCUMENT_TEMPLATES: ComplianceDocumentTemplate[] = [
  {
    id: 1,
    type: ComplianceDocumentTypes.ACCESSIBILITY_STATEMENT,
    created_at: new Date('2025-03-02T23:00:00.000Z'),
    updated_at: new Date('2025-03-02T23:00:00.000Z'),
    link: 'https://docs.google.com/document/d/1Bya66_aCjT1nguEMygrYXFJqUt5IFM7j5PKJdyzt8rk/edit?tab=t.0#heading=h.limshm3usf3m',
    // data: {
    //   url: '',
    // },
    ...templateToDocumentInfoMap[1],
  },
  {
    id: 2,
    type: ComplianceDocumentTypes.ACCESSIBILITY_STATEMENT,
    created_at: new Date('2025-03-02T23:00:00.000Z'),
    updated_at: new Date('2024-12-24T23:00:00.000Z'),
    link: 'https://docs.google.com/document/d/1CWEl0fqyr1u5IOb5EmmHG1xYnWg14u1L_N8mIngwDpo/edit?tab=t.0#heading=h.2t9hcwhgzbkl',
    // data: {
    //   url: '',
    // },
    ...templateToDocumentInfoMap[2],
  },
  {
    id: 3,
    type: ComplianceDocumentTypes.ACCESSIBILITY_STATEMENT,
    created_at: new Date('2023-04-13T23:00:00.000Z'),
    updated_at: new Date('2023-04-13T23:00:00.000Z'),
    link: 'https://docs.google.com/document/d/1Imkvf1gRLeLPeEApzwl9TpX7GaBZpJw_eFYWcJ31cko/edit?tab=t.0#heading=h.gjdgxs',
    ...templateToDocumentInfoMap[3],
  },
  {
    id: 4,
    type: ComplianceDocumentTypes.ACCESSIBILITY_STATEMENT,
    updated_at: new Date('2025-03-03T23:00:00.000Z'),
    created_at: new Date('2025-03-03T23:00:00.000Z'),
    link: 'https://docs.google.com/document/d/1MziYZXTsKTj1wteXBtwt4xsl6x8evvYOMc9XjTbQAuU/edit?usp=sharing',
    ...templateToDocumentInfoMap[4],
  },
];

export const initialState: ComplianceDocumentsState = {
  isLoading: false,
  complianceDocuments: [],
};

export const getComplianceDocuments = async (slug: string) => {
  const { error, data, isSuccess } =
    await ComplianceDocumentsAPI.getComplianceDocuments({ slug });
  if (isSuccess && data) {
    return data?.documents || [];
  } else {
    throw new Error(error?.message || 'Failed to fetch compliance documents');
  }
};
export const submitComplianceDocument = async ({
  domain,
  url,
  slug,
  template_id,
}: {
  domain: string;
  url: string;
  slug: string;
  template_id: number;
}) => {
  const { isSuccess, error, data } =
    await ComplianceDocumentsAPI.submitDocumentForApproval({
      type: ComplianceDocumentTypes.ACCESSIBILITY_STATEMENT,
      templateId: template_id,
      domain,
      url,
      slug,
    });

  if (isSuccess && !error && data) {
    return data;
  } else {
    throw new Error(error?.message || 'Failed to submit document');
  }
};
export const getUsersComplianceDocuments = async () => {
  const { error, data, isSuccess } =
    await ComplianceDocumentsAPI.getAllUsersDocuments();

  if (isSuccess && data) {
    return data?.documents || [];
  } else {
    throw new Error(error?.message || 'Failed to fetch compliance documents');
  }
};

export const updateComplianceDocument = async (
  documentData: UpdateDocumentFormData & {
    url: string;
    slug: string;
    template_id: number;
  },
) => {
  const { error, data, isSuccess } =
    await ComplianceDocumentsAPI.updateDocument(documentData);

  if (isSuccess && data) {
    return data?.document;
  } else {
    throw new Error(error?.message || 'Failed to update document');
  }
};

const slice = createSlice({
  name: 'complianceDocuments',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setComplianceDocuments(state, action: PayloadAction<ComplianceDocument>) {
      const filteredComplianceDocuments = state.complianceDocuments.filter(
        ({ id }) => action.payload.id !== id,
      );
      state.complianceDocuments = [
        action.payload,
        ...filteredComplianceDocuments,
      ];
    },
  },
});

export const { setIsLoading, setComplianceDocuments } = slice.actions;
export default slice.reducer;
