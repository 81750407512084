import moment from 'moment';
import { FilterCondition, ItemFilter } from '../types';
import { isNullOrUndefined } from '../../../utils/helpers';

enum DateFilterOperators {
  BEFORE = 'before',
  AFTER = 'after',
  BETWEEN = 'between',
  EQUAL_TO = 'equal_to',
  NOT_EQUAL_TO = 'not_equal_to',
  EXISTS = 'exists',
  DOES_NOT_EXIST = 'does_not_exist',
}

enum StringFilterOperators {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
  EXISTS = 'exists',
  DOES_NOT_EXIST = 'does_not_exist',
  NOT_EQUAL_TO = 'not_equal_to',
  EQUAL_TO = 'equal_to',
  EQUAL_TO_PRESET = 'equal_to_preset',
}

enum BooleanFilterOperators {
  NOT_EQUAL_TO = 'not_equal_to',
  EQUAL_TO = 'equal_to',
}

enum NumberFilterOperators {
  EQUAL_TO = 'equal_to',
  NOT_EQUAL_TO = 'not_equal_to',
  GREATER_THAN = 'greater_than',
  SMALLER_THAN = 'smaller_than',
  BETWEEN = 'between',
  EQUAL_TO_PRESET = 'equal_to_preset',
}

enum ArrayOfStringsOrNumbersFilterOperators {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
}

export const handleDateFilter = (
  itemValue: string,
  filterValue: string,
  filterCondition: string,
  filterValue2?: string,
): boolean => {
  const value = isNaN(Number(itemValue))
    ? new Date(itemValue)
    : Number(itemValue);
  const input = Number(filterValue);
  const formattedValue = moment(value).unix();
  const formattedInput = moment.unix(input).unix();

  if (filterCondition === DateFilterOperators.BEFORE) {
    return formattedValue < formattedInput;
  } else if (filterCondition === DateFilterOperators.AFTER) {
    return formattedValue > formattedInput;
  } else if (filterCondition === DateFilterOperators.EXISTS) {
    return formattedValue === formattedInput;
  } else if (filterCondition === DateFilterOperators.DOES_NOT_EXIST) {
    return formattedValue !== formattedInput;
  } else if (filterCondition === DateFilterOperators.BETWEEN && filterValue2) {
    const input2 = Number(filterValue2);
    const formattedInput2 = moment.unix(input2).unix();
    return formattedValue > formattedInput && formattedValue < formattedInput2;
  }

  return false;
};

export const handleStringFilter = (
  itemValue: string,
  filterValue: string,
  filterCondition: string,
): boolean => {
  const value = itemValue.toLowerCase();
  const input = filterValue.toLowerCase();

  if (filterCondition === StringFilterOperators.CONTAINS) {
    return value.includes(input);
  } else if (filterCondition === StringFilterOperators.DOES_NOT_CONTAIN) {
    return !value.includes(input);
  } else if (
    [
      StringFilterOperators.EXISTS,
      StringFilterOperators.EQUAL_TO,
      StringFilterOperators.EQUAL_TO_PRESET,
    ].includes(filterCondition as StringFilterOperators)
  ) {
    return value === input;
  } else if (
    filterCondition === StringFilterOperators.DOES_NOT_EXIST ||
    filterCondition === StringFilterOperators.NOT_EQUAL_TO
  ) {
    return value !== input;
  }

  return false;
};

export const handleBooleanFilter = (
  itemValue: boolean,
  filterValue: string,
  filterCondition: string,
): boolean => {
  const value = itemValue;
  const input = filterValue.toLowerCase() === 'true';

  if (filterCondition === BooleanFilterOperators.EQUAL_TO) {
    return value === input;
  } else if (filterCondition === BooleanFilterOperators.NOT_EQUAL_TO) {
    return value !== input;
  }

  return false;
};

export const handleNumberFilter = (
  itemValue: string,
  filterValue: string,
  filterCondition: string,
  filterValue2?: string,
): boolean => {
  const value = Number(itemValue);
  const input = Number(filterValue);

  if (
    filterCondition === NumberFilterOperators.EQUAL_TO ||
    filterCondition === NumberFilterOperators.EQUAL_TO_PRESET
  ) {
    return value === input;
  } else if (filterCondition === NumberFilterOperators.NOT_EQUAL_TO) {
    return value !== input;
  } else if (filterCondition === NumberFilterOperators.GREATER_THAN) {
    return value > input;
  } else if (filterCondition === NumberFilterOperators.SMALLER_THAN) {
    return value < input;
  } else if (
    filterCondition === NumberFilterOperators.BETWEEN &&
    filterValue2
  ) {
    const input2 = Number(filterValue2);
    return value > input && value < input2;
  }

  return false;
};

export const handleArrayOfStringsOrNumbersFilter = (
  itemValue: string,
  filterValue: string,
  filterCondition: string,
): boolean => {
  const value = itemValue as unknown as Array<string | number>;
  if (filterCondition === ArrayOfStringsOrNumbersFilterOperators.CONTAINS) {
    return value.includes(filterValue);
  } else if (
    filterCondition === ArrayOfStringsOrNumbersFilterOperators.DOES_NOT_CONTAIN
  ) {
    return !value.includes(filterValue);
  }

  return false;
};

export const isSatisfied = (obj: any, filter: ItemFilter): boolean => {
  let isSatisfied = false;
  const websiteFilterKeyVal = obj[filter.key];
  const filterValue = filter.value || '';

  if (filter.isSatisfied) {
    return filter.isSatisfied(obj, filterValue);
  }

  if (filter.filterCondition === FilterCondition.Exists) {
    return (
      websiteFilterKeyVal !== null &&
      websiteFilterKeyVal !== undefined &&
      websiteFilterKeyVal !== ''
    );
  }

  if (filter.filterCondition === FilterCondition.DoesNotExist) {
    return (
      websiteFilterKeyVal === null ||
      websiteFilterKeyVal === undefined ||
      websiteFilterKeyVal === ''
    );
  }

  if (
    isNullOrUndefined(websiteFilterKeyVal) ||
    isNullOrUndefined(filterValue) ||
    !filter.filterCondition
  ) {
    return isSatisfied;
  }

  if (filter.filterType === 'string') {
    isSatisfied = handleStringFilter(
      websiteFilterKeyVal,
      filterValue,
      filter.filterCondition,
    );
  } else if (filter.filterType === 'boolean') {
    isSatisfied = handleBooleanFilter(
      websiteFilterKeyVal,
      filter.value,
      filter.filterCondition,
    );
  } else if (filter.filterType === 'number') {
    isSatisfied = handleNumberFilter(
      websiteFilterKeyVal,
      filterValue,
      filter.filterCondition,
      filter.value2,
    );
  } else if (filter.filterType === 'date') {
    isSatisfied = handleDateFilter(
      websiteFilterKeyVal,
      filterValue,
      filter.filterCondition,
      filter.value2,
    );
  } else if (filter.filterType === 'array-of-strings-or-numbers') {
    isSatisfied = handleArrayOfStringsOrNumbersFilter(
      websiteFilterKeyVal,
      filterValue,
      filter.filterCondition,
    );
  }

  return isSatisfied;
};
