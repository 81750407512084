import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { StyledButton } from 'containers/coupons-page/coupons-table';
import { LatestDomainScansData } from './websites-table';

interface CsvExportProps {
  latestDomainScansData: LatestDomainScansData[];
}

interface DataRow {
  domainName: string;
  scannedPages: number;
  totalErrors: number;
  previousTotalErrors: number;
  totalWarnings: number;
  previousTotalWarnings: number;
  totalNotices: number;
  previousTotalNotices: number;
  accessibilityScore: number;
}

export const CsvExport = (props: CsvExportProps) => {
  const { latestDomainScansData } = props;
  const headers = [
    { label: 'Domain Name', key: 'domainName' },
    { label: 'Scanned Pages', key: 'scannedPages' },
    { label: 'Critical Issues', key: 'totalErrors' },
    { label: 'Previous Critical Issues', key: 'previousTotalErrors' },
    { label: 'Warning Issues', key: 'totalWarnings' },
    { label: 'Previous Warning Issues', key: 'previousTotalWarnings' },
    { label: 'Notice Issues', key: 'totalNotices' },
    { label: 'Previous Notice Issues', key: 'previousTotalNotices' },
    { label: 'Accessibility Score', key: 'accessibilityScore' },
  ];

  const data: DataRow[] = useMemo(() => {
    return latestDomainScansData.map((scanData) => ({
      domainName: scanData.domainName,
      scannedPages: scanData.scannedPagesCount,
      totalErrors: scanData.latestScanTotals.currentTotals.totalErrors,
      previousTotalErrors: scanData.latestScanTotals.previousTotals.totalErrors,
      totalWarnings: scanData.latestScanTotals.currentTotals.totalWarnings,
      previousTotalWarnings:
        scanData.latestScanTotals.previousTotals.totalWarnings,
      totalNotices: scanData.latestScanTotals.currentTotals.totalNotices,
      previousTotalNotices:
        scanData.latestScanTotals.previousTotals.totalNotices,
      accessibilityScore: scanData.accessibilityScore,
    }));
  }, [latestDomainScansData]);

  return (
    <Wrapper>
      <ExportBtn>
        <StyledCsvLink
          data={data}
          headers={headers}
          filename={`latest-domain-scan-${Date.now().toString(36)}.csv`}
        >
          Export
        </StyledCsvLink>
      </ExportBtn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-right: 20px;
`;

const ExportBtn = styled(StyledButton)`
  margin-right: 0;
  height: 40px;
`;

const StyledCsvLink = styled(CSVLink)`
  text-decoration: none;
  color: #ffffff;

  &:hover {
    color: #2eb7ff;
  }
`;
