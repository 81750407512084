import { NavigationItemList } from 'components/navigation/types';
import { DOMAIN_DETAIL_URLS } from 'containers/routes';

const addDomainIdToUrl = (url: string, domainId: string): string =>
  url.replace(':domainId', domainId);

export const getDomainPageMenu = (domainId: string): NavigationItemList[] => {
  return [
    {
      label: 'General',
      path: addDomainIdToUrl(DOMAIN_DETAIL_URLS.GENERAL, domainId),
    },
    /* {
      label: 'Payment History',
      path: addDomainIdToUrl(DOMAIN_DETAIL_URLS.PAYMENT_HISTORY, domainId),
    },
    {
      label: 'Scan Recommendations',
      path: addDomainIdToUrl(DOMAIN_DETAIL_URLS.SCAN_RECOMMENDATIONS, domainId),
    }, */
    {
      label: 'Widget Configuration',
      path: addDomainIdToUrl(
        DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.NAVIGATION,
        domainId,
      ),
      subItems: [
        {
          label: 'Improved navigation',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.NAVIGATION,
            domainId,
          ),
        },
        {
          label: 'Metadata',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.METADATA,
            domainId,
          ),
        },
        {
          label: 'Landmarks',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.LANDMARKS,
            domainId,
          ),
        },
        {
          label: 'More Roles',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.MORE_ROLES,
            domainId,
          ),
        },
        {
          label: 'Elements relationships',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.RELATIONSHIPS,
            domainId,
          ),
        },
        {
          label: 'Dynamic elements',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.DYNAMIC_ELEMENTS,
            domainId,
          ),
        },
        {
          label: 'Custom Attributes',
          path: addDomainIdToUrl(
            DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.CUSTOM_ATTRIBUTES,
            domainId,
          ),
        },
      ],
    },
  ];
};
