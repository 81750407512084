export const DOMAIN_API = process.env.REACT_APP_HTTP_CLIENT_ENDPOINT;
export const BASE_API_URL = `${DOMAIN_API}/api/v1/adminpanel/a/`;
export const UNAUTH_BASE_API_URL = `${DOMAIN_API}/api/v1/admin/u/`;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DB_URL = process.env.REACT_APP_FIREBASE_DB_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

export const IS_ADMIN = `${DOMAIN_API}/api/v1/adminpanel/a/isadmin`;

export const DELAYED_NAVIGATE_TIMEOUT = 3000;

export const FIELD_EMAIL = 'email';
export const FIELD_CHECKED = 'checked';
export const FIELD_AUTH_PASSWORD = 'password';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

export const TABLE_PAGE_LIMIT = 5;

export const ScreenPx = {
  MOBILE: '900px',
};

export const SUBSCRIPTION_PLAN = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  UNLIMITED: 'unlimited',
};

export const SUBSCRIPTION_DURATION = {
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
  BIANNUALLY: 'biannually',
};

export const SubscriptionDurationMap = {
  [SUBSCRIPTION_DURATION.MONTHLY]: 'Monthly',
  [SUBSCRIPTION_DURATION.ANNUALLY]: 'Annually',
  [SUBSCRIPTION_DURATION.BIANNUALLY]: 'Biannually',
};
