import React from 'react';
import styled from 'styled-components';
import { MenuItemProps, MenuItems } from './MenuItems';
import { MenuOption, UserInfo } from './UserInfo';
import { EquallyAiLogo } from '../equally-ai-logo/equally-ai-logo';
import { MenuItemAsComp, menuItem } from './HeaderWrapper';

interface HeaderDesktopProps {
  mobilePx: string;
  menuItems: menuItem[];
  navigateHome: () => void;
  userInfo: any;
  pathName: string;
  MenuItem: ({ to, label, id }: MenuItemProps) => JSX.Element;
  logoText?: string;
  hasNotificationBell?: boolean;
  containerWidthDesktop?: string;
  customMenuItem?: MenuItemAsComp;
  showLiveTourBtn?: boolean;
  startTourValue?: boolean;
  startTour?: () => void;
  menuOptions?: MenuOption[];
}

export const HeaderDesktop = ({
  mobilePx,
  menuItems,
  navigateHome,
  userInfo,
  pathName,
  MenuItem,
  logoText,
  hasNotificationBell,
  containerWidthDesktop,
  customMenuItem,
  showLiveTourBtn,
  startTour,
  startTourValue,
  menuOptions,
}: HeaderDesktopProps) => {
  return (
    <Wrapper>
      <Container
        mobilePx={mobilePx}
        containerWidthDesktop={containerWidthDesktop}
      >
        <InnerContainer>
          <LogoAndMenu>
            <EquallyAiLogo onClick={navigateHome} logoText={logoText} />
            <MenuItems
              menuItems={menuItems}
              className="menu-items"
              isMobile={false}
              pathName={pathName}
              MenuItem={MenuItem}
              customMenuItem={customMenuItem}
              showLiveTourBtn={showLiveTourBtn}
              startTour={startTour}
              startTourValue={startTourValue}
              showCustomMenu={customMenuItem && true}
            />
          </LogoAndMenu>
          <UserInfo
            userInfo={userInfo || {}}
            hasNotificationBell={hasNotificationBell}
            menuOptions={menuOptions}
          />
        </InnerContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  width: 100%;
  background-color: #000a14;
`;

const Container = styled.div<{
  mobilePx: string;
  containerWidthDesktop?: string;
}>`
  width: ${(p) => (p.containerWidthDesktop ? p.containerWidthDesktop : '80%')};
  margin: auto;

  @media screen and (max-width: ${(p) => p.mobilePx}) {
    width: 90%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
`;

const LogoAndMenu = styled.div`
  width: 80%;
  display: flex;
  align-items: center;

  .menu-items {
    margin-left: 50px;
  }
`;
