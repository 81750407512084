import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UsersAdminService } from 'api/users-admin-service';
import type { RootState } from 'store';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { NewBusinessRole } from 'types';

type LoadingStateValues = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface User {
  userID: number;
  name: string;
  firebaseID: string;
  email: string;
  active: string;
  admin: string;
  isTestAccount?: boolean;
  isSpecialDomain?: boolean;
  registrationDate: string;
}

export interface BusinessRole {
  business_id: number;
  role_id: number;
  user_id: number;
}

export type RoleIdToRoleMap = Record<number, string>;
export interface UserRolesAndRolesIdToRoleMap {
  role_id_to_role: RoleIdToRoleMap;
  user_roles: BusinessRole[];
}

interface UsersState {
  loading: LoadingStateValues;
  currentRequestId: string | undefined;
  users: User[];
  userRoles: any;
  businessRoles: NewBusinessRole;
  loadingRoles: boolean;
  userRolesAndRolesIdToRoleMap: UserRolesAndRolesIdToRoleMap;
}

const DEFAULT_USER_ROLES_AND_ROLES_ID_TO_ROLE_MAP = {
  role_id_to_role: {},
  user_roles: [],
};

const initialState: UsersState = {
  loading: 'idle',
  currentRequestId: undefined,
  users: [],
  userRoles: {},
  businessRoles: {} as NewBusinessRole,
  loadingRoles: false,
  userRolesAndRolesIdToRoleMap: DEFAULT_USER_ROLES_AND_ROLES_ID_TO_ROLE_MAP,
};

export const getUsers = createAsyncThunk(
  'users/get-all',
  async (): Promise<ApiResponse<User[]>> => {
    try {
      const { data } = await UsersAdminService.getUsers();
      return {
        data,
        error: null,
        isSuccess: true,
      };
    } catch (err: any) {
      return {
        data: null,
        error: err?.response?.data?.message ?? 'Error getting all users.',
        isSuccess: false,
      };
    }
  },
);

export const editUser = createAsyncThunk(
  'user/edit',
  async (userPayload: User): Promise<ApiResponse<User[]>> => {
    try {
      const { data } = await UsersAdminService.editUser(userPayload);
      return {
        data,
        error: null,
        isSuccess: true,
      };
    } catch (err: any) {
      return {
        data: null,
        error: 'Error editing user',
        isSuccess: false,
      };
    }
  },
);

export const getUserRoles = createAsyncThunk(
  'user/roles',
  async (userId: number): Promise<ApiResponse<any[]>> => {
    try {
      const { data } = await UsersAdminService.getUserRoles(userId);
      return {
        data,
        error: null,
        isSuccess: true,
      };
    } catch (err: any) {
      return {
        data: null,
        error: 'Error getting user roles',
        isSuccess: false,
      };
    }
  },
);

export const addBusinessRoles = createAsyncThunk(
  'user/add-roles',
  async (
    businessRolePayload: BusinessRole,
  ): Promise<ApiResponse<BusinessRole>> => {
    try {
      const { data } =
        await UsersAdminService.addBusinessRoles(businessRolePayload);
      return {
        data,
        error: null,
        isSuccess: true,
      };
    } catch (err: any) {
      return {
        data: null,
        error: 'Error adding business role',
        isSuccess: false,
      };
    }
  },
);

export const deleteBusinessRole = createAsyncThunk(
  'user/delete-role',
  async (
    businessRolePayload: BusinessRole,
  ): Promise<ApiResponse<BusinessRole>> => {
    try {
      const { data } =
        await UsersAdminService.deleteBusinessRole(businessRolePayload);
      return {
        data,
        error: null,
        isSuccess: true,
      };
    } catch (err: any) {
      return {
        data: null,
        error: 'Error deleting business role',
        isSuccess: false,
      };
    }
  },
);

export const getAllUserRoles = createAsyncThunk(
  'users/get-all-roles',
  async (): Promise<ApiResponse<UserRolesAndRolesIdToRoleMap>> => {
    try {
      const { data } = await UsersAdminService.getAllUserRoles();
      return {
        data,
        error: null,
        isSuccess: true,
      };
    } catch (err: any) {
      return {
        data: null,
        error: err?.response?.data?.message ?? 'Error getting all user roles.',
        isSuccess: false,
      };
    }
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.users = action.payload.data ?? [];
        }
      })
      .addCase(getUsers.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(editUser.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(editUser.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
        }
      })
      .addCase(editUser.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(getUserRoles.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getUserRoles.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.userRoles = action.payload.data ?? {};
        }
      })
      .addCase(getUserRoles.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(addBusinessRoles.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(addBusinessRoles.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.businessRoles = action.payload.data ?? ({} as NewBusinessRole);
        }
      })
      .addCase(addBusinessRoles.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(deleteBusinessRole.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteBusinessRole.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.businessRoles = action.payload.data ?? ({} as BusinessRole);
        }
      })
      .addCase(deleteBusinessRole.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(getAllUserRoles.pending, (state, action) => {
        state.loadingRoles = true;
      })
      .addCase(getAllUserRoles.fulfilled, (state, action) => {
        state.loadingRoles = false;
        state.userRolesAndRolesIdToRoleMap =
          action.payload.data ?? DEFAULT_USER_ROLES_AND_ROLES_ID_TO_ROLE_MAP;
      })
      .addCase(getAllUserRoles.rejected, (state, action) => {
        state.loadingRoles = false;
      });
  },
});

export const selectUsersState = (state: RootState) => state.users;
export default usersSlice.reducer;
