import { Box } from '@mui/material';
import React from 'react';
import { Typography } from '@mui/material';
import { PlansTable } from 'containers/subscriptions-page/components/plans-table';
import styled from 'styled-components';
import { SubscriptionTable } from 'containers/subscriptions-page/components/subscription-table';
import { SubscriptionRecordsAndPlans } from 'containers/businesses-redirect/utils/business';

interface SubscriptionsAndPlansProp {
  subscriptionsAndPlans: SubscriptionRecordsAndPlans;
}

const PAGE_LIMIT = 10;
export const SubscriptionsAndPlans = (props: SubscriptionsAndPlansProp) => {
  const { subscriptionsAndPlans } = props;

  return (
    <Box>
      <Box margin="10px 0 25px">
        <Title variant="h6" mb="10px">
          All Business Subscriptions
        </Title>
        <SubscriptionTable
          subscriptionRecords={subscriptionsAndPlans.subscriptionRecords}
          showFilter={false}
          showReviewBanner={false}
          pageLimit={PAGE_LIMIT}
        />
      </Box>
      <Box>
        <Title variant="h6" mb="10px">
          All Business Plans
        </Title>
        <PlansTable
          plans={subscriptionsAndPlans.plans}
          pageLimit={PAGE_LIMIT}
        />
      </Box>
    </Box>
  );
};

const Title = styled(Typography)`
  font-size: 16px;
`;
