import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import Modal from '@mui/material/Modal';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { TABLE_PAGE_LIMIT } from 'utils/constants';
import { Coupon } from 'store/types/coupon-types';
import { TableWrapper } from 'components/css-components';
import { AddCouponForm } from './add-coupon-form';
import { deleteCoupon } from 'store/slice/coupons-slice';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { useToaster } from 'contexts/toast-context';
import { EditCouponForm } from './edit-coupon-form';

const defaultSelectedCoupon = {
  name: '',
  amount: 0,
  total_credits: 0,
};

export const CouponsTable = () => {
  const dispatch = useAppDispatch();
  const { coupons } = useAppSelector((state) => state.coupons);
  const { pageCount, currentData, handlePageChange } = usePagination(
    coupons,
    TABLE_PAGE_LIMIT,
  );
  const { setToaster } = useToaster();
  const [selectedCoupon, setSelectedCoupon] = useState<Partial<Coupon>>(
    defaultSelectedCoupon,
  );
  const [editCoupon, setEditCoupon] = useState(false);
  const [addCoupon, setAddCoupon] = useState(false);

  const handleOpenAddCouponPopup = () => {
    setAddCoupon(true);
  };

  const handleCloseAddCouponPopup = () => {
    setAddCoupon(false);
  };

  const handleOpenEditCouponPopup = (currentCoupon: Coupon) => {
    setSelectedCoupon(currentCoupon);
    setEditCoupon(true);
  };

  const handleCloseEditCouponPopup = () => {
    setSelectedCoupon(defaultSelectedCoupon);
    setEditCoupon(false);
  };

  const handleDeleteCoupon = async (couponId: number) => {
    const response = await dispatch(deleteCoupon(couponId));
    const { isSuccess, error } = response.payload as ApiResponse<string>;

    if (!isSuccess) {
      setToaster('error', error);
    } else {
      setToaster('success', 'Coupon deleted successfully!');
    }
  };

  return (
    <TableWrapper>
      <TableHeaderSection>
        <AddCouponBtn onClick={handleOpenAddCouponPopup}>
          Add Coupon
        </AddCouponBtn>
      </TableHeaderSection>
      <TableContainer>
        <MUITable
          className="table"
          sx={{ minWidth: 650 }}
          aria-label="coupons table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Operator</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.length > 0 ? (
              currentData.map((row, idx) => {
                return (
                  <TableRow
                    key={idx}
                    sx={{
                      height: 80,
                      '&:last-child td, &:last-child th': {
                        border: '0',
                      },
                    }}
                  >
                    <TableCell scope="row">{row.name}</TableCell>
                    <TableCell scope="row">{row.type}</TableCell>
                    <TableCell scope="row">{row.operator}</TableCell>
                    <TableCell scope="row">
                      {row.operator === 'fix' ? row.total_credits : row.amount}
                    </TableCell>
                    <TableCell>
                      <StyledButton
                        variant="contained"
                        color="success"
                        style={{ width: 100 }}
                        onClick={() => handleOpenEditCouponPopup(row)}
                      >
                        Update
                      </StyledButton>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteCoupon(row.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="no-data">
                  No Coupons Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
      {currentData.length > 0 && (
        <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
      )}
      <Modal
        open={addCoupon}
        onClose={handleCloseAddCouponPopup}
        aria-describedby="coupon modal"
      >
        <>
          <AddCouponForm handleClosePopup={handleCloseAddCouponPopup} />
        </>
      </Modal>
      <Modal
        open={editCoupon}
        onClose={handleCloseEditCouponPopup}
        aria-describedby="coupon modal"
      >
        <>
          <EditCouponForm
            coupons={coupons}
            selectedCoupon={selectedCoupon}
            setSelectedCoupon={setSelectedCoupon}
            handleClosePopup={handleCloseEditCouponPopup}
          />
        </>
      </Modal>
    </TableWrapper>
  );
};

const TableHeaderSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const StyledButton = muiStyled(Button)<ButtonProps>(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: theme.palette.primary.main,
  marginRight: 15,

  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const AddCouponBtn = styled(StyledButton)`
  margin-right: 20px;
  height: 40px;
`;
