import React, { useEffect, useMemo, useState } from 'react';
import { Domains } from './tabs/domains';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Domain, getDomains } from 'store/slice/domains-slice';
import { DomainsGraph } from './domains-graph';
import { BusinessDomainsTable } from './business-domains-table';
import {
  BusinessAndOwner,
  getMergedBusinessAndOwner,
} from '../../containers/businesses-page/consts';
import { BusinessAdminService } from '../../api/business-admin-service';
import { useToaster } from '../../contexts/toast-context';
import { BusinessWithDomains } from './consts';
import { groupDomainsByBusiness } from './utils/domains';

export const DomainsPage = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const { domains } = useAppSelector((state) => state.domains);
  const [businessesAndOwner, setBusinessesAndOwner] = useState<
    BusinessAndOwner[]
  >([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedDomains, setSelectedDomains] = useState<
    Record<number, Domain>
  >({});

  const domainsMap: Record<number, Domain> = Object.fromEntries(
    domains.map((domain) => [domain.domainID, domain]),
  );

  const handleSelectDomain = (payload: {
    checked: boolean;
    domainId: number;
  }) => {
    const { checked, domainId } = payload;
    setSelectedDomains((prev) => {
      const updatedDomains = { ...prev };
      if (checked) {
        updatedDomains[domainId] = domainsMap[domainId];
      } else {
        delete updatedDomains[domainId];
      }
      return updatedDomains;
    });
  };

  const handleRemoveSelectedDomain = (domainId: number) => {
    setSelectedDomains((prev) => {
      const updatedDomains = { ...prev };
      delete updatedDomains[domainId];
      return updatedDomains;
    });
  };

  const handleClearAllSelectedDomains = () => {
    setSelectedDomains({});
  };

  const onTabChange = (evt: React.SyntheticEvent, newValue: number) => {
    handleClearAllSelectedDomains();
    setCurrentTab(newValue);
  };

  const getBusinesses = async () => {
    const { error, data, isSuccess } =
      await BusinessAdminService.getBusinesses();

    if (!isSuccess || !data) {
      setToaster('error', error);
      return;
    }

    const businessAndOwner = getMergedBusinessAndOwner(data);
    setBusinessesAndOwner(businessAndOwner);
  };

  const businessesWithDomains: BusinessWithDomains[] = useMemo(
    () => groupDomainsByBusiness(domains, businessesAndOwner),
    [domains, businessesAndOwner],
  );

  useEffect(() => {
    void getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDomains());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1>DomainsPage</h1>
      {currentTab === 0 ? (
        <Domains
          currentTab={currentTab}
          onTabChange={onTabChange}
          handleClearAllSelectedDomains={handleClearAllSelectedDomains}
          handleRemoveSelectedDomain={handleRemoveSelectedDomain}
          selectedDomains={selectedDomains}
          handleSelectDomain={handleSelectDomain}
        />
      ) : (
        <BusinessDomainsTable
          currentTab={currentTab}
          onTabChange={onTabChange}
          businessesWithDomains={businessesWithDomains}
          handleClearAllSelectedDomains={handleClearAllSelectedDomains}
          handleRemoveSelectedDomain={handleRemoveSelectedDomain}
          selectedDomains={selectedDomains}
          handleSelectDomain={handleSelectDomain}
        />
      )}
      <DomainsGraph />
    </>
  );
};
