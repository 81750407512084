import React from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { TableComponent } from './table';
import { ActionToolbar } from 'components/action-toolbar';

interface WidgetConfigTableSectionProps {
  title: string;
  columns: any[];
  isLoading: boolean;
  dataSource: any[];
  onAdd?: () => void;
  tableCellWordBreak: string;
}

export const WidgetConfigTableSection = ({
  title,
  columns,
  isLoading,
  dataSource,
  onAdd,
  tableCellWordBreak,
}: WidgetConfigTableSectionProps) => {
  return (
    <>
      <ActionToolbar title={title}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={onAdd}
        >
          Add
        </Button>
      </ActionToolbar>
      <TableComponent
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        size="small"
        wordBreak={tableCellWordBreak}
      />
    </>
  );
};
