import React from 'react';
import styled from 'styled-components';
import { EquallyAiLogo } from '../equally-ai-logo/equally-ai-logo';

interface HeaderProps {
  HeaderElement: React.ReactNode;
  isExtension: boolean;
}

export const Header = ({ HeaderElement, isExtension }: HeaderProps) => {
  return (
    <Wrapper className={isExtension ? 'draggable' : ''}>
      <Container>
        <InnerContainer isExtension={isExtension}>
          <>{HeaderElement}</>
          <GroupItems>
            {isExtension ? (
              <EquallyAiLogo logoText="Flowy" />
            ) : (
              <EquallyAiLogo />
            )}
          </GroupItems>
        </InnerContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #000a14;
  position: fixed;
  z-index: 9999999999999;
  top: 0;
`;

const Container = styled.div`
  width: 80%;
  margin: auto;

  @media screen and (max-width: 900px) {
    width: 90%;
  }
`;

const InnerContainer = styled.div<{ isExtension: boolean }>`
  height: 50px;
  display: flex;
  flex-direction: ${(p) => (p.isExtension ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
`;

const GroupItems = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
  height: inherit;
`;
