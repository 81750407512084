import React from 'react';
import styled from 'styled-components';
import { Tag } from 'components/css-components';
import { SUBSCRIPTION_PLAN } from 'utils/constants';

interface SubscriptionPlanProps {
  plan: string;
}

export const SubscriptionPlan = ({ plan }: SubscriptionPlanProps) => {
  if (plan === SUBSCRIPTION_PLAN.SMALL) return <PlanTag>Small</PlanTag>;
  if (plan === SUBSCRIPTION_PLAN.MEDIUM) return <PlanTag>Medium</PlanTag>;
  if (plan === SUBSCRIPTION_PLAN.LARGE) return <PlanTag>Large</PlanTag>;
  if (plan === SUBSCRIPTION_PLAN.UNLIMITED) return <PlanTag>Ultimited</PlanTag>;

  return null;
};

const PlanTag = styled(Tag)`
  background: #a4d2fb;
`;
