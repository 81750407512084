import { useAdminAuth } from 'contexts/admin-context';
import React from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { HeaderWrapper } from '@equally-ai-front/common/src/components/header/HeaderWrapper';
import { ScreenPx } from 'utils/constants';
import { MenuItem } from './menu-item';
import styled from 'styled-components';
import { MENU_ITEMS, ROUTES_URLS } from 'containers/routes';
import { MenuOption } from '@equally-ai-front/common/src/components/header/UserInfo';

interface ProtectedRouteProps {
  redirectPath?: string;
  children: React.ReactNode;
}

export const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}: ProtectedRouteProps) => {
  const { isAuthenticated, user, logOut } = useAdminAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToHomepage = () => {
    navigate('/');
  };

  const logAdminOut = () => {
    logOut();
    navigate(ROUTES_URLS.LOGIN);
  };

  const menuOptions: MenuOption[] = [
    {
      label: 'Log out',
      handleClickAction: logAdminOut,
    },
  ];

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <>
      <HeaderWrapper
        mobilePx={ScreenPx.MOBILE}
        menuItems={MENU_ITEMS}
        navigateHome={navigateToHomepage}
        userInfo={user}
        pathName={location.pathname}
        MenuItem={MenuItem}
        logoText="Admin"
        containerWidthDesktop="95%"
        menuOptions={menuOptions}
      />
      <PageWrapper>{children}</PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  width: 95%;
  margin: 10px auto 40px auto;

  h1 {
    margin: 0 0 40px 0;
  }

  @media screen and (max-width: ${ScreenPx.MOBILE}) {
    margin-top: 70px;

    h1 {
      font-size: 18px;
    }
  }
`;
