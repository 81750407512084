import { LinearProgress } from '@mui/material';
import { useAdminAuth } from 'contexts/admin-context';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from './routes';

export const App = () => {
  const { isLoading } = useAdminAuth();
  const router = useRoutes(routes);

  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <LinearProgress />
        </LoaderWrapper>
      ) : (
        router
      )}
    </>
  );
};

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    width: 80%;
    position: absolute;
  }
`;
