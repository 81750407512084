import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAllCoupons } from 'store/slice/coupons-slice';
import { useToaster } from 'contexts/toast-context';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { Coupon } from 'store/types/coupon-types';
import { CouponsTable } from './coupons-table';

export const CouponsPage = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const { loading } = useAppSelector((state) => state.coupons);

  const getCoupons = async () => {
    const resp = await dispatch(getAllCoupons());
    const { isSuccess, error } = resp.payload as ApiResponse<Coupon[]>;

    if (!isSuccess) {
      setToaster('error', error);
    }
  };

  useEffect(() => {
    void getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Coupons Page</h1>
      <CouponsTable />
      <LoadingView
        open={loading}
        loadingText="Loading coupons..."
        zIndex="13000"
      />
    </div>
  );
};
