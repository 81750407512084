import React from 'react';
import {
  ScansSummary,
  ScanSummaryCountTooltip,
} from './scan-summary-count-tooltip';
import styled from 'styled-components';

interface ScannedPagesSummaryProps {
  scansSummary: ScansSummary[];
  domainName: string;
}

export const ScannedPagesSummary = (props: ScannedPagesSummaryProps) => {
  const { scansSummary, domainName } = props;

  return (
    <Wrapper>
      <ScanSummaryCountTooltip domainName={domainName} scans={scansSummary} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
