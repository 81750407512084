import { ComplianceDocument } from '@equally-ai-front/common/src/types/compliance-document';
import { BusinessWithOwner } from 'types';

interface BusinessSlugAndOwnerEmail {
  businessSlug: string;
  businessOwnerEmail: string;
  businessName: string;
}

export type ComplianceDocumentWithBusinessDetails = BusinessSlugAndOwnerEmail &
  ComplianceDocument;

export const getComplianceDocumentData = (payload: {
  complianceDocuments: ComplianceDocument[];
  businesses: BusinessWithOwner[];
}): ComplianceDocumentWithBusinessDetails[] => {
  const { complianceDocuments, businesses } = payload;

  const businessIdToData = new Map<number, BusinessSlugAndOwnerEmail>();
  for (const { business, owner } of businesses) {
    businessIdToData.set(business.id, {
      businessSlug: business.slug,
      businessOwnerEmail: owner.email,
      businessName: business.name,
    });
  }

  const complianceDocumentsData = complianceDocuments.map(
    ({ data, ...doc }) => {
      const businessData = businessIdToData.get(doc.business_id) || {
        businessSlug: '',
        businessOwnerEmail: '',
        businessName: '',
      };
      return {
        ...doc,
        ...businessData,
        data: { url: data?.url || '' },
        type: doc.type,
      };
    },
  );
  return complianceDocumentsData.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );
};
