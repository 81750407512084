import React from 'react';
import { Chip } from '@mui/material';
import { TableConfig } from 'containers/business-details-page/components/table/consts';

import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import { ComplianceDocumentWithBusinessDetails } from 'containers/documents-redirect/utils';
import { DocumentStatus } from '../document-popup/document-status';

export const complianceDocumentFilterFields: Record<string, FilterField> = {
  businessSlug: {
    key: 'businessSlug',
    displayName: 'Business Slug',
    filterType: 'string',
  },
  url: {
    key: 'url',
    displayName: 'Url',
    filterType: 'string',
  },
  type: {
    key: 'type',
    displayName: 'Type',
    filterType: 'string',
  },
  businessOwnerEmail: {
    key: 'businessOwnerEmail',
    displayName: 'Business Owner Email',
    filterType: 'string',
  },
  status: {
    key: 'status',
    displayName: 'Status',
    filterType: 'string',
  },
  createdAt: {
    key: 'created_at',
    displayName: 'Created At',
    filterType: 'date',
  },
};

export const complianceDocumentsFields: TableConfig<ComplianceDocumentWithBusinessDetails>[] =
  [
    {
      displayName: 'Domain',
      key: 'domain',
    },
    {
      displayName: 'Url',
      key: 'data',
      format: (document: ComplianceDocumentWithBusinessDetails) =>
        document.data.url,
    },
    {
      displayName: 'Type',
      key: 'type',
      format: (document) => (
        <Chip
          label={document.type}
          variant="outlined"
          sx={{ textTransform: 'capitalize' }}
        />
      ),
    },
    {
      displayName: 'Notes',
      key: 'notes',
    },
    {
      displayName: 'Status',
      key: 'status',
      format: (document: ComplianceDocumentWithBusinessDetails) => (
        <DocumentStatus status={document.status} />
      ),
    },
    {
      displayName: 'Created At',
      key: 'created_at',
    },
  ];
