import React from 'react';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { calculateAccessibilityScore } from '@equally-ai-front/common/src/lib';
import {
  LatestDomainScansData,
  LatestDomainScansFieldMap,
} from './websites-table';
import {
  LatestDomainDetailsScanData,
  latestDomainScansDetailFields,
} from '../../containers/websites-container/consts';

interface DomainDetailsRowProps {
  isDomainDetailsOpen: boolean;
  latestDomainScansData: LatestDomainScansData;
  latestDomainScansDetailsMap: LatestDomainScansFieldMap[];
}

export const DomainDetailsRow = (props: DomainDetailsRowProps) => {
  const {
    isDomainDetailsOpen,
    latestDomainScansData,
    latestDomainScansDetailsMap,
  } = props;

  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={isDomainDetailsOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{ fontSize: '16px' }}
              >
                Domain Summary
              </Typography>
              <Table size="small" aria-label="domains summary">
                <TableHead>
                  <TableRow>
                    {Object.entries(latestDomainScansDetailFields).map(
                      ([_, DomainFieldDetail]) => (
                        <TableCell>{DomainFieldDetail.displayName}</TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {latestDomainScansData.scannedPages.length > 0 ? (
                    latestDomainScansData.scannedPages.map(
                      (pageScan, dpsidx) => {
                        const {
                          error,
                          warning,
                          notice,
                          previousError,
                          previousWarning,
                          previousNotice,
                          url,
                        } = pageScan;
                        const domainScanDetails: LatestDomainDetailsScanData = {
                          pageName: url,
                          latestScanTotals: {
                            currentTotals: {
                              totalErrors: error,
                              totalWarnings: warning,
                              totalNotices: notice,
                            },
                            previousTotals: {
                              totalErrors: previousError,
                              totalWarnings: previousWarning,
                              totalNotices: previousNotice,
                            },
                          },
                          accessibilityScore: calculateAccessibilityScore([
                            {
                              totalErrors: error,
                              totalWarnings: warning,
                              totalNotices: notice,
                            },
                          ]),
                        };

                        return (
                          <TableRow
                            key={`${latestDomainScansData.domainName}-${dpsidx}`}
                          >
                            {latestDomainScansDetailsMap.map((item, index) => (
                              <TableCell
                                key={`domains-table-row-${index}`}
                                align={item.align}
                              >
                                {item.format(domainScanDetails, item.key)}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      },
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        No page scanned yet.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
