// Large 1 Month
// Unlimited 1yr

import { SUBSCRIPTION_DURATION, SUBSCRIPTION_PLAN } from './constants';

// Small 1 Month
export const parseSubscription = (subscription: string) => {
  const preparedString = subscription.toLowerCase() ?? '';

  function getPlan() {
    if (preparedString.includes('small')) return SUBSCRIPTION_PLAN.SMALL;
    if (preparedString.includes('medium')) return SUBSCRIPTION_PLAN.MEDIUM;
    if (preparedString.includes('large')) return SUBSCRIPTION_PLAN.LARGE;
    if (preparedString.includes('unlimited'))
      return SUBSCRIPTION_PLAN.UNLIMITED;
    return '';
  }

  function getDuration() {
    if (preparedString.includes('1yr')) return SUBSCRIPTION_DURATION.ANNUALLY;
    if (preparedString.includes('2yr')) return SUBSCRIPTION_DURATION.BIANNUALLY;
    if (preparedString.includes('1 month'))
      return SUBSCRIPTION_DURATION.MONTHLY;
    return '';
  }

  return {
    plan: getPlan(),
    duration: getDuration(),
  };
};

export const logError = (error: unknown) => {
  // eslint-disable-next-line no-console
  console.error('[Error]: ', error);
};

export const logText = (text: string) => {
  // eslint-disable-next-line no-console
  console.log('[Log]: ', text);
};
