import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import {
  ComplianceDocumentStatus,
  ComplianceDocumentStatusEnum,
} from '@equally-ai-front/common/src/types/compliance-document';
import { statusIconColorMap } from '@equally-ai-front/common/src/components/compliance-documents/consts';

export const DocumentStatus = ({
  status,
}: {
  status: ComplianceDocumentStatus;
}) => {
  const documentStatusIcon = statusIconColorMap[status].icon;
  const documentStatusColor = statusIconColorMap[status].color;
  const documentStatusText = statusIconColorMap[status].text;
  return (
    <Wrapper status={status}>
      <>{documentStatusIcon}</>
      <Typography fontSize="14px" color={documentStatusColor}>
        {documentStatusText}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)<{
  status: ComplianceDocumentStatus;
}>`
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  border-radius: 6px;
  gap: 7px;
  cursor: ${({ status }) =>
    status === ComplianceDocumentStatusEnum.DECLINED ? 'pointer' : ''};
  width: fit-content;
`;
