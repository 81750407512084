import React, { useMemo, useState } from 'react';
import { TableWrapper } from 'components/css-components';
import { useAppSelector } from 'store/hooks';
import {
  Chip,
  FormControl,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Button } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { styled as MuiStyled } from '@mui/material/styles';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import {
  usePagination,
  OrderOptionsEnum,
  SortOption,
} from '@equally-ai-front/common/src/hooks/use-pagination';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import {
  ProviderIDToType,
  getActiveStatusBaseOnExpiration,
  itemFields,
  subscriptionConfig,
} from './consts';
import styled from 'styled-components';
import { CustomModal } from '../../components/modal/custom-modal';
import { RecordFilterContainer } from '@equally-ai-front/common/src/components/filters/record-filter/record-filter-container';
import { SubscriptionAndPlans } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { SubscriptionAndPlansPopup } from './subscriptions-plans-popup';
import { RequiresReviewBanner } from './requires-review-banner';
import { handleIsReviewRequired } from '@equally-ai-front/common/src/lib';
import { useAdminAuth } from 'contexts/admin-context';
import { SubscriptionAdminService } from 'api/subscriptions-admin-service';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { useToaster } from 'contexts/toast-context';
import { isAdminPermitted } from 'utils/permissions';
import CopyIcon from 'assets/svg/copy.svg';

export const SubscriptionTable = () => {
  const { subscriptions } = useAppSelector((state) => state.subscriptions);
  const { currentUser } = useAdminAuth();
  const { setToaster } = useToaster();
  const subRecords = useMemo(
    () =>
      subscriptions.map((sub) => ({
        ...sub,
        ...sub.subscription,
        provider: ProviderIDToType[sub.subscription.provider_id] || '-',
        duration: sub.plans[0].plan_template.duration_unit,
        plan_types: sub.plans.map((plan) => plan.plan_type).join(','),
        review_required: handleIsReviewRequired(sub),
      })),
    [subscriptions],
  );
  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
    handleFilterItems,
  } = useFilter({ filterOptions: itemFields, data: subRecords });
  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const sortFields: SortOption = {
    expiration: OrderOptionsEnum.DESC,
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  };

  const copyContent = async (value: string, successText: string) => {
    try {
      await copyTextToClipboard(value);
      setToaster('success', successText);
    } catch {
      setToaster('error', 'Failed to copy');
    }
  };

  const {
    pageCount,
    currentData,
    sortOptions,
    searchTerm: subscriptionSearchValue,
    handleSearch,
    handlePageChange,
    handleSort,
  } = usePagination(visibleData, 20, 'transaction_id', sortFields);

  const [open, setOpen] = useState(false);
  const [syncingSubscription, setSyncingSubscription] = useState(false);

  const [selectedSubscriptionAndPlans, setSelectSubscriptionAndPlans] =
    useState<SubscriptionAndPlans | null>(null);

  const handleOpenSubscriptionPopup = async (
    currentRecord: SubscriptionAndPlans,
  ) => {
    setSelectSubscriptionAndPlans(currentRecord);
    setOpen(true);
  };

  const handleCloseSubscriptionPopup = () => {
    setSelectSubscriptionAndPlans(null);
    setOpen(false);
  };

  const handleSyncSubscriptionWithProvider = async () => {
    setSyncingSubscription(true);
    const { isSuccess, error } =
      await SubscriptionAdminService.syncSubscriptionWithProvider(
        selectedSubscriptionAndPlans?.subscription?.transaction_id || '',
      );

    if (!isSuccess) {
      setToaster('error', error);
    } else {
      setToaster('success', 'Subscription successfully synced with provider');
    }
    setSyncingSubscription(false);
    handleCloseSubscriptionPopup();
  };

  const getModalTitle = (record: SubscriptionAndPlans | null) => {
    if (!record) {
      return <></>;
    }

    const isPermitted =
      currentUser &&
      isAdminPermitted(
        ['view.admin_subscription_sync', 'write.admin_subscription_sync'],
        currentUser?.permissions_map,
      );

    return (
      <>
        <CustomTypography marginBottom="20px">
          <CustomTypography mb="5px" fontSize="26px">
            Subscription Record
          </CustomTypography>
          {isPermitted && (
            <SyncWithProviderBtn
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleSyncSubscriptionWithProvider}
            >
              Sync
            </SyncWithProviderBtn>
          )}
        </CustomTypography>

        <OwnerContainer>
          <InputContainer>
            <Label htmlFor="owner">
              Transaction ID
              <TransactionIdCopy
                onClick={() => {
                  copyContent(
                    record.subscription.transaction_id,
                    'Transaction ID copied successfully',
                  );
                }}
                src={CopyIcon}
                alt="copy icon"
              />
            </Label>
            <CustomOwnerChip label={record.subscription.transaction_id} />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="owner-email">Amount</Label>
            <CustomOwnerChip
              label={`${record.subscription.payment_amount} ${record.subscription.payment_currency}`}
            />
          </InputContainer>
          {record.subscription.provider_id != 0 && (
            <InputContainer>
              <Label htmlFor="provider">Provider</Label>
              <CustomOwnerChip
                label={`${ProviderIDToType[record.subscription.provider_id]}`}
              />
            </InputContainer>
          )}
          <InputContainer>
            <Label htmlFor="expiration-date">Active</Label>
            {getActiveStatusBaseOnExpiration(record.subscription.expiration)}
          </InputContainer>
        </OwnerContainer>
      </>
    );
  };

  return (
    <>
      <RequiresReviewBanner
        data={visibleData}
        handleFilterItems={handleFilterItems}
        removeFilterType={removeFilterType}
      />
      <SubscriptionTableHeader>
        {visibleData.length > 0 && (
          <MultiFilterSelect
            handleFilterValueChange={handleFilterValueChange}
            handleFilterSecondValueChange={handleFilterSecondValueChange}
            handleFilterOperatorChange={handleFilterOperatorChange}
            searchTerm={searchTerm}
            addFilter={addFilter}
            isFilterFulfilled={isFilterFulfilled}
            setSearchTerm={setSearchTerm}
            handleOptionClick={handleOptionClick}
            handleSearchInputChange={handleSearchInputChange}
            options={filteredOptions}
            currentFilter={newFilter}
            filterValueOptions={filterValueOptions}
          />
        )}
        {subRecords.length > 0 && filters && (
          <RecordFilterContainer
            filters={filters}
            removeFilterType={removeFilterType}
            allowAdditionalInfo
          />
        )}

        <FormControl>
          <SubscriptionSearchBar
            type="text"
            value={subscriptionSearchValue}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              handleSearch(evt.target.value)
            }
            id="search-input"
            placeholder="Search"
            variant="outlined"
          />
        </FormControl>
      </SubscriptionTableHeader>

      <Typography variant="caption" ml="4px">
        {visibleData.length} Subscription records found
      </Typography>

      <TableWrapper>
        <TableContainer>
          <SubscriptionTableComponent
            className="table"
            sx={{ minWidth: 650 }}
            aria-label="Subscription record table"
          >
            <SubscriptionTableHead>
              <SubscriptionTableRow>
                {subscriptionConfig.map((subscription) => (
                  <CustomTableHeadCell
                    key={subscription.key}
                    style={{
                      minWidth: 50,
                      maxWidth: 90,
                    }}
                  >
                    <TableHeaderCell
                      key={subscription.key}
                      onClick={() => {
                        handleSort(subscription.key);
                      }}
                    >
                      {subscription.displayName}
                      <TableSortIconWrapper
                        isvisible={
                          sortOptions[subscription.key] ? 'true' : 'false'
                        }
                      >
                        {sortOptions[subscription.key] ? (
                          sortOptions[subscription.key] ===
                          OrderOptionsEnum.DESC ? (
                            <SouthIcon style={{ width: '65%' }} />
                          ) : (
                            <NorthIcon style={{ width: '65%' }} />
                          )
                        ) : (
                          <NorthIcon style={{ width: '65%' }} />
                        )}
                      </TableSortIconWrapper>
                    </TableHeaderCell>
                  </CustomTableHeadCell>
                ))}
              </SubscriptionTableRow>
            </SubscriptionTableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData.map((currentRecord, idx) => {
                  return (
                    <SubscriptionTableRow
                      key={idx}
                      sx={{
                        height: 40,
                        '&:last-child td, &:last-child th': {
                          border: '0',
                        },
                      }}
                    >
                      {subscriptionConfig.map((subscription) => {
                        if (subscription.isLink) {
                          return (
                            <CustomTableDataCell
                              key={subscription.key}
                              scope="row"
                              onClick={() =>
                                handleOpenSubscriptionPopup(currentRecord)
                              }
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                            >
                              {subscription.format(currentRecord)}
                            </CustomTableDataCell>
                          );
                        }
                        return (
                          <CustomTableDataCell
                            key={subscription.key}
                            scope="row"
                          >
                            {subscription.format(currentRecord)}
                          </CustomTableDataCell>
                        );
                      })}
                    </SubscriptionTableRow>
                  );
                })
              ) : (
                <SubscriptionTableRow>
                  <CustomTableDataCell colSpan={6} className="no-data">
                    No Records Available
                  </CustomTableDataCell>
                </SubscriptionTableRow>
              )}
            </TableBody>
          </SubscriptionTableComponent>
        </TableContainer>
        {currentData.length > 0 && (
          <Pagination
            pageCount={pageCount}
            handlePageChange={handlePageChange}
          />
        )}

        <CustomModal
          open={open}
          handleClosePopup={handleCloseSubscriptionPopup}
          ariaDescribedBy="business modal"
          modalTitle={
            selectedSubscriptionAndPlans
              ? getModalTitle(selectedSubscriptionAndPlans)
              : null
          }
        >
          {selectedSubscriptionAndPlans && (
            <SubscriptionAndPlansPopup
              plans={selectedSubscriptionAndPlans.plans}
            />
          )}
        </CustomModal>
      </TableWrapper>
      <LoadingView
        open={syncingSubscription}
        loadingText="Syncing subscription with provider..."
        zIndex="13000"
      />
    </>
  );
};

const CustomTypography = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OwnerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const CustomOwnerChip = styled(Chip)`
  && {
    background-color: #454284;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
  }

  &&:hover {
    color: #fff;
    background-color: #454284;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
  margin-right: 25px;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: #020401;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const TableSortIconWrapper = styled.span<{ isvisible: 'true' | 'false' }>`
  display: flex;
  align-items: center;
  margin-left: 2px;
  width: 25px;
  height: 25px;
  opacity: ${(p) => (p.isvisible === 'true' ? '1' : '0')};
`;

const SubscriptionTableComponent = styled(MUITable)`
  width: 100%;
  max-width: 100%;
  table-layout: auto;
`;

const SubscriptionTableHead = styled(TableHead)`
  border-top: none;
  border-bottom: 2px solid #f4f3fe;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const SubscriptionTableRow = withStyles((theme) => ({
  root: {
    width: '100%',
    tableLayout: 'fixed',
    display: 'table',

    '&:last-child td, &:last-child th': {
      borderTop: '1px solid #f4f3fe',
      borderRight: 'none',
      borderLeft: '2px solid #f5f7fa',
      width: 140,
    },
  },
}))(TableRow);

const CustomTableHeadCell = withStyles((theme) => ({
  head: {
    verticalAlign: 'bottom',
    border: '2px solid #f5f7fa',
    borderBottom: 'none',
    borderTop: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '200px',
    whiteSpace: 'nowrap',
  },
}))(TableCell);

const TableHeaderCell = styled.p`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0;
  cursor: pointer;
  width: fit-content;

  &:hover ${TableSortIconWrapper} {
    opacity: 0.5;
  }
`;

const CustomTableDataCell = withStyles((theme) => ({
  body: {
    padding: '0.75rem',
    verticalAlign: 'top',
    borderTop: '1px solid #f4f3fe',
    color: 'rgba(0, 0, 0, 0.87)',
    borderLeft: '2px solid #f5f7fa',
    borderRight: '2px solid #f5f7fa',
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:last-child': {
      borderRight: 'none',
    },
  },
}))(TableCell);

const SubscriptionTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 20px;
`;

const SubscriptionSearchBar = MuiStyled(TextField)({
  '.MuiOutlinedInput-input': {
    height: 10,
  },
});

const SyncWithProviderBtn = MuiStyled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    height: 40,
    borderRadius: 7,
    backgroundColor: '#454284',

    '&:hover': {
      backgroundColor: '#454284',
    },
  },
}));

const TransactionIdCopy = styled.img`
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
`;
