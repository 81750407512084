import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DocumentsPage } from '../documents-page/documents-page';
import { useAppDispatch } from 'store/hooks';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { getAllBusinesses } from 'store/slice/business-slice';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { useToaster } from 'contexts/toast-context';
import { BusinessWithOwner } from 'types';

export const DocumentsRedirect = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const [isLoading, setIsLoading] = useState(false);

  const handleGetAllUsersDocuments = async () => {
    try {
      setIsLoading(true);
      const businessesResponse = await dispatch(getAllBusinesses());
      // const {
      //   isSuccess: complianceDocumentsSuccess,
      //   error: complianceDocumentsError,
      // } = complianceDocumentsResponse.payload as ApiResponse<
      //   ComplianceDocument[]
      // >;
      // if (!complianceDocumentsSuccess) {
      //   setToaster('error', complianceDocumentsError);
      // }

      const { isSuccess: isBusinessesSuccess, error: businessesError } =
        businessesResponse.payload as ApiResponse<BusinessWithOwner[]>;
      if (!isBusinessesSuccess) {
        setToaster('error', businessesError);
      }
    } catch (error) {
      setToaster('error', 'An error occurred while fetching data');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    void handleGetAllUsersDocuments();
  }, []);

  return (
    <>
      <Routes>
        <Route index element={<DocumentsPage />} />
      </Routes>
      <LoadingView
        open={isLoading}
        loadingText="Loading compliance documents..."
      />
    </>
  );
};
