import { Business } from '@equally-ai-front/common/src/types/business';
import * as Yup from 'yup';

export interface FormValues {
  business: Business | undefined;
  provider: string;
  transaction_id: string;
  plan_template_id: string;
  plan_template_version: string;
  plan_template_name: string;
  plan_template_price: string;
  plan_template_quantity: string;
  plan_template_duration: string;
  plan_template_type: string;
  expiration_date: string;
}

const planTemplateSchema = Yup.object().shape({
  plan_template_version: Yup.string().required(
    'You have to select a plan template or create a new one',
  ),
  plan_template_id: Yup.number().when(
    'plan_template_version',
    (values, schema) => {
      return values[0] === 'existing'
        ? schema.required('Select an existing template')
        : schema.notRequired();
    },
  ),
  plan_template_name: Yup.string().when(
    'plan_template_version',
    (values, schema) => {
      return values[0] === 'new'
        ? schema.required('Template name is required')
        : schema.notRequired();
    },
  ),
  plan_template_price: Yup.number().when(
    'plan_template_version',
    (values, schema) => {
      return values[0] === 'new'
        ? schema.required('Price is required')
        : schema.notRequired();
    },
  ),
  plan_template_quantity: Yup.number().when(
    'plan_template_version',
    (values, schema) => {
      return values[0] === 'new'
        ? schema.required('Quantity is required')
        : schema.notRequired();
    },
  ),
  plan_template_duration: Yup.string().when(
    'plan_template_version',
    (values, schema) => {
      return values[0] === 'new'
        ? schema.required('Duration is required')
        : schema.notRequired();
    },
  ),
  plan_template_type: Yup.string().when(
    'plan_template_version',
    (values, schema) => {
      return values[0] === 'new'
        ? schema.required('Type is required')
        : schema.notRequired();
    },
  ),
});

const otherValidationSchema = Yup.object({
  business: Yup.object().required('Select a business'),
  provider: Yup.string().required('Select a provider'),
  transaction_id: Yup.string().when('provider', (provider, schema) => {
    return provider[0] !== '1'
      ? schema.required('Please provide a transaction id')
      : schema.notRequired();
  }),
});

export const validationSchema =
  otherValidationSchema.concat(planTemplateSchema);
