import React from 'react';
import { Grid, FormLabel } from '@mui/material';

interface InlineLayoutProps {
  title: string;
  children?: React.ReactNode;
}

export const InlineLayout = ({ title, children }: InlineLayoutProps) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <FormLabel>{title}</FormLabel>
      </Grid>
      <Grid item xs={6}>
        {children}
      </Grid>
    </Grid>
  );
};
