import React, { useMemo } from 'react';
import { get, isString, isFunction } from 'lodash';
import styled from '@emotion/styled';
import isNumber from 'lodash/isNumber';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';
import { LoadingWrapper } from '@equally-ai-front/common/src/components/loading-wrapper/LoadingWrapper';

interface TableProps {
  size: 'small' | 'medium';
  loading: boolean;
  columns: any[];
  dataSource: any[];
  rowKey: string | ((record: unknown) => string);
  wordBreak: string;
}

interface TableHeadProps {
  columns: any[];
  wordBreak: string;
}

interface TableRowProps {
  columns: any[];
  wordBreak: string;
  idx: number;
  dataObject: {
    [key: string]: any;
  };
}

export const TableComponent = ({
  size,
  loading,
  columns,
  dataSource,
  rowKey,
  wordBreak,
}: TableProps) => {
  const rows = useMemo(
    () =>
      dataSource?.map((record, idx) => {
        let key = 'key';
        if (isString(rowKey)) {
          key = get(record, rowKey);
        } else if (isFunction(rowKey)) {
          key = rowKey(record);
        } else {
          key = get(record, 'id');
        }

        return (
          <Row
            key={key}
            columns={columns}
            idx={idx}
            dataObject={record}
            wordBreak={wordBreak}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, dataSource],
  );

  return (
    <LoadingWrapper loading={loading} cover>
      <TableContainer style={{ padding: 12 }}>
        <Table size={size}>
          <Head columns={columns} wordBreak={wordBreak} />
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </LoadingWrapper>
  );
};

const Head = ({ columns, wordBreak }: TableHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map(({ key: colKey, dataIndex, title, width }) => {
          const key = colKey || dataIndex;
          return (
            <StyledTableCell key={key} wd={width} wordBreak={wordBreak}>
              {title}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const Row = ({ dataObject, idx, columns, wordBreak }: TableRowProps) => {
  return (
    <TableRow>
      {columns.map(({ align, dataIndex, render, key: colKey, width }) => {
        const text = get(dataObject, dataIndex);
        const content = render ? render(text, dataObject, idx) : text;
        const key = colKey || dataIndex;

        return (
          <StyledTableCell
            key={key}
            align={align}
            wd={width}
            wordBreak={wordBreak}
          >
            {content}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
};

// Styled Components
const StyledTableCell = styled(TableCell)<{
  wordBreak: string;
  wd?: number;
}>`
  word-break: ${(p) => (p.wordBreak ? p.wordBreak : 'break-all')};
  white-space: normal;
  width: ${({ wd }) => (isNumber(wd) ? `${wd}px` : 'initial')};
  max-width: ${({ wd }) => (isNumber(wd) ? `${wd}px` : 'initial')};
`;
