import React from 'react';
import styled from 'styled-components';
import { ToastType, useToaster } from 'contexts/toast-context';

interface StyledToastProps {
  ['toast-type']: ToastType;
}

export const StyledToast = styled.div<StyledToastProps>`
  position: fixed;
  top: 0;
  left: 0;
  margin: 10px;
  padding: 20px;
  min-width: 200px;
  background-color: #f0f0f0;
  border-left: 8px solid
    ${(props) => (props['toast-type'] === 'success' ? '#4caf50' : '#f44336')};
  color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  transform: translateX(0);
  opacity: 1;
  z-index: 999999999999999;

  &.hide {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const DismissButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
`;

export const CustomToastContainer = () => {
  const { toastList } = useToaster();

  return (
    <div>
      {toastList.map((toast) => (
        <StyledToast key={toast.id} toast-type={toast.type}>
          {toast.message}
          <DismissButton onClick={() => toast.dismiss(toast.id)}>
            &times;
          </DismissButton>
        </StyledToast>
      ))}
    </div>
  );
};
