import React from 'react';
import { Box, Button } from '@mui/material';

interface ColumnProp {
  onEdit: (arg: any) => void;
  onDelete: (arg: any) => void;
}

interface Column {
  title: string;
  key: string;
  dataIndex?: string;
  width?: string;
  render?: (_: any, record: any) => JSX.Element;
}

interface ActionsCompProps extends ColumnProp {
  record: any;
}

const ActionsComp = ({ record, onEdit, onDelete }: ActionsCompProps) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        variant="text"
        color="primary"
        onClick={() => onEdit && onEdit(record)}
      >
        Edit
      </Button>
      <Button
        variant="text"
        color="primary"
        onClick={() => onDelete && onDelete(record)}
      >
        Delete
      </Button>
    </Box>
  );
};

export const getTabIndexColumns = ({
  onEdit,
  onDelete,
}: ColumnProp): Column[] => [
  {
    title: 'Index',
    key: 'index',
    dataIndex: 'index',
    width: '20%',
  },
  {
    title: 'Selector',
    key: 'selector',
    dataIndex: 'selector',
  },
  {
    key: 'actions',
    title: '',
    width: '200px',
    render: (_, record) => (
      <ActionsComp record={record} onDelete={onDelete} onEdit={onEdit} />
    ),
  },
];

export const getLandmarkOrMoreRoleColumns = ({
  onEdit,
  onDelete,
}: ColumnProp): Column[] => [
  {
    title: 'El Selector',
    key: 'selector',
    dataIndex: 'selector',
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'role',
    width: '20%',
  },
  {
    key: 'actions',
    title: '',
    width: '200px',
    render: (_, record) => (
      <ActionsComp record={record} onDelete={onDelete} onEdit={onEdit} />
    ),
  },
];

export const getElementsRelationshipsColumns = ({
  onEdit,
  onDelete,
}: ColumnProp): Column[] => [
  {
    title: 'El Selector 1',
    key: 'selector1',
    dataIndex: 'selector1',
  },
  {
    title: 'Type of Relation',
    key: 'relation',
    dataIndex: 'relation',
    width: '20%',
  },
  {
    title: 'El Selector 2',
    key: 'selector2',
    dataIndex: 'selector2',
  },
  {
    key: 'actions',
    title: '',
    width: '200px',
    render: (_, record) => (
      <ActionsComp record={record} onDelete={onDelete} onEdit={onEdit} />
    ),
  },
];

export const getDynamicElementsColumns = ({
  onEdit,
  onDelete,
}: ColumnProp): Column[] => [
  {
    title: 'El Selector',
    key: 'selector',
    dataIndex: 'selector',
  },
  {
    title: 'Politeness setting',
    key: 'politeness',
    dataIndex: 'politeness',
    width: '20%',
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'role',
    width: '20%',
  },
  {
    key: 'actions',
    title: '',
    width: '200px',
    render: (_, record) => (
      <ActionsComp record={record} onDelete={onDelete} onEdit={onEdit} />
    ),
  },
];

export const getCustomAttributesColumns = ({
  onEdit,
  onDelete,
}: ColumnProp): Column[] => [
  {
    title: 'Selector',
    key: 'selector',
    dataIndex: 'selector',
  },
  {
    title: 'Custom Attribute',
    key: 'attribute',
    dataIndex: 'attribute',
    width: '20%',
  },
  {
    title: 'Value',
    key: 'value',
    dataIndex: 'value',
  },
  {
    key: 'actions',
    title: '',
    width: '200px',
    render: (_, record) => (
      <ActionsComp record={record} onDelete={onDelete} onEdit={onEdit} />
    ),
  },
];
