import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { Business } from '@equally-ai-front/common/src/types/business';
import axios from 'axios';
import { BASE_API_URL } from 'utils/constants';
import { BusinessWithOwner } from '../types';

class BusinessAdminService {
  static getBusinesses = async (): Promise<
    ApiResponse<BusinessWithOwner[]>
  > => {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'businesses');
    } catch (err: any) {
      console.error('Error getting businesses', err);
      error = err?.message || 'Failed to get all businesses';
    }

    const { data } = result || {};
    return {
      data: (data?.message?.data || []) as BusinessWithOwner[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };
  static getBusinessById = async (
    businessId: number,
  ): Promise<ApiResponse<BusinessWithOwner>> => {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'businesses/' + businessId);
    } catch (err: any) {
      console.error('Error getting business with Id ' + businessId, err);
      error = err?.message || 'Failed to get all businesses';
    }

    const { data } = result || {};

    return {
      data: (data?.data || {}) as BusinessWithOwner,
      isSuccess: data?.code === 200,
      error: error || data?.data,
    };
  };
}

export { BusinessAdminService };
