import React from 'react';
import styled from '@emotion/styled';
import { Toolbar, Typography, Box } from '@mui/material';

interface ActionToolbarProps {
  title: string;
  children?: React.ReactNode;
}

export const ActionToolbar = ({ title, children }: ActionToolbarProps) => {
  return (
    <StyledToolbar>
      <Box flex="1 1 100%">
        <Typography variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      </Box>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </StyledToolbar>
  );
};

const StyledToolbar = styled(Toolbar)`
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
`;

const ChildrenWrapper = styled.div`
  display: flex;

  button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
