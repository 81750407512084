import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { Domain } from 'store/slice/domains-slice';
import {
  WarningOutlined,
  CheckCircleOutlined,
  FolderOutlined,
  AutorenewOutlined,
  ErrorOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DomainsTableRow } from './domains-table';
import { withStyles } from '@mui/styles';

export const formatDate = (timeStamp: number) => {
  return moment.unix(timeStamp).format('DD/MM/YYYY');
};

interface DomainsTableBodyProps {
  currentData: Domain[];
}

const ICON_COLOR: Record<string, string> = {
  green: '#18864b',
  red: '#f60000',
  yellow: '#b59922',
};

export const DomainsTableBody = ({ currentData }: DomainsTableBodyProps) => {
  return (
    <TableBody>
      {currentData.length > 0 ? (
        currentData.map((row) => {
          return (
            <DomainsTableRow
              key={row.ID}
              sx={{ '&:last-child td, &:last-child th': { border: '0' } }}
            >
              <CustomTableDataCell scope="row">
                <Link to={`/domains/${row.domainID}`}>{row.domainName}</Link>
              </CustomTableDataCell>
              <CustomTableDataCell>{row.accountName}</CustomTableDataCell>
              <CustomTableDataCell>{row.email}</CustomTableDataCell>
              <CustomTableDataCell>{row.subscriptionPlan}</CustomTableDataCell>
              <CustomTableDataCell>{row.txID}</CustomTableDataCell>
              <CustomTableDataCell>
                <>{formatDate(row.registrationDate)}</>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>{formatDate(row.expirationDate)}</>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>{formatDate(row.lastPaymentDate)}</>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>
                  {row.hasWidget ? (
                    <CheckCircleOutlined
                      style={{ color: ICON_COLOR['green'] }}
                    />
                  ) : (
                    <WarningOutlined style={{ color: ICON_COLOR['red'] }} />
                  )}
                </>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>
                  {row.hasWidgetIntegrated ? (
                    <CheckCircleOutlined
                      style={{ color: ICON_COLOR['green'] }}
                    />
                  ) : (
                    <WarningOutlined style={{ color: ICON_COLOR['red'] }} />
                  )}
                </>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>{row.archived ? <FolderOutlined /> : ''}</>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>
                  {row.autoRenewal ? (
                    <AutorenewOutlined
                      style={{ color: ICON_COLOR['yellow'] }}
                    />
                  ) : (
                    ''
                  )}
                </>
              </CustomTableDataCell>
              <CustomTableDataCell>
                <>
                  {row.invalid ? (
                    <ErrorOutlined style={{ color: ICON_COLOR['red'] }} />
                  ) : (
                    ''
                  )}
                </>
              </CustomTableDataCell>
            </DomainsTableRow>
          );
        })
      ) : (
        <TableRow>
          <CustomTableDataCell colSpan={6} className="no-data">
            No Data Available
          </CustomTableDataCell>
        </TableRow>
      )}
    </TableBody>
  );
};

const CustomTableDataCell = withStyles((theme) => ({
  body: {
    padding: '0.75rem',
    verticalAlign: 'top',
    borderTop: '1px solid #f4f3fe',
    color: 'rgba(0, 0, 0, 0.87)',
    borderLeft: '2px solid #f5f7fa',
    borderRight: '2px solid #f5f7fa',
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(TableCell);
