import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { updateDomainDetail } from 'store/slice/domain-detail-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useFormik } from 'formik';
import {
  Divider,
  Box,
  Button,
  Switch,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Tag } from 'components/css-components';
import { ActionToolbar } from 'components/action-toolbar';
import { InlineLayout } from './inline-layout';
import { SubscriptionPlan } from './subscription-plan';
import { parseSubscription } from 'utils/helpers';
import { DatePickerField } from '@equally-ai-front/common/src/components/filters/date-picker-field';
import { SubscriptionDurationMap } from '../../utils/constants';
import { PayPal, Visa, MasterCard } from 'components/payment';
const DOMAIN_REGEX =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;

export const PaymentMethodMap: Record<any, any> = {
  visa: <Visa />,
  mastercard: <MasterCard />,
  paypal: <PayPal />,
};
const validationSchema = Yup.object({
  domainName: Yup.string().matches(DOMAIN_REGEX, 'Invalid domain'),
  autoRenewal: Yup.boolean(),
  expirationDate: Yup.number(),
});

const FormFields = Object.freeze({
  domainName: 'domainName',
  autoRenewal: 'autoRenewal',
  expirationDate: 'expirationDate',
});

export const DomainGeneralDetails = () => {
  const { domainId } = useParams();
  const dispatch = useAppDispatch();
  const { domainDetail, loading } = useAppSelector(
    (state) => state.domainDetail,
  );
  const isActive = !domainDetail?.archived;
  const subscription = parseSubscription(domainDetail?.subscriptionPlan ?? '');
  const nextCaption = domainDetail?.expirationDate
    ? DateTime.fromSeconds(domainDetail.expirationDate)
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_FULL)
    : null;

  const onFormSubmit = React.useCallback(
    (formObject: any) => {
      domainId &&
        dispatch(updateDomainDetail({ domainData: formObject, domainId }));
    },
    [dispatch, domainId],
  );

  const { setFieldTouched, setValues, resetForm, submitForm, ...formik } =
    useFormik({
      initialValues: {
        domainName: domainDetail?.domainName ?? '',
        autoRenewal: false,
        expirationDate: domainDetail?.expirationDate ?? 0,
      },
      validationSchema: validationSchema,
      onSubmit: onFormSubmit,
    });

  const canSave = formik.isValid && formik.dirty;
  const isModified = formik.dirty;
  const expiredDate = formik.values[FormFields.expirationDate];
  const isRenewal = !!formik.values[FormFields.autoRenewal];

  const onExpiredDateChange = React.useCallback(
    (date: number) => {
      formik.setFieldValue(FormFields.expirationDate, date);
    },
    [formik],
  );

  const onRenewalChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(FormFields.autoRenewal, evt.target.checked);
    },
    [formik],
  );

  const onSave = React.useCallback(() => {
    submitForm();
  }, [submitForm]);

  const onReset = React.useCallback(() => {
    resetForm();
  }, [resetForm]);

  React.useEffect(() => {
    domainDetail && resetForm({ values: domainDetail });
  }, [domainDetail, resetForm]);

  React.useEffect(() => {
    domainDetail && setValues(domainDetail);
    Object.keys(FormFields).forEach((fieldName) => {
      setFieldTouched(fieldName);
    });
  }, [domainDetail, setFieldTouched, setValues]);

  return (
    <>
      <ActionToolbar title="Website details" />
      <Content>
        <form>
          <Row>
            <TextField
              fullWidth
              disabled
              name={FormFields.domainName}
              label="Website URL"
              value={formik.values[FormFields.domainName] ?? ''}
              onChange={formik.handleChange}
              error={
                formik.touched[FormFields.domainName] &&
                Boolean(formik.errors[FormFields.domainName])
              }
              helperText={
                formik.touched[FormFields.domainName] &&
                formik.errors[FormFields.domainName]
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Row>
          <Row>
            <InlineLayout title="Status">
              {isActive ? (
                <ActiveTag>Active</ActiveTag>
              ) : (
                <InactiveTag>Inactive</InactiveTag>
              )}
            </InlineLayout>
          </Row>
          <Divider />
          <Row>
            <InlineLayout title="Plan">
              {subscription.plan ? (
                <SubscriptionPlan plan={subscription.plan} />
              ) : (
                '-'
              )}
            </InlineLayout>
          </Row>
          <Divider />

          <Row>
            <InlineLayout title="Your plan expires on">
              <DatePickerField
                value={expiredDate}
                onChange={onExpiredDateChange}
                disabled
              />
            </InlineLayout>
          </Row>
          <Divider />

          <Row>
            <InlineLayout title="Next caption">
              {nextCaption ?? '-'}
            </InlineLayout>
          </Row>
          <Divider />

          <Row>
            <InlineLayout title="Paid">
              {SubscriptionDurationMap[subscription.duration]}
            </InlineLayout>
          </Row>
          <Divider />

          <Row>
            <InlineLayout title="Auto-renewal">
              <FormControlLabel
                label={<></>}
                control={
                  <Switch
                    checked={isRenewal}
                    onChange={onRenewalChange}
                    disabled
                  />
                }
              />
            </InlineLayout>
          </Row>
          <Divider />

          <Row>
            <InlineLayout title="Payment method">
              {domainDetail?.paymentMethod
                ? PaymentMethodMap[domainDetail?.paymentMethod]
                : '-'}
            </InlineLayout>
          </Row>
          <Divider />

          <Row>
            <InlineLayout title="Price">
              {domainDetail?.price ? `$${domainDetail?.price}` : '-'}
            </InlineLayout>
          </Row>
        </form>
      </Content>
      <Box style={{ display: 'flex', margin: 2, justifyContent: 'center' }}>
        <Box style={{ marginRight: 10 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSave}
            disabled={!canSave || loading === 'pending'}
          >
            Save
          </Button>
        </Box>
        <Box style={{ marginLeft: 10 }}>
          <Button
            variant="outlined"
            onClick={onReset}
            disabled={!isModified || loading === 'pending'}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

const Content = styled.div`
  margin: 20px;
`;

const Row = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const ActiveTag = styled(Tag)`
  background: #bbe8d4;
`;

const InactiveTag = styled(Tag)`
  background: #bcc;
`;
