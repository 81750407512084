export const whiteList: string[] = [
  'bisola@equally.ai',
  'faith@equally.ai',
  'sabastine@equally.ai',
  'eugene@equally.ai',
  'vladimir@equally.ai',
  'bohdan.t@equally.ai',
  'ran@equally.ai',
  'gabriel@equally.ai',
  'sunday@equally.ai',
  'wallace@equally.ai',
  'pelumi@equally.ai',
  'stella@equally.ai',
  'king@equally.ai',
  'omer@equally.ai',
  'winnie@equally.ai',
];
