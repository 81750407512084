import React from 'react';
import styled from 'styled-components';
import {
  SuggestedCodeFixTemplate,
  SuggestionAndOriginalCodeTemplate,
} from '../../utils/devToolsTypeConfig';
import {
  CodeBlock,
  CodeButtonProp,
  HeaderIconProp,
} from '../code-block/CodeBlock';

interface CreateSuggestedFixProps {
  contextOriginalCode: string;
  templateForFix: SuggestedCodeFixTemplate;
  copySuggestedCode: (
    suggestedCodeTemplate: SuggestionAndOriginalCodeTemplate,
    extra1: string,
    extra2: string,
  ) => void;
  modifyHTML?: (
    suggestedCodeTemplate: SuggestionAndOriginalCodeTemplate,
    extra1: string,
    extra2: string,
  ) => void;
  codeDescriptionTitle: string;
  headerIcon1: HeaderIconProp | null;
  headerIcon2: HeaderIconProp | null;
  copyIcon: CodeButtonProp;
  codeIcon?: CodeButtonProp;
}

const selfClosingTags: string[] = [
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',
];

export const CreateSuggestedFix: React.FC<CreateSuggestedFixProps> = ({
  contextOriginalCode,
  templateForFix,
  copySuggestedCode,
  modifyHTML,
  codeDescriptionTitle,
  headerIcon1,
  headerIcon2,
  copyIcon,
  codeIcon,
}) => {
  let parser = new DOMParser();
  let originalCodeString = contextOriginalCode;

  if (contextOriginalCode && !contextOriginalCode.includes('>')) {
    originalCodeString = contextOriginalCode.concat(`">`);
  }

  const doc: Document = parser.parseFromString(originalCodeString, 'text/html');
  const bodyContent: HTMLCollection = doc.head.children[0]
    ? doc.head.children
    : doc.body.children[0]
      ? doc.body.children
      : doc.children;
  const originalCode: Element = bodyContent[0];

  if (contextOriginalCode.slice(-1) !== '>') {
    const originalTagName = originalCode.tagName.toLowerCase();
    const selfClosingTag = selfClosingTags.find(
      (selfClosing) => selfClosing === originalTagName,
    );
    const isSelfClosingTag = selfClosingTag ? true : false;
    originalCodeString = contextOriginalCode.concat(
      `${isSelfClosingTag ? '>' : `</${originalTagName}>`}`,
    );
  }

  const tagName: string = bodyContent[0].tagName.toLowerCase();
  const attributes: string[] = bodyContent[0].getAttributeNames();
  let strippedCode: string = '';
  const chosenTagToSplitFrom = `<${tagName}`;
  const regex = new RegExp(
    templateForFix?.targetTagSelector
      ? `<${templateForFix.targetTagSelector}`
      : `^${chosenTagToSplitFrom}`,
  );
  let codeList: string[] = [];

  const stripCodeToRemoveAttribute = (
    code: string,
    attributeKey: string,
    attributeValue: string,
  ): string => {
    let strippedCode = code;
    if (templateForFix.hasAttribute && code.includes(attributeKey)) {
      strippedCode = strippedCode.replace(
        `${attributeKey}="${attributeValue}"`,
        '',
      );
    }

    return strippedCode;
  };

  if (templateForFix.removeAttribute && templateForFix.attributeNameToRemove) {
    const attributeFound = attributes.filter(
      (attribute) => attribute === templateForFix.attributeNameToRemove,
    )[0];
    const attributeValue = originalCode.getAttribute(attributeFound) ?? '';
    strippedCode = stripCodeToRemoveAttribute(
      originalCodeString,
      templateForFix.attributeNameToRemove,
      attributeValue,
    );
    codeList = strippedCode.split(regex);
  } else {
    codeList = originalCodeString.split(regex);
  }

  const suggestionTemplateObject: SuggestionAndOriginalCodeTemplate = {
    extraBeginningElementSuggestion: '',
    extraElementAfterTag: '',
    contentSuggestion: '',
    attributeSuggestion: '',
    beginningCodeTag: '',
    beginningCodeTagSuggestion: '',
    originalContent: '',
    endingCodeTag: '',
    endingCodeTagSuggestion: '',
  };

  // // Content that is an anchor
  // if (templateForFix.hasContent && tagName === "a") {
  //   attributes.forEach((attr: string) => {
  //     const value: string =
  //       originalCode.getAttribute(attr) ?? "example attribute";
  //     if (attr === "href") {
  //       // console.log(value, "the value");
  //       const anchorContent = value.split("/")[1]
  //         ? value.split("/")[1].charAt(0).toUpperCase() +
  //           value.split("/")[1].slice(1)
  //         : "Home";
  //       suggestionTemplateObject["contentSuggestion"] = anchorContent;
  //     }
  //   });
  // }

  if (templateForFix.hasContent) {
    suggestionTemplateObject['contentSuggestion'] = templateForFix.content;
  }

  // One that needs an attribute.
  if (templateForFix.hasAttribute && templateForFix.attributeValue) {
    suggestionTemplateObject['attributeSuggestion'] = `${
      templateForFix.attributeKey
    }="${templateForFix.attributeValue.toLowerCase()}"`;
  }

  if (templateForFix.hasExtraElement) {
    suggestionTemplateObject.extraBeginningElementSuggestion =
      templateForFix.extraElementContent ?? '';
  }

  let attributeKeyToReplace: string = '';
  let attributeValueToReplace: string = '';

  // To replace an existing attribute if the the suggested one already exists
  if (templateForFix.hasAttribute) {
    const attributeFound = attributes.filter(
      (attribute) => attribute === templateForFix.attributeKey,
    )[0];
    attributeKeyToReplace = attributeFound ?? templateForFix.attributeKey;
    attributeValueToReplace =
      originalCode.getAttribute(attributeKeyToReplace) ?? '';
  }

  codeList.forEach((code) => {
    if (!code) {
      if (templateForFix.hasTag) {
        suggestionTemplateObject['beginningCodeTagSuggestion'] =
          `<${templateForFix.tagValue}`;
      } else {
        suggestionTemplateObject['beginningCodeTag'] = `<${tagName}`;
      }
    } else {
      if (code.includes('</')) {
        strippedCode = stripCodeToRemoveAttribute(
          code,
          attributeKeyToReplace,
          attributeValueToReplace,
        );

        //@ts-ignore
        const codeList1 = strippedCode.split(/>(.*)/s);
        const codeArr = strippedCode.split('</');

        if (templateForFix.isExtraElementAfterBeginningTag) {
          let content: string = '';

          codeList1.forEach((item, index) => {
            if (index === 0) {
              content += item + '>%&';
              suggestionTemplateObject['extraElementAfterTag'] =
                suggestionTemplateObject.extraBeginningElementSuggestion;
            } else {
              content += item;
            }
          });

          suggestionTemplateObject['originalContent'] = content;
        } else {
          if (codeList.length > 2) {
            let originalContent = '';

            codeList.forEach((code, index) => {
              if (index !== 0 && index !== codeList.length - 1) {
                originalContent += code;
              }
            });

            suggestionTemplateObject['originalContent'] =
              originalContent + codeArr[0];
          } else {
            suggestionTemplateObject['originalContent'] = codeArr[0];
          }

          if (templateForFix.hasTag) {
            suggestionTemplateObject['endingCodeTagSuggestion'] =
              '</' + templateForFix.tagValue + '>';
          } else {
            suggestionTemplateObject['endingCodeTag'] = `\n</${codeArr[1]}`;
          }
        }
      } else if (templateForFix?.targetTagSelector && code.includes('<')) {
        suggestionTemplateObject['beginningCodeTag'] = `${code}
          <${templateForFix.targetTagSelector}`;
      } else {
        strippedCode = stripCodeToRemoveAttribute(
          code,
          attributeKeyToReplace,
          attributeValueToReplace,
        );
        suggestionTemplateObject['endingCodeTag'] = `\n${strippedCode}`;
      }
    }
  });

  let newOriginalContent: string = '';
  let newOriginalContent1: string = '';

  if (templateForFix.isExtraElementAfterBeginningTag) {
    const result = suggestionTemplateObject.originalContent.split('%&');
    newOriginalContent = result[0];
    newOriginalContent1 = result[1];
  }

  copyIcon.action = () =>
    copySuggestedCode(
      suggestionTemplateObject,
      newOriginalContent,
      newOriginalContent1,
    );

  if (codeIcon) {
    codeIcon.action = () => {
      if (modifyHTML) {
        modifyHTML(
          suggestionTemplateObject,
          newOriginalContent,
          newOriginalContent1,
        );
      }
    };
  }

  return (
    <Wrapper>
      <CodeBlock
        codeDescriptionTitle={codeDescriptionTitle}
        codeContent={
          <>
            {suggestionTemplateObject.extraBeginningElementSuggestion &&
              !templateForFix.isExtraElementAfterBeginningTag && (
                <ContentSpan>
                  <mark>
                    {suggestionTemplateObject.extraBeginningElementSuggestion}
                  </mark>
                  <br />
                </ContentSpan>
              )}
            {suggestionTemplateObject.beginningCodeTagSuggestion ? (
              <mark>{suggestionTemplateObject.beginningCodeTagSuggestion}</mark>
            ) : (
              !templateForFix?.targetTagSelector && (
                <ContentSpan>
                  {suggestionTemplateObject.beginningCodeTag}
                </ContentSpan>
              )
            )}
            {templateForFix?.targetTagSelector ? (
              <>
                <>
                  {suggestionTemplateObject.beginningCodeTag}
                  <AttributeMarkElement>
                    {suggestionTemplateObject.attributeSuggestion}
                  </AttributeMarkElement>
                </>
              </>
            ) : (
              suggestionTemplateObject.attributeSuggestion && (
                <AttributeMarkElement>
                  {suggestionTemplateObject.attributeSuggestion}
                </AttributeMarkElement>
              )
            )}
            {newOriginalContent && newOriginalContent1 ? (
              <ContentSpan>
                <ContentSpan>{newOriginalContent}</ContentSpan>
                <mark>{suggestionTemplateObject.extraElementAfterTag}</mark>
                <ContentSpan>{newOriginalContent1}</ContentSpan>
              </ContentSpan>
            ) : (
              <ContentSpan>
                {suggestionTemplateObject.originalContent}
              </ContentSpan>
            )}
            {suggestionTemplateObject.contentSuggestion && (
              <mark>{suggestionTemplateObject.contentSuggestion}</mark>
            )}
            {suggestionTemplateObject.endingCodeTagSuggestion ? (
              <mark>{suggestionTemplateObject.endingCodeTagSuggestion}</mark>
            ) : (
              <ContentSpan>
                {suggestionTemplateObject.endingCodeTag}
              </ContentSpan>
            )}
          </>
        }
        copyIcon={copyIcon}
        codeIcon={codeIcon}
        headerIcon1={headerIcon1}
        headerIcon2={headerIcon2}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const AttributeMarkElement = styled.mark`
  margin-left: 5px;
  display: inline-block;
  color: rgb(31, 37, 51);
  background-color: rgb(231, 248, 230);
`;

const ContentSpan = styled.span`
  word-break: break-all;
`;
