import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { SubscriptionAdminService } from 'api/subscriptions-admin-service';
import { SubscriptionAndPlans } from '@equally-ai-front/common/src/types/plans-and-subscriptions';

interface SubscriptionsState {
  loading: boolean;
  subscriptions: SubscriptionAndPlans[];
}

const initialState: SubscriptionsState = {
  loading: false,
  subscriptions: [],
};

export const getAllSubscriptions = createAsyncThunk(
  'subscriptions/get-all',
  async (_, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await SubscriptionAdminService.getSubscriptions();

    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptions = action.payload.data;
      })
      .addCase(getAllSubscriptions.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectSubscriptionState = (state: RootState) =>
  state.subscriptions;
export default subscriptionsSlice.reducer;
