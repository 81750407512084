import React from 'react';
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const Loader = () => {
  return (
    <LoaderWrapper>
      <LinearProgress />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background-color: #ffffff;
  position: absolute;

  span {
    width: 80%;
    position: absolute;
  }
`;
