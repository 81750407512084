import { SubscriptionAndPlans } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';

interface SubscriptionBusinessProps {
  subscription: SubscriptionAndPlans;
}

export const SubscriptionBusiness = (props: SubscriptionBusinessProps) => {
  const { subscription } = props;
  const { businesses } = useAppSelector((state) => state.businesses);

  const currentBusiness = useMemo(() => {
    const subscriptionPlan = subscription.plans[0];
    const businessId = subscriptionPlan.business_id;
    if (!businessId) return;

    return businesses.find((business) => +business.business.id === +businessId);
  }, [businesses, subscription]);

  return <div>{currentBusiness ? currentBusiness.business.name : 'N/A'}</div>;
};
