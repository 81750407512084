import React from 'react';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { getDynamicElementsColumns } from './utils';
import {
  WidgetConfigurations,
  DynamicElementsConfig,
} from '@equally-ai-front/common/src/helpers';
import { WidgetConfigContainer } from './widget-config-container';

export const DynamicElements = () => {
  const { domainId } = useParams();
  const { data } = useAppSelector((state) => state.adminConfig);

  const dynamicElements = React.useMemo(
    () => data.dynamicElements as DynamicElementsConfig[],
    [data.dynamicElements],
  );

  return (
    <WidgetConfigContainer
      domainId={domainId ?? ''}
      elements={dynamicElements}
      title="Dynamic elements"
      configType={WidgetConfigurations.DYNAMIC_ELEMENTS}
      getColumns={getDynamicElementsColumns}
    />
  );
};
