import CustomAttributesForm from './custom-attributes-form';
import LandmarkForm from './landmark-form';
import MoreRolesForm from './more-roles-form';
import TabIndexForm from './tabindex-form';
import DynamicElementsForm from './dynamic-elements-form';
import ElementRelationForm from './relation-form';
import {
  AdminWidgetConfigType,
  CustomAttributesConfig,
  DynamicElementsConfig,
  ElementRelationshipConfig,
  LandmarkConfig,
  MetaData,
  MoreRolesConfig,
  TabIndexConfig,
  WidgetConfigurations,
} from '../../helpers';
import React from 'react';
import { MetaDataForm } from './meta-data-form';

interface RenderConfigFormProps {
  configType: AdminWidgetConfigType;
  onFormSubmit: (newElement: any) => Promise<void>;
  values?:
    | TabIndexConfig
    | LandmarkConfig
    | CustomAttributesConfig
    | MoreRolesConfig
    | DynamicElementsConfig
    | ElementRelationshipConfig;
  onClose: () => void;
}

const RenderConfigForm: React.FC<RenderConfigFormProps> = ({
  configType,
  onFormSubmit,
  values,
  onClose,
}) => {
  switch (configType) {
    case WidgetConfigurations.IMPROVED_NAVIGATION:
      return (
        <TabIndexForm
          value={values as TabIndexConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );

    case WidgetConfigurations.LANDMARKS:
      return (
        <LandmarkForm
          value={values as LandmarkConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );
    case WidgetConfigurations.MORE_ROLES:
      return (
        <MoreRolesForm
          value={values as MoreRolesConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );
    case WidgetConfigurations.CUSTOM_ATTRIBUTES:
      return (
        <CustomAttributesForm
          value={values as CustomAttributesConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );
    case WidgetConfigurations.CUSTOM_ATTRIBUTES:
      return (
        <CustomAttributesForm
          value={values as CustomAttributesConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );
    case WidgetConfigurations.DYNAMIC_ELEMENTS:
      return (
        <DynamicElementsForm
          value={values as DynamicElementsConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );
    case WidgetConfigurations.ELEMENTS_RELATIONSHIP:
      return (
        <ElementRelationForm
          value={values as ElementRelationshipConfig}
          onFormSubmit={onFormSubmit}
          type={configType}
          onClose={onClose}
        />
      );
    case WidgetConfigurations.META_DATA:
      return (
        <MetaDataForm
          value={values as unknown as MetaData}
          onFormSubmit={onFormSubmit}
          type={configType}
        />
      );
    default:
      return null;
  }
};

export default RenderConfigForm;
