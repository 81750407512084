import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { Modal } from '@equally-ai-front/common/src/components/modal/Modal';
import {
  AdminWidgetConfigType,
  editItem,
  findItem,
  LandmarkConfig,
  stripObjectID,
  TabIndexConfig,
} from '@equally-ai-front/common/src/helpers';
import { uploadConfig } from '@equally-ai-front/common/src/redux/admin-widget-config-slice';
import RenderConfigForm from '@equally-ai-front/common/src/components/admin-widget-config/render-form';
import { useToaster } from 'contexts/toast-context';

interface AddFormModalProps {
  isVisible: boolean;
  onClose: () => void;
  allElements: TabIndexConfig[] | LandmarkConfig[] | any[];
  domainID: string;
  configType: AdminWidgetConfigType;
  elementID?: string;
  title?: string;
  container?: Element | null;
}

export const FormModal: React.FC<AddFormModalProps> = ({
  onClose,
  allElements,
  domainID,
  configType,
  elementID,
  isVisible,
  title,
  container,
}) => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  let values;

  if (elementID) {
    values = findItem(allElements, elementID);
  }

  const onAddElement = async (
    newElement: TabIndexConfig | { [key: string]: string },
  ) => {
    if (onClose) {
      onClose();
    }

    const strippedData = allElements.map(stripObjectID);
    const updatedList = elementID
      ? editItem(allElements, elementID, newElement)
      : [...strippedData, newElement];

    try {
      await dispatch(
        uploadConfig({
          domainID,
          config: updatedList,
          configType,
        }),
      );
      setToaster('success', 'Updated successfully!');
    } catch (e) {
      setToaster('error', 'Failed to update!');
    }
  };

  return (
    <Modal
      showModal={isVisible}
      handleClose={onClose}
      title={title}
      container={container}
    >
      <RenderConfigForm
        configType={configType}
        onFormSubmit={onAddElement}
        values={values}
        onClose={onClose}
      />
    </Modal>
  );
};
