import { StyledButton } from 'containers/coupons-page/coupons-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  LatestDomainScansData,
  WebsitesTable,
} from '../../components/latest-scans-table/websites-table';
import { getScanPreviousAndCurrentTotalCountAndAverageScore } from '@equally-ai-front/common/src/lib';
import { LatestDomainScansSummary } from '@equally-ai-front/common/src/types/scans';
import { WebsitesAdminService } from 'api/websites-admin-service';
import { Loader } from 'components/loader';
import { useToaster } from 'contexts/toast-context';

export interface ScansData {
  [x: string]: {
    [x: string]: LatestDomainScansSummary;
  };
}

export const WebsitesPage = () => {
  const { setToaster } = useToaster();
  const navigate = useNavigate();
  const [loadingLatestDomainScans, setLoadingLatestDomainScans] =
    useState(false);
  const [latestDomainScans, setLatestDomainScans] = useState<
    [string, LatestDomainScansSummary][]
  >([]);

  const handleNavigateToAuditPage = () => {
    navigate('/websites/audit');
  };

  const latestDomainScansData: LatestDomainScansData[] = useMemo(() => {
    if (latestDomainScans.length === 0) return [];

    return latestDomainScans.map((latestDomainScan) => {
      const [domainName, domainPageScanSummary] = latestDomainScan;
      const latestScanTotals =
        getScanPreviousAndCurrentTotalCountAndAverageScore(
          domainPageScanSummary,
        );

      const scannedPages = Object.values(domainPageScanSummary?.scan || {}).map(
        (domainPageScanData) => {
          const {
            url,
            totals: { errors, notices, warnings },
          } = domainPageScanData;
          return {
            url,
            error: errors,
            notice: notices,
            warning: warnings,
            previousError: domainPageScanData.previous_totals?.errors || 0,
            previousWarning: domainPageScanData.previous_totals?.warnings || 0,
            previousNotice: domainPageScanData.previous_totals?.notices || 0,
          };
        },
      );

      const scanDataMap: LatestDomainScansData = {
        createdAt: domainPageScanSummary.created_at,
        paid:
          domainPageScanSummary.plan_id !== null &&
          domainPageScanSummary.plan_id !== 0,
        domainName,
        scannedPages,
        scannedPagesCount: scannedPages.length,
        latestScanTotals,
        accessibilityScore: Number(latestScanTotals.accessibilityScoreAverage),
      };

      return scanDataMap;
    });
  }, [latestDomainScans]);

  const getDomainsScans = async () => {
    setLoadingLatestDomainScans(true);
    const { isSuccess, error, data } =
      await WebsitesAdminService.getLatestDomainScans();

    setLoadingLatestDomainScans(false);
    if (!isSuccess) {
      setToaster('error', error);
      return;
    }
    setLatestDomainScans(Object.entries(data));
  };

  useEffect(() => {
    void getDomainsScans();
  }, []);

  if (loadingLatestDomainScans) {
    return <Loader />;
  }

  return (
    <div>
      <h1>Websites Page</h1>
      <WebsitePageHeader>
        <AddNewScanBtn onClick={handleNavigateToAuditPage}>
          Add New Scan
        </AddNewScanBtn>
      </WebsitePageHeader>
      <div>
        <WebsitesTable tableData={latestDomainScansData} />
      </div>
    </div>
  );
};

const WebsitePageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AddNewScanBtn = styled(StyledButton)`
  margin-right: 0;
  height: 40px;
`;
