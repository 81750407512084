import React from 'react';
import { useAdminAuth } from '../../contexts/admin-context';
import { NotFoundPageAuth } from './not-found-page-auth';
import { NotFoundPageUnAuth } from './not-found-page-unauth';

export const NotFoundPage = () => {
  const { isAuthenticated } = useAdminAuth();

  return <>{isAuthenticated ? <NotFoundPageAuth /> : <NotFoundPageUnAuth />}</>;
};
