import * as Yup from 'yup';
import { AdminWidgetConfigType, isXPathSelectorValid } from '../utils';

type AdminFormValidationSchema = {
  [key in AdminWidgetConfigType]: Yup.SchemaOf<any> | undefined;
};

export const validationSchema: AdminFormValidationSchema = {
  tabIndex: Yup.object({
    index: Yup.number()
      .required('Index is required')
      .integer('Index should be integer'),
    selector: Yup.string()
      .required('Selector is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
  }),
  customAttributes: Yup.object({
    attribute: Yup.string().required('Attribute is required'),
    selector: Yup.string()
      .required('Selector is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
    value: Yup.string().required('Attribute Value is required'),
  }),
  landmarks: Yup.object({
    role: Yup.string().required('Role is required'),
    selector: Yup.string()
      .required('Selector is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
  }),
  metadata: Yup.object({
    accessibilityFeature: Yup.array().of(Yup.string()),
    accessibilityHazard: Yup.array().of(Yup.string()),
    accessibilityAPI: Yup.array().of(Yup.string()),
    accessibilityControl: Yup.array().of(Yup.string()),
    accessMode: Yup.array().of(Yup.string()),
    accessModeSufficient: Yup.array().of(Yup.string()),
    accessibilitySummary: Yup.array().of(Yup.string()),
  }),
  elementsRelationships: Yup.object({
    selector1: Yup.string()
      .required('Selector 1 is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
    relation: Yup.string().required('Relation is required'),
    selector2: Yup.string()
      .required('Selector 2 is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
  }),
  dynamicElements: Yup.object({
    role: Yup.string().required('Role is required'),
    selector: Yup.string()
      .required('Selector is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
  }),
  menuSubmenu: Yup.object({
    selector: Yup.string()
      .required('Selector is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
  }),
  moreRoles: Yup.object({
    role: Yup.string().required('Role is required'),
    selector: Yup.string()
      .required('Selector is required')
      .test('is-selector', '${value} is not selector', isXPathSelectorValid),
  }),
};
