import React, { createContext, useContext, useEffect, useState } from 'react';

export type ToastType = 'success' | 'error';

interface Toast {
  id: number;
  message: string;
  type: ToastType;
  dismiss: (id: number) => void;
}

interface ToastContextType {
  toastList: Toast[];
  setToaster: (type: ToastType, message: string) => void;
}

const ToastContext = createContext<ToastContextType>({} as ToastContextType);

let toastCounter = 0;

export const ToastProvider = ({ children }: any) => {
  const [toastList, setToastList] = useState<Toast[]>([]);
  const id = toastCounter++;

  const dismiss = (id: number) => {
    setToastList((prevToastList) =>
      prevToastList.filter((toast) => toast.id !== id),
    );
  };
  const setToaster = (type: ToastType, message: string) => {
    setToastList([
      ...toastList,
      {
        id: id + 1,
        message,
        type,
        dismiss,
      },
    ]);
  };

  const removeToast = (id: number) => {
    setToastList((prevToastList) =>
      prevToastList.filter((toast) => toast.id !== id),
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length > 0) {
        removeToast(toastList[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList]);

  return (
    <ToastContext.Provider value={{ toastList, setToaster }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToaster = () => useContext(ToastContext);
