import React from 'react';
import { DomainsTable } from './domains-table';
import { useAppDispatch } from 'store/hooks';
import { getDomains } from 'store/slice/domains-slice';
import { DomainsGraph } from './domains-graph';

export const DomainsPage = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getDomains());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1>DomainsPage</h1>
      <DomainsGraph />
      <DomainsTable />
    </>
  );
};
