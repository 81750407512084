import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';

import {
  AdminWidgetConfigType,
  ElementRelationshipConfig,
} from '../../helpers';

import { ADMIN_CONFIG_FORMS_INITIAL_VALUES } from '../../helpers/templates/adminConfigForm';

import { validationSchema } from '../../helpers/templates/validationSchema';
import { LIST_OF_RELATIONS } from '../../lib';
import { SaveAndCancel } from './save-and-cancel';

interface RelationFormProps {
  onFormSubmit: (newElement: any) => Promise<void>;
  value?: ElementRelationshipConfig;
  type: AdminWidgetConfigType;
  onClose: () => void;
}

const RelationForm: React.FC<RelationFormProps> = ({
  onFormSubmit,
  value,
  type,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: ADMIN_CONFIG_FORMS_INITIAL_VALUES[type],
    validationSchema: validationSchema[type],
    onSubmit: onFormSubmit,
  });

  useEffect(() => {
    const formValue = value ?? ADMIN_CONFIG_FORMS_INITIAL_VALUES[type];
    formik.setValues(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box my={2}>
        <TextField
          fullWidth
          id="selector1"
          name="selector1"
          label="El Selector 1"
          value={formik.values.selector1}
          onChange={formik.handleChange}
          error={formik.touched.selector1 && Boolean(formik.errors.selector1)}
          helperText={
            formik.touched.selector1 && formik.errors.selector1?.toString()
          }
        />
      </Box>
      <Box my={2}>
        <FormControl
          fullWidth
          error={formik.touched.relation && Boolean(formik.errors.relation)}
        >
          <InputLabel>Type of Relation</InputLabel>
          <Select
            fullWidth
            id="relation"
            name="relation"
            label="Type of Relation"
            value={formik.values.relation}
            onChange={formik.handleChange}
            error={formik.touched.relation && Boolean(formik.errors.relation)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {LIST_OF_RELATIONS.map((relation) => (
              <MenuItem key={relation} value={relation}>
                {relation}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.relation && formik.errors.relation?.toString()}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          id="selector2"
          name="selector2"
          label="El Selector 2"
          value={formik.values.selector2}
          onChange={formik.handleChange}
          error={formik.touched.selector2 && Boolean(formik.errors.selector2)}
          helperText={
            formik.touched.selector2 && formik.errors.selector2?.toString()
          }
        />
      </Box>
      <SaveAndCancel onClose={onClose} />
    </form>
  );
};

export default RelationForm;
