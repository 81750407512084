import { CustomModal } from 'components/modal/custom-modal';
import React, { useEffect, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { Domain } from 'store/slice/domains-slice';
import { Dayjs } from 'dayjs';
import { UpdateDomainForm } from './update-domain-form';
import { UpdateDomainConfirmation } from './update-domain-confirmation';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';

interface UpdateDomainsModalProps {
  showUpdateDomainPopUp: boolean;
  handleCloseUpdateDomainPopUp: () => void;
  selectedDomains: Record<number, Domain>;
  handleRemoveSelectedDomain: (domainId: number) => void;
  handleClearAllSelectedDomains: () => void;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

export const UpdateDomainsModal = (props: UpdateDomainsModalProps) => {
  const {
    showUpdateDomainPopUp,
    handleCloseUpdateDomainPopUp,
    selectedDomains,
    handleRemoveSelectedDomain,
    handleClearAllSelectedDomains,
    setSelectedOption,
  } = props;
  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isUpdatingDomains, setIsUpdatingDomains] = useState(false);

  const onClose = () => {
    setSelectedOption('');
    setActiveStep(0);
    setExpirationDate(null);
    handleClearAllSelectedDomains();
    handleCloseUpdateDomainPopUp();
  };

  const handleNextStep = () => {
    if (Object.entries(selectedDomains).length === 0 || !expirationDate) return;
    setActiveStep(1);
  };

  return (
    <>
      <CustomModal
        open={showUpdateDomainPopUp}
        handleClosePopup={onClose}
        ariaDescribedBy="update domains modal"
        modalTitle={<Title>Update Domains</Title>}
      >
        {activeStep === 0 ? (
          <UpdateDomainForm
            selectedDomains={selectedDomains}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate}
            onClose={onClose}
            handleNextStep={handleNextStep}
            handleRemoveSelectedDomain={handleRemoveSelectedDomain}
          />
        ) : (
          activeStep === 1 && (
            <UpdateDomainConfirmation
              selectedDomains={selectedDomains}
              expirationDate={expirationDate}
              setActiveStep={setActiveStep}
              setIsUpdatingDomains={setIsUpdatingDomains}
              onClose={onClose}
            />
          )
        )}
      </CustomModal>
      <LoadingView
        open={isUpdatingDomains}
        loadingText="Updating domains..."
        zIndex="13000"
      />
    </>
  );
};

export const Title = styled(Typography)`
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
`;
