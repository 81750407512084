import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Button,
} from '@mui/material';
import { NavigationItemList, NavigationItem } from './types';
import { useAdminAuth } from 'contexts/admin-context';
import { useConfirmModal } from 'hooks/use-confirm-modal';

interface NavigationProps {
  navList: NavigationItemList[];
}

export const Navigation = ({ navList }: NavigationProps) => {
  const { isAdmin } = useAdminAuth();
  const { ConfirmModalWrapper, modalVisible, setModalVisible, onClose } =
    useConfirmModal();

  const renderSubItems = (subItems: NavigationItem[]) => {
    return (
      subItems?.length > 0 &&
      subItems.map(({ label, path }) => (
        <Collapse key={label} in timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NestedListItem button>
              <StyledRouterLink to={path}>
                <ListItemLabel secondary={label} />
              </StyledRouterLink>
            </NestedListItem>
          </List>
        </Collapse>
      ))
    );
  };

  return (
    <NavigationContainer>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Navigation
          </ListSubheader>
        }
      >
        {navList.map(({ label, path, subItems }) => {
          if (label === 'Widget Configuration' && !isAdmin) {
            return <div key="no-config-here" />;
          }
          return (
            <div key={label}>
              <ListItem component={NavLink} button to={path}>
                <ListItemLabel primary={label} />
              </ListItem>
              {subItems && renderSubItems(subItems)}
            </div>
          );
        })}
        <ListItem>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '50px' }}
            onClick={() => setModalVisible(true)}
          >
            Mark Completed
          </Button>
        </ListItem>
        <ConfirmModalWrapper
          isVisible={modalVisible}
          onClose={onClose}
          config={navList}
        />
      </List>
    </NavigationContainer>
  );
};

const NestedListItem = styled(ListItem)`
  padding-left: 4px;
`;

const ListItemLabel = styled(ListItemText)`
  font-size: 10px;
`;

const NavigationContainer = styled.div`
  border-right: 1px solid #d7d8da;
  max-width: 360px;
  width: 300px;
  background: #fafafa;

  .active {
    p/* ,
    span */ {
      color: #3f51b5;
      font-weight: bold;
    }
  }
`;

const StyledRouterLink = styled(NavLink)`
  text-decoration: none;
  width: 100%;
`;
