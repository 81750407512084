import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { domainFields as itemFields } from '../consts';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { DomainsPageTableHeader } from '../domains-page-table-header';
import { Domain } from 'store/slice/domains-slice';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import { DomainsTable } from 'components/domains-table/domains-table';

const DOMAIN_TABLE_PAGE_LIMIT = 50;

interface DomainsTableProps {
  currentTab: number;
  onTabChange: (evt: React.SyntheticEvent, newValue: number) => void;
  selectedDomains: Record<number, Domain>;
  handleSelectDomain: (payload: { checked: boolean; domainId: number }) => void;
  handleRemoveSelectedDomain: (domainId: number) => void;
  handleClearAllSelectedDomains: () => void;
}

const getDomainsTableFilterFields = () => {
  const domainsFilterFields: Record<string, FilterField> = {};
  for (const fieldKey in itemFields) {
    if (itemFields[fieldKey].isFilter) {
      domainsFilterFields[fieldKey] = itemFields[fieldKey];
    }
  }
  return domainsFilterFields;
};
export const Domains = (props: DomainsTableProps) => {
  const {
    currentTab,
    onTabChange,
    selectedDomains,
    handleSelectDomain,
    handleRemoveSelectedDomain,
    handleClearAllSelectedDomains,
  } = props;
  const { domains } = useAppSelector((state) => state.domains);
  const domainsFilterFields = useMemo(getDomainsTableFilterFields, []);
  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({
    filterOptions: domainsFilterFields,
    data: domains,
  });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    currentData,
    handlePageChange,
    handleSort,
    sortOptions,
    searchTerm: domainSearchValue,
    handleSearch,
  } = usePagination(visibleData, DOMAIN_TABLE_PAGE_LIMIT, 'domainName');

  return (
    <Wrapper>
      <DomainsPageTableHeader
        currentTab={currentTab}
        onTabChange={onTabChange}
        filteredOptions={filteredOptions}
        searchTerm={searchTerm}
        filters={filters}
        removeFilterType={removeFilterType}
        setSearchTerm={setSearchTerm}
        handleSearchInputChange={handleSearchInputChange}
        addFilter={addFilter}
        handleOptionClick={handleOptionClick}
        handleFilterValueChange={handleFilterValueChange}
        handleFilterSecondValueChange={handleFilterSecondValueChange}
        newFilter={newFilter}
        isFilterFulfilled={isFilterFulfilled}
        handleFilterOperatorChange={handleFilterOperatorChange}
        filterValueOptions={filterValueOptions}
        domainSearchValue={domainSearchValue}
        handleSearch={handleSearch}
        currentDomainsData={visibleData}
        selectedDomains={selectedDomains}
        handleRemoveSelectedDomain={handleRemoveSelectedDomain}
        handleClearAllSelectedDomains={handleClearAllSelectedDomains}
      />
      <DomainsTable
        pageCount={pageCount}
        currentData={currentData}
        handlePageChange={handlePageChange}
        selectedDomains={selectedDomains}
        handleSelectDomain={handleSelectDomain}
        handleSort={handleSort}
        sortOptions={sortOptions}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  overflow-y: auto;
  position: relative;
  margin-bottom: 50px;
`;
