import React from 'react';
import styled from 'styled-components';
import { NavigationItemList } from './types';
import { Navigation } from './navigation';
import { Content } from './content';

interface NavigationWithContentProps {
  children: React.ReactNode;
  navigationList: NavigationItemList[];
}

export const NavigationWithContent = ({
  navigationList,
  children,
}: NavigationWithContentProps) => {
  return (
    <Wrapper>
      <Navigation navList={navigationList} />
      <Content children={children} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;
