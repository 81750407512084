import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomModal } from '../../components/modal/custom-modal';
import { useAppSelector } from 'store/hooks';
import { useToaster } from 'contexts/toast-context';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Autocomplete,
  Theme,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import {
  AdminCreateSubscriptionPayload,
  CreateSuscriptionWithExistingPlanTemplate,
  CreateSuscriptionWithNewPlanTemplate,
  PlanTemplate,
} from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { SubscriptionAdminService } from 'api/subscriptions-admin-service';
import { FormValues, validationSchema } from './form-consts';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { BusinessWithOwner, Owner } from 'types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import styled from 'styled-components';

interface CreateSubscriptionPopupProps {
  open: boolean;
  handleClose: () => void;
  onCreateSubscription: () => Promise<void>;
}

export const CreateSubscriptionPopup = ({
  handleClose,
  open,
  onCreateSubscription,
}: CreateSubscriptionPopupProps) => {
  const { businesses } = useAppSelector((state) => state.businesses);
  const { setToaster } = useToaster();
  const [inputValue, setInputValue] = useState('');
  const [planTemplates, setPlanTemplates] = useState<PlanTemplate[]>([]);

  const owners = useMemo(() => {
    const uniqueOwners: Owner[] = [];
    const userIds = new Set<number>();

    businesses.map(({ business, owner }) => {
      if (!userIds.has(owner.userID)) {
        userIds.add(owner.userID);
        uniqueOwners.push(owner);
      }
    });

    return uniqueOwners;
  }, [businesses]);

  const [selectedOwner, setSelectedOwner] = useState<string | null>(null);
  const [ownerBusinesses, setOwnerBusinesses] =
    useState<BusinessWithOwner[]>(businesses);

  useMemo(() => {
    if (!selectedOwner || !businesses || businesses.length === 0) {
      return;
    }

    const filteredOwnerBusinesses = businesses.filter(
      ({ business, owner }) => selectedOwner === owner.email,
    );

    setOwnerBusinesses(filteredOwnerBusinesses);
  }, [selectedOwner, businesses]);

  useEffect(() => {
    setOwnerBusinesses(businesses);
  }, [businesses]);

  const onFormSubmit = React.useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      if (!values.business) {
        return;
      }
      formikHelpers.setSubmitting(true);

      let planTemplateDetails:
        | CreateSuscriptionWithExistingPlanTemplate
        | CreateSuscriptionWithNewPlanTemplate = {
        id: Number(values.plan_template_id),
      };

      if (values.plan_template_version === 'new') {
        planTemplateDetails = {
          name: values.plan_template_name,
          price: Number(values.plan_template_price),
          duration: values.plan_template_duration,
          quantity: Number(values.plan_template_quantity),
        };
      }

      const createSubscriptionPayload: AdminCreateSubscriptionPayload = {
        business_id: values.business.id,
        plan_template: planTemplateDetails,
        expiration_date: values.expiration_date,
        subscription: {
          provider_id: Number(values.provider),
          transaction_id: values.transaction_id,
        },
      };

      const { isSuccess, error } =
        await SubscriptionAdminService.createSubscriptions(
          createSubscriptionPayload,
        );

      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      setInputValue('');

      if (!isSuccess) {
        setToaster('error', error);
        return;
      }
      onCreateSubscription();
      setToaster('success', 'Subscription created successfully');
      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik<FormValues>({
    initialValues: {
      business: undefined,
      provider: '',
      transaction_id: '',
      plan_template_id: '',
      plan_template_version: '',
      plan_template_name: '',
      plan_template_price: '',
      plan_template_quantity: '',
      plan_template_duration: '',
      plan_template_type: '',
      expiration_date: moment().format('YYYY-MM-DD'),
    },
    validationSchema,
    onSubmit: onFormSubmit,
  });

  const onReset = () => {
    resetForm();
    handleClose();
  };

  const onSelectPlanTemplateVersion = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (values.plan_template_version === value) {
      setFieldValue('plan_template_version', '');
      return;
    }

    setFieldValue('plan_template_version', value);
  };

  const getPlanTemplates = useCallback(async () => {
    const { data } = await SubscriptionAdminService.getPlanTemplates();

    if (data) {
      setPlanTemplates(data);
    }
  }, []);

  useEffect(() => {
    void getPlanTemplates();
  }, [getPlanTemplates]);

  const disableCreateNewTemplateField =
    values.plan_template_version === 'existing';

  return (
    <CustomModal
      open={open}
      handleClosePopup={onReset}
      ariaDescribedBy="business modal"
      modalTitle={
        <Typography variant="h4" fontWeight="500">
          Create New Subscription
        </Typography>
      }
    >
      <SubscriptionFormWrapper>
        <form onSubmit={handleSubmit}>
          <Box mt={2} />
          <Autocomplete
            value={selectedOwner}
            id="ownerEmail"
            options={owners.map((owner) => owner.email)}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                label="Select Business Owner' Email"
              />
            )}
            onChange={(event, newValue) => {
              if (!newValue) {
                setOwnerBusinesses(businesses);
              }

              setSelectedOwner(newValue);
            }}
            autoComplete={false}
            autoCorrect="off"
          />
          <Box mt={2} />
          <Autocomplete
            value={values.business || null}
            onChange={(event, newValue) => {
              setFieldValue('business', newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="select-business"
            options={ownerBusinesses.map(({ business, owner }) => ({
              id: business.id,
              name: business.name,
            }))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Select Business" />
            )}
            fullWidth
            color="secondary"
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={`${option.id}`}>
                  {option.name}
                </li>
              );
            }}
          />
          <Box mt={2} />
          <Box display="flex" justifyContent="space-between">
            <FormControl sx={{ minWidth: '45%' }}>
              <InputLabel id="provider-id-label">Provider</InputLabel>
              <Select
                labelId="provider-id-label"
                id="provider-id"
                value={values.provider}
                label="Provider"
                onChange={handleChange}
                name="provider"
                color="secondary"
              >
                <MenuItem value="1">Equally</MenuItem>
                <MenuItem value="2">Paypal</MenuItem>
                <MenuItem value="3">Stripe</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              value={values.transaction_id}
              label="Transaction ID"
              onChange={handleChange}
              aria-label="Transaction ID"
              name="transaction_id"
              disabled={values.provider === '1'}
              helperText={errors.transaction_id}
              sx={{ minWidth: '45%' }}
              color="secondary"
            />
          </Box>
          <Divider sx={{ my: '20px' }} />
          <Box padding={1}>
            <Typography variant="h5">Plan Template</Typography>
            <Box display="flex" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    value="existing"
                    name="plan_template_version"
                    onChange={onSelectPlanTemplateVersion}
                    checked={disableCreateNewTemplateField}
                  />
                }
                label="Select existing plan template"
                sx={{ minWidth: '45%' }}
              />

              <FormControl sx={{ minWidth: '45%' }}>
                <InputLabel id="select-template-label">
                  Select Template
                </InputLabel>
                <Select
                  labelId="select-template-label"
                  id="plan-template-id"
                  value={values.plan_template_id}
                  label="Provider"
                  onChange={handleChange}
                  name="plan_template_id"
                >
                  {planTemplates.map((planTemplate) => (
                    <MenuItem key={planTemplate.id} value={planTemplate.id}>
                      {planTemplate.name} - {planTemplate.product_type},{' '}
                      {planTemplate.duration_unit}, {planTemplate.domain_size},{' '}
                      {planTemplate.price > 0
                        ? `${planTemplate.currency}${planTemplate.price}`
                        : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="plan_template_version"
                    value="new"
                    onChange={onSelectPlanTemplateVersion}
                    checked={values.plan_template_version === 'new'}
                  />
                }
                label="Create New"
              />
              <Box sx={{ padding: 2 }}>
                <TextField
                  variant="outlined"
                  value={values.plan_template_name}
                  label="Plan Template Name"
                  onChange={handleChange}
                  aria-label="Plan Template Name"
                  name="plan_template_name"
                  disabled={disableCreateNewTemplateField}
                  color="secondary"
                  helperText={errors.plan_template_name}
                  fullWidth
                />
                <Box mt={2} />
                <Box display="flex" justifyContent="space-between">
                  <FormControl sx={{ minWidth: '45%' }}>
                    <InputLabel id="select-template-type">Type</InputLabel>
                    <Select
                      labelId="select-template-type"
                      id="plan_template_type"
                      value={values.plan_template_type}
                      label="Type"
                      onChange={handleChange}
                      name="plan_template_type"
                      disabled={disableCreateNewTemplateField}
                      color="secondary"
                    >
                      <MenuItem value="flowy">Flowy</MenuItem>
                      <MenuItem value="widget">Widget</MenuItem>
                    </Select>
                    <FormHelperText>{errors.plan_template_type}</FormHelperText>
                  </FormControl>
                  <FormControl sx={{ minWidth: '45%' }}>
                    <InputLabel id="select-template-duration">
                      Duration
                    </InputLabel>
                    <Select
                      labelId="select-template-duration"
                      id="plan-template-duration"
                      value={values.plan_template_duration}
                      label="Duration"
                      onChange={handleChange}
                      name="plan_template_duration"
                      disabled={disableCreateNewTemplateField}
                      color="secondary"
                    >
                      <MenuItem value="YEAR">Yearly</MenuItem>
                      <MenuItem value="MONTH">Monthly</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.plan_template_duration}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box mt={2} />
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    variant="outlined"
                    value={values.plan_template_price}
                    label="Plan Template Price"
                    onChange={handleChange}
                    aria-label="Plan Template Price"
                    name="plan_template_price"
                    helperText={errors.plan_template_price}
                    sx={{ minWidth: '45%' }}
                    disabled={disableCreateNewTemplateField}
                    color="secondary"
                    type="number"
                  />
                  <TextField
                    variant="outlined"
                    value={values.plan_template_quantity}
                    label="Plan Template Quantity"
                    onChange={handleChange}
                    aria-label="Plan Template Quantity"
                    name="plan_template_quantity"
                    helperText={errors.plan_template_quantity}
                    sx={{ minWidth: '45%' }}
                    disabled={disableCreateNewTemplateField}
                    color="secondary"
                    type="number"
                  />
                </Box>
                <Box mt={2} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <StyledDatePicker
                    renderInput={(params) => <TextField {...params} />}
                    label="Expiration Date"
                    value={moment(values.expiration_date)}
                    disabled={disableCreateNewTemplateField}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue(
                          'expiration_date',
                          (value as any).format('YYYY-MM-DD'),
                        );
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '20px',
              justifyContent: 'flex-end',
              position: 'fixed',
              bottom: 0,
              width: 'calc(100% - 40px)',
              backgroundColor: '#fff',
              padding: '10px 0',
              zIndex: 999999,
            }}
          >
            <Box style={{ marginRight: 10 }}>
              <Button variant="outlined" onClick={onReset} color="secondary">
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!isValid}
              >
                Create
              </Button>
            </Box>
          </Box>
        </form>
      </SubscriptionFormWrapper>
      <LoadingView
        open={isSubmitting}
        loadingText="Creating your subscription"
        zIndex="99999999"
      />
    </CustomModal>
  );
};

const SubscriptionFormWrapper = styled.div`
  max-height: 86vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const StyledDatePicker = styled(DatePicker)`
  &.Mui-selected {
    background-color: #454284;
    color: #fff;
  }
`;
