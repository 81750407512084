import React from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';
import { Coupon } from 'store/types/coupon-types';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { editCoupon } from 'store/slice/coupons-slice';
import { useToaster } from 'contexts/toast-context';
import { StyledButton } from './coupons-table';

interface EditCouponFormProps {
  coupons: Coupon[];
  selectedCoupon: Partial<Coupon>;
  setSelectedCoupon: React.Dispatch<React.SetStateAction<Partial<Coupon>>>;
  handleClosePopup: () => void;
}

export const EditCouponForm = ({
  coupons,
  selectedCoupon,
  setSelectedCoupon,
  handleClosePopup,
}: EditCouponFormProps) => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();

  const handleCouponChange = (evt: any) => {
    const { name, value } = evt.target;
    setSelectedCoupon({
      ...selectedCoupon,
      [name]: value,
    });
  };

  const handleEditCoupon = async () => {
    const initialCouponData = coupons.filter(
      (coupon) => coupon.id === selectedCoupon.id,
    )[0];
    const dataToSend = {
      ...selectedCoupon,
    };

    if (dataToSend.operator === 'fix') {
      dataToSend.amount = initialCouponData.amount;
      dataToSend.total_credits = Number(dataToSend.total_credits);
    } else if (dataToSend.operator === 'percent') {
      dataToSend.total_credits = initialCouponData.total_credits;
      dataToSend.amount = Number(dataToSend.amount);
    }

    const response = await dispatch(editCoupon(dataToSend));
    const { isSuccess, error } = response.payload as ApiResponse<string>;

    if (!isSuccess) {
      setToaster('error', error);
    } else {
      setToaster('success', 'Coupon updated successfully!');
      handleClosePopup();
    }
  };

  const handleSubmitButton = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    handleEditCoupon();
  };

  return (
    <ModalContent>
      <ModalTitle>Edit Coupon</ModalTitle>
      <form onSubmit={handleSubmitButton}>
        <InputDiv>
          <TextField
            type="text"
            name="name"
            value={selectedCoupon.name}
            onChange={handleCouponChange}
            id="coupon-name"
            label="Name"
            variant="outlined"
            required
          />
        </InputDiv>
        <InputDiv>
          <FormControl fullWidth>
            <InputLabel id="coupon-type">Type</InputLabel>
            <Select
              labelId="coupon-type"
              id="coupon-type-select"
              value={selectedCoupon.type || ''}
              name="type"
              label="Type"
              onChange={handleCouponChange}
              required
            >
              <MenuItem value="widget">Widget</MenuItem>
              <MenuItem value="developer">Developer</MenuItem>
            </Select>
          </FormControl>
        </InputDiv>
        <InputDiv>
          <FormControl fullWidth>
            <InputLabel id="operator">Operator</InputLabel>
            <Select
              labelId="operator"
              id="coupon-operator-select"
              value={selectedCoupon.operator || ''}
              name="operator"
              label="Operator"
              onChange={handleCouponChange}
              required
            >
              <MenuItem value="fix">Fix</MenuItem>
              <MenuItem value="percent">Percent</MenuItem>
            </Select>
          </FormControl>
        </InputDiv>
        <InputDiv>
          <TextField
            type="number"
            name={
              selectedCoupon.operator === 'fix' ? 'total_credits' : 'amount'
            }
            value={
              selectedCoupon.operator === 'fix'
                ? selectedCoupon.total_credits
                : selectedCoupon.amount
            }
            onChange={handleCouponChange}
            id="coupon-amount"
            label="Amount"
            variant="outlined"
            required
          />
        </InputDiv>
        <StyledButton type="submit" fullWidth>
          Edit Coupon
        </StyledButton>
      </form>
    </ModalContent>
  );
};

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
`;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
  width: 400px;
  border-radius: 10px;
  padding: 10px 20px 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;

  &:focus {
    outline: none;
  }
`;

const ModalTitle = styled.h3`
  font-size: 15px;
  margin-bottom: 30px;
  text-align: center;
`;
