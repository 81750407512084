import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import React from 'react';
import styled from 'styled-components';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import {
  businessesWithDomainsFields,
  businessesWithDomainsMap,
  BusinessWithDomains,
  businessWithDomainsDetails,
  domainFields,
} from './consts';
import { DomainsPageTableHeader } from './domains-page-table-header';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { DomainRow } from '../../components/latest-scans-table/domain-row';
import { Domain } from 'store/slice/domains-slice';
import { LatestDomainScansFieldMap } from 'components/latest-scans-table/websites-table';

interface BusinessDomainsTableProps {
  currentTab: number;
  onTabChange: (evt: React.SyntheticEvent, newValue: number) => void;
  businessesWithDomains: BusinessWithDomains[];
  selectedDomains: Record<number, Domain>;
  handleSelectDomain: (payload: { checked: boolean; domainId: number }) => void;
  handleRemoveSelectedDomain: (domainId: number) => void;
  handleClearAllSelectedDomains: () => void;
}

const BUSINESSES_WITH_DOMAIN_TABLE_PAGE_LIMIT = 50;

export const BusinessDomainsTable = (props: BusinessDomainsTableProps) => {
  const {
    currentTab,
    onTabChange,
    businessesWithDomains,
    selectedDomains,
    handleSelectDomain,
    handleRemoveSelectedDomain,
    handleClearAllSelectedDomains,
  } = props;

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({
    filterOptions: businessesWithDomainsFields,
    data: businessesWithDomains,
  });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    currentData: visibleBusinessesWithDomains,
    handlePageChange,
    handleSort,
    sortOptions,
    searchTerm: domainSearchValue,
    handleSearch,
  } = usePagination(
    visibleData,
    BUSINESSES_WITH_DOMAIN_TABLE_PAGE_LIMIT,
    'businessName',
  );

  const handleCheckboxChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    domainId: number,
  ) => {
    const { checked } = evt.target;
    handleSelectDomain({ checked, domainId });
  };

  const domainsTableCheckBoxFieldMap: LatestDomainScansFieldMap = {
    key: 'domainID',
    format: ({ domainID }: Domain) => (
      <Checkbox
        inputProps={{
          'aria-label': 'Domain id checkbox',
        }}
        checked={!!selectedDomains[domainID]}
        onChange={(evt) => handleCheckboxChange(evt, domainID)}
      />
    ),
    align: 'left',
  };

  return (
    <Wrapper>
      <DomainsPageTableHeader
        currentTab={currentTab}
        onTabChange={onTabChange}
        filteredOptions={filteredOptions}
        searchTerm={searchTerm}
        filters={filters}
        removeFilterType={removeFilterType}
        setSearchTerm={setSearchTerm}
        handleSearchInputChange={handleSearchInputChange}
        addFilter={addFilter}
        handleOptionClick={handleOptionClick}
        handleFilterValueChange={handleFilterValueChange}
        handleFilterSecondValueChange={handleFilterSecondValueChange}
        newFilter={newFilter}
        isFilterFulfilled={isFilterFulfilled}
        handleFilterOperatorChange={handleFilterOperatorChange}
        filterValueOptions={filterValueOptions}
        domainSearchValue={domainSearchValue}
        handleSearch={handleSearch}
        currentDomainsData={visibleBusinessesWithDomains}
        selectedDomains={selectedDomains}
        handleRemoveSelectedDomain={handleRemoveSelectedDomain}
        handleClearAllSelectedDomains={handleClearAllSelectedDomains}
      />
      <TableContainer component={Paper}>
        <Table aria-label="business domains table">
          <TableHead>
            <TableRow>
              <TableCell />
              {Object.entries(businessesWithDomainsFields).map(
                ([_, businessWithDomainField]) => {
                  const currentSortKey = Object.keys(sortOptions)[0];
                  const currentSortOrder =
                    Object.values(sortOptions)[0] || 'desc';

                  return (
                    <TableCell
                      key={businessWithDomainField.key}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      <TableSortLabel
                        active={currentSortKey === businessWithDomainField.key}
                        direction={currentSortOrder}
                        onClick={() => handleSort(businessWithDomainField.key)}
                      >
                        {businessWithDomainField.displayName}
                      </TableSortLabel>
                    </TableCell>
                  );
                },
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleBusinessesWithDomains.map(
              (businessWithDomain: BusinessWithDomains, bdidx) => {
                return (
                  <DomainRow
                    key={`${businessWithDomain.id}-${bdidx}`}
                    latestDomainScansResultMap={businessesWithDomainsMap}
                    latestDomainScansData={businessWithDomain}
                    latestDomainScansDetailsMap={[
                      domainsTableCheckBoxFieldMap,
                      ...businessWithDomainsDetails,
                    ]}
                    domainDetailsTitle="Business Domains"
                    domainDetailFields={domainFields}
                    domainDetails={businessWithDomain.domains}
                    emptyDomainDetailsText="No domains available for this business"
                  />
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination handlePageChange={handlePageChange} pageCount={pageCount} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
