import { ROUTES_URLS } from 'containers/routes';
import { useAdminAuth } from 'contexts/admin-context';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface UnProtectedRouteProps {
  children: React.ReactNode;
}

export const UnProtectedRoute = ({ children }: UnProtectedRouteProps) => {
  const { isAdmin } = useAdminAuth();

  return (
    <>
      {isAdmin ? (
        <Navigate to={ROUTES_URLS.HOME_PAGE} replace />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
