import axios from 'axios';
import {
  User,
  BusinessRole,
  UserRolesAndRolesIdToRoleMap,
} from 'store/slice/users-slice';
import { BASE_API_URL } from 'utils/constants';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';

class UsersAdminService {
  static getUsers = async (): Promise<ApiResponse<User[]>> => {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'users');
    } catch (err: any) {
      console.error('Error getting users');
      error = err?.message || 'Failed to get users';
    }

    const { data } = result || {};

    return {
      data: (data?.message || []) as User[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };

  static editUser = async (payload: User): Promise<ApiResponse<User[]>> => {
    let result;
    let error;

    try {
      result = await axios.post(BASE_API_URL + 'user/edit', payload);
    } catch (err: any) {
      console.error('Error editting user information');
      error = err?.message || 'Failed to edit user information';
    }

    const { data } = result || {};

    return {
      data: (data?.message || []) as User[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };

  static getUserRoles = async (userId: number): Promise<ApiResponse<any>> => {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'roles/users/' + userId);
    } catch (err: any) {
      console.error('Error getting user role');
      error = err?.message || 'Failed to get user role';
    }

    const { data } = result || {};

    return {
      data: data || {},
      isSuccess: Object.keys(data).length > 0,
      error: error || data,
    };
  };

  static getAllUserRoles = async (): Promise<
    ApiResponse<UserRolesAndRolesIdToRoleMap>
  > => {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'roles/users/get/all');
    } catch (err: any) {
      console.error('Error getting user roles');
      error = err?.message || 'Failed to get user roles';
    }
    const { data } = result || {};

    return {
      data: data?.data || {},
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };

  static addBusinessRoles = async (
    payload: BusinessRole,
  ): Promise<ApiResponse<any>> => {
    let result;
    let error;

    try {
      result = await axios.post(BASE_API_URL + 'roles', payload);
    } catch (err: any) {
      console.error('Error adding business role');
      error = err?.message || 'Failed to adding business role';
    }

    const { data } = result || {};

    return {
      data: data.data || {},
      isSuccess: data?.code === 200,
      error: error || data.data,
    };
  };

  static deleteBusinessRole = async (
    payload: BusinessRole,
  ): Promise<ApiResponse<any>> => {
    let result;
    let error;

    try {
      result = await axios.delete(BASE_API_URL + 'roles', { data: payload });
    } catch (err: any) {
      console.error('Error deleting business role');
      error = err?.message || 'Failed to deleting business role';
    }

    const { data } = result || {};

    return {
      data: data.data || {},
      isSuccess: data?.code === 200,
      error: error || data.data,
    };
  };
}

export { UsersAdminService };
