import React from 'react';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import { Box } from '@mui/material';

interface ModalProps {
  open: boolean;
  handleClosePopup: () => void;
  modalTitle: React.ReactNode | null;
  children: React.ReactNode;
  ariaDescribedBy: string;
}

export const CustomModal = (props: ModalProps) => {
  const { open, handleClosePopup, modalTitle, children, ariaDescribedBy } =
    props;

  return (
    <Modal
      open={open}
      onClose={handleClosePopup}
      aria-describedby={ariaDescribedBy}
    >
      <ModalContent>
        {modalTitle && <ModalTitle>{modalTitle}</ModalTitle>}
        {children}
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
  width: 50%;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px 20px 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;

  &:focus {
    outline: none;
  }
`;

const ModalTitle = styled.h3`
  font-size: 15px;
  margin-bottom: 10px;
`;
