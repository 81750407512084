import React from 'react';
import { Domain, getDomains } from 'store/slice/domains-slice';
import { Dayjs } from 'dayjs';
import { Box, Button, Typography } from '@mui/material';
import { StyledButton } from 'containers/coupons-page/coupons-table';
import styled from 'styled-components';
import DomainsAPI, {
  DomainToUpdate,
} from '@equally-ai-front/common/src/api/domains';
import { useAppDispatch } from 'store/hooks';
import { useToaster } from 'contexts/toast-context';

interface UpdateDomainConfirmationProps {
  selectedDomains: Record<number, Domain>;
  expirationDate: Dayjs | null;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setIsUpdatingDomains: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export const UpdateDomainConfirmation = (
  props: UpdateDomainConfirmationProps,
) => {
  const {
    selectedDomains,
    expirationDate,
    setActiveStep,
    setIsUpdatingDomains,
    onClose,
  } = props;
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const formattedExpirationDate = expirationDate?.format('DD/MM/YYYY');

  const handleGoBack = () => {
    setActiveStep(0);
  };

  const handleSubmit = async () => {
    const expiration = expirationDate?.format('YYYY-MM-DD');
    if (!expiration) {
      console.error('Expiration date is missing!');
      return;
    }
    setIsUpdatingDomains(true);
    const payload: DomainToUpdate[] = Object.entries(selectedDomains).map(
      ([_, domain]) => ({
        url: domain.domainName,
        business_id: String(domain.businessId),
        expires: expiration,
      }),
    );

    const { isSuccess, error } =
      await DomainsAPI.updateDomainExpiration(payload);
    if (!isSuccess) {
      setToaster('error', error);
    } else {
      setToaster('success', 'Domains expiration updated successfully');
      dispatch(getDomains());
      onClose();
    }
    setIsUpdatingDomains(false);
  };

  return (
    <Box>
      <List>
        {Object.entries(selectedDomains).map(([_, domain], idx) => (
          <li key={`${domain.domainName}-${idx}`}>
            <Typography>{domain.domainName}</Typography>
          </li>
        ))}
      </List>
      <Box marginY="15px">
        {Object.entries(selectedDomains).length} domains expiration date will
        change to&nbsp;
        {formattedExpirationDate}
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        gap="10px"
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleGoBack}
          sx={{ height: '44px' }}
        >
          Back
        </Button>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ height: '44px', marginRight: 0 }}
        >
          Submit
        </StyledButton>
      </Box>
    </Box>
  );
};

const List = styled.ul`
  padding-left: 15px;
`;
