import { Box, Typography } from '@mui/material';
import {
  complianceDocumentFilterFields,
  complianceDocumentsFields,
} from './consts';
import React, { useMemo, useState } from 'react';
import {
  OrderOptionsEnum,
  usePagination,
} from '@equally-ai-front/common/src/hooks/use-pagination';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { TableWrapper } from 'components/css-components';
import { Table } from 'containers/business-details-page/components/table/table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import { RecordFilterContainer } from '@equally-ai-front/common/src/components/filters/record-filter/record-filter-container';
import {
  ComplianceDocumentWithBusinessDetails,
  getComplianceDocumentData,
} from 'containers/documents-redirect/utils';
import { CustomModal } from 'components/modal/custom-modal';
import { DocumentPopup } from '../document-popup/document-popup';
import {
  ComplianceDocument,
  UpdateDocumentFormData,
} from '@equally-ai-front/common/src/types/compliance-document';
import { updateComplianceDocument } from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';
import { useToaster } from 'contexts/toast-context';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { COMPLIANCE_DOCUMENTS_QUERY_KEY } from '../../consts';

interface DocumentsTableProps {
  documents: ComplianceDocument[];
}
export const DocumentsTable = (props: DocumentsTableProps) => {
  const { documents: complianceDocuments } = props;
  const { setToaster } = useToaster();
  const { businesses } = useAppSelector((state) => ({
    businesses: state.businesses.businesses,
  }));
  const queryClient = useQueryClient();
  const [selectedDocument, setSelectedDocument] =
    useState<ComplianceDocumentWithBusinessDetails | null>(null);

  const complianceDocumentsData = useMemo(
    () => getComplianceDocumentData({ complianceDocuments, businesses }),
    [complianceDocuments, businesses],
  );

  const handleOpenDocumentPopup = (
    data: ComplianceDocumentWithBusinessDetails,
  ) => {
    setSelectedDocument(data);
  };

  const handleCloseDocumentPopup = () => {
    setSelectedDocument(null);
  };
  const { mutateAsync: updateDocument, isPending: isLoading } = useMutation({
    mutationFn: updateComplianceDocument,
    onSuccess: async () => {
      // @ts-ignore
      await queryClient.invalidateQueries([COMPLIANCE_DOCUMENTS_QUERY_KEY]);
    },
  });

  const handleEditDocument = async (
    evt: React.FormEvent<HTMLFormElement>,
    newDocument: UpdateDocumentFormData,
  ) => {
    evt.preventDefault();
    try {
      const {
        data,
        businessSlug,
        domain,
        template_id: templateId,
      } = selectedDocument as ComplianceDocumentWithBusinessDetails;
      await updateDocument({
        ...newDocument,
        url: data.url,
        slug: businessSlug,
        domain,
        template_id: templateId,
      });
      setToaster('success', 'Document updated successfully!');
      handleCloseDocumentPopup();
    } catch (error) {
      setToaster('error', (error as Error).message);
    }
  };

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({
    filterOptions: complianceDocumentFilterFields,
    data: complianceDocumentsData,
  });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const { pageCount, currentData, handlePageChange, sortOptions, handleSort } =
    usePagination(visibleData, 10, 'domain', {
      created_at: OrderOptionsEnum.DESC,
    });

  return (
    <Box>
      <MultiFilterSelect
        filterValueOptions={filterValueOptions}
        handleFilterValueChange={handleFilterValueChange}
        handleFilterSecondValueChange={handleFilterSecondValueChange}
        handleFilterOperatorChange={handleFilterOperatorChange}
        searchTerm={searchTerm}
        addFilter={addFilter}
        isFilterFulfilled={isFilterFulfilled}
        setSearchTerm={setSearchTerm}
        handleOptionClick={handleOptionClick}
        handleSearchInputChange={handleSearchInputChange}
        options={filteredOptions}
        currentFilter={newFilter}
      />
      {Object.entries(filters).length > 0 && (
        <RecordFilterContainer
          filters={filters}
          removeFilterType={removeFilterType}
          allowAdditionalInfo
        />
      )}
      <TableWrapper>
        <Table
          handleSort={handleSort}
          sortOptions={sortOptions}
          currentData={currentData}
          tableFields={complianceDocumentsFields}
          tableAriaLabel="Compliance documents table"
          emptyDataText="No Documents Available"
          onRowClick={handleOpenDocumentPopup}
        />
        {currentData.length > 0 && (
          <Pagination
            pageCount={pageCount}
            handlePageChange={handlePageChange}
          />
        )}
        <CustomModal
          open={!!selectedDocument}
          handleClosePopup={handleCloseDocumentPopup}
          ariaDescribedBy="document popup"
          modalTitle={
            <Typography
              mb="5px"
              fontSize="26px"
              display="flex"
              alignItems="center"
            >
              Compliance Document
            </Typography>
          }
        >
          {selectedDocument && (
            <DocumentPopup
              complianceDocumentData={selectedDocument}
              handleEditDocument={handleEditDocument}
            />
          )}
        </CustomModal>
      </TableWrapper>
      <LoadingView
        open={isLoading}
        loadingText="Updating compliance document..."
        zIndex="1500"
      />
    </Box>
  );
};
