import React from 'react';

import { Box, Button } from '@mui/material';

import styled from 'styled-components';

interface SaveAndCancelProps {
  onClose: () => void;
}

interface DeleteOrCancelProps extends SaveAndCancelProps {
  onDelete: () => void;
}

export const SaveAndCancel: React.FC<SaveAndCancelProps> = ({ onClose }) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <CancelDelete onClick={onClose}>Cancel</CancelDelete>
      <SaveButton type="submit">Save</SaveButton>
    </Box>
  );
};

export const DeleteOrCancel: React.FC<DeleteOrCancelProps> = ({
  onClose,
  onDelete,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <CancelDelete onClick={onClose}>Cancel</CancelDelete>
      <StyledButton variant="contained" onClick={onDelete}>
        Delete
      </StyledButton>
    </Box>
  );
};

const StyledButton = styled(Button)`
  text-transform: capitalize;
  background-color: #454284;
  color: #fff;

  &:hover {
    background-color: #3b3871;
  }
`;

const CancelDelete = styled(StyledButton)`
  border: 1px solid #454284;
  margin-right: 8px;

  background-color: #fff;
  color: #454284;

  &:hover {
    color: #fff;
  }
`;

const SaveButton = styled(StyledButton)`
  background-color: #454284;
  color: #fff;

  &:hover {
    background-color: #3b3871;
  }
`;
