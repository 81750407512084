import { Box } from '@mui/material';
import React from 'react';
import { DocumentsTable } from './components/table/documents-table';
import { useQuery } from '@tanstack/react-query';
import { getUsersComplianceDocuments } from '@equally-ai-front/common/src/redux/compliance-documents-slice/compliance-documents';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import {
  COMPLIANCE_DOCUMENTS_QUERY_KEY,
  QUERY_DATA_REFRESH_INTERVAL_DEFAULT,
} from './consts';

export const DocumentsPage = () => {
  const {
    isLoading,
    isError,
    data: documents,
    error,
  } = useQuery({
    queryKey: [COMPLIANCE_DOCUMENTS_QUERY_KEY],
    queryFn: () => getUsersComplianceDocuments(),
    refetchInterval: QUERY_DATA_REFRESH_INTERVAL_DEFAULT,
  });

  if (isLoading)
    return (
      <LoadingView
        open={isLoading}
        loadingText="Loading compliance documents..."
      />
    );

  if (isError) return <div>Error: {(error as Error).message}</div>;

  return (
    <Box>
      <h1>Documents Page</h1>
      <DocumentsTable documents={documents} />
    </Box>
  );
};
