import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
import { BOOK_DEMO_URL } from '../../utils/constants';
import { useBookADemo } from '@equally-ai-front/common/src/hooks/use-book-a-demo';

export const BookADemo = () => {
  const { handleBookDemo } = useBookADemo();
  const handleBookDemoClick = () => {
    handleBookDemo({
      bookADemoLink: BOOK_DEMO_URL,
      productType: 'widget',
      source: 'user panel',
    });
  };
  return (
    <Wrapper>
      <Content>
        <Text variant="body1">Ready to get started?</Text>
        <Button onClick={handleBookDemoClick}>BOOK A DEMO</Button>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1100px;
  margin: 75px auto 185px;
  background-color: #000a14;
  box-shadow: 0px 15px 25px 0px #00122626;
  height: 268px;
  padding: 48px;
  border-radius: 16px;

  @media screen and (max-width: 1300px) {
    max-width: 85%;
  }

  @media screen and (max-width: 1130px) {
    max-width: 95%;
  }

  @media screen and (max-width: 850px) {
    margin-bottom: 250px;
  }

  @media screen and (max-width: 690px) {
    display: flex;
  }
`;

const Content = styled(Box)`
  width: 510px;
  height: 172px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 690px) {
    width: fit-content;
    height: fit-content;
  }
`;

const Text = styled(Typography)`
  text-transform: capitalize;
  width: 220px;
  margin-bottom: 32px;
  height: 96px;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 690px) {
    width: fit-content;
    font-size: 30px;
    height: fit-content;
  }
`;

const Button = MuiStyled(PrimaryButton)(() => ({
  [`&.${buttonClasses.root}`]: {
    height: 48,
    borderRadius: 8,
    whiteSpace: 'nowrap',
    padding: '12px 24px',
    backgroundColor: '#454284',
    color: '#ffffff',
    lineHeight: 24,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    width: 172,
  },
}));
