import React from 'react';
import { AriaContainer } from '@equally-ai/audit-ui/src/containers/aria-container';
import { useAutomaticScan } from '@equally-ai-front/common/src/hooks/use-automatic-scan';

export const Audit = () => {
  const {
    performSingleAudit,
    isLoading: loading,
    scans,
    getIssues,
  } = useAutomaticScan({
    onError: () => {},
    isAdmin: true,
  });

  const createNotification = (
    message: string,
    variant: 'error' | 'success',
  ) => {};

  return (
    <div>
      <AriaContainer
        //@ts-ignore
        performAudit={performSingleAudit}
        allowNonBusiness
        loading={loading}
        getIssues={getIssues}
        scans={scans}
        onStartFreeTrialClick={() => {}}
        createNotification={createNotification}
        isAdmin
      />
    </div>
  );
};
