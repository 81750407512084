import { TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import styled from 'styled-components';
import { DomainsTableBody, formatDate } from './domains-table-body';
import { Close } from '@mui/icons-material';
import { domainFields as itemFields } from './consts';
import { withStyles } from '@mui/styles';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import { TextField } from '@mui/material';

import { styled as MuiStyled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';

const DOMAIN_TABLE_PAGE_LIMIT = 50;

export const DomainsTable = () => {
  const { domains } = useAppSelector((state) => state.domains);
  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({ filterOptions: itemFields, data: domains });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    currentData,
    handlePageChange,
    handleSort,
    sortOptions,
    searchTerm: domainSearchValue,
    handleSearch,
  } = usePagination(visibleData, DOMAIN_TABLE_PAGE_LIMIT, 'domainName');

  return (
    <Wrapper>
      <DomainTableHeader>
        <MultiFilterSelect
          filterValueOptions={filterValueOptions}
          handleFilterValueChange={handleFilterValueChange}
          handleFilterSecondValueChange={handleFilterSecondValueChange}
          handleFilterOperatorChange={handleFilterOperatorChange}
          searchTerm={searchTerm}
          addFilter={addFilter}
          isFilterFulfilled={isFilterFulfilled}
          setSearchTerm={setSearchTerm}
          handleOptionClick={handleOptionClick}
          handleSearchInputChange={handleSearchInputChange}
          options={filteredOptions}
          currentFilter={newFilter}
        />
        <FormControl>
          <DomainSearchBar
            type="text"
            value={domainSearchValue}
            onChange={(evt) => handleSearch(evt.target.value)}
            id="search-input"
            placeholder="Search"
            variant="outlined"
          />
        </FormControl>
      </DomainTableHeader>
      {Object.values(filters).length > 0 && (
        <SelectedFilterWrapper>
          {Object.values(filters).map(
            ({
              key,
              displayName,
              filterType,
              filterCondition,
              value,
              value2,
            }) => (
              <SelectedFilterTag key={key}>
                <FilterTag>
                  <TagLabel>{displayName}:</TagLabel>
                  <TagValue>
                    {filterType === 'date' ? formatDate(value) : value}
                  </TagValue>
                  {value2 && (
                    <TagValue style={{ marginLeft: '4px' }}>
                      &nbsp;and&nbsp;
                      {filterType === 'date'
                        ? formatDate(Number(value2))
                        : value2}
                    </TagValue>
                  )}
                </FilterTag>
                <FilterTag>
                  <TagLabel>Operator:</TagLabel>
                  <TagValue>
                    {filterCondition && filterCondition.replaceAll('_', ' ')}
                  </TagValue>
                </FilterTag>
                <RemoveTag onClick={() => removeFilterType(key)} />
              </SelectedFilterTag>
            ),
          )}
        </SelectedFilterWrapper>
      )}

      <TableContainer>
        <DomainsTableComponent
          sx={{ minWidth: 650 }}
          aria-label="domains table"
        >
          <DomainsTableHead>
            <DomainsTableRow>
              {Object.values(itemFields).map((itemField) => (
                <CustomTableHeadCell
                  key={itemField.key}
                  style={{
                    minWidth: 50,
                    maxWidth: 200,
                  }}
                >
                  <TableHeaderCell
                    onClick={() => {
                      handleSort(itemField.key);
                    }}
                  >
                    <span>{itemField.displayName}</span>
                    <TableSortIconWrapper
                      $isvisible={sortOptions[itemField.key] ? 'true' : 'false'}
                    >
                      {sortOptions[itemField.key] ? (
                        sortOptions[itemField.key] === 'desc' ? (
                          <SouthIcon style={{ width: '65%' }} />
                        ) : (
                          <NorthIcon style={{ width: '65%' }} />
                        )
                      ) : (
                        <NorthIcon style={{ width: '65%' }} />
                      )}
                    </TableSortIconWrapper>
                  </TableHeaderCell>
                </CustomTableHeadCell>
              ))}
            </DomainsTableRow>
          </DomainsTableHead>
          <DomainsTableBody currentData={currentData} />
        </DomainsTableComponent>
      </TableContainer>
      <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  overflow-y: auto;
  position: relative;
  margin-bottom: 50px;
`;

const DomainsTableComponent = styled(MUITable)`
  width: 100%;
  max-width: 100%;
  table-layout: auto;
`;

const DomainsTableHead = styled(TableHead)`
  border-top: none;
  border-bottom: 2px solid #f4f3fe;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const CustomTableHeadCell = withStyles((theme) => ({
  head: {
    verticalAlign: 'bottom',
    border: '2px solid #f5f7fa',
    borderBottom: 'none',
    borderTop: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '200px',
    whiteSpace: 'nowrap',
  },
}))(TableCell);

export const DomainsTableRow = withStyles((theme) => ({
  root: {
    width: '100%',
    tableLayout: 'fixed',
    display: 'table',

    '&:last-child td, &:last-child th': {
      borderTop: '1px solid #f4f3fe',
      borderRight: '2px solid #f5f7fa',
      borderLeft: '2px solid #f5f7fa',
    },
  },
}))(TableRow);

const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid rgb(228, 231, 235);
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
`;

const SelectedFilterTag = styled.p`
  border-radius: 16px;
  background-color: rgb(245, 247, 250);
  position: relative;
  margin: 10px 10px 10px 0;
  padding: 7px 28px 7px 12px;
  font-size: 12px;
`;

const FilterTag = styled.span`
  margin: 0;
  display: block;
`;

const TagLabel = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const TagValue = styled.span``;

const RemoveTag = styled(Close)`
  color: red;
  position: absolute;
  font-size: 15px !important;
  top: 2px;
  right: 10px;
  cursor: pointer;
`;

const TableSortIconWrapper = styled.span<{ $isvisible: 'true' | 'false' }>`
  display: flex;
  align-items: center;
  margin-left: 2px;
  width: 25px;
  height: 25px;
  opacity: ${(p) => (p.$isvisible === 'true' ? '1' : '0')};
`;

const TableHeaderCell = styled.p`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0;
  cursor: pointer;
  width: fit-content;

  &:hover ${TableSortIconWrapper} {
    opacity: 0.5;
  }
`;

const DomainTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 20px;
`;

const DomainSearchBar = MuiStyled(TextField)({
  '.MuiOutlinedInput-input': {
    height: 10,
  },
});
