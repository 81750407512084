import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  ListSubheader,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { AdminWidgetConfigType, CustomAttributesConfig } from '../../helpers';

import { ADMIN_CONFIG_FORMS_INITIAL_VALUES } from '../../helpers/templates/adminConfigForm';

import { validationSchema } from '../../helpers/templates/validationSchema';
import {
  ARIA_ATTRIBUTE_STATE,
  ARIA_ATTRIBUTE_STRINGS,
  CONTRAST,
  MENU_NAVIGATION,
  OTHERS,
  IMAGE,
  LIST_OF_RELATIONS,
} from '../../lib';
import { FormEvent } from 'react';
import { SaveAndCancel } from './save-and-cancel';

interface CustomAttributeFormProps {
  onFormSubmit: (newElement: any) => Promise<void>;
  value?: CustomAttributesConfig;
  type: AdminWidgetConfigType;
  onClose: () => void;
}

const CustomAttributeForm: React.FC<CustomAttributeFormProps> = ({
  onFormSubmit,
  value,
  type,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: ADMIN_CONFIG_FORMS_INITIAL_VALUES[type],
    validationSchema: validationSchema[type],
    onSubmit: onFormSubmit,
  });

  const [addAsComponent, setAddAsComponent] = useState(false);

  const toggleAddAsComponent = () => setAddAsComponent(!addAsComponent);

  useEffect(() => {
    const formValue = value ?? ADMIN_CONFIG_FORMS_INITIAL_VALUES[type];
    formik.setValues(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (addAsComponent) {
      formik.setValues({
        ...formik.values,
        value: '@placeholder_' + formik.values.value,
      });
    }
    formik.handleSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box my={2}>
        <TextField
          fullWidth
          id="selector"
          name="selector"
          label="Selector"
          value={formik.values.selector}
          onChange={formik.handleChange}
          error={formik.touched.selector && Boolean(formik.errors.selector)}
          helperText={
            formik.touched.selector && formik.errors.selector?.toString()
          }
        />
      </Box>
      <Box my={2}>
        <FormControl
          fullWidth
          error={formik.touched.relation && Boolean(formik.errors.relation)}
        >
          <InputLabel>Attribute</InputLabel>
          <Select
            fullWidth
            id="attribute"
            name="attribute"
            label="Type of Attribute"
            value={formik.values.attribute}
            onChange={formik.handleChange}
            error={formik.touched.attribute && Boolean(formik.errors.attribute)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <ListSubheader value="" disableSticky>
              Aria Attributes String Values
            </ListSubheader>
            {ARIA_ATTRIBUTE_STRINGS.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Aria Attributes Boolean Values (true or false)
            </ListSubheader>
            {ARIA_ATTRIBUTE_STATE.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Aria Relationships
            </ListSubheader>
            {LIST_OF_RELATIONS.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Menu Navigation
            </ListSubheader>
            {MENU_NAVIGATION.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Contrast
            </ListSubheader>
            {CONTRAST.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Image
            </ListSubheader>
            {IMAGE.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Others
            </ListSubheader>
            {OTHERS.map((attribute) => (
              <MenuItem key={attribute} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.attribute && formik.errors.attribute?.toString()}
          </FormHelperText>
        </FormControl>
      </Box>
      {formik.values.attribute && (
        <Box my={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value={addAsComponent}
                  onChange={toggleAddAsComponent}
                />
              }
              label="Add as Component"
            />
          </FormControl>
        </Box>
      )}
      <Box my={2}>
        <TextField
          fullWidth
          id="value"
          name="value"
          label="Value"
          value={formik.values.value}
          onChange={formik.handleChange}
          error={formik.touched.value && Boolean(formik.errors.value)}
          helperText={formik.touched.value && formik.errors.value?.toString()}
        />
      </Box>
      <SaveAndCancel onClose={onClose} />
    </form>
  );
};

export default CustomAttributeForm;
