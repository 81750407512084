import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';

import { AdminWidgetConfigType, DynamicElementsConfig } from '../../helpers';

import { ADMIN_CONFIG_FORMS_INITIAL_VALUES } from '../../helpers/templates/adminConfigForm';

import { validationSchema } from '../../helpers/templates/validationSchema';
import { POLITENESS_SETTING, DYNAMIC_ELEMENTS_ROLES } from '../../lib';
import { SaveAndCancel } from './save-and-cancel';

interface MoreRolesFormProps {
  onFormSubmit: (newElement: any) => Promise<void>;
  value?: DynamicElementsConfig;
  type: AdminWidgetConfigType;
  onClose: () => void;
}

const MoreRolesForm: React.FC<MoreRolesFormProps> = ({
  onFormSubmit,
  value,
  type,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: ADMIN_CONFIG_FORMS_INITIAL_VALUES[type],
    validationSchema: validationSchema[type],
    onSubmit: onFormSubmit,
  });

  useEffect(() => {
    const formValue = value ?? ADMIN_CONFIG_FORMS_INITIAL_VALUES[type];
    formik.setValues(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box my={2}>
        <TextField
          fullWidth
          id="selector"
          name="selector"
          label="El Selector"
          value={formik.values.selector}
          onChange={formik.handleChange}
          error={formik.touched.selector && Boolean(formik.errors.selector)}
          helperText={
            formik.touched.selector && formik.errors.selector?.toString()
          }
        />
      </Box>
      <Box my={2}>
        <FormControl
          fullWidth
          error={formik.touched.politeness && Boolean(formik.errors.politeness)}
        >
          <InputLabel>Politeness setting</InputLabel>
          <Select
            fullWidth
            id="politeness"
            name="politeness"
            label="Politeness setting"
            value={formik.values.politeness}
            onChange={formik.handleChange}
            error={
              formik.touched.politeness && Boolean(formik.errors.politeness)
            }
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {POLITENESS_SETTING.map((politeness) => (
              <MenuItem key={politeness} value={politeness}>
                {politeness}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.politeness && formik.errors.politeness?.toString()}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box my={2}>
        <FormControl
          fullWidth
          error={formik.touched.role && Boolean(formik.errors.role)}
        >
          <InputLabel>Role</InputLabel>
          <Select
            fullWidth
            id="role"
            name="role"
            label="Role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.touched.role && Boolean(formik.errors.role)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {DYNAMIC_ELEMENTS_ROLES.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.role && formik.errors.role?.toString()}
          </FormHelperText>
        </FormControl>
      </Box>
      <SaveAndCancel onClose={onClose} />
    </form>
  );
};

export default MoreRolesForm;
