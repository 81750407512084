import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  BusinessRole,
  deleteBusinessRole,
  editUser,
  getUserRoles,
} from 'store/slice/users-slice';
import Modal from '@mui/material/Modal';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { User } from 'store/slice/users-slice';
import { useToaster } from 'contexts/toast-context';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { TableWrapper } from 'components/css-components';
import { Loader } from 'components/loader';
import { UsersModal } from './users-modal';
import { UsersFilterToolBar } from './users-filter-toolbar';
import { UserBusinessRole } from '../../types';
import moment from 'moment';

const USER_TABLE_PAGE_LIMIT = 20;

export const UsersTable = () => {
  const dispatch = useAppDispatch();
  const { users, loading } = useAppSelector((state) => state.users);
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
  const {
    pageCount,
    currentData,
    handlePageChange,
    searchTerm: userSearchValue,
    handleSearch,
  } = usePagination(filteredUsers, USER_TABLE_PAGE_LIMIT, 'name');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { setToaster } = useToaster();
  const [open, setOpen] = useState(false);
  const [userBusinessRole, setUserBusinessRole] = useState<UserBusinessRole>({
    roles: {},
    userBusinessIdToRoles: {},
    userBusinesses: {},
    allBusinesses: {},
  });

  const handleOpenRowPopup = async (currentUser: User) => {
    setSelectedUser(currentUser);
    setOpen(true);
  };

  const handleCloseRowPopup = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  const handleUserChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    selectedUser &&
      setSelectedUser({
        ...selectedUser,
        [name]: value,
      });
  };

  const handleEditUser = async () => {
    if (selectedUser) {
      const response = await dispatch(editUser(selectedUser));
      const { isSuccess, error } = response.payload as ApiResponse<User[]>;
      if (!isSuccess) {
        setToaster('error', error);
      }
    }
  };

  const getUsersRoles = async () => {
    if (!selectedUser) {
      return;
    }

    const response = await dispatch(getUserRoles(selectedUser.userID));
    const { isSuccess, error, data } = response.payload as ApiResponse<any>;

    if (!isSuccess || !data) {
      setToaster('error', error);
      return;
    }

    const {
      business_id_to_roles_map,
      role_id_to_role,
      business_id_to_name_map,
    } = data;

    const filteredUserBusiness = getUserBusinesses(
      business_id_to_name_map,
      business_id_to_roles_map,
    );

    setUserBusinessRole({
      roles: role_id_to_role,
      userBusinessIdToRoles: business_id_to_roles_map,
      userBusinesses: filteredUserBusiness,
      allBusinesses: business_id_to_name_map,
    });
  };

  const handleUserChipDelete = async (businessId: number, roleId: number) => {
    if (!selectedUser) {
      return;
    }

    const payload: BusinessRole = {
      business_id: businessId,
      role_id: roleId,
      user_id: selectedUser.userID,
    };

    const response = await dispatch(deleteBusinessRole(payload));
    const { isSuccess, error, data } = response.payload as ApiResponse<any>;

    if (!isSuccess || !data) {
      setToaster('error', error);
      return;
    }
    await getUsersRoles();

    setToaster('success', 'Successfully deleted business role.');
  };

  const getUserBusinesses = (
    businesses: Record<number, string>,
    userBusinessIds: Record<number, number[]>,
  ) => {
    if (
      Object.keys(userBusinessIds).length === 0 ||
      Object.keys(businesses).length === 0
    ) {
      return {};
    }

    const filteredUserBusiness: Record<number, string> = {};

    Object.entries(businesses).filter(([id, name]) => {
      const businessId = parseInt(id, 10);
      if (userBusinessIds[businessId]?.length > 0) {
        filteredUserBusiness[businessId] = name;
      }
    });

    return filteredUserBusiness;
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    getUsersRoles();
  }, [open]);

  const handleFilterChange = (filteredData: User[]) => {
    setFilteredUsers(filteredData);
  };

  const formatDate = (format: string) => {
    return moment(format).format('DD/MM/YYYY');
  };

  return loading === 'pending' ? (
    <Loader />
  ) : (
    <Wrapper>
      <UsersFilterToolBar
        {...{
          users,
          userSearchValue,
          handleSearch,
        }}
        onFilterChange={handleFilterChange}
      />
      <TableWrapper>
        <TableContainer>
          <MUITable
            className="table"
            sx={{ minWidth: 650 }}
            aria-label="users table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Is Special Domain</TableCell>
                <TableCell>Is Test Domain</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData.map((row) => {
                  return (
                    <TableRow
                      key={row.userID}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: '0',
                        },
                      }}
                      onClick={() => handleOpenRowPopup(row)}
                    >
                      <TableCell
                        className="td"
                        scope="row"
                        style={{ fontWeight: 'bold' }}
                      >
                        ...
                      </TableCell>
                      <TableCell className="td" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell className="td">{row.email}</TableCell>
                      <TableCell className="td">
                        {formatDate(row.registrationDate)}
                      </TableCell>
                      <TableCell className="td">
                        {row.isSpecialDomain ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell className="td">
                        {row.isTestAccount ? 'Yes' : 'No'}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="no-data">
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MUITable>
        </TableContainer>
        {currentData.length > 0 && (
          <Pagination
            pageCount={pageCount}
            handlePageChange={handlePageChange}
          />
        )}
        <Modal
          open={false}
          onClose={handleCloseRowPopup}
          aria-describedby="user modal"
        >
          {selectedUser ? (
            <ModalContent>
              <ModalTitle>User's Information</ModalTitle>
              <InputContainer>
                <Label htmlFor="user">User</Label>
                <Input
                  id="user"
                  type="text"
                  name="name"
                  value={selectedUser.name}
                  onChange={handleUserChange}
                />
              </InputContainer>
              <LabelWrapper>
                <LabelText>Email:</LabelText>
                <LabelValue>{selectedUser.email}</LabelValue>
              </LabelWrapper>
              <SubmitBtn onClick={handleEditUser}>Submit</SubmitBtn>
            </ModalContent>
          ) : (
            <></>
          )}
        </Modal>
        <UsersModal
          {...{
            selectedUser,
            userBusinessRole,
            open,
            getUsersRoles,
            handleCloseRowPopup,
            handleUserChipDelete,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  overflow-y: auto;
  position: relative;
  margin-bottom: 50px;
`;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
  width: 400px;
  border-radius: 10px;
  padding: 10px 20px 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;

  &:focus {
    outline: none;
  }
`;

const ModalTitle = styled.h3`
  font-size: 15px;
  margin-bottom: 30px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: #020401;
  margin-bottom: 10px;
`;

const Input = styled.input`
  border: 1.5px solid #e1e3e3;
  border-radius: 7px;
  padding: 10px;

  &:focus {
    outline: none;
    border: 2px solid #e1e3e3;
  }
`;

const SubmitBtn = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #000a14;
  color: #ffffff;
  cursor: pointer;
`;

const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const LabelText = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #020401;
  margin: 0 5px 0;
`;

const LabelValue = styled.p`
  font-size: 14px;
  margin: 0;
`;
