import React, { ChangeEvent, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Chip,
  FormLabel,
} from '@mui/material';
import * as MetaDataEntity from './meta-data-entities';
import { AdminWidgetConfigType, MetaData } from '../../helpers';

import { ADMIN_CONFIG_FORMS_INITIAL_VALUES } from '../../helpers/templates/adminConfigForm';

import { validationSchema } from '../../helpers/templates/validationSchema';
import styled from '@emotion/styled';

interface SummaryProp {
  name: string;
  label?: string;
  title?: string;
  value: string[];
  onChange: (arg: string[]) => void;
}

const Summary = ({ value, onChange, title, label, name }: SummaryProp) => {
  const [accessibilitySummary] = value || [];
  const isChecked = !!accessibilitySummary && accessibilitySummary === name;

  const onCheck = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = evt.target;
      onChange(checked ? [name] : []);
    },
    [name, onChange],
  );

  return (
    <FormGroup>
      <FormLabel style={{ minHeight: 42 }}>{title}</FormLabel>
      <FormControlLabel
        control={
          <Checkbox checked={isChecked} onChange={onCheck} name={name} />
        }
        label={label}
      />
    </FormGroup>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const isFeatureChecked = (list: string[], feature: string) => {
  return list.indexOf(feature) > -1;
};

interface ChipSelectProps {
  name: string;
  label: string;
  error?: boolean | undefined;
  list?: any[];
  value?: string[];
  onChange?: (evt: ChangeEvent<any>) => void;
}

export const ChipSelect = ({
  name,
  label,
  error,
  value,
  onChange,
  list,
}: ChipSelectProps) => {
  return (
    <div>
      <FormLabel style={{ minHeight: 42 }}>{label}</FormLabel>
      <FormControl fullWidth error={error}>
        <Select
          fullWidth
          id={name}
          name={name}
          label={label}
          multiple
          value={value}
          onChange={onChange}
          error={error}
          input={<Input />}
          renderValue={(selectedFeatures) => (
            <Chips>
              {selectedFeatures.map((feature) => (
                <StyledChip key={feature} label={feature} />
              ))}
            </Chips>
          )}
          MenuProps={MENU_PROPS}
        >
          {list?.map((feature) => (
            <MenuItem
              style={{ padding: 16, display: 'flex' }}
              key={feature}
              value={feature}
            >
              <Checkbox checked={value && isFeatureChecked(value, feature)} />
              <ListItemText style={{ marginLeft: 10 }} primary={feature} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

ChipSelect.defaultProps = {
  error: false,
  list: [],
  value: [],
};

// Styled Components
const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  margin: 2px;
`;

interface MetaDataFormProps {
  onFormSubmit: (newElement: any) => Promise<void>;
  value?: MetaData;
  type: AdminWidgetConfigType;
}

const formFields = {
  accessibilityFeature: 'accessibilityFeature',
  accessibilityHazard: 'accessibilityHazard',
  accessibilityAPI: 'accessibilityAPI',
  accessibilityControl: 'accessibilityControl',
  accessMode: 'accessMode',
  accessModeSufficient: 'accessModeSufficient',
  accessibilitySummary: 'accessibilitySummary',
};

export const MetaDataForm = ({
  onFormSubmit,
  value,
  type,
}: MetaDataFormProps) => {
  const formik = useFormik({
    initialValues: ADMIN_CONFIG_FORMS_INITIAL_VALUES[type],
    validationSchema: validationSchema[type],
    onSubmit: onFormSubmit,
  });

  useEffect(() => {
    const formValue = value ?? ADMIN_CONFIG_FORMS_INITIAL_VALUES[type];
    formik.setValues(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={2}>
        <ChipSelect
          name={formFields.accessibilityFeature}
          label="accessibilityFeature"
          error={
            formik.touched[formFields.accessibilityFeature] &&
            Boolean(formik.errors[formFields.accessibilityFeature])
          }
          value={formik.values[formFields.accessibilityFeature] || []}
          onChange={formik.handleChange}
          list={MetaDataEntity.ACCESSIBILITY_FEATURE}
        />
      </Box>
      <Box mb={2}>
        <ChipSelect
          name={formFields.accessibilityHazard}
          label="accessibilityHazard"
          error={
            formik.touched[formFields.accessibilityHazard] &&
            Boolean(formik.errors[formFields.accessibilityHazard])
          }
          value={formik.values[formFields.accessibilityHazard] || []}
          onChange={formik.handleChange}
          list={MetaDataEntity.ACCESSIBILITY_HAZARD}
        />
      </Box>
      <Box mb={2}>
        <ChipSelect
          name={formFields.accessibilityAPI}
          label="accessibilityAPI"
          error={
            formik.touched[formFields.accessibilityAPI] &&
            Boolean(formik.errors[formFields.accessibilityAPI])
          }
          value={formik.values[formFields.accessibilityAPI] || []}
          onChange={formik.handleChange}
          list={MetaDataEntity.ACCESSIBILITY_API}
        />
      </Box>
      <Box mb={2}>
        <ChipSelect
          name={formFields.accessibilityControl}
          label="accessibilityControl"
          error={
            formik.touched[formFields.accessibilityControl] &&
            Boolean(formik.errors[formFields.accessibilityControl])
          }
          value={formik.values[formFields.accessibilityControl] || []}
          onChange={formik.handleChange}
          list={MetaDataEntity.ACCESSIBILITY_CONTROL}
        />
      </Box>
      <Box mb={2}>
        <ChipSelect
          name={formFields.accessMode}
          label="accessMode"
          error={
            formik.touched[formFields.accessMode] &&
            Boolean(formik.errors[formFields.accessMode])
          }
          value={formik.values[formFields.accessMode] || []}
          onChange={formik.handleChange}
          list={MetaDataEntity.ACCESS_MODE}
        />
      </Box>
      <Box mb={2}>
        <ChipSelect
          name={formFields.accessModeSufficient}
          label="accessModeSufficient"
          error={
            formik.touched[formFields.accessModeSufficient] &&
            Boolean(formik.errors[formFields.accessModeSufficient])
          }
          value={formik.values[formFields.accessModeSufficient] || []}
          onChange={formik.handleChange}
          list={MetaDataEntity.ACCESS_MODE_SUFFICIENT}
        />
      </Box>
      <Box mb={2}>
        <Summary
          label="Human readable summary of accessibility features and deficiencies."
          value={formik.values[formFields.accessibilitySummary]}
          title="accessibilitySummary"
          name={formFields.accessibilitySummary}
          onChange={(value) => {
            formik.setFieldValue(formFields.accessibilitySummary, value);
          }}
        />
      </Box>
      <Box>
        <Button type="submit">Save</Button>
      </Box>
    </form>
  );
};
