import React from 'react';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';

interface SelectedFilterTagProps {
  displayName: string;
  index: string;
  filterType: string;
  value: string;
  value2: string | undefined;
  filterCondition: string | undefined;
  onFormatDate: (value: number) => string;
  onRemoveFilterType: (key: string) => void;
}

export const SelectedFilterTag = (props: SelectedFilterTagProps) => {
  const {
    displayName,
    filterType,
    index,
    value,
    value2,
    filterCondition,
    onFormatDate,
    onRemoveFilterType,
  } = props;
  return (
    <SelectedFilter>
      <FilterTag>
        <TagLabel>{displayName}:</TagLabel>
        <TagValue>
          {filterType === 'date' ? onFormatDate(Number(value)) : value}
        </TagValue>
        {value2 && (
          <TagValue style={{ marginLeft: '4px' }}>
            &nbsp;and&nbsp;
            {filterType === 'date' ? onFormatDate(Number(value2)) : value2}
          </TagValue>
        )}
      </FilterTag>
      <FilterTag>
        <TagLabel>Operator:</TagLabel>
        <TagValue>
          {filterCondition && filterCondition.replaceAll('_', ' ')}
        </TagValue>
      </FilterTag>
      <RemoveTag
        onClick={() => {
          onRemoveFilterType(index);
        }}
      />
    </SelectedFilter>
  );
};

const SelectedFilter = styled.p`
  border-radius: 16px;
  background-color: rgb(245, 247, 250);
  position: relative;
  margin: 10px 10px 10px 0;
  padding: 7px 28px 7px 12px;
  font-size: 12px;
`;

const FilterTag = styled.span`
  margin: 0;
  display: block;
`;

const TagLabel = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const TagValue = styled.span``;

const RemoveTag = styled(Close)`
  color: red;
  position: absolute;
  font-size: 15px !important;
  top: 2px;
  right: 10px;
  cursor: pointer;
`;
