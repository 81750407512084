import React from 'react';
import styled from 'styled-components';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import { FormControl, TextField } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { User } from 'store/slice/users-slice';
import moment from 'moment';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { userFields as itemFields } from './consts';
import { SelectedFilterTag } from 'components/selected-filter-tag/selected-filter-tag';

interface UsersFilterBarProps {
  userSearchValue: string;
  users: User[];
  handleSearch: (query: string) => void;
  onFilterChange: (filteredData: User[]) => void;
}

export const UsersFilterToolBar = (props: UsersFilterBarProps) => {
  const { userSearchValue, users, handleSearch, onFilterChange } = props;

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({ filterOptions: itemFields, data: users });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const formatDate = (timeStamp: number) => {
    return moment.unix(timeStamp).format('DD/MM/YYYY');
  };

  onFilterChange(visibleData);

  return (
    <UserTableWrapper>
      <UserTableHeader>
        <MultiFilterSelect
          filterValueOptions={filterValueOptions}
          handleFilterValueChange={handleFilterValueChange}
          handleFilterSecondValueChange={handleFilterSecondValueChange}
          handleFilterOperatorChange={handleFilterOperatorChange}
          searchTerm={searchTerm}
          addFilter={addFilter}
          isFilterFulfilled={isFilterFulfilled}
          setSearchTerm={setSearchTerm}
          handleOptionClick={handleOptionClick}
          handleSearchInputChange={handleSearchInputChange}
          options={filteredOptions}
          currentFilter={newFilter}
        />
        <FormControl>
          <UserSearchBar
            type="text"
            value={userSearchValue}
            onChange={(evt) => handleSearch(evt.target.value)}
            id="search-input"
            placeholder="Search"
            variant="outlined"
          />
        </FormControl>
      </UserTableHeader>
      {Object.values(filters).length > 0 && (
        <SelectedFilterWrapper>
          {Object.values(filters).map(
            ({
              key,
              displayName,
              filterType,
              filterCondition,
              value,
              value2,
            }) => (
              <SelectedFilterTag
                key={key}
                {...{
                  displayName,
                  filterType,
                  index: key,
                  value,
                  value2,
                  filterCondition,
                  onRemoveFilterType: removeFilterType,
                  onFormatDate: formatDate,
                }}
              />
            ),
          )}
        </SelectedFilterWrapper>
      )}
    </UserTableWrapper>
  );
};

const UserTableWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
`;

const UserTableHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 20px;
`;

const UserSearchBar = MuiStyled(TextField)({
  '.MuiOutlinedInput-input': {
    height: 10,
  },
});

const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid rgb(228, 231, 235);
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
`;
