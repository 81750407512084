import React from 'react';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { getCustomAttributesColumns } from './utils';
import {
  WidgetConfigurations,
  CustomAttributesConfig,
} from '@equally-ai-front/common/src/helpers';
import { WidgetConfigContainer } from './widget-config-container';

export const CustomAttributes = () => {
  const { domainId } = useParams();
  const { data } = useAppSelector((state) => state.adminConfig);

  const customAttributes = React.useMemo(
    () => data.customAttributes as CustomAttributesConfig[],
    [data.customAttributes],
  );

  return (
    <WidgetConfigContainer
      domainId={domainId ?? ''}
      elements={customAttributes}
      title="Custom Attributes"
      configType={WidgetConfigurations.CUSTOM_ATTRIBUTES}
      getColumns={getCustomAttributesColumns}
    />
  );
};
