import React from 'react';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import styled from 'styled-components';
import { LatestScanTotals } from '@equally-ai-front/common/src/types/scans';

interface ScanIssuesCountSummaryProps {
  scanTotals: LatestScanTotals;
  issueTypeKey: 'totalErrors' | 'totalWarnings' | 'totalNotices';
}

export const ScanIssuesCountSummary = (props: ScanIssuesCountSummaryProps) => {
  const {
    scanTotals: { currentTotals, previousTotals },
    issueTypeKey,
  } = props;
  const currentCount = currentTotals[issueTypeKey];
  const previousCount = previousTotals[issueTypeKey];

  return (
    <IssuesCountWrapper>
      <IssuesCount>{currentCount}</IssuesCount>
      <>
        {currentCount > previousCount ? (
          <ArrowUpward color="error" />
        ) : (
          <ArrowDownward color="success" />
        )}
      </>
    </IssuesCountWrapper>
  );
};

const IssuesCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IssuesCount = styled.p`
  margin: 0;
`;
