import { FilterField } from '@equally-ai-front/common/src/components/filters/types';

export const domainFields: Record<string, FilterField> = {
  domainName: {
    key: 'domainName',
    displayName: 'Domain Name',
    filterType: 'string',
  },
  accountName: {
    key: 'accountName',
    displayName: 'Account Name',
    filterType: 'string',
  },
  email: {
    key: 'email',
    displayName: 'Email',
    filterType: 'string',
  },
  subscriptionPlan: {
    key: 'subscriptionPlan',
    displayName: 'Subscription',
    filterType: 'string',
  },
  txID: {
    key: 'txID',
    displayName: 'Transaction ID',
    filterType: 'string',
  },
  registrationDate: {
    key: 'registrationDate',
    displayName: 'Registration Date',
    filterType: 'date',
  },
  expirationDate: {
    key: 'expirationDate',
    displayName: 'Expiration Date',
    filterType: 'date',
  },
  lastPaymentDate: {
    key: 'lastPaymentDate',
    displayName: 'Last Payment',
    filterType: 'date',
  },
  hasWidget: {
    key: 'hasWidget',
    displayName: 'Has Widget',
    filterType: 'boolean',
  },
  hasWidgetIntegrated: {
    key: 'hasWidgetIntegrated',
    displayName: 'Has Widget Integrated',
    filterType: 'boolean',
  },
  archived: {
    key: 'archived',
    displayName: 'Archived',
    filterType: 'boolean',
  },
  autoRenewal: {
    key: 'autoRenewal',
    displayName: 'Auto Renewal',
    filterType: 'boolean',
  },
  invalid: {
    key: 'invalid',
    displayName: 'Invalid',
    filterType: 'boolean',
  },
};
