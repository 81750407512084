import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { User, getUsers } from 'store/slice/users-slice';
import { UsersTable } from './users-table';
import { useToaster } from 'contexts/toast-context';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';

export const UsersPage = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();

  const getUsersList = async () => {
    const response = await dispatch(getUsers());
    const { isSuccess, error } = response.payload as ApiResponse<User[]>;
    if (!isSuccess) {
      setToaster('error', error);
    }
  };

  React.useEffect(() => {
    void getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Users Page</h1>
      <UsersTable />
    </div>
  );
};
