import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const AccessibilityScoreIcon = ({ score }: { score: number }) => {
  const getColor = () => {
    if (score < 80) return '#dc2626'; // red
    if (score >= 80 && score < 90) return '#f97316'; // orange
    return '#22c55e'; // green
  };

  return (
    <span
      style={{
        display: 'inline-block',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: getColor(),
      }}
    />
  );
};
