import { getDomainPageMenu } from 'containers/domain-detail-page/consts';
import React from 'react';

export const useDomainNavList = (domainId: string) => {
  const navigationList = React.useMemo(
    () => (domainId ? getDomainPageMenu(domainId) : []),
    [domainId],
  );

  return {
    navigationList,
  };
};
