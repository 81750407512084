import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StyledButton } from 'containers/coupons-page/coupons-table';
import SearchIcon from '@mui/icons-material/Search';
import {
  Paper,
  Box,
  InputBase,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Domain } from 'store/slice/domains-slice';
import { Dayjs } from 'dayjs';
import { AttributesChip } from '@equally-ai-front/common/src/components/attribute-chip/attributes-chip';

interface UpdateDomainFormProps {
  selectedDomains: Record<number, Domain>;
  expirationDate: Dayjs | null;
  setExpirationDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  onClose: () => void;
  handleNextStep: () => void;
  handleRemoveSelectedDomain: (domainId: number) => void;
}

export const UpdateDomainForm = (props: UpdateDomainFormProps) => {
  const {
    selectedDomains,
    expirationDate,
    setExpirationDate,
    onClose,
    handleNextStep,
    handleRemoveSelectedDomain,
  } = props;
  const [filteredDomains, setFilteredDomains] =
    useState<Record<number, Domain>>(selectedDomains);

  const handleSearchDomain = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = evt.target.value;
    if (!searchTerm) {
      setFilteredDomains(selectedDomains);
    } else {
      const filtered = Object.fromEntries(
        Object.entries(selectedDomains).filter(([_, domain]) =>
          domain.domainName.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
      setFilteredDomains(filtered);
    }
  };

  useEffect(() => {
    setFilteredDomains(selectedDomains);
  }, [selectedDomains]);

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: '0px 10px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '200px',
          backgroundColor: '#FAFAFB',
          height: '40px',
          border: '1px solid #ECEFF2',
          borderRadius: '15px',
          margin: '30px 0',
        }}
      >
        <IconButton type="submit" sx={{ p: 0 }} aria-label="search">
          <SearchIcon sx={{ fill: '#1079E6' }} />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search..."
          inputProps={{ 'aria-label': 'search domains' }}
          onChange={handleSearchDomain}
        />
      </Paper>
      <Box
        display="flex"
        flexWrap="wrap"
        gap="10px"
        mb="30px"
        maxHeight="300px"
        overflow="hidden scroll"
      >
        {Object.values(filteredDomains).map(({ domainName, domainID }) => (
          <AttributesChip
            key={`${domainName}-${domainID}`}
            attribute={domainName}
            value={domainName}
            onDelete={() => handleRemoveSelectedDomain(domainID)}
          />
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="30px"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select expiration"
            value={expirationDate}
            onChange={(newValue) => {
              setExpirationDate(newValue);
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </LocalizationProvider>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        gap="10px"
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{ height: '44px' }}
        >
          Cancel
        </Button>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          sx={{ height: '44px', marginRight: 0 }}
          disabled={
            !expirationDate || Object.values(filteredDomains).length === 0
          }
        >
          Next
        </StyledButton>
      </Box>
    </>
  );
};
