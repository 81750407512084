import { makeStyles } from '@mui/styles';

export const useLoginPageStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    width: '100%',
  },
  container: {
    textAlign: 'center',
    background: 'white',
  },
  heading: {
    fontSize: '2rem',
  },
  input: {
    width: '100%',
  },
  checkbox: {
    textAlign: 'left',
    '& span': {
      fontSize: '0.875rem',
    },
  },
  forgetPassword: {
    textAlign: 'right',
    '& a': {
      fontSize: '0.875rem',
    },
  },
  button: {
    color: 'black',
    marginBottom: 30,

    '&:hover': {
      color: 'white',
    },
  },
  extraActions: {
    maxWidth: '100%',
  },
  or: {
    fontSize: '1.125rem',
    '& hr': {
      width: '44px',
      height: '1px',
      border: 'none',
      background: '#c4c4c4',
      padding: 0,
    },
  },
  googleButton: {
    width: '100%!important',
    margin: '0!important',
    '& > div > div:last-of-type': {
      textAlign: 'center!important',
      marginRight: '26px',
    },
  },
}));
