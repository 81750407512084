import { configureStore } from '@reduxjs/toolkit';
import domainsReducer from './slice/domains-slice';
import usersReducer from './slice/users-slice';
import domainDetailReducer from './slice/domain-detail-slice';
import { adminConfigReducer } from '@equally-ai-front/common/src/redux/admin-widget-config-slice';
import couponsReducer from './slice/coupons-slice';
import businessesReducer from './slice/business-slice';
import subscriptionsReducer from './slice/subscriptions-slice';

export const store = configureStore({
  reducer: {
    domains: domainsReducer,
    users: usersReducer,
    domainDetail: domainDetailReducer,
    adminConfig: adminConfigReducer,
    coupons: couponsReducer,
    businesses: businessesReducer,
    subscriptions: subscriptionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
