import React from 'react';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { getLandmarkOrMoreRoleColumns } from './utils';
import {
  LandmarkConfig,
  WidgetConfigurations,
} from '@equally-ai-front/common/src/helpers';
import { WidgetConfigContainer } from './widget-config-container';

export const Landmarks = () => {
  const { domainId } = useParams();
  const { data } = useAppSelector((state) => state.adminConfig);

  const landMarkElements = React.useMemo(
    () => data.landmarks as LandmarkConfig[],
    [data.landmarks],
  );

  return (
    <WidgetConfigContainer
      domainId={domainId ?? ''}
      title="Landmarks"
      elements={landMarkElements}
      configType={WidgetConfigurations.LANDMARKS}
      getColumns={getLandmarkOrMoreRoleColumns}
    />
  );
};
