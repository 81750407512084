import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { BusinessAdminService } from 'api/business-admin-service';
import { Business } from '@equally-ai-front/common/src/types/business';
import { BusinessWithOwner } from 'types';

interface BusinessState {
  loading: boolean;
  businesses: BusinessWithOwner[];
  business: BusinessWithOwner;
}

const initialState: BusinessState = {
  loading: false,
  businesses: [],
  business: {} as BusinessWithOwner,
};

export const getAllBusinesses = createAsyncThunk(
  'businesses/get-all',
  async (_, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await BusinessAdminService.getBusinesses();

    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const getBusinessById = createAsyncThunk(
  'businesses/get-business',
  async (businessId: number, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await BusinessAdminService.getBusinessById(businessId);
    if (isSuccess && data) {
      return { isSuccess, data };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);

export const businessesSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBusinesses.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = action.payload.data;
      })
      .addCase(getAllBusinesses.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBusinessById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBusinessById.fulfilled, (state, action) => {
        state.loading = false;
        state.business = action.payload.data;
      })
      .addCase(getBusinessById.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectBusinessesState = (state: RootState) => state.businesses;
export default businessesSlice.reducer;
