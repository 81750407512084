import React from 'react';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IssuesSummary } from '@equally-ai-front/common/src/components/issues-summary';
import TextLink from '@equally-ai-front/common/src/components/TextLink';
import InfoCircleSuccess from '../../assets/svg/info-circle-success.svg';

export interface ScansSummary {
  url: string;
  error: number;
  warning: number;
  notice: number;
}

interface ScanSummaryCountTooltipProps {
  scans: ScansSummary[];
  domainName: string;
}

export const ScanSummaryCountTooltip = (
  props: ScanSummaryCountTooltipProps,
) => {
  const { scans, domainName } = props;

  return (
    <CustomTooltip
      placement="top"
      title={
        <TooltipContent>
          <DomainUrlName>{domainName}</DomainUrlName>
          <DomainUrlsWrapper>
            {scans.map((scan, index) => {
              const domainUrlPath = scan.url.replace(domainName, '') || '/';
              return (
                <DomainUrlContent key={`${scan.url}-${index}`}>
                  <DomainUrlLink href={`https://${scan.url}`} target="_blank">
                    {domainUrlPath}
                  </DomainUrlLink>
                  <IssuesSummary
                    error={scan.error}
                    warning={scan.warning}
                    notice={scan.notice}
                  />
                </DomainUrlContent>
              );
            })}
          </DomainUrlsWrapper>
        </TooltipContent>
      }
    >
      <ScansWrapper>
        <ScanInfoIcon src={InfoCircleSuccess} alt="info icon" />
        <ScansLabel>{scans.length} scans</ScansLabel>
      </ScansWrapper>
    </CustomTooltip>
  );
};

const ScansWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ScansLabel = styled.span`
  font-weight: 600;
  margin-left: 2px;
`;

const ScanInfoIcon = styled.img``;

const CustomTooltip = MuiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, '-10'],
          },
        },
      ],
    }}
  />
))(() => ({
  [`&.${tooltipClasses.popper}`]: {
    width: 'fit-content',
    minWidth: 300,
    maxWidth: 350,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#000000',
    maxWidth: '100%',
    padding: 0,
    border: '1.5px solid #e0e0e0',
    borderRadius: 12,
  },
}));

const TooltipContent = styled.div`
  width: 100%;
`;

const DomainUrlName = styled.h6`
  border-bottom: 1.5px solid #bbc1c4;
  font-weight; 500;
  font-size: 18px;
  padding: 15px;
  margin: 0;
`;

const DomainUrlsWrapper = styled.div`
  height: fit-content;
  max-height: 250px;
  overflow: hidden auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
`;

const DomainUrlContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const DomainUrlLink = styled(TextLink)`
  border-bottom: none;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
  max-width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  margin-bottom: 5px;
`;
