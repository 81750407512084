import React from 'react';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import { getElementsRelationshipsColumns } from './utils';
import { WidgetConfigurations } from '@equally-ai-front/common/src/helpers';
import { WidgetConfigContainer } from './widget-config-container';

export const ElementsRelationship = () => {
  const { domainId } = useParams();
  const { data } = useAppSelector((state) => state.adminConfig);

  const elementsRelationships = React.useMemo(
    () => data.elementsRelationships as any[],
    [data.elementsRelationships],
  );

  return (
    <WidgetConfigContainer
      domainId={domainId ?? ''}
      elements={elementsRelationships}
      title="Elements relationships"
      configType={WidgetConfigurations.ELEMENTS_RELATIONSHIP}
      getColumns={getElementsRelationshipsColumns}
    />
  );
};
