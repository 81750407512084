import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: rgba(0, 10, 20, 0.85);
`;

const VisaImg = styled.img`
  width: 32px;
  height: 25px;
  padding: 8px 4px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
`;

const MastercardImg = styled.img`
  width: 32px;
  height: 25px;
  padding: 6px 6px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
`;

const PayPalImg = styled.img`
  width: 32px;
  height: 25px;
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
`;

export const Visa = () => (
  <Container>
    <VisaImg alt="visa" src="/visa.png" />
    <div>Visa</div>
  </Container>
);

export const MasterCard = () => (
  <Container>
    <MastercardImg alt="mastercard" src="/mc.png" />
    <div>Mastercard</div>
  </Container>
);

export const PayPal = () => (
  <Container>
    <PayPalImg alt="paypal" src="/pp.png" />
    <div>PayPal</div>
  </Container>
);
