import React from 'react';
import { NavLink } from 'react-router-dom';

interface MenuItemProps {
  to: string;
  label: string;
}

export const MenuItem = ({ to, label }: MenuItemProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'link-active link' : 'link link-inactive'
      }
      to={to}
    >
      {label}
    </NavLink>
  );
};
