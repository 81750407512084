import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import { TextField, Box } from '@mui/material';
import { AdminWidgetConfigType, TabIndexConfig } from '../../helpers';
import { ADMIN_CONFIG_FORMS_INITIAL_VALUES } from '../../helpers/templates/adminConfigForm';
import { validationSchema } from '../../helpers/templates/validationSchema';
import { SaveAndCancel } from './save-and-cancel';

export const FormFields = Object.freeze({
  index: 'index',
  selector: 'selector',
});

type GenericObject = {
  [key: string]: string;
};

interface TabIndexFormProps {
  onFormSubmit: (newElement: any) => Promise<void>;
  value?: TabIndexConfig | GenericObject;
  type: AdminWidgetConfigType;
  onClose: () => void;
}

const TabIndexForm: React.FC<TabIndexFormProps> = ({
  onFormSubmit,
  value,
  type,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: ADMIN_CONFIG_FORMS_INITIAL_VALUES[type],
    validationSchema: validationSchema[type],
    onSubmit: onFormSubmit,
  });

  useEffect(() => {
    const formValue = value ?? ADMIN_CONFIG_FORMS_INITIAL_VALUES[type];
    formik.setValues(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box my={2}>
        <TextField
          fullWidth
          id={FormFields.index}
          name={FormFields.index}
          label="Index"
          value={formik.values[FormFields.index]}
          onChange={formik.handleChange}
          error={
            formik.touched[FormFields.index] &&
            Boolean(formik.errors[FormFields.index])
          }
          helperText={
            formik.touched[FormFields.index] &&
            formik.errors[FormFields.index]?.toString()
          }
          type="number"
        />
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          id={FormFields.selector}
          name={FormFields.selector}
          label="Selector"
          value={formik.values[FormFields.selector]}
          onChange={formik.handleChange}
          error={
            formik.touched[FormFields.selector] &&
            Boolean(formik.errors[FormFields.selector])
          }
          helperText={
            formik.touched[FormFields.selector] &&
            formik.errors[FormFields.selector]?.toString()
          }
        />
      </Box>
      <SaveAndCancel onClose={onClose} />
    </form>
  );
};

export default TabIndexForm;
