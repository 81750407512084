import { useMediaQuery } from '../../hooks/useMediaQuery';
import React from 'react';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';
import { MenuItemProps } from './MenuItems';
import { MenuOption } from './UserInfo';

export type menuItem = {
  label: string;
  to: string;
  key: string;
  id: string;
};

interface CustomMenuProps {
  isOnMobile?: boolean;
}

export type MenuItemAsComp = ({ isOnMobile }: CustomMenuProps) => JSX.Element;

interface HeaderWrapperProps {
  mobilePx: string;
  menuItems: menuItem[];
  navigateHome: () => void;
  userInfo: any;
  pathName: string;
  MenuItem: ({ to, label, id }: MenuItemProps) => JSX.Element;
  logoText?: string;
  hasNotificationBell?: boolean;
  containerWidthDesktop?: string;
  customMenuItem?: MenuItemAsComp;
  showLiveTourBtn?: boolean;
  startTourValue?: boolean;
  startTour?: () => void;
  showCustomMenu?: boolean;
  menuOptions?: MenuOption[];
}

export const HeaderWrapper = ({
  mobilePx,
  menuItems,
  navigateHome,
  userInfo,
  pathName,
  MenuItem,
  logoText,
  hasNotificationBell,
  containerWidthDesktop,
  customMenuItem,
  showLiveTourBtn,
  startTour,
  startTourValue,
  showCustomMenu,
  menuOptions,
}: HeaderWrapperProps) => {
  const mobileDevice = useMediaQuery(`(max-width: ${mobilePx})`);

  return mobileDevice ? (
    <HeaderMobile
      menuItems={menuItems}
      pathName={pathName}
      MenuItem={MenuItem}
      showLiveTourBtn={showLiveTourBtn}
      startTour={startTour}
      startTourValue={startTourValue}
      customMenuItem={customMenuItem}
      showCustomMenu={showCustomMenu}
      menuOptions={menuOptions}
    />
  ) : (
    <HeaderDesktop
      menuItems={menuItems}
      mobilePx={mobilePx}
      navigateHome={navigateHome}
      userInfo={userInfo}
      pathName={pathName}
      MenuItem={MenuItem}
      logoText={logoText}
      hasNotificationBell={hasNotificationBell}
      containerWidthDesktop={containerWidthDesktop}
      customMenuItem={customMenuItem}
      showLiveTourBtn={showLiveTourBtn}
      startTour={startTour}
      startTourValue={startTourValue}
      menuOptions={menuOptions}
    />
  );
};
