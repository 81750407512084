import React, { SyntheticEvent, useState } from 'react';
import { Box, Chip, Tab, Tabs, Typography } from '@mui/material';
import { CustomBusinessTabPanel } from '../businesses-page/custom-business-tabpanel';
import styled from 'styled-components';
import { BusinessAndOwner } from '../businesses-page/consts';
import { SubscriptionsAndPlans } from './components/tabs/subscriptions-and-plans';
import { UsersWithRoles } from './components/tabs/users-with-roles';
import { UserWithRoles } from './consts';
import { SubscriptionRecordsAndPlans } from 'containers/businesses-redirect/utils/business';
import { BusinessWithDomains } from 'containers/domains-page/consts';
import { BusinessDomains } from './components/tabs/business-domains';

interface BusinessDetailsTabProps {
  label: string;
  id: string;
  children: React.ReactNode;
}

interface BusinessDetailsPageProps {
  usersWithRoles: UserWithRoles[];
  subscriptionsAndPlans: SubscriptionRecordsAndPlans;
  businessWithOwner: BusinessAndOwner | null;
  businessWithDomains: BusinessWithDomains | null;
}

export const BusinessDetailsPage = (props: BusinessDetailsPageProps) => {
  const {
    usersWithRoles,
    subscriptionsAndPlans,
    businessWithOwner,
    businessWithDomains,
  } = props;
  const [businessTabValue, setBusinessTabValue] = useState(0);

  const businessDetailsTabs: BusinessDetailsTabProps[] = [
    {
      label: 'Users',
      id: 'users',
      children: <UsersWithRoles usersWithRoles={usersWithRoles} />,
    },
    {
      label: 'Domains',
      id: 'domains',
      children: <BusinessDomains businessWithDomains={businessWithDomains} />,
    },
    {
      label: 'Subscriptions',
      id: 'subscriptions',
      children: (
        <SubscriptionsAndPlans subscriptionsAndPlans={subscriptionsAndPlans} />
      ),
    },
  ];

  const handleBusinessTabChange = (evt: SyntheticEvent, newValue: number) => {
    setBusinessTabValue(newValue);
  };

  return (
    <>
      <h1>Business Details Page</h1>
      <Box width="100%">
        {businessWithOwner && (
          <Box>
            <CustomTypography marginBottom="20px">
              {businessWithOwner.businessName}, {businessWithOwner.slug}
            </CustomTypography>
            <OwnerContainer>
              <InputContainer>
                <Label htmlFor="owner">Owner</Label>
                <CustomOwnerChip label={businessWithOwner.ownerName} />
              </InputContainer>
              <InputContainer>
                <Label htmlFor="owner-email">Email</Label>
                <CustomOwnerChip label={businessWithOwner.ownerEmail} />
              </InputContainer>
            </OwnerContainer>
          </Box>
        )}
        <CustomBusinessTabs
          value={businessTabValue}
          onChange={handleBusinessTabChange}
          aria-label="business details tabs"
        >
          {businessDetailsTabs.map(
            (tab: BusinessDetailsTabProps, index: number) => (
              <Tab
                value={index}
                label={tab.label}
                id={tab.id}
                key={`${tab.label}-${index}`}
              />
            ),
          )}
        </CustomBusinessTabs>
        {businessDetailsTabs.map(
          (tab: BusinessDetailsTabProps, index: number) => {
            return (
              <CustomBusinessTabPanel
                value={businessTabValue}
                index={index}
                key={`${tab.label}-${index}`}
              >
                {tab.children}
              </CustomBusinessTabPanel>
            );
          },
        )}
      </Box>
    </>
  );
};

const CustomBusinessTabs = styled(Tabs)`
  margin-bottom: 15px;

  & .MuiTab-root.Mui-selected {
    color: #454284;
  }
  & .MuiTabs-indicator {
    background-color: #454284;
  }
`;

const CustomTypography = styled(Typography)``;

const OwnerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const CustomOwnerChip = styled(Chip)`
  && {
    background-color: #454284;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
  }

  &&:hover {
    color: #fff;
    background-color: #454284;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
  margin-right: 25px;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: #020401;
  margin-bottom: 10px;
`;
