import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';

interface MenuButtonProps {
  open: boolean;
  onClick: () => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({ open, onClick }) => {
  return (
    <StyledButton
      startIcon={
        open ? (
          <Close className="menu-icon" />
        ) : (
          <MenuIcon className="menu-icon" />
        )
      }
      onClick={onClick}
    />
  );
};

const StyledButton = styled(Button)`
  .menu-icon {
    color: #ffffff;
    font-size: 24px !important;
  }

  .MuiButton-startIcon {
    margin-right: 0 !important;
  }

  .MuiButton-label {
    width: fit-content;
  }
`;
