import { Button } from '@mui/material';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
import ErrorIcon from '../../../public/error-icon.svg';
import {
  IsSatisfiedFunc,
  ItemFilter,
} from '@equally-ai-front/common/src/components/filters/types';
import { isSatisfied } from '@equally-ai-front/common/src/components/filters/lib/filters';
import { FilterType } from '@equally-ai-front/common/src/components/filters/types';
import { handleIsReviewRequired } from '@equally-ai-front/common/src/lib';

interface RequiresReviewBannerProps {
  data: any[];
  handleFilterItems: (
    filters: ItemFilter[],
    isSatisfied: IsSatisfiedFunc,
  ) => void;
  removeFilterType: (key: string) => void;
}

export const RequiresReviewBanner = ({
  data,
  handleFilterItems,
  removeFilterType,
}: RequiresReviewBannerProps) => {
  const [isFiltered, setIsFiltered] = useState(false);
  const numOfSubscriptionsThatRequiresReview = useMemo(
    () =>
      data.filter((subscription) => handleIsReviewRequired(subscription))
        .length,
    [data],
  );

  const handleToggleIsFiltered = () => {
    setIsFiltered(!isFiltered);
  };

  const handleReviewNow = () => {
    const filterType: FilterType = 'boolean';
    const filters = [
      {
        key: 'review_required',
        displayName: 'Review Required',
        filterType,
        value: 'true',
        isSatisfied: handleIsReviewRequired,
      },
    ];
    handleFilterItems(filters, isSatisfied);
    handleToggleIsFiltered();
  };

  const handleResetReviewRequired = () => {
    removeFilterType('review_required');
    handleToggleIsFiltered();
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          {numOfSubscriptionsThatRequiresReview} subscription requires review
        </Title>
        <ActionWrapper>
          {isFiltered ? (
            <IconContainer onClick={handleResetReviewRequired}>
              <img src={ErrorIcon} alt="close icon" />
            </IconContainer>
          ) : (
            <ReviewBtn
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleReviewNow}
            >
              Review now
            </ReviewBtn>
          )}
        </ActionWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f1f3f5;
  color: #000000;
  padding: 10px 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 96%;
  position: absolute;
  top: 0;

  @media screen and (max-width: 768px) {
    max-width: -webkit-fill-available;
    top: -10px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const ActionWrapper = styled.div``;

const ReviewBtn = MuiStyled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    height: 40,
    borderRadius: 7,

    '&:hover': {
      backgroundColor: '#454284',
    },
  },
}));

const IconContainer = styled.div`
  width: 32px;
  margin: 0 8px;
  cursor: pointer;
`;
