import React from 'react';

import { Chip } from '@mui/material';

import { User } from 'store/slice/users-slice';
import { TableConfig } from './components/table/consts';

export interface UserWithRoles {
  name: string;
  email: string;
  roles: string[];
}

export const usersWithRolesFields: TableConfig<UserWithRoles>[] = [
  {
    displayName: 'Name',
    key: 'name',
  },
  {
    displayName: 'Email',
    key: 'email',
  },
  {
    displayName: 'Role',
    key: 'roles',
    format: (user) => (
      <>
        {user.roles.map((role, idx) => (
          <Chip
            key={`${role}-${idx}`}
            label={role}
            sx={{ marginRight: '10px' }}
            variant="outlined"
          />
        ))}
      </>
    ),
  },
];
