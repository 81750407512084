import React from 'react';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import { LatestDomainScansFieldMap } from 'components/latest-scans-table/websites-table';
import { BusinessAndOwner } from 'containers/businesses-page/consts';
import { Domain } from 'store/slice/domains-slice';
import {
  formatDate,
  ICON_COLOR,
} from 'components/domains-table/domains-table-body';
import {
  WarningOutlined,
  CheckCircleOutlined,
  FolderOutlined,
  AutorenewOutlined,
  ErrorOutlined,
} from '@mui/icons-material';
import moment from 'moment';

export interface BusinessWithDomains extends BusinessAndOwner {
  domains: Domain[];
  domainsCount: number;
  subscriptionsCount: number;
  lastDomainCreatedAt: string;
}

export const domainFields: Record<string, FilterField> = {
  id: {
    key: 'id',
    displayName: '#',
    filterType: 'string',
    isFilter: false,
  },
  domainName: {
    key: 'domainName',
    displayName: 'Domain Name',
    filterType: 'string',
    isFilter: true,
  },
  accountName: {
    key: 'accountName',
    displayName: 'Account Name',
    filterType: 'string',
    isFilter: true,
  },
  email: {
    key: 'email',
    displayName: 'Email',
    filterType: 'string',
    isFilter: true,
  },
  subscriptionPlan: {
    key: 'subscriptionPlan',
    displayName: 'Subscription',
    filterType: 'string',
    isFilter: true,
  },
  txID: {
    key: 'txID',
    displayName: 'Transaction ID',
    filterType: 'string',
    isFilter: true,
  },
  registrationDate: {
    key: 'registrationDate',
    displayName: 'Registration Date',
    filterType: 'date',
    isFilter: true,
  },
  expirationDate: {
    key: 'expirationDate',
    displayName: 'Expiration Date',
    filterType: 'date',
    isFilter: true,
  },
  lastPaymentDate: {
    key: 'lastPaymentDate',
    displayName: 'Last Payment',
    filterType: 'date',
    isFilter: true,
  },
  hasWidget: {
    key: 'hasWidget',
    displayName: 'Has Widget',
    filterType: 'boolean',
    isFilter: true,
  },
  hasWidgetIntegrated: {
    key: 'hasWidgetIntegrated',
    displayName: 'Has Widget Integrated',
    filterType: 'boolean',
    isFilter: true,
  },
};

export const businessesWithDomainsFields: Record<string, FilterField> = {
  businessId: {
    key: 'id',
    displayName: 'ID',
    filterType: 'number',
  },
  businessName: {
    key: 'businessName',
    displayName: 'Name',
    filterType: 'string',
  },
  ownerEmail: {
    key: 'ownerEmail',
    displayName: 'Owner email',
    filterType: 'string',
  },
  domainsCount: {
    key: 'domainsCount',
    displayName: 'Domains (count)',
    filterType: 'number',
  },
  subscriptionsCount: {
    key: 'subscriptionsCount',
    displayName: 'Subscriptions (count)',
    filterType: 'number',
  },
  createdAt: {
    key: 'createdAt',
    displayName: 'Created At',
    filterType: 'string',
  },
  lastDomainCreatedAt: {
    key: 'lastDomainCreatedAt',
    displayName: 'Last Domain Created At',
    filterType: 'string',
  },
};

export const businessesWithDomainsMap: LatestDomainScansFieldMap[] = [
  {
    key: 'id',
    format: ({ id }: BusinessWithDomains) => <>{id}</>,
    align: 'left',
  },
  {
    key: 'businessName',
    format: ({ businessName }: BusinessWithDomains) => <>{businessName}</>,
    align: 'left',
  },
  {
    key: 'ownerEmail',
    format: ({ ownerEmail }: BusinessWithDomains) => <>{ownerEmail}</>,
    align: 'left',
  },
  {
    key: 'domainsCount',
    format: ({ domainsCount }: BusinessWithDomains) => <>{domainsCount}</>,
    align: 'left',
  },
  {
    key: 'subscriptionsCount',
    format: ({ subscriptionsCount }: BusinessWithDomains) => (
      <>{subscriptionsCount}</>
    ),
    align: 'left',
  },
  {
    key: 'createdAt',
    format: ({ createdAt }: BusinessWithDomains) => (
      <>{moment(createdAt).format('DD/MM/YYYY')}</>
    ),
    align: 'left',
  },
  {
    key: 'lastDomainCreatedAt',
    format: ({ lastDomainCreatedAt }: BusinessWithDomains) => (
      <>{lastDomainCreatedAt}</>
    ),
    align: 'left',
  },
];

export const businessWithDomainsDetails: LatestDomainScansFieldMap[] = [
  {
    key: 'domainName',
    format: ({ domainName }: Domain) => <>{domainName}</>,
    align: 'left',
  },
  {
    key: 'accountName',
    format: ({ accountName }: Domain) => <>{accountName}</>,
    align: 'left',
  },
  {
    key: 'email',
    format: ({ email }: Domain) => <>{email}</>,
    align: 'left',
  },
  {
    key: 'subscriptionPlan',
    format: ({ subscriptionPlan }: Domain) => <>{subscriptionPlan}</>,
    align: 'left',
  },
  {
    key: 'txID',
    format: ({ txID }: Domain) => <>{txID}</>,
    align: 'left',
  },
  {
    key: 'registrationDate',
    format: ({ registrationDate }: Domain) => (
      <>{formatDate(registrationDate)}</>
    ),
    align: 'left',
  },
  {
    key: 'expirationDate',
    format: ({ expirationDate }: Domain) => <>{formatDate(expirationDate)}</>,
    align: 'left',
  },
  {
    key: 'lastPaymentDate',
    format: ({ lastPaymentDate }: Domain) => <>{formatDate(lastPaymentDate)}</>,
    align: 'left',
  },
  {
    key: 'hasWidget',
    format: ({ hasWidget }: Domain) => (
      <>
        {hasWidget ? (
          <CheckCircleOutlined style={{ color: ICON_COLOR['green'] }} />
        ) : (
          <WarningOutlined style={{ color: ICON_COLOR['red'] }} />
        )}
      </>
    ),
    align: 'left',
  },
  {
    key: 'hasWidgetIntegrated',
    format: ({ hasWidgetIntegrated }: Domain) => (
      <>
        {hasWidgetIntegrated ? (
          <CheckCircleOutlined style={{ color: ICON_COLOR['green'] }} />
        ) : (
          <WarningOutlined style={{ color: ICON_COLOR['red'] }} />
        )}
      </>
    ),
    align: 'left',
  },
  {
    key: 'archived',
    format: ({ archived }: Domain) => <>{archived ? <FolderOutlined /> : ''}</>,
    align: 'left',
  },
  {
    key: 'autoRenewal',
    format: ({ autoRenewal }: Domain) => (
      <>
        {autoRenewal ? (
          <AutorenewOutlined style={{ color: ICON_COLOR['yellow'] }} />
        ) : (
          ''
        )}
      </>
    ),
    align: 'left',
  },
  {
    key: 'invalid',
    format: ({ invalid }: Domain) => (
      <>
        {invalid ? <ErrorOutlined style={{ color: ICON_COLOR['red'] }} /> : ''}
      </>
    ),
    align: 'left',
  },
];

export const domainsCSVHeaders = [
  {
    label: 'ID',
    key: 'ID',
  },
  {
    label: 'Account Name',
    key: 'accountName',
  },
  {
    label: 'Active',
    key: 'active',
  },
  {
    label: 'Archived',
    key: 'archived',
  },
  {
    label: 'Auto Renewal',
    key: 'autoRenewal',
  },
  {
    label: 'Domain ID',
    key: 'domainID',
  },
  {
    label: 'Domain Name',
    key: 'domainName',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Expiration Date',
    key: 'expirationDate',
  },
  {
    label: 'Has Widget',
    key: 'hasWidget',
  },
  {
    label: 'Has Widget Integrated',
    key: 'hasWidgetIntegrated',
  },
  {
    label: 'Invalid',
    key: 'invalid',
  },
  {
    label: 'Last Payment Date',
    key: 'lastPaymentDate',
  },
  {
    label: 'Next Capture',
    key: 'nextCapture',
  },
  {
    label: 'Payment Method',
    key: 'paymentMethod',
  },
  {
    label: 'Payment Schedule',
    key: 'paymentSchedule',
  },
  {
    label: 'Price',
    key: 'price',
  },
  {
    label: 'Registration Date',
    key: 'registrationDate',
  },
  {
    label: 'Subscription Plan',
    key: 'subscriptionPlan',
  },
  {
    label: 'Transaction ID',
    key: 'txID',
  },
  {
    label: 'User ID',
    key: 'userID',
  },
];
