import {
  PlanWithPlanTemplate,
  Subscription,
  SubscriptionAndPlans,
} from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';
import moment from 'moment';
import React from 'react';
import { Alert, AlertColor, Box } from '@mui/material';
import { isNullOrUndefined } from '@equally-ai-front/common/src';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/utils/helpers';
import { handleIsReviewRequired } from '@equally-ai-front/common/src/lib';
import { SubscriptionBusiness } from './subscription-business';
import {
  SortOption,
  OrderOptionsEnum,
} from '@equally-ai-front/common/src/hooks/use-pagination';
import { TableConfig } from 'containers/business-details-page/components/table/consts';

export interface SubscriptionRecord extends SubscriptionAndPlans, Subscription {
  provider: string;
  duration: string;
  plan_types: string;
  review_required: boolean;
}

export const getSubscriptionRecords = (
  subscriptions: SubscriptionAndPlans[],
): SubscriptionRecord[] => {
  return subscriptions.map((sub) => ({
    ...sub,
    ...sub.subscription,
    provider: ProviderIDToType[sub.subscription.provider_id] || '-',
    duration: sub.plans[0].plan_template.duration_unit,
    plan_types: sub.plans.map((plan) => plan.plan_type).join(','),
    review_required: handleIsReviewRequired(sub),
  }));
};

export const subscriptionsTableSortFields: SortOption = {
  expiration: OrderOptionsEnum.DESC,
};

export const ProviderIDToType: Record<number, string> = {
  1: 'Equally',
  2: 'Paypal',
  3: 'Stripe',
};

export const getDaysLeft = (expirationDate: string) => {
  const currentDate = new Date();
  const expiration = new Date(expirationDate);

  const timeDiff = expiration.getTime() - currentDate.getTime();

  const daysRemaining = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));
  return daysRemaining;
};

export const subscriptionConfig: TableConfig<SubscriptionRecord>[] = [
  {
    displayName: 'Transcation ID',
    key: 'transaction_id',
    isLink: true,
    format: (subscription) => subscription.subscription.transaction_id,
  },
  {
    displayName: 'Business Name',
    key: 'id',
    format: (subscription) => (
      <SubscriptionBusiness subscription={subscription} />
    ),
  },
  {
    displayName: 'Status',
    key: 'status',
    format: (subscription) =>
      !isNullOrUndefined(subscription.subscription.cancelled_at)
        ? 'Cancelled'
        : !isNullOrUndefined(subscription.subscription.suspended_at)
          ? 'Suspended'
          : 'Active',
  },
  {
    displayName: 'Duration',
    key: 'duration',
    format: (subscription) => subscription.plans[0].plan_template.duration_unit,
  },
  {
    displayName: 'Amount',
    key: 'payment_amount',
    format: (subscription) =>
      `${subscription.subscription.payment_amount} ${subscription.subscription.payment_currency}`,
  },
  {
    displayName: 'Provider',
    key: 'provider',
    format: (subscription) =>
      ProviderIDToType[subscription.subscription.provider_id] || '-',
  },
  {
    displayName: 'Plans',
    key: 'plan_types',
    format: (subscription) => (
      <span style={{ textTransform: 'capitalize' }}>
        {subscription.plans.map((plan) => plan.plan_type).join(',')}
      </span>
    ),
  },
  {
    displayName: 'Expiration',
    key: 'expiration',
    format: (subscription) => {
      const expiration = subscription.subscription.expiration;
      const daysLeft = getDaysLeft(expiration);
      const { severity } = getSeverityAndStatus(daysLeft);

      return (
        <Alert
          variant="filled"
          severity={severity}
          icon={false}
          sx={{ maxWidth: 'max-content' }}
        >
          {moment(subscription.subscription.expiration).format('DD/MM/YYYY')}
        </Alert>
      );
    },
  },
  {
    displayName: 'Review Required',
    key: 'review_required',
    format: (subscriptionAndPlan) => {
      const value = handleIsReviewRequired(subscriptionAndPlan);
      const shouldReviewNow = capitalizeFirstLetter(value.toString());
      return <ReviewRequired>{shouldReviewNow}</ReviewRequired>;
    },
  },
];

export const plansTableFields: TableConfig<PlanWithPlanTemplate>[] = [
  {
    displayName: 'Type',
    key: 'plan_type',
    isLink: true,
  },
  {
    displayName: 'Credit',
    key: 'quantity',
    format: (plan) => (
      <Box>{`${plan.quantity / plan.plan_template.domain_size || 1}`}</Box>
    ),
  },
  {
    displayName: 'Name',
    key: 'plan_template',
    format: (plan) => <Box>{plan.plan_template.name}</Box>,
  },
  {
    displayName: 'Price',
    key: 'plan_template',
    format: (plan) => (
      <Box>{`${plan.plan_template.price} ${plan.plan_template.currency}`}</Box>
    ),
  },
  {
    displayName: 'Expiration',
    key: 'expiration',
    format: (plan) => moment(plan.expiration).format('DD/MM/YYYY'),
  },
];

export const itemFields: Record<string, FilterField> = {
  transaction_id: {
    key: 'transaction_id',
    displayName: 'Transcation ID',
    filterType: 'string',
  },
  amount: {
    key: 'payment_amount',
    displayName: 'Amount',
    filterType: 'number',
  },
  provider: {
    key: 'provider',
    displayName: 'Provider',
    filterType: 'string',
  },
  expiration: {
    key: 'expiration',
    displayName: 'Expiration',
    filterType: 'date',
  },
  reviewRequired: {
    key: 'review_required',
    displayName: 'Review Required',
    filterType: 'boolean',
  },
};

export const MIN_EXPIRY_DAYS = 0;
export const MAX_EXPIRY_DAYS = 7;

export const getActiveStatusBaseOnExpiration = (expirationDate: string) => {
  const daysLeft = getDaysLeft(expirationDate);
  const { severity, isActive } = getSeverityAndStatus(daysLeft);

  return (
    <Alert
      variant="filled"
      severity={severity}
      icon={false}
      sx={{
        '.MuiAlert-message': {
          padding: 0,
        },
      }}
    >
      {isActive}
    </Alert>
  );
};

const getSeverityAndStatus = (daysLeft: number) => {
  let severity: AlertColor = 'success';
  let isActive: 'Active' | 'InActive' = 'Active';

  if (daysLeft <= MAX_EXPIRY_DAYS && daysLeft > MIN_EXPIRY_DAYS) {
    severity = 'warning';
  } else if (daysLeft <= MIN_EXPIRY_DAYS) {
    severity = 'error';
    isActive = 'InActive';
  }

  return { severity, isActive };
};

const ReviewRequired = styled.div`
  width: 300px;
`;
