import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import {
  PlanTemplate,
  SubscriptionAndPlans,
  AdminCreateSubscriptionPayload,
} from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import axios from 'axios';
import { BASE_API_URL, UNAUTH_BASE_API_URL } from 'utils/constants';

class SubscriptionAdminService {
  static getSubscriptions = async (): Promise<
    ApiResponse<SubscriptionAndPlans[]>
  > => {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'plan-and-subscriptions');
    } catch (err: any) {
      console.error('Error getting subscriptions', err);
      error = err?.message || 'Failed to get all subscriptions record';
    }

    const { data } = result || {};
    return {
      data: (data?.data || []) as SubscriptionAndPlans[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };
  static createSubscriptions = async (
    payload: AdminCreateSubscriptionPayload,
  ): Promise<ApiResponse<SubscriptionAndPlans[]>> => {
    let result;
    let error;

    try {
      result = await axios.post(
        BASE_API_URL + 'plan-and-subscriptions',
        payload,
      );
    } catch (err: any) {
      console.error('Error getting subscriptions', err);
      error =
        err?.response?.data?.message ||
        err?.message ||
        'Failed to create subscription';
    }

    const { data } = result || {};

    return {
      data: (data?.data || []) as SubscriptionAndPlans[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };

  static getPlanTemplates = async (): Promise<ApiResponse<PlanTemplate[]>> => {
    let result;
    let error;

    try {
      result = await axios.get(
        UNAUTH_BASE_API_URL + 'plan-templates?product_types=widget,flowy',
      );
    } catch (err: any) {
      console.error('Error getting subscriptions', err);
      error = err?.message || 'Failed to get all subscriptions record';
    }

    const { data } = result || {};
    return {
      data: (data?.message || []) as PlanTemplate[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  };

  static syncSubscriptionWithProvider = async (
    subscriptionTransactionId: string,
  ): Promise<ApiResponse<string>> => {
    let result;
    let error;

    try {
      result = await axios.get(
        `${BASE_API_URL}/paypal/${subscriptionTransactionId}`,
      );
    } catch (err: any) {
      console.error('Error syncing subscription with provider', err);
      error = err?.message || 'Failed to sync subscription with provider';
    }

    const { data } = result || {};
    return {
      data: data?.message || '',
      isSuccess: data?.code === 200,
      error: error,
    };
  };
}

export { SubscriptionAdminService };
