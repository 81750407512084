import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { Box } from '@mui/material';
import { DomainsTable } from 'components/domains-table/domains-table';
import { BusinessWithDomains } from 'containers/domains-page/consts';
import { DomainTableEditButton } from 'containers/domains-page/domain-table-edit-button';
import React, { useState } from 'react';
import { Domain } from 'store/slice/domains-slice';

interface BusinessDomainsProps {
  businessWithDomains: BusinessWithDomains | null;
}
export const BusinessDomains = (props: BusinessDomainsProps) => {
  const { businessWithDomains } = props;
  const [selectedDomains, setSelectedDomains] = useState<
    Record<number, Domain>
  >({});

  const { pageCount, currentData, handlePageChange, handleSort, sortOptions } =
    usePagination(businessWithDomains?.domains || [], 10, 'domainName');

  const domainsMap: Record<number, Domain> = Object.fromEntries(
    (businessWithDomains?.domains || []).map((domain) => [
      domain.domainID,
      domain,
    ]),
  );

  const handleSelectDomain = (payload: {
    checked: boolean;
    domainId: number;
  }) => {
    const { checked, domainId } = payload;
    setSelectedDomains((prev) => {
      const updatedDomains = { ...prev };
      if (checked) {
        updatedDomains[domainId] = domainsMap[domainId];
      } else {
        delete updatedDomains[domainId];
      }
      return updatedDomains;
    });
  };

  const handleRemoveSelectedDomain = (domainId: number) => {
    setSelectedDomains((prev) => {
      const updatedDomains = { ...prev };
      delete updatedDomains[domainId];
      return updatedDomains;
    });
  };

  const handleClearAllSelectedDomains = () => {
    setSelectedDomains({});
  };

  return (
    <Box>
      <Box mb="30px">
        <DomainTableEditButton
          selectedDomains={selectedDomains}
          handleRemoveSelectedDomain={handleRemoveSelectedDomain}
          handleClearAllSelectedDomains={handleClearAllSelectedDomains}
        />
      </Box>
      <DomainsTable
        pageCount={pageCount}
        currentData={currentData}
        handlePageChange={handlePageChange}
        selectedDomains={selectedDomains}
        handleSelectDomain={handleSelectDomain}
        handleSort={handleSort}
        sortOptions={sortOptions}
      />
    </Box>
  );
};
