import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAllBusinesses } from 'store/slice/business-slice';
import { BusinessesTable } from './businesses-table';
import { useToaster } from 'contexts/toast-context';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { Business } from '@equally-ai-front/common/src/types/business';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { BusinessAndOwner, getMergedBusinessAndOwner } from './consts';
import { BusinessAdminService } from '../../api/business-admin-service';

export const BusinessesPage = () => {
  const { setToaster } = useToaster();
  const [loading, setLoading] = useState<boolean>(true);
  const [businessesAndOwner, setBusinessesAndOwner] = useState<
    BusinessAndOwner[]
  >([]);

  const getBusinesses = async () => {
    const { error, data, isSuccess } =
      await BusinessAdminService.getBusinesses();

    setLoading(false);

    if (!isSuccess || !data) {
      setToaster('error', error);
      return;
    }

    const businessAndOwner = getMergedBusinessAndOwner(data);
    setBusinessesAndOwner(businessAndOwner);
  };

  React.useEffect(() => {
    void getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Businesses Page</h1>
      <BusinessesTable businessesAndOwner={businessesAndOwner} />
      <LoadingView
        open={loading}
        loadingText="Loading businesses..."
        zIndex="13000"
      />
    </div>
  );
};
