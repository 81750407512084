import React from 'react';
import ChevronDown from '../../assets/svg/chevron-down-white.svg';
import NotificationBell from '../../assets/svg/notification-bell.svg';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface MenuOption {
  label: string;
  handleClickAction: () => void;
}

interface UserInfoProps {
  userInfo: any;
  hasNotificationBell?: boolean;
  menuOptions?: MenuOption[];
}

export const UserInfo = ({
  userInfo,
  hasNotificationBell,
  menuOptions,
}: UserInfoProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      {hasNotificationBell && <NotificationBellIcon src={NotificationBell} />}
      <Name>{Object.entries(userInfo).length > 0 ? userInfo.name : ''}</Name>
      <Button
        id="menu-button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={ChevronDown} className="chevron-icon" alt="" />
      </Button>
      {menuOptions && (
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
        >
          {menuOptions.map(({ label, handleClickAction }) => (
            <MenuItem
              key={label}
              onClick={() => {
                handleClickAction();
                handleClose();
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  #menu-button {
    padding: 0;
    min-width: fit-content;

    &:hover {
      background-color: transparent;
    }
  }

  .chevron-icon {
    margin-left: 5px;
    cursor: pointer;
  }
`;

const NotificationBellIcon = styled.img`
  margin-right: 10px;
  display: inline-block;
`;

const Name = styled.p`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
`;
