import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';
import { NewCoupon } from 'store/types/coupon-types';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { addCoupon, getAllCoupons } from 'store/slice/coupons-slice';
import { useToaster } from 'contexts/toast-context';
import { StyledButton } from './coupons-table';

interface AddCouponFormProps {
  handleClosePopup: () => void;
}

export const AddCouponForm = ({ handleClosePopup }: AddCouponFormProps) => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const [newCoupon, setNewCoupon] = useState<NewCoupon>({
    name: '',
    type: '',
    operator: '',
    amount: 0,
    total_credits: 0,
  });

  const handleNewCouponChange = (evt: any) => {
    const { name, value } = evt.target;

    if (
      (name === 'total_credits' && newCoupon.total_credits === 0) ||
      (name === 'amount' && newCoupon.amount === 0)
    ) {
      const newValue = value.replace('0', '');
      setNewCoupon({
        ...newCoupon,
        [name]: newValue,
      });
      return;
    }

    setNewCoupon({
      ...newCoupon,
      [name]: value,
    });
  };

  const handleAddCoupon = async () => {
    const dataToSend = {
      ...newCoupon,
    };

    if (dataToSend.operator === 'fix') {
      dataToSend.amount = 0;
      dataToSend.total_credits = Number(dataToSend.total_credits);
    } else if (dataToSend.operator === 'percent') {
      dataToSend.total_credits = 0;
      dataToSend.amount = Number(dataToSend.amount);
    }

    const response = await dispatch(addCoupon(dataToSend));
    const { isSuccess, error } = response.payload as ApiResponse<string>;

    if (!isSuccess) {
      setToaster('error', error);
    } else {
      setToaster('success', 'Coupon created successfully!');
      handleClosePopup();
      dispatch(getAllCoupons());
    }
  };

  const handleSubmitButton = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    handleAddCoupon();
  };

  return (
    <ModalContent>
      <ModalTitle>Add New Coupon</ModalTitle>
      <form onSubmit={handleSubmitButton}>
        <InputDiv>
          <TextField
            type="text"
            name="name"
            value={newCoupon.name}
            onChange={handleNewCouponChange}
            id="coupon-name"
            label="Name"
            variant="outlined"
            required
          />
        </InputDiv>
        <InputDiv>
          <FormControl fullWidth>
            <InputLabel id="coupon-type">Type</InputLabel>
            <Select
              labelId="coupon-type"
              id="coupon-type-select"
              value={newCoupon.type || ''}
              name="type"
              label="Type"
              onChange={handleNewCouponChange}
              required
            >
              <MenuItem value="widget">Widget</MenuItem>
              <MenuItem value="developer">Developer</MenuItem>
              <MenuItem value="flowy">Flowy</MenuItem>
            </Select>
          </FormControl>
        </InputDiv>
        <InputDiv>
          <FormControl fullWidth>
            <InputLabel id="operator">Operator</InputLabel>
            <Select
              labelId="operator"
              id="coupon-operator-select"
              value={newCoupon.operator || ''}
              name="operator"
              label="Operator"
              onChange={handleNewCouponChange}
              required
            >
              <MenuItem value="fix">Fix</MenuItem>
              <MenuItem value="percent">Percent</MenuItem>
            </Select>
          </FormControl>
        </InputDiv>
        <InputDiv>
          <TextField
            type="number"
            name={newCoupon.operator === 'fix' ? 'total_credits' : 'amount'}
            value={
              newCoupon.operator === 'fix'
                ? newCoupon.total_credits
                : newCoupon.amount
            }
            onChange={handleNewCouponChange}
            id="coupon-amount"
            label="Amount"
            variant="outlined"
            required
          />
        </InputDiv>
        <StyledButton type="submit" fullWidth>
          Add Coupon
        </StyledButton>
      </form>
    </ModalContent>
  );
};

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: fit-content;
  padding-bottom: 0;
`;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
  width: 400px;
  border-radius: 10px;
  padding: 10px 20px 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
  background-color: #ffffff;

  &:focus {
    outline: none;
  }
`;

const ModalTitle = styled.h3`
  font-size: 15px;
  margin-bottom: 30px;
  text-align: center;
`;
