import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import graphqlClient from '@equally-ai-front/common/src/utils/graphql.util';

class WebsitesAdminService {
  static async getLatestDomainScans(): Promise<ApiResponse<any>> {
    const query = `
        query getAdminLatestDomainScans($payload: Request) {
            getAdminLatestDomainScans(payload: $payload) {
                data
                message
                statusCode
            }
        }
    `;

    const result = await graphqlClient.execute({
      functionName: 'getAdminLatestDomainScans',
      query,
      payload: {
        data: JSON.stringify({}),
      },
    });

    const { data, isSuccess } = result;
    return {
      data,
      isSuccess,
      error: !isSuccess
        ? 'Unable to get latest domain scans, try again later.'
        : '',
    };
  }
}

export { WebsitesAdminService };
