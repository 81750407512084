import { useAnalytics } from '../contexts/AnalyticsProvider';

export type BookDemoProductType = 'flowy' | 'widget';
export interface BookADemoPayload {
  bookADemoLink: string;
  productType: BookDemoProductType;
  source: 'promo' | 'user panel';
  businessId?: string;
}

export const useBookADemo = () => {
  const analytics = useAnalytics();

  const handleBookDemo = (payload: BookADemoPayload) => {
    const { bookADemoLink, productType, source, businessId } = payload;
    window.open(bookADemoLink, '_blank', 'noopener,noreferrer');
    const analyticsData: Record<string, string> = {
      product_type: productType,
      source,
    };
    if (businessId) {
      analyticsData['business_id'] = businessId;
    }

    analytics.track('Book A Demo Clicked', analyticsData);
  };

  const getBookDemoProductType = (): BookDemoProductType => {
    return window.location.href.includes('flowy') ? 'flowy' : 'widget';
  };

  return { handleBookDemo, getBookDemoProductType };
};
