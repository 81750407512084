import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getConfig,
  uploadConfig,
} from '@equally-ai-front/common/src/redux/admin-widget-config-slice';
import { useToaster } from 'contexts/toast-context';
import { WidgetConfigTableSection } from 'components/widget-config-table/widget-config-table-section';
import { FormModal } from './form-modal';
import DeleteModal from '@equally-ai-front/common/src/components/admin-widget-config/delete-modal';
import { AdminWidgetConfigType } from '@equally-ai-front/common/src/helpers';
import { logError } from 'utils/helpers';
import { deleteItem } from '@equally-ai-front/common/src/helpers';

interface ColumnProp {
  onEdit: (arg: any) => void;
  onDelete: (arg: any) => void;
}

interface WidgetConfigContainerProps {
  domainId: string;
  title: string;
  elements: any[];
  configType: AdminWidgetConfigType;
  getColumns: (arg: ColumnProp) => any[];
}

export const WidgetConfigContainer = ({
  domainId,
  title,
  elements,
  configType,
  getColumns,
}: WidgetConfigContainerProps) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.adminConfig);
  const { setToaster } = useToaster();

  const [isAddModalVisible, setAddModalVisible] = React.useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [editElementID, setEditElementID] = React.useState<string>('');

  const onEditElements = React.useCallback((element: any) => {
    setEditElementID(element.id);
    setAddModalVisible(true);
  }, []);

  const onAddElements = React.useCallback(() => {
    setAddModalVisible(true);
  }, []);

  const onDeleteElements = React.useCallback((element: any) => {
    setEditElementID(element.id);
    setDeleteModalVisible(true);
  }, []);

  const onCloseAddModal = React.useCallback(() => {
    setAddModalVisible(false);
    setEditElementID('');
  }, []);

  const onCloseDeleteModal = React.useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const onDelete = async () => {
    const newElements = deleteItem(elements, editElementID);
    onCloseDeleteModal();

    try {
      await dispatch(
        uploadConfig({
          domainID: domainId ?? '',
          config: newElements,
          configType,
        }),
      );
      setToaster('success', 'Item Deleted!');
    } catch (err) {
      setToaster('error', 'Failed to delete Item!');
      logError(err);
    }
  };

  React.useEffect(() => {
    if (domainId) {
      dispatch(
        getConfig({
          configType,
          domainID: domainId,
        }),
      );
    }
  }, [domainId, dispatch, configType]);

  const columns = React.useMemo(
    () =>
      getColumns({
        onEdit: onEditElements,
        onDelete: onDeleteElements,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteElements, onEditElements],
  );

  return (
    <>
      <WidgetConfigTableSection
        title={title}
        columns={columns}
        isLoading={loading}
        dataSource={elements}
        tableCellWordBreak="..."
        onAdd={onAddElements}
      />
      {isAddModalVisible && (
        <FormModal
          allElements={elements}
          configType={configType}
          domainID={domainId ?? ''}
          isVisible={isAddModalVisible}
          onClose={onCloseAddModal}
          title={title}
          elementID={editElementID}
        />
      )}
      <DeleteModal
        title={`Delete ${title}`}
        isVisible={isDeleteModalVisible}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
};
