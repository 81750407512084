import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_URLS } from 'containers/routes';
import { useToaster } from 'contexts/toast-context';
import { useAdminAuth } from 'contexts/admin-context';

export const HomePage = () => {
  const { logOut } = useAdminAuth();
  const { setToaster } = useToaster();
  const navigate = useNavigate();

  const logAdminOut = () => {
    logOut();
    navigate(ROUTES_URLS.LOGIN);
  };

  const handleToast = () => {
    setToaster('success', 'A toaster');
  };

  return (
    <div>
      Home Page
      <button onClick={handleToast}>Set toaster</button>
      <button onClick={logAdminOut}>Log out</button>
    </div>
  );
};
