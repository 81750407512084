import React from 'react';
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingWrapperProps {
  loading: boolean;
  children: React.ReactNode;
  cover?: boolean;
  size?: number;
}

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}

const theme = createTheme({
  status: {
    danger: '#FF9999',
  },
  palette: {
    primary: {
      main: '#AEEAD3',
    },
    neutral: {
      main: '#1E5643',
    },
  },
});

export const LoadingWrapper = (props: LoadingWrapperProps) => {
  const { loading = false, children, cover = false, size = 68 } = props;
  const CoverEl = cover ? Cover : Absolute;

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {loading && (
          <CoverEl>
            <Center>
              <CircularProgress size={size} color="primary" />
            </Center>
          </CoverEl>
        )}
        {children}
      </Container>
    </ThemeProvider>
  );
};

const Cover = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: rgba(255, 255, 255, 0.7);
`;

const Absolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Center = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
`;
