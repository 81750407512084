import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import axios from 'axios';
import { Coupon, NewCoupon } from 'store/types/coupon-types';
import { BASE_API_URL } from 'utils/constants';

class CouponAdminService {
  static async getAllCoupons(): Promise<ApiResponse<Coupon[]>> {
    let result;
    let error;

    try {
      result = await axios.get(BASE_API_URL + 'coupons/templates');
    } catch (err: any) {
      console.error('Error getting coupons', err);
      error = err?.message || 'Failed to get all coupons';
    }

    const { data } = result || {};
    return {
      data: (data?.message?.data || []) as Coupon[],
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  }

  static async addCoupon(coupon: NewCoupon): Promise<ApiResponse<string>> {
    let result;
    let error;

    try {
      result = await axios.post(BASE_API_URL + 'coupons/templates', coupon);
    } catch (err: any) {
      console.error(`Error creating coupon`, err);
      error = err?.message || `Failed to create coupon`;
    }

    const { data } = result || {};
    return {
      data: data?.message || '',
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  }

  static async editCoupon(
    coupon: Partial<Coupon>,
  ): Promise<ApiResponse<string>> {
    let result;
    let error;

    try {
      result = await axios.put(
        BASE_API_URL + 'coupons/templates/' + coupon.id,
        coupon,
      );
    } catch (err: any) {
      console.error(`Error updating ${coupon.name} coupon`, err);
      error = err?.message || `Failed to edit ${coupon.name} coupon`;
    }

    const { data } = result || {};
    return {
      data: data?.message || '',
      isSuccess: data?.code === 200,
      error: error || data?.message,
    };
  }

  static async deleteCoupon(couponId: number): Promise<ApiResponse<string>> {
    let result;
    let error;

    try {
      result = await axios.delete(
        BASE_API_URL + 'coupons/templates/' + couponId,
      );
    } catch (err: any) {
      console.error(`Error deleting coupon`, err);
      error = err?.message || `Failed to delete coupon`;
    }

    const { data, status } = result || {};
    return {
      data: data || '',
      isSuccess: status === 200,
      error: error || data,
    };
  }
}

export { CouponAdminService };
