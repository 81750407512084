import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';
import {
  AdminWidgetConfigState,
  GetConfigType,
  UploadConfigType,
} from './types';
import {
  generateAdminDomainConfigRoute,
  injectObjectID,
  WidgetConfigurations,
} from '../../helpers';
import { AdminWidgetConfigAPI } from '../../api/admin-widget-config';
import DomainsAPI from '../../api/domains';

export const initialState: AdminWidgetConfigState = {
  data: {
    tabIndex: [],
    landmarks: [],
    customAttributes: [],
    moreRoles: [],
    elementsRelationships: [],
    metadata: {
      accessibilityAPI: [],
      accessibilityControl: [],
      accessibilityFeature: [],
      accessibilityHazard: [],
      accessModeSufficient: [],
    },
    dynamicElements: [],
  },
  loading: false,
  error: '',
  message: '',
};

export const getConfig = createAsyncThunk(
  `adminConfig/getConfig`,
  async (actions: GetConfigType, { rejectWithValue }) => {
    const { domainID, configType } = actions;
    const endpoint = generateAdminDomainConfigRoute(domainID, configType);
    const { error, data, isSuccess } =
      actions.isAdmin === undefined || actions.isAdmin
        ? await AdminWidgetConfigAPI.getConfig(endpoint)
        : await DomainsAPI.getConfig(endpoint);
    if (isSuccess) {
      return { data, configType };
    } else {
      return rejectWithValue(error);
    }
  },
);

export const uploadConfig = createAsyncThunk(
  `adminConfig/uploadConfig`,
  async (actions: UploadConfigType, { rejectWithValue }) => {
    const { domainID, configType, config } = actions;
    const endpoint = generateAdminDomainConfigRoute(domainID, configType);

    const { error, data, isSuccess } =
      actions.isAdmin === undefined || actions.isAdmin
        ? await AdminWidgetConfigAPI.saveConfig(endpoint, config)
        : await DomainsAPI.saveConfig(endpoint, config);
    if (isSuccess) {
      return { data, configType };
    } else {
      return rejectWithValue(error);
    }
  },
);

const slice = createSlice({
  name: 'adminConfig',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },

    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getConfig.pending.type]: (state) => {
      state.loading = true;
    },
    [getConfig.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data[action.payload.configType] =
        action.payload.configType === WidgetConfigurations.META_DATA
          ? action.payload.data || {}
          : action.payload.data.map(injectObjectID) || [];
      state.message = 'Ta-da! Your flow is ready';
      state.error = '';
    },
    [getConfig.rejected.type]: (state, action) => {
      const noData =
        action.meta.arg.configType === WidgetConfigurations.META_DATA ? {} : [];
      state.data[action.meta.arg.configType] = noData as any;
      state.loading = false;
      state.error = action.error;
      state.message = "Couldn't process your request.";
    },
    [uploadConfig.pending.type]: (state) => {
      state.loading = true;
    },
    [uploadConfig.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data[action.payload.configType] =
        action.payload.configType === WidgetConfigurations.META_DATA
          ? action.payload.data || {}
          : action.payload.data.map(injectObjectID) || [];
      state.message = 'We saved it for you!';
      state.error = '';
    },
    [uploadConfig.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.message = "Couldn't process your request.";
    },
  },
});

export const { actions: adminConfig, reducer: adminConfigReducer } = slice;
export const useAdminConfig = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
