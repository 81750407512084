import { Box, Button, buttonClasses } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { ComplianceDocumentWithBusinessDetails } from 'containers/documents-redirect/utils';
import React, { useState } from 'react';
import { DocumentView } from './document-view';
import { DocumentEdit } from './document-edit';
import { UpdateDocumentFormData } from '@equally-ai-front/common/src/types/compliance-document';

enum DocumentPopupMode {
  VIEW = 'view',
  EDIT = 'edit',
}

const documentModeSwitchButtonTextMap: Record<DocumentPopupMode, string> = {
  [DocumentPopupMode.EDIT]: 'Cancel',
  [DocumentPopupMode.VIEW]: 'Edit',
};

interface DocumentPopupProps {
  complianceDocumentData: ComplianceDocumentWithBusinessDetails;
  handleEditDocument: (
    evt: React.FormEvent<HTMLFormElement>,
    data: UpdateDocumentFormData,
  ) => Promise<void>;
}

export const DocumentPopup = (props: DocumentPopupProps) => {
  const { complianceDocumentData, handleEditDocument } = props;
  const [documentPopupMode, setDocumentPopupMode] = useState<DocumentPopupMode>(
    DocumentPopupMode.VIEW,
  );

  const handleDocumentPopupModeChange = () => {
    switch (documentPopupMode) {
      case DocumentPopupMode.EDIT:
        setDocumentPopupMode(DocumentPopupMode.VIEW);
        break;
      case DocumentPopupMode.VIEW:
        setDocumentPopupMode(DocumentPopupMode.EDIT);
        break;
      default:
        setDocumentPopupMode(DocumentPopupMode.VIEW);
        break;
    }
  };

  return (
    <Box>
      <Box mb="25px">
        <DocumentModeSwitchButton
          onClick={handleDocumentPopupModeChange}
          variant="contained"
          color="secondary"
          type="button"
        >
          {documentModeSwitchButtonTextMap[documentPopupMode]}
        </DocumentModeSwitchButton>
      </Box>
      <Box>
        {documentPopupMode === DocumentPopupMode.VIEW && (
          <DocumentView complianceDocumentData={complianceDocumentData} />
        )}
        {documentPopupMode === DocumentPopupMode.EDIT && (
          <DocumentEdit
            complianceDocumentData={complianceDocumentData}
            handleEditDocument={handleEditDocument}
          />
        )}
      </Box>
    </Box>
  );
};

const StyledButton = MuiStyled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    borderRadius: 7,

    '&:hover': {
      backgroundColor: '#454284',
    },
  },
}));

const DocumentModeSwitchButton = MuiStyled(StyledButton)(() => ({
  height: '40px',
}));

export const SubmitButton = MuiStyled(StyledButton)(() => ({
  height: '56px',
  width: '100%',
}));
