export type FilterDate = 'date';

export type FilterType =
  | 'string'
  | FilterDate
  | 'boolean'
  | 'number'
  | 'array-of-strings-or-numbers';

export enum FilterCondition {
  EqualTo = 'equal_to',
  NotEqualTo = 'not_equal_to',
  GreaterThan = 'greater_than',
  SmallerThan = 'smaller_than',
  Between = 'between',
  Before = 'before',
  After = 'after',
  Exists = 'exists',
  DoesNotExist = 'does_not_exist',
  Contains = 'contains',
  DoesNotContain = 'does_not_contain',
  Custom = 'custom',
  EqualToPreset = 'equal_to_preset',
}

export interface ItemFilter {
  key: string;
  displayName: string;
  filterType: FilterType;
  customType?: string;
  value: any;
  value2?: string;
  filterCondition?: FilterCondition;
  isSatisfied?: (obj: any, value?: any) => boolean;
  isPreset?: boolean;
}

export interface FilterOperatorOptions {
  value: FilterCondition;
  label: string;
  isSatisfied?: (obj: any, value?: any) => boolean;
  filterValue?: string | number;
  isPreset?: boolean;
}

export interface FilterField {
  key: string;
  displayName: string;
  filterType: FilterType;
  customType?: string;
  isFilter?: boolean;
}

export interface InputValues {
  [key: string]: string;
}

export enum CustomElementTypes {
  Inputs = 'inputs',
  Headings = 'headings',
  Butttons = 'buttons',
  Images = 'images',
  Links = 'links',
  Forms = 'forms',
  Landmarks = 'landmarks',
  Iframes = 'iframes',
}

export type IsSatisfiedFunc = (item: any, filter: ItemFilter) => boolean;
