export const ACCESSIBILITY_FEATURE = [
  'alternativeText',
  'annotations',
  'audioDescription',
  'bookmarks',
  'braille',
  'captions',
  'ChemML',
  'describedMath',
  'displayTransformability',
  'highContrastAudio',
  'highContrastDisplay',
  'index',
  'largePrint',
  'latex',
  'longDescription',
  'MathML',
  'none',
  'printPageNumbers',
  'readingOrder',
  'rubyAnnotations',
  'signLanguage',
  'structuralNavigation',
  'synchronizedAudioText',
  'tableOfContents',
  'taggedPDF',
  'tactileGraphic',
  'tactileObject',
  'timingControl',
  'transcript',
  'ttsMarkup',
  'unlocked',
];

export const ACCESSIBILITY_HAZARD = [
  'flashing',
  'noFlashingHazard',
  'motionSimulation',
  'noMotionSimulationHazard',
  'sound',
  'noSoundHazard',
  'unknown',
  'none',
];

export const ACCESSIBILITY_API = [
  'AndroidAccessibility',
  'ARIA',
  'ATK',
  'AT-SPI',
  'BlackberryAccessibility',
  'iAccessible2',
  'iOSAccessibility',
  'JavaAccessibility',
  'MacOSXAccessibility',
  'MSAA',
  'UIAutomation',
];

export const ACCESSIBILITY_CONTROL = [
  'fullKeyboardControl',
  'fullMouseControl',
  'fullSwitchControl',
  'fullTouchControl',
  'fullVideoControl',
  'fullVoiceControl',
];

export const ACCESS_MODE = [
  'auditory',
  'chartOnVisual',
  'chemOnVisual',
  'colorDependent',
  'diagramOnVisual',
  'mathOnVisual',
  'musicOnVisual',
  'tactile',
  'textOnVisual',
  'textual',
  'visual',
];

export const ACCESS_MODE_SUFFICIENT = [
  'auditory',
  'tactile',
  'textual',
  'visual',
];

export const ACCESSIBILITY_SUMMARY = ['accessibilitySummary'];
