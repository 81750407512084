import { cognitoDir } from '@equally-ai/auth-service';
import { Button, Container, Grid } from '@mui/material';
import { useAdminAuth } from 'contexts/admin-context';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useLoginPageStyles } from 'hooks/use-login-page-styles';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { LoginWithEmailAndPassword } from 'utils/auth';
import { EMAIL_REGEX, FIELD_AUTH_PASSWORD, FIELD_EMAIL } from 'utils/constants';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX, 'Invalid email')
    .trim()
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues = {
  [FIELD_EMAIL]: '',
  [FIELD_AUTH_PASSWORD]: '',
};

export const LoginPage = () => {
  const { logIn } = useAdminAuth();
  const classes = useLoginPageStyles();
  const { isAuthenticated } = useAdminAuth();
  const navigate = useNavigate();
  const handleSubmit = (details: LoginWithEmailAndPassword) => {
    logIn(details);
  };

  const onGoogleClick = () => {
    window.location.href = cognitoDir.getGoogleUrl();
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    navigate('/domains');
  }, [isAuthenticated]);

  return (
    <Container className={classes.container}>
      <h1>Login to Your Account</h1>
      <Container>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting, submitCount, isValid }) => {
            const disabled = isSubmitting || (submitCount > 0 && !isValid);
            return (
              <Form>
                <Container>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={10}>
                      <Field
                        component={TextField}
                        className={classes.input}
                        required
                        id="email"
                        label="Email"
                        type="email"
                        name={FIELD_EMAIL}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Field
                        component={TextField}
                        className={classes.input}
                        required
                        id="password"
                        name={FIELD_AUTH_PASSWORD}
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={10}>
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        aria-label="Login"
                        onClick={submitForm}
                        disabled={disabled}
                        style={{
                          margin: '30px 0 40px 0',
                          width: '100%',
                        }}
                      >
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            );
          }}
        </Formik>
      </Container>
      <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.or}
          >
            <hr />
            OR
            <hr />
          </Grid>
          <Grid item xs={10}>
            <GoogleLoginButton
              onClick={onGoogleClick}
              className={classes.googleButton}
            />
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
