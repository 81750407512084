import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ConfirmModal } from 'components/confirm-modal';
import { useToaster } from 'contexts/toast-context';
import { sendIntegrationCompleted } from 'store/slice/domain-detail-slice';

interface ConfirmModalProps {
  isVisible: boolean;
  onClose: () => void;
  config: any;
}

const ConfirmModalWrapper = ({
  isVisible,
  onClose,
  config,
}: ConfirmModalProps) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.adminConfig);
  const { setToaster } = useToaster();
  const sendCompleteEvent = async (pathString: string) => {
    const domainID = parseInt(pathString.split('/')[2], 10);
    const response = await dispatch(sendIntegrationCompleted(domainID));
    const result = response.payload as any;
    if (result.isSuccess) {
      setToaster('success', result.data);
    } else {
      setToaster('error', result.error);
    }
    onClose();
  };

  return (
    <ConfirmModal
      visible={isVisible}
      title="Mark Integration Completed?"
      onCancel={onClose}
      okText="Yes, Confirm"
      onOk={() => sendCompleteEvent(config[0].path)}
      confirmLoading={loading}
    >
      Are you sure you want to?
    </ConfirmModal>
  );
};

export const useConfirmModal = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const onClose = () => {
    setModalVisible(false);
  };

  return { ConfirmModalWrapper, modalVisible, setModalVisible, onClose };
};
