import React from 'react';

import { Modal } from '../modal/Modal';
import { Typography } from '@mui/material';
import { DeleteOrCancel } from './save-and-cancel';

interface DeleteModalProps {
  isVisible: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
  container?: Element | null;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isVisible,
  onClose,
  onDelete,
  title,
  container,
}) => {
  return (
    <Modal
      showModal={isVisible}
      handleClose={onClose}
      title={title}
      container={container}
    >
      <Typography variant="subtitle1">
        Are you sure you want to delete this Item?
      </Typography>
      <DeleteOrCancel onClose={onClose} onDelete={onDelete} />
    </Modal>
  );
};

export default DeleteModal;
