import { Box, Chip, Typography } from '@mui/material';
import styled from 'styled-components';
import { ComplianceDocumentWithBusinessDetails } from 'containers/documents-redirect/utils';
import React from 'react';
import { DocumentStatus } from './document-status';

interface DocumentViewProps {
  complianceDocumentData: ComplianceDocumentWithBusinessDetails;
}

export const DocumentView = (props: DocumentViewProps) => {
  const { complianceDocumentData } = props;
  return (
    <Box>
      <DocumentRow>
        <DocumentRowLabel>Business Name:</DocumentRowLabel>
        <Typography>{complianceDocumentData.businessName}</Typography>
      </DocumentRow>
      <DocumentRow>
        <DocumentRowLabel>Domain:</DocumentRowLabel>
        <Typography>{complianceDocumentData.domain}</Typography>
      </DocumentRow>
      <DocumentRow>
        <DocumentRowLabel>URL:</DocumentRowLabel>
        <Typography>{complianceDocumentData.data.url}</Typography>
      </DocumentRow>
      <DocumentRow>
        <DocumentRowLabel>Type:</DocumentRowLabel>
        <Chip
          label={complianceDocumentData.type.replaceAll('-', ' ')}
          variant="outlined"
          sx={{ textTransform: 'capitalize' }}
        />
      </DocumentRow>
      <DocumentRow>
        <DocumentRowLabel>Status:</DocumentRowLabel>
        <DocumentStatus status={complianceDocumentData.status} />
      </DocumentRow>
      <DocumentRow>
        <DocumentRowLabel>Notes:</DocumentRowLabel>
        <Typography>{complianceDocumentData?.notes || 'N/A'}</Typography>
      </DocumentRow>
    </Box>
  );
};

export const DocumentRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const DocumentRowLabel = styled(Typography)`
  font-weight: 600;
  margin-right: 15px;
  width: 100%;
  max-width: 140px;
`;
