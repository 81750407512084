import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface ConfirmModalProps {
  title: string;
  text?: string;
  confirmLoading?: boolean;
  okText?: string;
  cancelText?: string;
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  children: React.ReactNode;
}

export const ConfirmModal = ({
  children,
  title,
  text,
  visible,
  confirmLoading,
  okText,
  cancelText,
  onOk,
  onCancel,
}: ConfirmModalProps) => {
  return (
    <Dialog disableEscapeKeyDown open={visible} onClose={onCancel} fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{title || ''}</Box>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          {cancelText || 'Cancel'}
        </Button>
        <Button
          onClick={onOk}
          color="primary"
          startIcon={confirmLoading && <CircularProgress size={24} />}
          disabled={confirmLoading}
        >
          {okText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
