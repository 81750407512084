import React, { useState, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { UpdateDomainsModal } from './update-domains-modal';
import { Domain } from 'store/slice/domains-slice';

const options = ['expiration'];

interface DomainTableEditButtonProps {
  selectedDomains: Record<number, Domain>;
  handleRemoveSelectedDomain: (domainId: number) => void;
  handleClearAllSelectedDomains: () => void;
}
export const DomainTableEditButton = (props: DomainTableEditButtonProps) => {
  const {
    selectedDomains,
    handleRemoveSelectedDomain,
    handleClearAllSelectedDomains,
  } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [showUpdateDomainPopUp, setShowUpdateDomainPopUp] = useState(false);

  const handleOpenUpdateDomainPopUp = () => {
    setShowUpdateDomainPopUp(true);
  };

  const handleCloseUpdateDomainPopUp = () => {
    setShowUpdateDomainPopUp(false);
  };

  const handleEditButtonClick = () => {
    handleOpenUpdateDomainPopUp();
  };

  const handleMenuItemClick = (
    evt: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedOption(options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (evt: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(evt.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Edit domain table button"
        disabled={Object.entries(selectedDomains).length < 1}
      >
        <Button onClick={handleEditButtonClick} disabled={!selectedOption}>
          Edit
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Select option to edit domain table with"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 5,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={option === selectedOption}
                      onClick={(evt) => handleMenuItemClick(evt, index)}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <UpdateDomainsModal
        showUpdateDomainPopUp={showUpdateDomainPopUp}
        handleCloseUpdateDomainPopUp={handleCloseUpdateDomainPopUp}
        selectedDomains={selectedDomains}
        setSelectedOption={setSelectedOption}
        handleRemoveSelectedDomain={handleRemoveSelectedDomain}
        handleClearAllSelectedDomains={handleClearAllSelectedDomains}
      />
    </>
  );
};
