import { FilterField } from '@equally-ai-front/common/src/components/filters/types';

export const userFields: Record<string, FilterField> = {
  name: {
    key: 'name',
    displayName: 'Full Name',
    filterType: 'string',
  },
  email: {
    key: 'email',
    displayName: 'Email',
    filterType: 'string',
  },
  registrationDate: {
    key: 'registrationDate',
    displayName: 'Registration Date',
    filterType: 'date',
  },
  isTestAccount: {
    key: 'isTestAccount',
    displayName: 'Test Account',
    filterType: 'boolean',
  },
  isSpecialDomain: {
    key: 'isSpecialDomain',
    displayName: 'Special Domain',
    filterType: 'boolean',
  },
};
