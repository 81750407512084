import React, { useState } from 'react';
import { ComplianceDocumentWithBusinessDetails } from 'containers/documents-redirect/utils';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DocumentRow } from './document-view';
import { SubmitButton } from './document-popup';
import {
  ComplianceDocumentStatusEnum,
  UpdateDocumentFormData,
} from '@equally-ai-front/common/src/types/compliance-document';

interface SelectOption {
  value: string;
  label: string;
}

const documentStatusSelectOptions: SelectOption[] = [
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'declined',
    label: 'Declined',
  },
];

const documentTypeSelectOptions: SelectOption[] = [
  {
    value: 'accessibility-statement',
    label: 'Accessibility Statement',
  },
];

interface DocumentEditProps {
  complianceDocumentData: ComplianceDocumentWithBusinessDetails;
  handleEditDocument: (
    evt: React.FormEvent<HTMLFormElement>,
    data: UpdateDocumentFormData,
  ) => Promise<void>;
}

export const DocumentEdit = (props: DocumentEditProps) => {
  const { complianceDocumentData, handleEditDocument } = props;
  const [documentData, setDocumentData] = useState<UpdateDocumentFormData>({
    id: complianceDocumentData.id,
    domain: complianceDocumentData.domain || '',
    data: { url: complianceDocumentData.data.url || '' },
    type: complianceDocumentData.type || '',
    status:
      complianceDocumentData.status || ComplianceDocumentStatusEnum.PENDING,
    notes: complianceDocumentData?.notes || '',
  });

  const handleDocumentDataChange = (
    evt:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
  ) => {
    const { name, value } = evt.target;
    setDocumentData({
      ...documentData,
      [name]: value,
    });
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await handleEditDocument(evt, documentData);
  };

  return (
    <Box mt="20px">
      <form onSubmit={(evt) => handleSubmit(evt)}>
        <DocumentRow>
          <TextField
            type="text"
            name="url"
            value={documentData.data.url}
            onChange={handleDocumentDataChange}
            id="url"
            label="URL"
            variant="outlined"
            fullWidth
            required
          />
        </DocumentRow>
        <DocumentRow>
          <Select
            labelId="type"
            id="type"
            value={documentData.type || ''}
            name="type"
            label="Type"
            onChange={handleDocumentDataChange}
            required
            fullWidth
          >
            {documentTypeSelectOptions.map(({ value, label }, idx) => (
              <MenuItem key={`${value}-${label}-${idx}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </DocumentRow>
        <DocumentRow>
          <Select
            labelId="status"
            id="status"
            value={documentData.status || ''}
            name="status"
            label="Status"
            onChange={handleDocumentDataChange}
            required
            fullWidth
          >
            {documentStatusSelectOptions.map(({ value, label }, idx) => (
              <MenuItem key={`${value}-${label}-${idx}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </DocumentRow>
        <DocumentRow>
          <TextField
            type="text"
            name="notes"
            value={documentData.notes}
            onChange={handleDocumentDataChange}
            id="notes"
            label="Notes"
            variant="outlined"
            fullWidth
            required
          />
        </DocumentRow>
        <SubmitButton variant="contained" color="secondary" type="submit">
          Submit
        </SubmitButton>
      </form>
    </Box>
  );
};
