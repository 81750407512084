import React from 'react';
import { plansTableFields, subscriptionsTableSortFields } from '../consts';

import { PlanWithPlanTemplate } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { TableWrapper } from 'components/css-components';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { Table } from 'containers/business-details-page/components/table/table';

interface PlansTableProps {
  plans: PlanWithPlanTemplate[];
  pageLimit?: number;
}

const DEFAULT_PAGE_LIMIT = 20;
export const PlansTable = (props: PlansTableProps) => {
  const { plans, pageLimit } = props;

  const {
    pageCount,
    currentData: currentPlans,
    handlePageChange,
    handleSort,
    sortOptions,
  } = usePagination(
    plans,
    pageLimit || DEFAULT_PAGE_LIMIT,
    'id',
    subscriptionsTableSortFields,
  );

  return (
    <TableWrapper>
      <Table
        currentData={currentPlans}
        tableFields={plansTableFields}
        tableAriaLabel="Plans table"
        emptyDataText="No Plans Available"
        handleSort={handleSort}
        sortOptions={sortOptions}
      />
      {currentPlans.length > 0 && (
        <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
      )}
    </TableWrapper>
  );
};
