import React, { useState } from 'react';
import {
  LatestDomainScansData,
  LatestDomainScansFieldMap,
} from './websites-table';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DomainDetailsRow } from './domain-details-row';

export interface DomainRowProps {
  latestDomainScansResultMap: LatestDomainScansFieldMap[];
  latestDomainScansDetailsMap: LatestDomainScansFieldMap[];
  latestDomainScansData: LatestDomainScansData;
}

export const DomainRow = (props: DomainRowProps) => {
  const {
    latestDomainScansResultMap,
    latestDomainScansData,
    latestDomainScansDetailsMap,
  } = props;
  const [isDomainDetailsOpen, setIsDomainDetailsOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsDomainDetailsOpen(!isDomainDetailsOpen)}
          >
            {isDomainDetailsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {latestDomainScansResultMap.map((item, index) => (
          <TableCell key={`domains-table-row-${index}`} align={item.align}>
            {item.format(latestDomainScansData, item.key)}
          </TableCell>
        ))}
      </TableRow>
      <DomainDetailsRow
        isDomainDetailsOpen={isDomainDetailsOpen}
        latestDomainScansData={latestDomainScansData}
        latestDomainScansDetailsMap={latestDomainScansDetailsMap}
      />
    </>
  );
};
