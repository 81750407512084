import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  ListSubheader,
} from '@mui/material';

import { AdminWidgetConfigType, MoreRolesConfig } from '../../helpers';

import { ADMIN_CONFIG_FORMS_INITIAL_VALUES } from '../../helpers/templates/adminConfigForm';

import { validationSchema } from '../../helpers/templates/validationSchema';
import {
  ABSTRUCT_ROLES_AREA,
  WIDGET_ROLE_AREA,
  COMPOSITE_USER_INTERFACE_WIDGETS_AREA,
  DOCUMENT_STRUCTURE_AREA,
} from '../../lib';
import { SaveAndCancel } from './save-and-cancel';

interface MoreRolesFormProps {
  onFormSubmit: (newElement: any) => Promise<void>;
  value?: MoreRolesConfig;
  type: AdminWidgetConfigType;
  onClose: () => void;
}

const MoreRolesForm: React.FC<MoreRolesFormProps> = ({
  onFormSubmit,
  value,
  type,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: ADMIN_CONFIG_FORMS_INITIAL_VALUES[type],
    validationSchema: validationSchema[type],
    onSubmit: onFormSubmit,
  });

  useEffect(() => {
    const formValue = value ?? ADMIN_CONFIG_FORMS_INITIAL_VALUES[type];
    formik.setValues(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box my={2}>
        <TextField
          fullWidth
          id="selector"
          name="selector"
          label="El Selector"
          value={formik.values.selector}
          onChange={formik.handleChange}
          error={formik.touched.selector && Boolean(formik.errors.selector)}
          helperText={
            formik.touched.selector && formik.errors.selector?.toString()
          }
        />
      </Box>
      <Box my={2}>
        <FormControl
          fullWidth
          error={formik.touched.role && Boolean(formik.errors.role)}
        >
          <InputLabel>Role</InputLabel>
          <Select
            fullWidth
            id="role"
            name="role"
            label="Role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.touched.role && Boolean(formik.errors.role)}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  maxHeight: '70vh',
                  borderRadius: '8px',
                  top: '117px !important',
                },
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <ListSubheader value="" disableSticky>
              Abstract Roles area
            </ListSubheader>
            {ABSTRUCT_ROLES_AREA.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Widget Roles area
            </ListSubheader>
            {WIDGET_ROLE_AREA.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Composite User Interface Widgets area
            </ListSubheader>
            {COMPOSITE_USER_INTERFACE_WIDGETS_AREA.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
            <ListSubheader value="" disableSticky>
              Document structure area
            </ListSubheader>
            {DOCUMENT_STRUCTURE_AREA.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.role && formik.errors.role?.toString()}
          </FormHelperText>
        </FormControl>
      </Box>
      <SaveAndCancel onClose={onClose} />
    </form>
  );
};

export default MoreRolesForm;
