import {
  Autocomplete,
  Box,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import {
  arrayOfStringsOrNumbersFilterOptions,
  booleanFilterOptions,
  CustomElementsFilterCondition,
  dateFilterOptions,
  numberFilterOptions,
  stringFilterOptions,
} from '../consts';
import React from 'react';
import { FilterCondition, FilterType, ItemFilter } from '../types';
import { DateInputFields, InputFields } from '../input-fields';
import styled from 'styled-components';

interface FilterConditionSelectProps {
  currentFilter: ItemFilter;
  filterValueOptions: any[];
  handleFilterOperatorChange: (
    operator: string,
    filterValue?: string | number,
    isSatisfied?: (obj: any) => boolean,
    isPreset?: boolean,
  ) => void;
  handleFilterValueChange: (key: string, value: string) => void;
  handleFilterSecondValueChange: (key: string, value: string) => void;
  filterOptionType?: 'preset' | 'custom';
}

export const FilterConditionSelect = ({
  currentFilter,
  filterValueOptions,
  handleFilterOperatorChange,
  handleFilterValueChange,
  handleFilterSecondValueChange,
  filterOptionType,
}: FilterConditionSelectProps) => {
  const filterInput = () => {
    if (
      currentFilter.filterCondition === FilterCondition.Exists ||
      currentFilter.filterCondition === FilterCondition.DoesNotExist ||
      currentFilter.filterCondition === FilterCondition.EqualToPreset
    ) {
      return null;
    }
    if (
      currentFilter.filterCondition === FilterCondition.EqualTo ||
      currentFilter.filterCondition === FilterCondition.NotEqualTo
    ) {
      return (
        <Autocomplete
          disablePortal
          onChange={(event, value) => {
            handleFilterValueChange(currentFilter.key, value);
          }}
          id="combo-box-demo"
          options={filterValueOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                dangerouslySetInnerHTML: undefined,
              }}
              label={`Select ${currentFilter.displayName}`}
            />
          )}
        />
      );
    } else if (
      currentFilter.filterType === 'string' ||
      currentFilter.filterType === 'number' ||
      currentFilter.filterType === 'array-of-strings-or-numbers'
    ) {
      return (
        <InputFields
          option={currentFilter}
          handleFilterValueChange={handleFilterValueChange}
          handleFilterSecondValueChange={handleFilterSecondValueChange}
          filter={currentFilter || ''}
        />
      );
    } else if (currentFilter.filterType === 'date') {
      return (
        <DateInputFields
          option={currentFilter}
          handleFilterValueChange={handleFilterValueChange}
          handleFilterSecondValueChange={handleFilterSecondValueChange}
          filter={currentFilter || ''}
        />
      );
    }
    return <> </>;
  };

  let inputs = filterInput();
  const getCurrentFilterOperators = () => {
    if (!currentFilter.filterType) {
      return [];
    }

    if (filterOptionType === 'custom' && currentFilter.customType) {
      return CustomElementsFilterCondition[currentFilter.customType] || [];
    }

    return {
      string: stringFilterOptions,
      number: numberFilterOptions,
      date: dateFilterOptions,
      boolean: booleanFilterOptions,
      'array-of-strings-or-numbers': arrayOfStringsOrNumbersFilterOptions,
    }[currentFilter.filterType as FilterType];
  };

  const handleMenuItemClick = (
    value: string,
    filterValue?: any,
    isSatisfied?: (obj: any) => boolean,
    isPreset?: boolean,
  ) => {
    handleFilterOperatorChange(value, filterValue, isSatisfied, isPreset);
  };

  return (
    <Box>
      {getCurrentFilterOperators().map(
        ({ value, label, filterValue, isSatisfied, isPreset }) => (
          <Box key={value + label}>
            <MenuItem
              style={{ width: '100%' }}
              onClick={(evt) =>
                handleMenuItemClick(value, filterValue, isSatisfied, isPreset)
              }
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                style={{ width: '100%' }}
              >
                <FilterContainer>
                  <Radio checked={currentFilter.filterCondition === value} />
                  <FilterLabel variant={'h5'}>{label}</FilterLabel>
                </FilterContainer>
              </Box>
            </MenuItem>
            <Box
              display={'flex'}
              flexDirection={'column'}
              style={{ width: '83%', marginLeft: '35px' }}
            >
              {currentFilter.filterCondition === value && !isPreset && inputs}
            </Box>
          </Box>
        ),
      )}
    </Box>
  );
};
const FilterLabel = styled(Typography)`
  display: flex;
  justify-content: center;

  &.MuiTypography-h5 {
    font-size: 13px;
    font-weight: 600;
  }
`;
const FilterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
