import graphqlClient from '../utils/graphql.util';
import {
  ComplianceDocumentStatus,
  UpdateDocumentFormData,
} from '../types/compliance-document';
import { ApiResponse } from '../utils/axios';

class ComplianceDocumentsAPI {
  static async getComplianceDocuments(payload: {
    slug: string;
  }): Promise<ApiResponse<any>> {
    const { slug } = payload;
    const query = `
      query getDocuments($payload: Request!) {
        getDocuments(payload: $payload) {
          data
          message
          statusCode
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'getDocuments',
      query,
      payload: {
        data: JSON.stringify({ slug }),
      },
    });

    const { data, isSuccess, message } = result;
    if (!isSuccess) {
      return {
        data: [],
        isSuccess,
        error:
          message || 'Unable to get compliance documents, try again later.',
      };
    }
    return {
      data,
      isSuccess,
      error: null,
    };
  }

  static async submitDocumentForApproval(payload: {
    type: string;
    domain: string;
    url: string;
    slug: string;
    templateId: number;
    status?: ComplianceDocumentStatus;
  }): Promise<ApiResponse<any>> {
    const { type, domain, url, slug, templateId, status } = payload;
    const query = `
      mutation upsertDocument($payload: Request!) {
        upsertDocument(payload: $payload) {
          data
          message
          statusCode
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'upsertDocument',
      query,
      payload: {
        data: JSON.stringify({
          slug,
          document: {
            type,
            domain,
            template_id: templateId,
            status,
            data: {
              url,
            },
          },
        }),
      },
    });

    const { data, isSuccess, message } = result;
    if (!isSuccess || Object.entries(data).length === 0) {
      return {
        data: null,
        isSuccess,
        error: message || 'Unable to submit document, try again later.',
      };
    }
    return {
      data,
      isSuccess,
      error: null,
    };
  }

  static getAllUsersDocuments = async (): Promise<ApiResponse<any>> => {
    const query = `
        query getAllUsersDocuments($payload: Request!) {
            getAllUsersDocuments(payload: $payload) {
                data
                message
                statusCode
            }
        }
    `;

    const result = await graphqlClient.execute({
      functionName: 'getAllUsersDocuments',
      query,
      payload: {
        data: JSON.stringify({}),
      },
    });

    const { data, isSuccess, message } = result;
    if (!isSuccess) {
      return {
        data: [],
        isSuccess,
        error:
          message || 'Unable to get compliance documents, try again later.',
      };
    }
    return {
      data,
      isSuccess,
      error: null,
    };
  };

  static async updateDocument(
    payload: UpdateDocumentFormData & {
      url: string;
      slug: string;
      template_id: number;
    },
  ): Promise<ApiResponse<any>> {
    const {
      id,
      type,
      domain,
      url,
      template_id: templateId,
      slug,
      status,
      notes,
    } = payload;
    const query = `
      mutation upsertDocuments($payload: Request!) {
        upsertDocuments(payload: $payload) {
          data
          message
          statusCode
        }
      }
    `;

    const result = await graphqlClient.execute({
      functionName: 'upsertDocuments',
      query,
      payload: {
        data: JSON.stringify({
          document: {
            id,
            domain,
            type,
            status,
            notes,
            data: {
              url,
            },
            slug,
            template_id: templateId,
          },
        }),
      },
    });

    const { data, isSuccess, message } = result;
    if (!isSuccess || Object.entries(data).length === 0 || !data) {
      return {
        data: null,
        isSuccess,
        error: message || 'Unable to edit document, try again later.',
      };
    }
    return {
      data,
      isSuccess,
      error: null,
    };
  }
}

export { ComplianceDocumentsAPI };
