import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { WebsitesPage } from './websites-page';
import { Audit } from '../../components/audit';

export const WebsitesContainer = () => {
  return (
    <Routes>
      <Route index element={<WebsitesPage />} />
      <Route path={'audit'} element={<Audit />} />
    </Routes>
  );
};
