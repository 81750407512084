import {
  PlanWithPlanTemplate,
  SubscriptionAndPlans,
} from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { UserWithRoles } from 'containers/business-details-page/consts';
import {
  BusinessesTableData,
  getMergedBusinessAndOwner,
} from 'containers/businesses-page/consts';
import {
  getSubscriptionRecords,
  SubscriptionRecord,
} from 'containers/subscriptions-page/consts';
import { User, UserRolesAndRolesIdToRoleMap } from 'store/slice/users-slice';
import { BusinessWithOwner } from 'types';

export const getMergeBusinessUserRolesAndSubscriptions = (
  businesses: BusinessWithOwner[],
  subscriptions: SubscriptionAndPlans[],
  roleMap: UserRolesAndRolesIdToRoleMap,
  users: User[],
): BusinessesTableData[] => {
  const businessesWithOwner = getMergedBusinessAndOwner(businesses);
  const userEmailMap = new Map<number, string>();
  for (const user of users) {
    userEmailMap.set(user.userID, user.email);
  }

  const businessTeamMap = new Map<number, Set<string>>();
  for (const role of roleMap.user_roles) {
    const email = userEmailMap.get(role.user_id);
    if (email) {
      if (!businessTeamMap.has(role.business_id)) {
        businessTeamMap.set(role.business_id, new Set());
      }
      businessTeamMap.get(role.business_id)!.add(email);
    }
  }

  const businessSubscriptionsMap = new Map<number, Set<string>>();
  for (const { subscription, plans } of subscriptions) {
    for (const plan of plans) {
      const businessId = +(plan.business_id || '');
      if (!businessSubscriptionsMap.has(businessId)) {
        businessSubscriptionsMap.set(businessId, new Set());
      }
      businessSubscriptionsMap
        .get(businessId)!
        .add(subscription.transaction_id);
    }
  }

  return businessesWithOwner.map((business) => ({
    ...business,
    teamMemberEmails: Array.from(businessTeamMap.get(business.id) || []),
    subscriptionTransactionIds: Array.from(
      businessSubscriptionsMap.get(business.id) || [],
    ),
  }));
};

export const getUsersWithRoles = (payload: {
  businessId: number;
  roleMap: UserRolesAndRolesIdToRoleMap;
  users: User[];
}): UserWithRoles[] => {
  const { roleMap, users, businessId } = payload;
  if (roleMap.user_roles.length === 0 || users.length === 0 || !businessId) {
    return [];
  }

  const userIdToRoleMap: Record<number, Set<string>> = {};
  const roleIdToNameMap = roleMap.role_id_to_role;
  for (const { user_id, role_id, business_id } of roleMap.user_roles) {
    if (business_id !== businessId) continue;
    if (!userIdToRoleMap[user_id]) {
      userIdToRoleMap[user_id] = new Set();
    }
    userIdToRoleMap[user_id].add(roleIdToNameMap[role_id]);
  }

  const mergedUsersWithRoles = users
    .filter((user) => userIdToRoleMap[user.userID])
    .map((user) => ({
      name: user.name,
      email: user.email,
      roles: Array.from(userIdToRoleMap[user.userID] || []),
    }));

  return mergedUsersWithRoles;
};

export interface SubscriptionRecordsAndPlans {
  subscriptionRecords: SubscriptionRecord[];
  plans: PlanWithPlanTemplate[];
}

export const getSubscriptionRecordsAndPlans = (payload: {
  subscriptions: SubscriptionAndPlans[];
  businessId: number;
}): SubscriptionRecordsAndPlans => {
  const { subscriptions, businessId } = payload;
  const businessPlans = new Map<number, PlanWithPlanTemplate>();
  // To keep track of subscription ids added for the business to prevent duplicates.
  const subscriptionIds = new Set<number>();
  const businessSubscriptions: SubscriptionAndPlans[] = [];

  const currentBusinessPlans = subscriptions.flatMap((subscription) => {
    const filteredCurrentBusinessPlans = subscription.plans.filter(
      (plan) => plan.business_id && +plan.business_id === businessId,
    );

    if (
      filteredCurrentBusinessPlans.length > 0 &&
      !subscriptionIds.has(subscription.subscription.id)
    ) {
      businessSubscriptions.push(subscription);
      subscriptionIds.add(subscription.subscription.id);
    }

    return filteredCurrentBusinessPlans;
  });

  // Save unique plans inside Map to prevent duplicates
  for (const plan of currentBusinessPlans) {
    if (!businessPlans.has(plan.id)) {
      businessPlans.set(plan.id, plan);
    }
  }

  return {
    plans: Array.from(businessPlans.values()),
    subscriptionRecords: getSubscriptionRecords(businessSubscriptions),
  };
};
