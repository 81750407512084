import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DomainDetailService } from 'api/domain-detail-service';
import type { RootState } from 'store';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { AdminWidgetConfigAPI } from '@equally-ai-front/common/src/api/admin-widget-config';

type LoadingStateValues = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface DomainDetail {
  ID: number;
  domainID: number;
  domainName: string;
  accountName: string;
  email: string;
  subscriptionPlan: string;
  expirationDate: number;
  lastPaymentDate: number;
  archived: boolean;
  invalid: boolean;
  active: boolean;
  hasWidget: boolean;
  hasWidgetIntegrated: boolean;
  nextCapture: string;
  paymentSchedule: string;
  autoRenewal: boolean;
  price: string;
  paymentMethod: string;
  userID: number;
  registrationDate: number;
  txID: string;
}

export interface UpdateDomainDetailPayload {
  domainData: DomainDetail;
  domainId: string;
}

interface DomainDetailState {
  loading: LoadingStateValues;
  currentRequestId: string | undefined;
  domainDetail: DomainDetail | null;
}

const initialState: DomainDetailState = {
  loading: 'idle',
  currentRequestId: undefined,
  domainDetail: null,
};

export const getDomainDetail = createAsyncThunk(
  'domain-detail/get',
  async (domainId: string): Promise<ApiResponse<DomainDetail>> => {
    try {
      const { data, error, isSuccess } =
        await DomainDetailService.getDomainDetail(domainId);
      return {
        data,
        error,
        isSuccess,
      };
    } catch (err: any) {
      return {
        data: null,
        error: err?.response?.data?.message ?? 'Error getting domain detail.',
        isSuccess: false,
      };
    }
  },
);

export const updateDomainDetail = createAsyncThunk(
  'domain-detail/update',
  async ({
    domainData,
    domainId,
  }: UpdateDomainDetailPayload): Promise<ApiResponse<DomainDetail>> => {
    try {
      const { data, error, isSuccess } =
        await DomainDetailService.updateDomainDetails({
          domainData,
          domainId,
        });

      return {
        data,
        error,
        isSuccess,
      };
    } catch (err: any) {
      return {
        data: null,
        error: 'Error updating domain detail',
        isSuccess: false,
      };
    }
  },
);

export const sendIntegrationCompleted = createAsyncThunk(
  `adminConfig/sendIntegrationCompleted`,
  async (domainID: number) => {
    const { error, data, isSuccess } =
      await AdminWidgetConfigAPI.sendIntegrationCompleted(domainID);

    if (isSuccess) {
      return { data, isSuccess };
    } else {
      return { error, isSuccess };
    }
  },
);

export const domainDetailSlice = createSlice({
  name: 'domainDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDomainDetail.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getDomainDetail.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.domainDetail = action.payload.data;
        }
      })
      .addCase(getDomainDetail.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(updateDomainDetail.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateDomainDetail.fulfilled, (state, action) => {
        if (state.loading === 'pending' && action.payload) {
          state.loading = 'idle';
          state.domainDetail = action.payload.data;
        }
      })
      .addCase(updateDomainDetail.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(sendIntegrationCompleted.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = action.meta.requestStatus;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(sendIntegrationCompleted.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      })
      .addCase(sendIntegrationCompleted.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'idle';
        }
      });
  },
});

export const selectDomainDetailState = (state: RootState) => state.domainDetail;
export default domainDetailSlice.reducer;
