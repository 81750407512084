import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { SubscriptionAndPlans } from '@equally-ai-front/common/src/types/plans-and-subscriptions';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';
import { BusinessDetailsPage } from 'containers/business-details-page/business-details-page';
import { BusinessesPage } from 'containers/businesses-page/businesses-page';
import { useToaster } from 'contexts/toast-context';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAllSubscriptions } from 'store/slice/subscriptions-slice';
import {
  getAllUserRoles,
  getUsers,
  User,
  UserRolesAndRolesIdToRoleMap,
} from 'store/slice/users-slice';
import {
  getSubscriptionRecordsAndPlans,
  getUsersWithRoles,
} from './utils/business';
import { getAllBusinesses } from 'store/slice/business-slice';
import { BusinessWithOwner } from 'types';
import { getMergedBusinessAndOwner } from 'containers/businesses-page/consts';
import { groupDomainsByBusiness } from 'containers/domains-page/utils/domains';
import { Domain, getDomains } from 'store/slice/domains-slice';

export const BusinessesRedirect = () => {
  const dispatch = useAppDispatch();
  const { setToaster } = useToaster();
  const [loading, setLoading] = useState(false);
  const {
    users,
    userRolesAndRolesIdToRoleMap,
    subscriptions,
    domains,
    businesses,
  } = useAppSelector((state) => ({
    users: state.users.users,
    userRolesAndRolesIdToRoleMap: state.users.userRolesAndRolesIdToRoleMap,
    subscriptions: state.subscriptions.subscriptions,
    domains: state.domains.domains,
    businesses: state.businesses.businesses,
  }));
  const [searchParams] = useSearchParams();
  const businessId = +(searchParams.get('businessId') || '');

  const usersWithRoles = useMemo(() => {
    return getUsersWithRoles({
      businessId,
      roleMap: userRolesAndRolesIdToRoleMap,
      users,
    });
  }, [userRolesAndRolesIdToRoleMap, users, businessId]);

  const subscriptionsAndPlans = useMemo(() => {
    return getSubscriptionRecordsAndPlans({
      subscriptions,
      businessId,
    });
  }, [subscriptions, businessId]);

  const handleFetchBusinessesUsersSubscriptionsAndUserRoles = async () => {
    setLoading(true);
    try {
      const [
        userRolesResponse,
        usersResponse,
        subscriptionsResponse,
        businessesResponse,
        domainsResponse,
      ] = await Promise.all([
        dispatch(getAllUserRoles()),
        dispatch(getUsers()),
        dispatch(getAllSubscriptions()),
        dispatch(getAllBusinesses()),
        dispatch(getDomains()),
      ]);

      const { isSuccess: isUserRolesSuccess, error: userRolesError } =
        userRolesResponse.payload as ApiResponse<UserRolesAndRolesIdToRoleMap>;
      if (!isUserRolesSuccess) {
        setToaster('error', userRolesError);
      }

      const { isSuccess: isUsersSuccess, error: usersError } =
        usersResponse.payload as ApiResponse<User[]>;
      if (!isUsersSuccess) {
        setToaster('error', usersError);
      }

      const { isSuccess: isSubscriptionsSuccess, error: subscriptionsError } =
        subscriptionsResponse.payload as ApiResponse<SubscriptionAndPlans[]>;
      if (!isSubscriptionsSuccess) {
        setToaster('error', subscriptionsError);
      }

      const { isSuccess: isBusinessesSuccess, error: businessesError } =
        businessesResponse.payload as ApiResponse<BusinessWithOwner[]>;
      if (!isBusinessesSuccess) {
        setToaster('error', businessesError);
      }

      const { error: domainsError } = domainsResponse.payload as ApiResponse<
        Domain[]
      >;
      if (domainsError) {
        setToaster('error', domainsError);
      }
    } catch (error) {
      setToaster('error', 'An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  const businessWithOwner = useMemo(() => {
    const business = businesses.find(
      (business) => business.business.id === businessId,
    );
    if (!businessId || !business) {
      return null;
    }

    const [businessAndOwner] = getMergedBusinessAndOwner([business]);
    return businessAndOwner;
  }, [businesses, businessId]);

  const businessWithDomains = useMemo(() => {
    if (!businessWithOwner) {
      return null;
    }

    const [businessWithDomains] = groupDomainsByBusiness(domains, [
      businessWithOwner,
    ]);
    return businessWithDomains;
  }, [domains, businessWithOwner]);

  useEffect(() => {
    void handleFetchBusinessesUsersSubscriptionsAndUserRoles();
  }, []);

  return (
    <>
      <Routes>
        <Route index element={<BusinessesPage />} />
        <Route
          path="/business-details"
          element={
            <BusinessDetailsPage
              usersWithRoles={usersWithRoles}
              subscriptionsAndPlans={subscriptionsAndPlans}
              businessWithOwner={businessWithOwner}
              businessWithDomains={businessWithDomains}
            />
          }
        />
      </Routes>
      <LoadingView open={loading} loadingText="Loading..." zIndex="13000" />
    </>
  );
};
