import React from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useParams } from 'react-router-dom';
import {
  getConfig,
  uploadConfig,
} from '@equally-ai-front/common/src/redux/admin-widget-config-slice';
import { WidgetConfigurations } from '@equally-ai-front/common/src/helpers';
import RenderConfigForm from '@equally-ai-front/common/src/components/admin-widget-config/render-form';
import { useToaster } from 'contexts/toast-context';
import { logError } from 'utils/helpers';
import styled from 'styled-components';
import { LoadingWrapper } from '@equally-ai-front/common/src/components/loading-wrapper/LoadingWrapper';

export const Metadata = () => {
  const { domainId } = useParams();
  const { setToaster } = useToaster();
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.adminConfig);
  const configType = WidgetConfigurations.META_DATA;

  const metadataElements = React.useMemo(
    () => data.metadata as any,
    [data.metadata],
  );

  const updateMetaDataConfig = async (newElement: {
    [key: string]: string;
  }) => {
    try {
      await dispatch(
        uploadConfig({
          domainID: domainId ?? '',
          config: newElement,
          configType,
        }),
      );
      setToaster('success', 'Updated successfully!');
    } catch (err) {
      setToaster('error', 'Failed to update!');
      logError(err);
    }
  };

  React.useEffect(() => {
    if (domainId) {
      dispatch(
        getConfig({
          configType,
          domainID: domainId,
        }),
      );
    }
  }, [domainId, dispatch, configType]);

  return (
    <LoadingWrapper loading={loading} cover>
      <Wrapper>
        <Title>Metadata</Title>
        <RenderConfigForm
          configType={configType}
          onFormSubmit={updateMetaDataConfig}
          values={metadataElements}
          onClose={() => {}}
        />
      </Wrapper>
    </LoadingWrapper>
  );
};

const Wrapper = styled.div`
  width: 95%;
  margin: auto;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 15px 0 25px;
`;
