export type ComplianceDocumentStatus = ComplianceDocumentStatusEnum;

export interface ComplianceDocumentTemplate {
  id: number;
  title: string;
  description: string;
  link: string;
  country: string;
  type: ComplianceDocumentType;
  created_at: Date;
  updated_at: Date;
}

export enum ComplianceDocumentStatusEnum {
  APPROVED = 'approved',
  PENDING = 'pending',
  DECLINED = 'declined',
  DEFAULT = 'default',
}
export enum ComplianceDocumentTypes {
  ACCESSIBILITY_STATEMENT = 'accessibility-statement',
}
export type ComplianceDocumentType = ComplianceDocumentTypes;

export interface ComplianceDocument {
  id: string;
  status: ComplianceDocumentStatus;
  domain: string;
  type: ComplianceDocumentType;
  template_id: number;
  business_id: number;
  notes: string | null;
  data: {
    url: string;
  };
  created_at: string;
  updated_at: string;
}

export interface UpdateDocumentFormData {
  domain: string;
  data: Record<string, string>;
  type: string;
  notes: string;
  status: string;
  id: string;
}

// export interface UpdateDocumentUserRequest{
//
// }
//
// export interface UpdateDocumentAdminRequest{
//
// }

export interface ComplianceDocumentsState {
  isLoading: boolean;
  complianceDocuments: ComplianceDocument[];
}
