import React from 'react';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import MUITable from '@mui/material/Table';
import { TableConfig } from './consts';
import { SortOption } from '@equally-ai-front/common/src/hooks/use-pagination';

interface TableProps {
  currentData: any[];
  tableFields: TableConfig<any>[];
  emptyDataText: string;
  tableAriaLabel: string;
  onRowClick?: (data: any) => void;
  sortOptions: SortOption;
  handleSort: (key: string) => void;
}

export const Table = (props: TableProps) => {
  const {
    currentData,
    tableFields,
    emptyDataText,
    tableAriaLabel,
    onRowClick,
    sortOptions,
    handleSort,
  } = props;

  const handleRowClick = (data: any) => {
    if (!onRowClick) {
      return;
    }
    onRowClick(data);
  };
  return (
    <TableContainer>
      <MUITable
        className="table"
        sx={{ minWidth: 650 }}
        aria-label={tableAriaLabel}
      >
        <TableHead>
          <TableRow>
            {tableFields.map((tableField, index) => {
              const currentSortKey = Object.keys(sortOptions)[0];
              const currentSortOrder = Object.values(sortOptions)[0] || 'desc';
              return (
                <TableCell key={`${tableField.displayName}-${index}`}>
                  <TableSortLabel
                    active={currentSortKey === tableField.key}
                    direction={currentSortOrder}
                    onClick={() => handleSort(String(tableField.key))}
                  >
                    {tableField.displayName}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData.length > 0 ? (
            currentData.map((data, idx) => {
              return (
                <TableRow
                  key={idx}
                  sx={{
                    height: 80,
                    cursor: onRowClick ? 'pointer' : '',
                    '&:last-child td, &:last-child th': {
                      border: '0',
                    },
                  }}
                >
                  {tableFields.map((tableField, index) => {
                    return (
                      <TableCell
                        key={`${tableField.displayName}-${index}`}
                        scope="row"
                        onClick={() => handleRowClick(data)}
                      >
                        {tableField.format ? (
                          tableField.format(data)
                        ) : (
                          <>{data[tableField.key] || 'N/A'}</>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={tableFields.length} className="no-data">
                {emptyDataText}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};
