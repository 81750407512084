import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';
import { useAppSelector } from 'store/hooks';
import { Domain } from 'store/slice/domains-slice';
import styled from 'styled-components';
import { CustomScroll } from 'components/css-components';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatBarChartTooltipText = (inputDate: string): string => {
  const [month, shortYear] = inputDate.split('/').map(Number);

  if (isNaN(month) || isNaN(shortYear)) {
    return 'Invalid Date';
  }

  const currentYear = new Date().getFullYear();
  const fullYear = shortYear + (currentYear - (currentYear % 100));
  const formattedDate = `${months[month - 1]} ${fullYear}`;
  return formattedDate;
};

interface MonthDomainInfo {
  newDomains: number;
  totalDomains: number;
}

interface DomainGraphData {
  [key: string]: MonthDomainInfo;
}

const getDomainsGraphData = (data: Domain[]) => {
  const domainGraphData: DomainGraphData = {};
  const domainsList = [...data];

  domainsList.sort((a, b) => a.registrationDate - b.registrationDate);
  domainsList.forEach((entry, index) => {
    const registrationDate = new Date(entry.registrationDate * 1000);
    const fullYear = registrationDate.getFullYear();
    const shortYear = fullYear.toString().slice(-2);
    const month = registrationDate.getMonth() + 1;
    const key = `${month}/${shortYear}`;

    domainGraphData[key] = domainGraphData[key] || {
      newDomains: 0,
      totalDomains: 0,
    };
    domainGraphData[key].totalDomains++;
  });

  const domainsGraphDataList = Object.entries(domainGraphData);

  return domainsGraphDataList.map(([date, { totalDomains }], index) => {
    const [, previousMonthDomainInfo] =
      domainsGraphDataList[index > 0 ? index - 1 : 0];
    const newDomains = Math.max(
      0,
      totalDomains - previousMonthDomainInfo.totalDomains,
    );

    return {
      date,
      newDomains,
      totalDomains,
    };
  });
};

const getSquarePath = (
  x: number,
  y: number,
  width: number,
  height: number,
  borderRadius: number,
) => {
  return `M${x},${y + borderRadius}
      L${x},${y + height - borderRadius}
      Q${x},${y + height} ${x + borderRadius},${y + height}
      L${x + width - borderRadius},${y + height}
      Q${x + width},${y + height} ${x + width},${y + height - borderRadius}
      L${x + width},${y + borderRadius}
      Q${x + width},${y} ${x + width - borderRadius},${y}
      L${x + borderRadius},${y}
      Q${x},${y} ${x},${y + borderRadius}
      Z`;
};

const RoundedBar = (props: any) => {
  const { fill, x, y, width, height } = props;
  return (
    <path d={getSquarePath(x, y, width, height, 8)} stroke="none" fill={fill} />
  );
};

export const DomainsGraph = () => {
  const { domains } = useAppSelector((state) => state.domains);
  const domainsGraphData = useMemo(() => {
    if (domains.length === 0) {
      return [];
    }

    return getDomainsGraphData(domains);
  }, [domains]);

  if (domains.length === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <BarChart width={800} height={200} data={domainsGraphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 14 }}
          label={{ fontSize: 14 }}
        />
        <YAxis tick={{ fontSize: 14 }} label={{ fontSize: 14 }} />
        <Tooltip
          labelFormatter={(label) => {
            return `${formatBarChartTooltipText(label)}`;
          }}
          formatter={(value, name) => [
            `${
              name === 'newDomains' ? 'New Domains' : 'Total Domains'
            }: ${value}`,
          ]}
        />
        <Bar
          dataKey="newDomains"
          fill="#baefff"
          barSize={25}
          shape={<RoundedBar />}
        >
          <Cell fill="#baefff" />
        </Bar>
        <Bar
          dataKey="totalDomains"
          fill="#115dfc"
          barSize={25}
          shape={<RoundedBar />}
        >
          <Cell fill="#115dfc" />
        </Bar>
      </BarChart>
    </Wrapper>
  );
};

const Wrapper = styled(CustomScroll)`
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  margin: 30px 0 20px;
`;
