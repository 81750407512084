import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import React from 'react';
import {
  ThemeProvider as ThemeProviderMui,
  StyledEngineProvider,
} from '@mui/material/styles';
import { theme } from 'utils/theme';

const globalStyles = {
  body: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
  },
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProviderMui theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={globalStyles} />
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </ThemeProviderMui>
    </StyledEngineProvider>
  );
};
